import React, { useEffect, useMemo } from 'react'
import { useAuth } from '../../../context/AuthContext';
import { resourcesFormValidationSchema } from '../../../functions/validationSchemas';
import { Form, Formik } from 'formik';
import { GenerateFormInput, GenerateSelectInput } from '../../../functions/formik';
import ImageUploader from '../../../components/util/ImageUploader';
import { useAdminToggle } from '../../../context/AdminContext';
import { createResource, updateResource } from '../../../functions/firebase/firestoreResources';
import Swal from 'sweetalert2';


export default function ResourceForm({cancel, resource, isEditing = false, setShowResourceForm, isAdmin}) {

    const types = [
        "video",
        "article",
        "course"
    ];

    const times = [
        "5 minute",
        "10 minute",
        "15 minute",
        "20 minute",
        "30 minute",
        "1 hour",
        "1.5 hour",
        "2 hour",
        "3 hour",
        "4 hour",
        "1 day",
        "2 day",
        "3 day",
        "4 day",
        "5 day",
        "6 day",
        "1 week",
        "2 week",
        "3 week",
        "1 month",
        "2 month",
        "3 month",
        "4 month",
        "5 month",
        "6 month",
        "7 month",
        "8 month",
        "9 month",
        "10 month",
        "11 month",
        "1 year",
        "2 year",
        "3 year",
        "4 year"
    ]

    const auth = useAuth();

    const initialValues = {
        title: resource?.title || "",
        description: resource?.description || "",
        duration: resource?.duration || "",
        type: resource?.type || "",
        userId: resource?.userId || auth.currentUser.uid,
        imageURL: resource?.imageURL || "",
        link: resource?.link || "",
        thumbnail: null,
    };

    function submitPosting(values) {
        Swal.fire({
            title: "Confirmation",
            text: "Your posting will be sent to be reviewed by our admins. We will look over this as soon as we can, and if the post is approved, it will be available for everyone to see.",
            cancelButtonText: "Cancel",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonText: "Confirm Request",
            fontFamily: "DMsans",
            reverseButtons: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    Swal.fire({
                        title: 'Submitting resource request',
                        text: 'Please wait...',
                        allowOutsideClick: false,
                        didOpen: () => {
                        Swal.showLoading();
                        }
                      });

                    const valuesToSubmit = {
                        title: values.title,
                        description: values.description,
                        image: values.thumbnail,
                        imageURL: values.imageURL,
                        type: values.type,
                        userId: values.userId,
                        duration: values.duration,
                        link: values.link,
                        inReview: true,
                    };
                    console.log(valuesToSubmit);
                    await createResource(valuesToSubmit);

                    Swal.fire({
                        title: "Resource request submitted successfully!",
                        icon: "success"
                      }).then((result) => {
                        localStorage.removeItem(auth.currentUser.uid + "resourceForm");
                      });
                } catch (error) {
                    Swal.fire({
                        title: "Could not upload resource",
                        text: "reason: " + error,
                        icon: "error"
                    });
                }
            }
        });
    }

    async function editPosting(values) {
        Swal.fire({
            title: "Confirmation",
            text: "You are about to edit this posting. You cannot undo these changes, however, you can come back anytime to edit again.",
            cancelButtonText: "Cancel",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonText: "Confirm Changes",
            fontFamily: "DMsans",
            reverseButtons: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    Swal.fire({
                        title: 'Updating resource',
                        text: 'Please wait...',
                        allowOutsideClick: false,
                        didOpen: () => {
                        Swal.showLoading();
                        }
                      });

                    const valuesToSubmit = {
                        title: values.title,
                        description: values.description,
                        image: values.thumbnail,
                        imageURL: values.imageURL,
                        type: values.type,
                        userId: values.userId,
                        duration: values.duration,
                        link: values.link,
                        inReview: false,
                        id: resource.id,
                    };
                    console.log(valuesToSubmit);
                    await updateResource(valuesToSubmit);

                    cancel();
                    
                    Swal.fire({
                        title: "Resource updated successfully!",
                        icon: "success"
                      });
                } catch (error) {
                    Swal.fire({
                        title: "Could not upload resource",
                        text: "reason: " + error,
                        icon: "error"
                    });
                }
            }
        });
    }

    function onSubmit(values, actions) {
        if (isEditing) {
            editPosting(values);
        } else {
            submitPosting(values);
        }
    }

    return (
        <div>
            <Formik
            initialValues={initialValues}
            validationSchema={resourcesFormValidationSchema}
            onSubmit={onSubmit}>
                {({ isSubmitting, values, setFieldValue, setValues }) => {

            /**
            * If the form validation doesn't pass, scroll user back to the top (errors automatically display when submitting the form too).
            * 
            * @param {Object} formData - data in the form 
            */
            function goBackToTopIfStillErrors(formData) {
                        // Validate the formData against the schema to check for errors
                        resourcesFormValidationSchema.validate(formData)
                        .catch(error => {
                            // if the end time is required but optionalTime is checked, we don't care
                            console.error(error);
                            // Scroll to the top if errors exist
                            setTouched(true);
                            window.scrollTo({
                            top: 0,
                            behavior: 'smooth', // Optional: for smooth scrolling behavior
                            });
                        });
                    }

                    // Load cached values from localStorage
                    useMemo(() => {
                        if (!isEditing) {
                        const cachedValues = JSON.parse(localStorage.getItem(auth.currentUser.uid + "resourceForm"));
                        if (!isEditing) {
                            if (cachedValues) {
                                setValues(cachedValues);
                            }
                        }
                        }
                    }, []);

                    // Save form values to localStorage upon dismount
                    useEffect(() => {
                        return () => {
                            if (!isEditing) {
                                delete values.thumbnail;
                                localStorage.setItem(auth.currentUser.uid + "resourceForm", JSON.stringify(values));
                            }
                        };
                    }, [values]);

                    return <Form>
                        <div className="flex md:flex-row flex-col mb-2 gap-4">
                            <div className="flex-1">
                                <GenerateFormInput id={0} displayName={"Link"} inputName={"link"} jobPostingValidationSchema={resourcesFormValidationSchema} />
                            </div>
                        </div>
                        <div className="flex md:flex-row flex-col mb-2 gap-4">
                            <div className="flex-1">
                                <GenerateFormInput id={1} displayName="Title" inputName="title" jobPostingValidationSchema={resourcesFormValidationSchema} />
                            </div>
                            <div className="flex-1">
                                <GenerateFormInput id={2} displayName="Description" inputName="description" jobPostingValidationSchema={resourcesFormValidationSchema} />
                            </div>
                        </div>
                        <div className="flex md:flex-row flex-col mb-2 gap-4">
                            <div className="flex-1">
                                <GenerateSelectInput id={3} displayName="Duration" inputName="duration"
                                    options={[
                                    <option key="default" disabled value="">
                                        Please select a duration
                                    </option>,
                                    ...
                                    times.map((currentTime, index) => 
                                        <option key={index} selected={currentTime === values["time"] ? true : undefined}
                                        value={currentTime}>{currentTime + " " + values["type"]}</option> )
                                    ]} jobPostingValidationSchema={resourcesFormValidationSchema}  
                                />
                            </div>
                            <div className="flex-1">
                                <GenerateSelectInput id={4} displayName="Type" inputName="type"
                                    options={[
                                    <option key="default" disabled value="">
                                        Please select a type
                                    </option>,
                                    ...
                                    types.map((currentType, index) => 
                                        <option key={index} selected={currentType === values["type"] ? true : undefined}
                                        value={currentType}>{currentType}</option> )
                                    ]} jobPostingValidationSchema={resourcesFormValidationSchema}  
                                />
                            </div>
                        </div>

                        <ImageUploader displayName="Upload File" inputName="thumbnail" formValues={values} setFieldValue={setFieldValue} />

                        <div className="flex flex-row justify-end gap-4 my-4">
                            <button
                                onClick={cancel}
                                type="button"
                                className="py-1 px-2 border border-blue-500 rounded-md text-blue-500 hover:bg-gray-300"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                onClick={(e) => {
                                goBackToTopIfStillErrors(values);
                                }}
                                className="py-1 px-8 rounded-md bg-blue-500 text-white hover:bg-blue-700"
                            >
                                {!isAdmin ? "Publish" : !isEditing ? "Confirm event" : "Confirm changes"}
                            </button>
                        </div>
                    </Form>
                }}
            </Formik>
        </div>
    )
}
