import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { useToast } from '../../../context/ToastContext';

export default function EmailVerificationInfo({setForm}) {
  const auth = useAuth();
  const { showSuccessToast, showErrorToast, showLoadingToast } = useToast();

  /**
   * The function to send off the email to obtain a new password.
   */
  async function handleSubmitResendEmail() {
      try {
          await auth.emailVerification();
          showSuccessToast("Successfully Resent");
      } catch (error) {
          showErrorToast("Failed to Resend");
          console.error(error);
      }
  }
  
  const errorStyle = 'text-red-500 text-xs pt-1';
  return (
      <div className="max-w-[400px] mx-auto flex flex-col">
        <h1 className="text-center text-2xl font-bold mb-4">Check Your Email</h1>

        <div className="text-center mb-4 text-[#959595] text-sm font-medium">
          We've sent a verification email to <span className='text-black font-bold'>{auth?.currentUser?.email}</span> with a link to activate your account. If you've already verified, refresh the page.
        </div>
        <div className="text-center mb-2 text-sm font-bold">
          Didn't get an Email?
        </div>
        <button
            type="button"
            onClick={() => handleSubmitResendEmail()}
            className="bg-blue-500 text-white rounded-lg px-4 py-2 mb-2 hover:bg-blue-700"
          >
            Resend Verification Email
          </button>

          <div className="mb-4 text-[#959595] text-xs font-bold">
          Want to come back later? Go back to the login
          {' '}
          <button
            type="button"
            onClick={async () => {
              try {
                await auth.signOut();
                setForm("login");
              } catch (error) {
                console.error(error);
              }
            }}
            className="text-primaryBlue font-bold"
          >
            here
          </button>
        </div>
      </div>
  );
}
