import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import UserModal from "./common/UserModal"; // Import the UserModal component
import { signOut } from "firebase/auth";
import { useAuth } from "../context/AuthContext";
import { useToast } from "../context/ToastContext";
import { useUser } from "../context/UserContext";

export default function UserProfileDropdown() {
  const auth = useAuth();
  const user = useUser();
  const navigate = useNavigate();

  const [modalVisible, setModalVisible] = useState(false);
  const { showSuccessToast, showErrorToast, showLoadingToast } = useToast();

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  /**
   *  Signs the user out from their firebase session
   */
  async function handleSignOut() {
    try {
      await signOut(auth);
      showSuccessToast("Sign Out Success");
      navigate("/login");
    } catch (error) {
      showErrorToast();
      console.error(error);
    }
  }

  return (
    <div className="fixed top-16 right-3 mt-2 w-222px rounded-md border bg-white z-50 shadow-lg flex">
      <div className="flex flex-col w-100">
        <div className="p-4">
          <div id="displayName" className="text-sm font-medium">
            {user?.profilePictureURL ? (
              <img
                src={user?.profilePictureURL}
                alt="User Profile"
                className="w-12 h-12 rounded-full"
              />
            ) : (
              <img className="" src="/assets/images/icons/user.svg" alt="" />
          )}        
          <span className="inline-block pt-4">
            {user?.firstName?.charAt(0)?.toUpperCase() + user?.firstName?.slice(1) + " " + user?.lastName?.charAt(0)?.toUpperCase() + user?.lastName?.slice(1)}
          </span>
          </div>
        </div>
        <div className="flex justify-left px-4">
          <button
            onClick={openModal}
            className="font-roboto bg-[#4079E2] hover:bg-primaryBlue text-white px-4 py-1 rounded-lg text-sm transition-all duration-300 ease-in-out"
          >
            View Profile
          </button>
        </div>
        <div className="border-b text-[#959595] w-100 py-2"></div>
        <div className="w-full justify-left px-4 py-3">
          <div className="text-sm font-medium py-2">Account</div>
          <nav>
            <ul>
              <li>
                <Link
                  to="/settings"
                  className="text-[#959595] font-medium w-full text-left py-3 text-sm"
                >
                  Settings & Privacy
                </Link>
              </li>
              {/* TODO: Link to page */}
              <li>
                <Link
                  to="/settings/help"
                  className="text-[#959595] font-medium w-full text-left py-3 text-sm"
                >
                  Help
                </Link>
              </li>
              {/* TODO: Link to page */}
              <li>
                <Link
                  to="/settings/language"
                  className="text-[#959595] font-medium w-full text-left py-3 text-sm"
                >
                  Language
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="border-b text-[#959595] w-100 py-2"></div>
        <button
          onClick={handleSignOut}
          className="text-[#959595] font-medium w-full text-left px-4 py-3 text-sm"
        >
          Sign out
        </button>
      </div>
      {modalVisible && (
        <UserModal key={user.uid} user={user} handleClose={closeModal} />
      )}
    </div>
  );
}
