import React, { useEffect, useState } from 'react'
import NotificationCard from './includes/NotificationCard';
import { clearNotifications, getUnreadNotificationCount } from '../../functions/firebase/firestoreNotifications';
import { realtimeNotifications } from '../../functions/realtime/realtimeService';
import { useAuth } from '../../context/AuthContext';
import Swal from 'sweetalert2';
import NotificationSignInPopup from './includes/NotificationSignInPopup';
import { getAuth } from 'firebase/auth';


export default function NotificationsPanel({close, toggleNotificationsPanel, isNotificationsPanelOpen, setUnreadNotificationCount, notifications}) {

    const [activeTab, setActiveTab] = useState('All');
    const [filteredNotifications, setFilteredNotifications] = useState([]);
    const auth = getAuth();

    function handleClose() {
      close();
    }

    // could just raise state here actually
    useEffect(() => {
      if (auth?.currentUser) {
        const count = getUnreadNotificationCount(auth.currentUser.uid).then(count => setUnreadNotificationCount(count))
        .catch(error => console.log(error));
        setUnreadNotificationCount(count);
      }
    }, [notifications]);

    useEffect(() => {
      if (activeTab === "All") {
        setFilteredNotifications(notifications);
      } else if (activeTab === "Unread") {
        setFilteredNotifications(notifications.filter(notification => !notification.hasRead));
      } else if (activeTab === "Mentions") {
        setFilteredNotifications(notifications.filter(notification => notification.type === "mention"));
      }
    }, [activeTab, notifications]);

    const customClass = {
      confirmButton: "max-w-[200px]",
    }

    function handleClear() {
        try {
            Swal.fire({
                title: "Are you sure you want to delete all notifications?",
                text: "All your notifications will be deleted forever and this cannot be undone!",
                icon: "warning",
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonColor: "red",
                confirmButtonText: "Delete all",
                customClass: customClass,
            }).then(async result => {
                if (result.isConfirmed) {
                    await clearNotifications(notifications);
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

    return (
      <>
        {auth?.currentUser ?
          <div
              className={`z-40 chat-scroll px-6 pt-4 font-dmsans fixed top-16 ${
                isNotificationsPanelOpen ? 'right-0' : '-right-[100%]'
              } sm:w-96 w-full max-w-[422px] h-[calc(100vh-64px)] border bg-white overflow-y-auto transi duration-500 ease-in-out`}
            >
            <div className="py-1 flex flex-row justify-between items-center ">
              <h1 className="font-medium text-2xl">Notifications</h1>
              <img className="cursor-pointer" onClick={handleClose} src="/assets/images/icons/cancel.svg" alt="Cancel"/>
            </div>
            <div className="flex text-xs my-4 justify-between">
              <div className="flex gap-x-2">
                <button
                  type="button"
                  onClick={() => setActiveTab('All')}
                  className={`rounded text-c enter transition-all duration-300 ease-in-out ${
                    activeTab === 'All' ? 'bg-blue-500 text-white px-4 py-2' : 'bg-blue-0 px-3 py-1.5 hover:text-blue-500'
                  }`}
                >
                  All
                </button>
                <button
                  type="button"
                  onClick={() => setActiveTab('Unread')}
                  className={`rounded text-center transition-all duration-300 ease-in-out ${
                    activeTab === 'Unread' ? 'bg-blue-500 text-white px-4 py-2' : 'bg-blue-0  px-3 py-1.5 hover:text-blue-500'
                  }`}
                >
                  Unread
                </button>
                <button
                  type="button"
                  onClick={() => setActiveTab('Mentions')}
                  className={`rounded text-center transition-all duration-300 ease-in-out ${
                    activeTab === 'Mentions' ? 'bg-blue-500 text-white px-4 py-2' : 'bg-blue-0 px-3 py-1.5 hover:text-blue-500'
                  }`}
                >
                  Mentions
                </button>
              </div>
              <button className='text-blue-500 hover:text-blue-900 transition-colors rounded-md px-2 py-1' onClick={handleClear}>Delete All</button>
            </div>
            <div className="pr-1 py-2 flex flex-col max-h-[calc(85vh-64px)] pb-20">
              {filteredNotifications.map((notification) => (
                <NotificationCard key={notification.id} notification={notification} />
              ))}
            </div>
          </div>
          :
          <NotificationSignInPopup close={close} isOpen={isNotificationsPanelOpen} />
        }
      </>
    )
    
}
