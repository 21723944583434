import React, { useEffect, useState } from 'react';
import CommentItem from './CommentItem';
import { getPostComment } from '../../../functions/firebase/firestorePosts';
import { getUser } from '../../../functions/firebase/firestoreUsers';
import HighLevelCommet from './HighLevelCommet';

export default function Comments({ post, comments, setComments }) {

  return (
    <div className="">
      {comments.map((comment, index) => (
        <div key={index}>
          <HighLevelCommet post={post} comment={comment} setComments={setComments}/>
        </div>
      ))}
    </div>
  );
}