import React, { useState } from 'react';
import {
  ErrorMessage, Field, Form, Formik,
} from 'formik';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { loginValidationSchema } from '../../../functions/validationSchemas';
import { useAuth } from '../../../context/AuthContext';
import { useToast } from '../../../context/ToastContext';

function LoginForm({ setForm }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const {signInWithEmailPassword, signInWithGoogle, 
          signInWithFacebook, signInWithLinkedIn, currentUser} = useAuth();
  const { showSuccessToast, showErrorToast, showLoadingToast } = useToast();

  /**
   * Handles the submit functionality for signing in with email and password.
   * 
   * @param {Object} values - sign in values (email and password)
   */
  const handleSubmit = async (values) => {
    try {
      await signInWithEmailPassword(values.email, values.password);
      showSuccessToast('Successfuly Signed In');
    } catch (error) {
      showErrorToast("Failed to Sign In");
      console.log("Failed to sign in with email and password");
    }
  };

  /**
   * Handles the sign in functionality for signing in with google.
   */
  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
      showSuccessToast('Successfuly Signed In');
    } catch (error) {
      showErrorToast("Failed to Sign In");
      console.log("Failed to sign in with google");
    }
  }

  /**
   * Handles the sign in functionality for signing in with facebook.
   */
  const handleFacebookSignIn = async () => { 
    try {
      await signInWithFacebook();
      showSuccessToast('Successfuly Signed In');
    } catch (error) {
      showErrorToast("Failed to Sign In");
      console.error(error);
    }
  }

  /**
   * Handles the sign in functionality for signing in with linkedin.
   */
  const handleLinkedInSignIn = async () => {
      try {
        await signInWithLinkedIn();
      } catch (error) {
        alert("Failed to sign in with linkedin due to the following: " + error);
      }
  }

  // styles
  const errorStyle = 'text-red-500 text-xs pt-1';
  const socialBtnStyle = 'mr-5 border border-[#BEBEBE] rounded-md w-[38px] h-[32px] hover:shadow-md transition-all duration-300 ease-in-out';
  return (
    <div className="max-w-[244px] mx-auto">
      <h1 className="text-center text-2xl font-bold mb-8">Sign in</h1>

      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={loginValidationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <div className="relative mb-4">
              <label className="absolute -top-2 left-5 px-1 bg-white text-[#959595] text-xs font-medium">
                Email
              </label>
              <Field
                type="email"
                name="email"
                className="w-full p-2 border border-[#d8d8d8] rounded-xl focus:ring-primaryBlue focus:border-primaryBlue"
              />
              <div className={errorStyle}>
                <ErrorMessage name="email" />
              </div>
            </div>

            <div className="relative mb-6">
              <label className="absolute z-10 -top-2 left-5 px-1 bg-white text-[#959595] text-xs font-medium">
                Password
              </label>
              <div className="relative">
                <Field
                  type={showPassword ? "text" : "password"}
                  name="password"
                  className="w-full p-2 border border-[#d8d8d8] rounded-xl focus:ring-primaryBlue focus:border-primaryBlue"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2"
                >
                  {showPassword ? (
                    <FaRegEyeSlash color="#939393" />
                  ) : (
                    <FaRegEye color="#939393" />
                  )}
                </button>
              </div>
              <div className={errorStyle}>
                <ErrorMessage name="password" />
              </div>
            </div>

            <button
              type="submit"
              className="w-full text-sm bg-primaryBlue text-white p-2 rounded-full mb-5 hover:shadow-md transition-all duration-300 ease-in-out"
            >
              Continue
            </button>
          </Form>
        )}
      </Formik>

      <div className="">
        <div className="relative before:absolute before:content-[''] before:w-[calc(50%-3rem)] before:h-[1px] before:bg-[#959595] before:left-0 before:top-1/2 before:-translate-y-1/2 after:absolute after:content-[''] after:w-[calc(50%-3rem)] after:h-[1px] after:bg-[#959595] after:right-0 after:top-1/2 after:-translate-y-1/2 text-center text-sm text-[#959595] font-medium mb-5">
          Sign in with
        </div>

        <div className="flex justify-left my-8">
          {/* <button type="button" className={socialBtnStyle}>
              <img
                onClick={handleFacebookSignIn}
                src="/assets/images/icons/facebook.png"
                alt="facebook icon"
                className="w-full h-full object-contain object-center mx-auto p-1"
              />
            </button> */}
          <button type="button" className={socialBtnStyle}>
            <img
              data-testid="google-signin-button"
              onClick={handleGoogleSignIn}
              src="/assets/images/icons/google.png"
              alt="google icon"
              className="w-full h-full object-contain object-center mx-auto p-1"
            />
          </button>

          {/* Not yet supported */}
          {/* <button onClick={handleLinkedInSignIn} type="button" className={socialBtnStyle}>
              <img
                src="/assets/images/icons/linkedin.png"
                alt="linkedin icon"
                className="w-full h-full object-contain object-center mx-auto p-1"
              />
            </button> */}
          {/* <button type="button" className={socialBtnStyle}>
              <img
                src="/assets/images/icons/apple.png"
                alt="apple icon"
                className="w-full h-full object-contain object-center mx-auto p-1"
              />
            </button> */}
        </div>
      </div>

      <div className="mb-4 text-[#959595] text-xs font-bold">
        Don't have an account?{" "}
        <button
          type="button"
          onClick={() => setForm("signup")}
          className="text-primaryBlue font-normal"
        >
          Sign up
        </button>
      </div>

      <div
        onClick={() => setForm("forgotPassword")}
        className="mb-4 text-[#959595] text-xs font-bold"
      >
        Forgot password?{" "}
        <span className="text-primaryBlue font-normal cursor-pointer">
          Click here
        </span>
      </div>

        <div className="text-[#959595] text-xs">
          By creating your account, you agree to the
          <a href="/tos" className="font-bold underline">
            {' '}
            Terms of Service
          </a>
          {' '}
          and
          <a href="/privacy" className="font-bold underline">
            {' '}
            Privacy Policy
          </a>
        </div>
      </div>
  );
}

export default LoginForm;
