import React, { useEffect, useRef, useState, useLayoutEffect } from 'react'
import Rodal from 'rodal'
import EmojiButton from '../../../components/common/EmojiButton';

export default function PostRodal({contentRef, isEditing, handleTagRemove, allTags, handleTagSelect, tagColors, setPost, post, visible, setDropdownVisible, dropdownRef, selectedTags, isDropdownVisible, setVisible, user, handleSubmitPost, handleFileUpload, handleOnChange, fileInputRef, handleRemoveImage, adjustHeight={adjustHeight}, emojiIsActive={emojiIsActive}, setEmojiIsActive, handleEmojiClicked, setFocusedInput}) {

  function handleClose() {
    setEmojiIsActive(false);
    setVisible(false);
  }

  return (
      <div className="sm:block hidden">
        <Rodal visible={visible} onClose={() => handleClose()}>
          <div className="font-roboto p-4">
            <div className="flex items-center gap-x-2">
        
              <img
                className="rounded-full bg-gray-100 w-12 h-12"
                src={user?.profilePictureURL ? user?.profilePictureURL : "./assets/images/community/placeholder-user.svg"}
                alt="User"
              />
            
              <div>
                <p className="text-sm font-bold">
                  {user?.firstName?.charAt(0)?.toUpperCase() + user?.firstName?.slice(1) + " " + user?.lastName?.charAt(0)?.toUpperCase() + user?.lastName?.slice(1)},
                  {' '}
                  <span className="font-normal text-[#959595]">
                    {user?.role[0]}
                  </span>
                </p>
                <div
                  className="text-[#939393] flex flex-wrap py-1 items-center gap-x-1 cursor-pointer"
                  onClick={() => setDropdownVisible((prev) => !prev)}
                >
                  <img src="/assets/images/icons/globe.svg" alt="" />
                  <p className="font-bold text-sm">Tags</p>
                  <img
                    className={`${
                      isDropdownVisible ? 'rotate-180' : 'rotate-0'
                    } transition-all duration-300 ease-in-out`}
                    src="/assets/images/icons/arrow-down.svg"
                    alt=""
                  />

                  <div className="flex flex-wrap gap-2 relative">
                    {selectedTags.slice(0, 3).map((tag) => (
                      <span
                        key={tag}
                        className={`${
                          tagColors[tag]
                            ? `${tagColors[tag]} text-white`
                            : 'bg-gray-200'
                        } rounded px-2 py-1 text-xs`}
                      >
                        {tag}
                      </span>
                    ))}
                    {selectedTags.length > 3 && (
                      <span className="bg-gray-200 rounded px-2 py-1 text-xs cursor-pointer hover:bg-gray-300 group">
                        +
                        {selectedTags.length - 3}
                        <div className="absolute top-7 left-0 mt-2 w-48 p-4 flex flex-wrap gap-2 bg-white border rounded shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 ease-in-out">
                          {selectedTags.slice(3).map((tag) => (
                            <span
                              key={tag}
                              className={`w-fit mt-1 ${
                                tagColors[tag]
                                  ? `${tagColors[tag]} text-white`
                                  : 'bg-gray-200'
                              } rounded px-2 py-1 text-xs`}
                            >
                              {tag}
                            </span>
                          ))}
                        </div>
                      </span>
                    )}
                  </div>
                </div>
                <div ref={dropdownRef} className="relative w-full">
                  {isDropdownVisible && (
                    <div className="z-10 absolute top-100 left-0 w-[198px] bg-[#FAFAFA] shadow rounded-md">
                      <img
                        onClick={() => setDropdownVisible(false)}
                        className="ml-auto cursor-pointer"
                        src="/assets/images/icons/close-default.svg"
                        alt=""
                      />
                      <div className="flex flex-wrap border-b rounded-md px-2 py-1">
                        {selectedTags.length === 0 && (
                          <span className="text-[#959595] text-xs p-2">
                            Select tags
                          </span>
                        )}
                        {selectedTags.map((tag) => (
                          <span
                            key={tag}
                            className={`${
                              tagColors[tag]
                                ? `${tagColors[tag]} text-white`
                                : 'bg-gray-200'
                            } rounded px-2 py-1 text-xs flex w-max m-1`}
                          >
                            {tag}
                            <button
                              type="button"
                              onClick={() => handleTagRemove(tag)}
                              className="ml-1 cursor-pointer"
                            >
                              <img
                                src="/assets/images/icons/close-default-white.svg"
                                alt=""
                              />
                            </button>
                          </span>
                        ))}
                      </div>
                      <div className="rounded-md z-10 mt-2">
                        {allTags
                          .filter((tag) => !selectedTags.includes(tag))
                          .map((tag) => (
                            <div
                              key={tag}
                              onClick={() => handleTagSelect(tag)}
                              className="font-bold text-xs cursor-pointer py-2 px-4 rounded hover:bg-[#959595] hover:text-white transition-all duration-300 ease-in-out"
                            >
                              {tag}
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <form className="mt-4" onSubmit={handleSubmitPost}>
              <div className="">
                <input
                  onFocus={() => setFocusedInput("title")}
                  required
                  type="text"
                  id="title"
                  name="title"
                  value={post.title}
                  onChange={handleOnChange}
                  placeholder="Add Title"
                  className="focus:ring-0 mt-1 px-2 py-1 w-full border-none rounded-md placeholder:text-[#959595] font-semibold"
                />
              </div>
              <div className="mb-4 pb-2 border-b border-[#959595]">
              <textarea 
                  onFocus={() => setFocusedInput("content")}
                  ref={contentRef}
                  required
                  onChange={(e) => {
                    handleOnChange(e);
                    adjustHeight(e.target);
                  }}
                  value={post.content}
                  id="content"
                  name="content"
                  placeholder="What’s on your mind?"
                  className="focus:ring-0 px-2 w-full border-none rounded-md"
                  style={{
                    whiteSpace: 'pre-wrap', // Preserve whitespace and line breaks
                    height: 'auto',
                    maxHeight: '50vh',
                    minHeight: '350px', // Set a minimum height if needed
                    overflowY: post.content.split('\n').length > 9 ? 'auto' : 'hidden', // Toggle overflow based on number of lines
                  }}
                />

                <div className="relative">
                  {post.imagePreview !== null && (
                    <div className="relative inline-block">
                      <img src={post.imagePreview} alt="Image Preview" width={300} height={300} />
                      <button onClick={handleRemoveImage} className="absolute top-0 right-0 p-2 text-white rounded-full">
                        <img src="./assets/images/icons/cancel.svg" />
                      </button>
                    </div>
                  )}
                </div>
              </div>

              <div className="flex items-center mt-2">
                <div className="">
                

                  {/* image upload */}
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: 'none'}}
                    onChange={handleOnChange}
                    ref={fileInputRef}
                  />

                  
                  <>
                  {
                    !isEditing && 
                    <button
                      onClick={handleFileUpload}
                      type="button"
                      className="p-1 rounded-lg hover:shadow-md transition-all duration-300 ease-in-out"
                    >
                      <img src="/assets/images/icons/image.svg" alt="" />
                    </button>
                  }

                    <EmojiButton setEmojiIsActive={setEmojiIsActive} emojiIsActive={emojiIsActive} isComment={false} handleEmojiClicked={handleEmojiClicked}/>
                    
                    {/* analytic button */}
                    <button
                        type="button"
                        className="p-1 rounded-lg hover:shadow-md transition-all duration-300 ease-in-out ml-1"
                    >
                        {/* <img src="/assets/images/icons/poll.svg" alt="" /> */}
                    </button>
                  </>
                  

                </div>
                <button
                  type="submit"
                  className="text-sm ml-auto px-3 py-1 shadow-lg bg-blue-500 text-white rounded-md"
                >
                  {!isEditing ? "Post" : "Save changes"}
                </button>
              </div>
            </form>
          </div>
        </Rodal>
      </div>
  )
};