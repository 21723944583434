import React, { useEffect, useState } from 'react';
import LoginForm from './includes/LoginForm';
import PersonalInfoForm from './includes/PersonalInfoForm';
import SignupForm from './includes/SignupForm';
import EmailVerificationInfo from './includes/EmailVerificationInfo';
import { useAuth } from '../../context/AuthContext';
import { useUser } from '../../context/UserContext';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ForgotPassword from './includes/ForgotPassword';
import Header from "../../components/Header";
import { Helmet } from 'react-helmet';
import { signOut } from 'firebase/auth';


export default function Login({isPopup = false, closePopup}) {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page');

  const auth = useAuth();
  const user = useUser();
  const navigate = useNavigate();
  const [form, setForm] = useState(page && page !== "personalInfo" ? page : 'login');

  useEffect(() => {
    if (auth?.currentUser && user !== null && auth?.currentUser?.emailVerified) {
      if (isPopup) {
        closePopup();
      } else {
        navigate("/explore");
      }
    } else if (auth?.currentUser && auth.currentUser.emailVerified) {
      setForm("personalInfo");
    } else if (auth?.currentUser && !auth.currentUser.emailVerified) {
      setForm("emailVerificationInfo");
    }
  }, [auth?.currentUser, user, navigate]);

  return (
      <>
        {
          !isPopup &&
          <>
            <Header isSignUpPage={true} />
            <Helmet>
              <title>UX Was Here - Login</title>
            </Helmet>
          </>
        }
        <div className={!isPopup ? "bg-[#FBFBFD] h-screen" : "py-[125px]"}>
          <div className={!isPopup ? "font-roboto flex justify-center items-center h-screen" : "h-full"}>

            <div className={!isPopup && form !== "personalInfo" ? "bg-white px-4 md:py-24 py-16 rounded-2xl shadow-primaryCardShadow w-full max-w-[551px]" : "flex h-full items-center"}>
                {form ==='login' &&<LoginForm setForm={setForm} />}
                {form === 'signup' && <SignupForm setForm={setForm} />}
                {form === 'emailVerificationInfo' && <EmailVerificationInfo setForm={setForm}/>}
                {form === 'personalInfo' && <PersonalInfoForm setForm={setForm} />}
                {form === 'forgotPassword' && <ForgotPassword setForm={setForm} />}
            </div>
          </div>
      </div>
      </>  
  );
}
