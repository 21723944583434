import React, { useEffect, useState } from 'react';
import UserCard from '../user/UserCard';
import { useUser } from '../../context/UserContext';
import SearchFilter from '../../pages/jobs/SearchFilter';
import ChatBox from './ChatBox';

function ChatPanel({
  chats, requests, onSelectChatId, isChatPanelOpen, toggleChatPanel
}) {
  const [activeTab, setActiveTab] = useState('inbox');
  const [searchQuery, setSearchQuery] = useState('');

  const filteredChats = chats.filter((chat) => chat.name.toLowerCase().includes(searchQuery.toLowerCase()));

  const filteredRequests = requests.filter((request) => request.name.toLowerCase().includes(searchQuery.toLowerCase()));

  function handleClose() {
    toggleChatPanel();
  }

  const maxMessageSize = 80; 

  return (
    <div
      className={`z-50 chat-scroll lg:px-6 px-2 pb-10 font-dmsans fixed top-16 ${
        isChatPanelOpen ? 'right-0' : '-right-[100%]'
      } sm:w-96 w-full max-w-[422px] h-[calc(100vh-64px)] border-l bg-white overflow-y-auto transi duration-500 ease-in-out px-4`}
    >
      <div className="py-1 flex-row gap-4">
        <div className="flex justify-between my-2">
          <h1 className="text-xl">Messages (Coming Soon)</h1>
          <img className="cursor-pointer" onClick={handleClose} src="/assets/images/icons/cancel.svg" alt="Cancel"/>
        </div>
      </div>

      <div className="relative flex items-center gap-x-2 py-2">
        <input
          onChange={(e) => setSearchQuery(e.target.value)}
          type="text"
          placeholder="Search Messages"
          className="text-[#959595] placeholder:text-[#959595] w-full ps-8 pe-2 py-1 text-sm border-gray-200 rounded"
        />
        <img
          src="/assets/images/icons/search.svg"
          alt=""
          className="absolute left-1"
        />
        <button>
          <img
            className="border rounded py-2 px-4 hover:border-primaryBlue transition-all duration-300 ease-in-out"
            src="/assets/images/icons/pencil-grey.svg"
            alt="pencil icon"
          />
        </button>
      </div>

      {/* Chat List */}
      {activeTab === 'inbox'
        && filteredChats.map((chat) => (
          <div
            key={chat.id}
            onClick={() => {
              console.log(chat);
              onSelectChatId(chat.id);
            }}
            className="p-2 cursor-pointer hover:bg-primarySlateMedium rounded transition-all duration-300 ease-in-out max-h-20 min-h-20 flex"
          >
            <div className="flex items-center gap-x-2">
              <div className="w-10 min-w-10 h-10 bg-gray-700 rounded-full" />
              <div className="">
                <p className="font-bold text-sm">{chat.name}</p>
                <p className="text-gray-600 text-sm">{chat.message.slice(0, maxMessageSize)}{chat.message.length > maxMessageSize ? "..." : ""}</p>
              </div>
            </div>
          </div>
        ))}

      {/* {activeTab === 'requests'
        && filteredRequests.map((chat) => (
          <div key={chat.id} className="flex justify-between p-2">
            <div className="flex items-center gap-x-2">
              <div className="w-10 h-10 bg-gray-700 rounded-full" />
              <div className="">
                <p className="font-bold text-sm">{chat.name}</p>
                <p className="text-gray-600 text-sm">{chat.message}</p>
              </div>
            </div>
            <div className="flex items-center gap-x-2">
              <button className="bg-gray-100 rounded-full p-1 hover:shadow">
                <img
                  src="/assets/images/icons/check-green.svg"
                  alt="check icon"
                />
              </button>
              <button className="bg-gray-100 rounded-full p-1 hover:shadow">
                <img
                  src="/assets/images/icons/close-red.svg"
                  alt="close icon"
                />
              </button>
            </div>
          </div>
        ))} */}
    </div>
  );
}

export default ChatPanel;
