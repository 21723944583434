import React, { useState } from 'react'
import { IoIosArrowUp } from 'react-icons/io';
import { MdEvent, MdShare, MdWork } from 'react-icons/md';
import LoginModal from './util/LoginModal';
import { useAuth } from '../context/AuthContext';
import JobForm from '../pages/jobs/JobForm';
import { useParams } from 'react-router-dom';
import EventForm from '../pages/events/includes/EventForm';
import ResourceForm from '../pages/resources/includes/ResourceForm';


export default function CardPosting({formType, isAdmin = false}) {

    const [showForm, setShowForm] = useState(false);

    const [showLoginModal, setShowLoginModal] = useState(false);
    const auth = useAuth();
    const { id } = useParams();
  
    function openSignInModal() {
      setShowLoginModal(true);
    }

    function cancelJobPosting() {
        setShowForm((prev) => !prev);
    }

  return (
    <div>
           {
              !isAdmin && !id &&
              <div className="bg-white rounded-md border-gray-200 border">
                <div className="flex md:flex-row flex-col py-4 px-10">
                  <div className="md:pr-40">
                    <h1 className="font-medium text-lg pb-2 flex justify-items-center items-center">
                        {
                            formType === "job" &&
                            <>
                                <MdWork size={24} className="mr-2" /> {/* Adjust the size and margin as needed */}
                                <span>Looking to hire?</span>
                            </>
                        }
                        {
                            formType === "event" &&
                            <>
                                <MdEvent size={24} className="mr-2" /> {/* Adjust the size and margin as needed */}
                                <span>Share an event</span>
                            </>
                        }
                        {
                          formType === "resource" &&
                          <>
                                <MdShare size={24} className="mr-2" /> {/* Adjust the size and margin as needed */}
                                <span>Share a Resource</span>
                            </>
                        }
    
                    </h1>
                    {
                        formType === "job" &&
                        <p className="text-sm">Find your next candidate directly from a community of UX enthusiasts.</p>
                    }
                    {
                        formType === "event" &&
                        <p className="text-sm">Do you know any events that UX people need to be aware of?</p>
                    }
                    {
                      formType === "resource" &&
                      <p className="text-sm">Got any media to share?</p>
                    }
                  </div>
                  <button
                    onClick={() => {
                      if (!auth.currentUser || !auth.currentUser.emailVerified) {
                        openSignInModal();
                        return;
                      }

                      if (showForm) {
                        cancelJobPosting();
                      } else {
                        setShowForm(true);
                      }
                    }}
                    className="text-center rounded-md bg-blue-500 md:w-72 w-full text-white self-center md:mt-0 mt-4 py-2 cursor-pointer hover:bg-blue-700 flex items-center justify-center"
                  >
                    {!showForm ? (
                      formType === "job" ? "Post a job" :
                      formType === "event" ? "Post an event" :
                      formType === "resource" ? "Post a resource" :
                      ""
                    ) : (
                      <>
                        <IoIosArrowUp className="mr-2" />
                        Cancel Posting
                      </>
                    )}
                  </button>
              </div> 
              
              {
                showForm &&
                <div className="py-2 px-8">
                    {
                        formType === "job" && <JobForm id={1} cancel={() => cancelJobPosting()} setShowJobPostForm={setShowForm}/> 
                    }
                    {
                        formType === "event" && <EventForm id={1} cancel={() => cancelJobPosting()} setShowEventPostForm={setShowForm} />
                    }
                    {
                      formType === "resource" && <ResourceForm id={1} cancel={() => cancelJobPosting()} setShowResourceForm={setShowForm} />
                    }
                </div>
              }
            </div>
            }
            <LoginModal setIsOpen={setShowLoginModal} modalIsOpen={showLoginModal} />
    </div>
  )
}
