import React, { useState } from 'react';

export default function SearchFilter({ searchTerm, setSearchTerm, name }) {
  const [value, setValue] = useState(searchTerm);

  // Update local value state and parent component's searchTerm state on change
  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue); // Update local state
    setSearchTerm(newValue); // Update parent component's searchTerm state
  };

  const handleInputClick = () => {
    // Check if the input has not been clicked before
    if (!value) { // Assuming we want to trigger on first click with an empty value
      // Fire GTM event only on the first click or when input value is not empty
      // window.dataLayer = window.dataLayer || [];
      // window.dataLayer.push({
      //   event: 'jobSearchInputClick', // Custom event name for the input click action
      //   searchTerm: value, // The current value of the search input at the time of click
      // });

      console.log('Search input clicked with value:', value);
    }
  };

  return (
    <div className="relative items-center lg:max-w-sm max-w-md sm:w-auto w-full">
        <input
            type="text"
            placeholder={name}
            className="ps-10 pe-4 rounded-md text-sm border-gray-300 w-full focus:ring-primaryBlue"
            value={value}
            onChange={handleChange} // Use handleChange for input changes
            onClick={handleInputClick} // Optional: Handle click event on the input
        />
        <img
            className="absolute top-2 left-2"
            src="/assets/images/icons/search.svg"
            alt=""
        />
    </div>
  );
}
