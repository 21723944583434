import React from 'react';
import { Link } from 'react-router-dom';

export default function UpdatedHeader() {
    return (
        <header>
            <nav className="navbar">
                <a href="/" class="logo-link">
                    <img className="w-auto h-10" src="/assets/images/logo.png" alt="Icon"/>
                </a>

                <input type="checkbox" id="menu-toggle" className="hidden menu-toggle" />
                <label for="menu-toggle" className="hamburger-menu">
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </label>
                <div className="mobile-menu">
                    <ul>
                        <li><a href="/story">Our Story</a></li>
                        <li><a href="/explore">Community</a></li>
                        <li><a href="/events">Events</a></li>
                        <li><a href="/jobs">Jobs</a></li>
                        <li><a href="/login">Sign In</a></li>
                    </ul>
                </div>

                <ul className="nav-links">
                    <li><a href="/story">Our Story</a></li>
                    <li><a href="/explore">Community</a></li>
                    <li><a href="/events">Events</a></li>
                    <li><a href="/login">Sign In</a></li>
                    <li>
                    <Link to={{ pathname: '/login', search: new URLSearchParams({page: "signup"}).toString() }}>

                            <button className="bg-blue-600 hover:bg-blue-700 text-white text-md py-2 px-6 rounded scale-100">
                            Get Started
                            </button>
                        </Link>
                    </li>
                </ul>
            </nav>
        </header>
    );
}