import { formatDistanceToNow } from 'date-fns';
import { app, analytics, firestore, auth, storage } from '../../../firebaseConfig';
import { doc, setDoc, getDoc, getDocs, serverTimestamp, addDoc, collection, query, orderBy, limit, startAfter, updateDoc, arrayUnion, increment, arrayRemove, onSnapshot, deleteDoc, Timestamp, startAt, where, Transaction, runTransaction, getCountFromServer} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { createNotification } from './firestoreNotifications';

/**
 * Uploads all the post data to the firestore database and uploads the image to the firebase storage
 * 
 * @param {Object} post - data containing the post information 
 */
export const createPost = async (post, userId) => {
    const { title, content, image, tags, postId } = post;
    try {
        let postURL = "";

        // Create a post object with the image URL
        const postWithImageUrl = {
            title,
            content,
            postURL,
            tags,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
            userId: userId,
            likes: [],
            comments: 0,
        };

        // Add the post to Firestore
        const docRef = await addDoc(collection(firestore, 'posts2'), postWithImageUrl);

        // Retrieve the Firestore-generated ID for the post
        const postId = docRef.id;

        if (image) {
            // Upload image to Firebase Storage
            const storageReference = ref(storage, `images2/posts/${postId}`);
            await uploadBytes(storageReference, image);

            // Get the download URL of the uploaded image
            postURL = await getDownloadURL(storageReference);
        }

        // update the post URL if uploading an image
        await updateDoc(docRef, {
            postURL: postURL,
        });

        //gtag post create
        window.dataLayer.push({
            event: 'postCreation',
            postDetails: {
                postId: docRef.id,
                title: title,
                tags: tags,
                userId: userId
            }
        });

        // Return the post ID and image URL for reference
        return { postId, postURL };
    } catch (error) {
        console.error('Error creating post:', error);
        throw new Error('Failed to create post');
    }
};

/**
 * Obtains all the posts in the database with the specified search paramters. 
 * Sorts by date and fetches all by default.
 * 
 * @param {String} order - the order type that we want to order by
 * @param {Boolean} asc - Should we order in ascending or descending order
 * @param {Number} lim - the amount of posts we want to obtain (0 means all posts) 
 * 
 * @returns the posts array
 */
export const getPosts = async (order = 'createdAt', whereFilter = null, asc = false, lim = null, after = null) => {
    try {
        // build query
        const postsCollection = collection(firestore, 'posts2');
        let q = query(postsCollection, orderBy(order, asc ? 'asc' : 'desc'));

        if (whereFilter) {
            q = query(q, whereFilter);
        }

        if (after) {
            console.log(after);
            q = query(q, startAfter(after));
        }

        if (lim) {
            q = query(q, limit(lim));
        }

        // obtain posts and last doc from the query snapshot
        const querySnapshot = await getDocs(q);
        const posts = [];
        let lastDoc = null;
        
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            const createdAt = data.createdAt;
            let date = null;
            if (data.createdAt && data.createdAt instanceof Timestamp) {
                date = formatDistanceToNow(new Date(data.createdAt.toDate()), { addSuffix: true });
            } else {
                date = "Just now";
            }

            const postData = {
                postId: doc.id,
                ...data,
                date: date,
                createdAt: createdAt,
            };
            posts.push(postData);
        });
        
        // Retrieve the last document for pagination
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        if (lastVisible) {
            lastDoc = lastVisible;
        }

        return {
            postsReceived: posts, 
            lastDocReceived: lastDoc,
        };
    } catch (error) {
        console.error('Error getting posts:', error);
        throw new Error('Failed to get posts');
    }
};

/**
 * Get the post from the firestore database with the corresponding id.
 * 
 * @param {String} postId - id of the post to get 
 * 
 * @return the post 
 */
export const getPost = async (postId) => {
    try {
        const postRef = doc(firestore, "posts2", postId);
        const postDoc = await getDoc(postRef);
        if (postDoc.exists()) {
            const data = postDoc.data();

            const createdAt = data.createdAt;
            const postData = {
                postId: postDoc.id,
                ...data,
                createdAt: createdAt,
            };
            return postData;
        } else {
            throw new Error("post does not exits!");
        }
    } catch (error) {
        throw new Error("Failed to obtain post due to the following: " + error);
    }
}

/**
 * A snapshot listener for posts collection in case any changes have taken place.
 * Takes in a callback for you to provide your own logic for what to do with
 * the changed post. 
 * 
 * @param {Function} onUpdate - callback for the logic we want to do with the changed data 
 * @returns the callback function to unsubscribe from the listener 
 */
export const listenForPostChanges = (onUpdate) => {
    const postsCollection = collection(firestore, "posts2");
    
    const unsubscribe = onSnapshot(postsCollection, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
            // Check if the change is not a local change and has actual data changes
            const data = change.doc.data();
            let date = null;
            if (data.createdAt && data.createdAt instanceof Timestamp) {
                date = formatDistanceToNow(new Date(data.createdAt.toDate()), { addSuffix: true });
            } else {
                date = "Just now";
            }
            const updatePost = {
                postId: change.doc.id,
                ...data,
                date: date,
            };
            onUpdate(updatePost, change.type);
        });
    });
    return unsubscribe;
}

/**
 * Updates the post data using the id of the post. 
 * 
 * @param {string} postId - id of the post to update 
 */
export const updatePost = async (post) => {
    const {title, content, createdAt, updatedAt, tags, likes} = post;
    try {
        console.log("In update post");
        console.log(post);
        const postRef = doc(firestore, "posts2", post.postId);
        return await updateDoc(postRef, {
            title,
            content,
            tags,
            likes,
            updatedAt: serverTimestamp(),
        });
    } catch (error) {
        throw new Error("Failed to update post due to the following: " + error);
    }   
}

/**
 * This will either like or unlike a post by adding the user's id to the post 
 * if it doesn't currently exist on there or remove it if it does.
 * 
 * @param postId - the id of the post to toggle the like on
 * @param userId - the id of the user who is either liking or unliking the post
 */
export const togglePostLike = async (postId, userId) => {
    try {
        const postRef = doc(firestore, "posts2", postId);
        const postDoc = await getDoc(postRef);

        if (postDoc.exists()) {
            const liked = postDoc.data().likes && postDoc.data().likes.includes(userId);

            let updateAction;
            if (liked) {
                updateAction = arrayRemove(userId);

                // Send unlike event to GTM data layer
                window.dataLayer.push({
                    event: 'postUnliked',
                    postDetails: {
                        postId: postId,
                        userId: userId
                    }
                });    
            } else {
                updateAction = arrayUnion(userId);
                // Send like event to GTM data layer
                window.dataLayer.push({
                    event: 'postLiked',
                    postDetails: {
                        postId: postId,
                        userId: userId
                    }
                });
                
            }

            // Update the likes array based on the updateAction
            await updateDoc(postRef, {
                likes: updateAction
            });
        } else {
            throw new Error("Post does not exist for toggling the like!");
        }
    } catch (error) {
        throw new Error("Failed to toggle like on post due to the following: " + error);
    }
};

/**
 * Toggle the saved field in the post with your id.
 * 
 * @param {JSON} post - post to be saved
 * @param {string} userId - the id to save the post under
 */
export const togglePostSave = async (post, userId) => {
    try {
        if (post.id === null) {
            throw new Error("Cannot toggle saved for a post with no id!");
        }
        if (post.saved === null) {
            throw new Error("Cannot toggle saved for a post with no saved field!");
        }

        const postRef = doc(firestore, "posts2", post.postId);
        const postDoc = await getDoc(postRef);
        if (postDoc.exists()) {
            // figure out to remove or add the userId to saved (toggle)
            let updateAction = null;
            if (postDoc.data().saved?.includes(userId)) {
                updateAction = arrayRemove(userId);
            } else {
                updateAction = arrayUnion(userId);
            }

            // toggle 
            await updateDoc(postRef, {
                saved: updateAction
            });
        } else {
            throw new Error("Failed to toggle post saved because the post doesn't exist!");
        }
    } catch (error) {
        throw new Error("Failed to toggle saved on post due to the following: " + error);
    }
}

/**
 * Deletes the post.
 * 
 * @param {Object} post - post to be deleted 
 */
export const deletePost = async (post) => {
    try {
        const postRef = doc(firestore, "posts2", post.postId);
        await deleteDoc(postRef);
        const postCommentsRef = collection(postRef, "comments");

        // delete all post comments
        const querySnapshot = await getDocs(postCommentsRef);
        querySnapshot.forEach(async (doc) => {
            await deleteDoc(doc.ref);
        });

        //Gtag manager delete post
        window.dataLayer.push({
            event: 'postDeleted',
            postDetails: {
                postId: post.postId
            }
        });
        

        // delete the post image if it exists
        if (post.postURL) {
            const postRef = ref(storage, post.postURL);
            try {
                await deleteObject(postRef);
            } catch (error) {
                console.error("Could not remove post image due to the following: " + error);
            }
        }

    } catch (error) {
        throw new Error ("Could not delete the post: " + error);
    }
}

/**
 * Adds a comment as a sub collection to the post document.
 * 
 * @param {String} postId - id of the post we want to add the comment to 
 * @param {Object} comment - the comment we are adding 
 * 
 * @return the comment id
 */
export async function addPostComment(postId, comment, isReply = false) {
    try {
        const { content, replies, userId, image} = comment;
        let imageURL = null;

        // upload image to firebase storage and get a reference url to put in firestore db
        if (image !== null) {
            const storageReference = ref(storage, `images2/comments/${Date.now()}`);
            await uploadBytes(storageReference, image);

            imageURL = await getDownloadURL(storageReference);
        }

        const commentsCollection = collection(firestore, 'posts2', postId, 'comments');
    
        // Create a new comment document in the comments sub-collection
        const newCommentRef = await addDoc(commentsCollection, {
            imageURL,
            isReply,
            content,
            replies,
            userId,
            likes: [],
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
        });

        // Retrieve the ID of the newly added comment
        const newCommentId = newCommentRef.id;

        // GTM event for posting a comment
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'postComment',
            postId: postId,
            commentId: newCommentId,
            userId: userId,
            content: content,
            isReply: isReply,
            imageURL: imageURL ? true : false, // Indicate whether the comment includes an image
        });

    //Send notification logic 
    const postRef = doc(firestore, "posts2", postId);
    const postSnapshot = await getDoc(postRef);
    console.log("POSTID IS.", postId);
    console.log("postsnapshot IS.", postSnapshot);

    if (postSnapshot.exists()) {
        const postOwnerId = postSnapshot.data().userId;

        console.log("postOwnerId is:", postOwnerId);
        console.log("postId is:", postId);
        console.log("userID is:", userId);
        console.log("comment.content is:", comment.content);
    }

        return newCommentId; // Return the ID of the newly added comment
    } catch (error) {
        throw new Error("Could not add comment to post: " + error);
    }
}

/**
 * Updates only the likes field on a post.
 * 
 * @param {String} postId - id of the post we want to update the likes field of the comment on
 * @param {Object} comment - comment we want to update the likes field of 
 */
export const updatePostCommentLikes = async (postId, comment) => {
    const {likes, commentId} = comment;
    try {
        const commentRef = doc(firestore, "posts2", postId, "comments", commentId);
        await updateDoc(commentRef, {
            likes: likes
        });
    } catch (error) {
        throw new Error("Failed to toggle comment like: " + error);
    }
}

/**
 * Updates a comment on a post with the new comment information.
 * 
 * @param {String} postId - id of the post we are updating the comment on
 * @param {String} commentId - id of the comment on the post we are updating
 * @param {Object} comment - modified comment data
 */
export const updatePostComment = async (postId, comment) => {
    const {content, userId, createdAt, commentId, likes, isReply, replies } = comment;
    console.log("comment that is going to be updated", comment);
    try {
        const commentRef = doc(firestore, 'posts2', postId, 'comments', commentId);
        await updateDoc(commentRef, {
            isReply,
            content,
            userId,
            createdAt,
            updatedAt: serverTimestamp(),
            likes,
            replies,
        });
    } catch (error) {
        throw new Error("Failed to update comment: " + error);
    }
}

/**
 * Reply to a comment on a post. First we add the id of the comment reply to the replies field 
 * in the comment we are replying to. Then we create a new comment reply. 
 * 
 * @param {} postId - id of the post the comment being replied to is on 
 * @param {*} commentReply - the new comment that that is being created as a reply
 * @param {*} commentRepliedTo - the comment being replied to
 */
export const replyToComment = async (postId, commentReply, commentRepliedTo, highLevelCommentId) => {
    try {
        const replyId = await addPostComment(postId, commentReply, true);
        // Get the reference to the comment
        const commentRef = doc(firestore, 'posts2', postId, 'comments', commentRepliedTo.commentId);
        const commentSnapshot = await getDoc(commentRef);

        if (!commentSnapshot.exists()) {
            throw new Error('Comment does not exist');
        }

        const updatedReplies = [...commentSnapshot.data().replies, replyId];

        // Update the comment with the new replyId
        await updateDoc(commentRef, { replies: updatedReplies });
        // reply to a reply, we need to add this to the high level comment replies as well
        if (highLevelCommentId !== null && highLevelCommentId !== commentRepliedTo?.commentId) {
            const highLevelCommentRef = doc(firestore, 'posts2', postId, 'comments', highLevelCommentId);
            const highLevelCommentSnapshot = await getDoc(highLevelCommentRef);
            if (!highLevelCommentSnapshot.exists()) {
                throw new Error('High level comment does not exist');
            }
            const highlevelUpdatedReplies = [...highLevelCommentSnapshot.data().replies, replyId];
            await updateDoc(highLevelCommentRef, {replies: highlevelUpdatedReplies});
        }

        // Return the ID of the newly added reply if needed
        return replyId; 
    } catch (error) {
        console.error(error);
        throw new Error("Failed to reply to comment: " + error);
    }
};

/**
 * Obtains the comments on the post with the corresponding id.
 * Sort by the time the comment was created by default in ascending order and fetch all by default.
 *  
 * @param {String} order - what we want to sort the post by 
 * @param {Boolean} asc - sorting in ascending or descending order
 * @param {Number} lim - limit the amount of comments to obtain
 */
export async function getPostComments(postId, order = "createdAt", asc = false, lim = 0, after = null) {
    try {
        const commentsCollection = collection(firestore, 'posts2', postId, 'comments');
        
        let q = query(commentsCollection, where("isReply", "==", false));
        
        // Add ordering by createdAt
        q = query(q, orderBy(order, asc ? 'asc' : 'desc'));

        // If there's a starting point (after), use startAfter
        if (after) {
            q = query(q, startAfter(after));
        }

        // Add limit to the query
        if (lim) {
            q = query(q, limit(lim));
        }

        const querySnapshot = await getDocs(q);
        const comments = [];
        querySnapshot.forEach((comment) => {
            let date = null;
            if (comment.data().createdAt && comment.data().createdAt instanceof Timestamp) {
                date = formatDistanceToNow(new Date(comment.data().createdAt.toDate()), { addSuffix: true });
            } else {
                date = "Just now";
            }

            const commentData = {
                commentId: comment.id,
                ...comment.data(),
                date: date,
            };
            comments.push(commentData);
        });

        // Retrieve the last document in the query for pagination
        const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

        return { commentsReceived: comments, lastDocReceived: lastDoc };
    } catch (error) {
        throw new Error("Failed to get comments from post: " + error);
    }
}

/**
 * Obtain the comment count for the given post id
 * 
 * @param {String} postId - id of the post we are trying to get the comment count from 
 * 
 * @returns count of all the comments for the given post id
 */
export async function listenForCommentCount(postId, onUpdate) {
    try {
        const commentCollection = collection(firestore, "posts2", postId, "comments");

        const unsubscribe = onSnapshot(commentCollection, (snapshot) => {
            if (!snapshot.empty) {
                const count = snapshot.docs.length;
                onUpdate(count);
            }
        });

        return unsubscribe;
    } catch (error) {
        throw new Error("Failed to get the comment count for the post with id: " + postId + ": " + error);
    }
}

/**
 * Get the count for all the non reply comments
 * @param {} postId - id of the post to get the non reply comments on
 * @returns the count for non reply comments
 */
export async function getNonReplyCommentCount(postId) {
    try {
        const commentCollection = collection(firestore, "posts2", postId, "comments");
        const q = query(commentCollection, where("isReply", "==", false));
        const snapshot = await getCountFromServer(q);
        return snapshot.data().count;
    } catch (error) {
        throw new Error("Could not obtain non reply comment count: " + error);
    }
}

/**
 * Get a specific comment from a post
 * 
 * @param {String} postId - id of the post we want the comment from
 * @param {String} commentId - id of the comment on the post
 * @returns the comment
 */
export async function getPostComment(postId, commentId) {
    try {
        const commentDoc = doc(firestore, "posts2", postId, "comments", commentId);
        const comment = await getDoc(commentDoc);
        if (comment.exists()) {
            return {
                commentId: comment.id,
                ...comment.data(),
            };
        } else {
            throw new Error("Comment does not exist with id " + commentId + " under post with id " + id + "!");
        }
    } catch (error) {
        console.error(error);
        throw new Error("Failed to get comment from post: " + error);
    }
}

/**
 * A snapshot listener for the post comments colllection in case any changes 
 * have taken place. Takes in a callback for you to provide your own logic 
 * for what to do with the changed comment. 
 * 
 * @param {Function} onUpdate - callback for the logic we want to do with the changed 
 * comment data
 * 
 * @returns the callback function to unsubscribe from the listener 
 */
export const listenForCommentChanges = (postId, onUpdate) => {
    const commentsCollection = collection(firestore, "posts2", postId, "comments");

    const unsubscribe = onSnapshot(commentsCollection, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
            // Check if the change is not a local change and has actual data changes
            const data = change.doc.data();
            let date = null;
            if (data.createdAt && data.createdAt instanceof Timestamp) {
                date = formatDistanceToNow(new Date(data.createdAt.toDate()), { addSuffix: true });
            } else {
                date = "Just now";
            }
            const updateComment = {
                postId: change.doc.id,
                ...data,
                date: date,
            };
            switch (change.type) {
                case "modified":
                    onUpdate(updateComment, "modified");
                break;
                case "removed":
                    onUpdate(updateComment, "removed");
                break;
                case "added":
                    onUpdate(updateComment, "added");
                break;
            }
        });
    });

    return unsubscribe;
}