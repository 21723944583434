import React, { useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ExploreTabs from "./includes/ExploreTabs";
import NewPost from "./includes/NewPost";
import PostCard from "../../components/common/PostCard";
import { getPost, getPosts } from "../../functions/firebase/firestorePosts";
import { useAuth } from "../../context/AuthContext";
import {
  getSensitiveUserData,
  getUser,
  listenForUserChanges,
} from "../../functions/firebase/firestoreUsers";
import { useUser } from "../../context/UserContext";
import { useParams } from "react-router-dom";
import { useToast } from "../../context/ToastContext";
import { toast } from "react-toastify";
import { realTimePosts } from "../../functions/realtime/realtimeService";
import Announcements from "../../components/common/Announcements";
import LoginModal from "../../components/util/LoginModal";
import { Helmet } from "react-helmet";

export default function Explore() {
  const { id } = useParams();

  const [activeTab, setActiveTab] = useState("All");
  const [posts, setPosts] = useState([]);
  const [tabPosts, setTabPosts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const { showSuccessToast, showErrorToast, showLoadingToast } = useToast();
  const [postModalVisible, setPostModalVisible] = useState(false);

  // keep track for fetching new documents after again via infinite scroll
  const [lastDoc, setLastDoc] = useState(null);
  const [morePosts, setMorePosts] = useState(true);

  const auth = useAuth();
  const user = useUser();

  const postCount = 5;

  const [showLoginModal, setShowLoginModal] = useState(false);
  function openSignInModal() {
    setShowLoginModal(true);
  }

  /**
   * Obtain all the posts initially (TODO: implement caching and figure out adding posts via listener)
   */
  async function fetchPosts() {
    try {
      if (morePosts) {
        let { postsReceived, lastDocReceived } = await getPosts(
          "createdAt",
          null,
          false,
          postCount,
          lastDoc
        );

       // console.log("EXPLORE postsReceived", postsReceived);
        postsReceived = await Promise.all(
          postsReceived.map(async (post) => {
            post.user = await getUser(post.userId);
            return post;
          })
        );

        if (!lastDocReceived) {
          setMorePosts(false);
          return;
        }

        // for now put it in a set, will have to figure out why they are duplicated still
        setPosts((prevPosts) => {
          const postIds = new Set(prevPosts.map((post) => post.postId)); // Create a Set of existing postIds
          const uniquePosts = postsReceived.filter(
            (post) => !postIds.has(post.postId)
          ); // Filter out posts with existing postIds

          const updatedPosts = [...prevPosts, ...uniquePosts]; // Concatenate unique posts with existing posts
          updatedPosts.sort((a, b) => b.createdAt - a.createdAt); // Sort by createdAt if needed

          return updatedPosts;
        });

        setLastDoc(lastDocReceived);
      }
    } catch (error) {
      console.error("LINE93", error);
    }
  }

  /**
   * Updates the posts to show on the page based on the current filter whenever the
   * posts state changes.
   */
  useEffect(() => {
    //console.log("setTabPosts", setTabPosts);
    //console.log("setTabPosts POSTS", posts);
    if (activeTab === "All") {
      setTabPosts(posts);
    } else {
      const filteredTabPosts = posts.filter((post) =>
        post?.tags?.includes(activeTab)
      );
      setTabPosts(filteredTabPosts);
    }
  }, [activeTab, posts]);

  useEffect(() => {
    if (id) {
      if (!auth.currentUser) {
        openSignInModal();
      }
      const get = async () => {
        try {
          const postArray = [];
          const post = await getPost(id);
          post.user = await getUser(post.userId);
          postArray.push(post);
          setTabPosts(postArray);
          setPosts(postArray);
          setHasMore(false);
          setLastDoc({});
        } catch (error) {
          showErrorToast();
          console.error(error);
        }
      };
      get();
    } else {
      fetchPosts();
      let unsubscribe = realTimePosts(setPosts, auth, user, showErrorToast);

      // update the user's info on their post without needing to refresh the page when it changes
      if (auth.currentUser && auth.currentUser.emailVerified) {
        listenForUserChanges(auth.currentUser.uid, (user) => {
          setPosts((prevPosts) => {
            return prevPosts.map((post) => {
              if (post.userId === auth.currentUser.uid) {
                post.user = user;
              }
              return post;
            });
          });
        });
      }

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, []);

  const fetchMoreData = () => {
    if (!id) {
      fetchPosts();
    }
  };

  return (
    <div className="flex font-din" data-testid="explore-container">
      <Helmet>
        <title>UX Was Here - Explore</title>
      </Helmet>

      <div
        className="flex-1 lg:pt-8 py-4 px-4 lg:px-14 md:px-6"
        //data-testid="infinite-scroll"
      >
        <h2 className="text-2xl pb-4 font-bold">Explore</h2>
        {!id && (
          <>
            <div className="mb-4">
              <ExploreTabs activeTab={activeTab} setActiveTab={setActiveTab} />
            </div>
            <div className="mb-4">
              {auth && (
                <>
                  <div
                    onClick={() => {
                      if (!auth || !auth?.currentUser?.emailVerified) {
                        openSignInModal();
                      } else {
                        setPostModalVisible(true);
                      }
                    }}
                    className="bg-white hover:bg-gray-100 cursor-pointer border rounded pb-4 px-2 pt-2"
                  >
                    <div className="flex items-center pb-6 px-4 pt-2 cursor-pointer rounded transition-all duration-300 ease-in-out">
                      <img
                        src={
                          user?.profilePictureURL ||
                          "./assets/images/icons/user.svg"
                        }
                        alt="user profile picture"
                        className="w-7 h-7 rounded-full"
                      />
                      <div className="ml-2 flex-grow bg-transparent text-sm text-[#959595]">
                        Start a post
                      </div>
                    </div>
                    <div className="flex justify-between pt-1 ps-1 pe-4">
                      <div className="">
                        {/* <button className="p-1 rounded-lg hover:shadow-md transition-all duration-300 ease-in-out ml-1">
                      <img src="/assets/images/icons/poll.svg" alt="" />
                    </button> */}
                      </div>

                      <button className="py-1 px-3 shadow-md bg-primaryBlue rounded-lg hover:bg-[rgb(46,133,214)] transition-all duration-300 ease-in-out ml-2">
                        <img
                          src="/assets/images/icons/pencil-white.svg"
                          alt=""
                        />
                      </button>
                    </div>
                  </div>
                  <NewPost
                    user={user}
                    visible={postModalVisible}
                    setVisible={setPostModalVisible}
                    isEditing={false}
                  />
                </>
              )}
            </div>
          </>
        )}
        <InfiniteScroll
          data-testid="infinite-scroll"
          dataLength={posts.length}
          next={fetchMoreData}
          hasMore={morePosts}
          loader={
            <p className="mt-12 text-center text-m text-gray-700">
              {!id ? <b>Loading...</b> : null}
            </p>
          }
          endMessage={
            <p className="mt-12 text-center text-xs text-gray-400">
              <b>End of the Page</b>
            </p>
          }
        >
          <div className="">
            {tabPosts.map((post) => (
              <PostCard key={post.postId} post={post} />
            ))}
          </div>
        </InfiniteScroll>
      </div>
      <Announcements />
      <LoginModal modalIsOpen={showLoginModal} setIsOpen={setShowLoginModal} />
    </div>
  );
}
