import React, { useState } from 'react'
import UserModal from '../common/UserModal';

export default function UserCard({handleClick, user, isOnPopup = false}) {

  return (
    <div className={"flex w-fit items-center gap-x-1"}>
        <img onClick={() => handleClick()}
          src={
            user?.profilePictureURL
              ? user?.profilePictureURL
              : '/assets/images/community/placeholder-user.svg'
          }
          alt={user?.firstName?.charAt(0)?.toUpperCase() + user?.firstName?.slice(1) + " " + user?.lastName?.charAt(0)?.toUpperCase() + user?.lastName?.slice(1)}
          className={!isOnPopup ? "w-9 h-9 rounded-full cursor-pointer transition duration-300 ease-in-out hover:border-blue-500 border-transparent" : "w-9 h-9 rounded-full"}
          style={{borderWidth: '2px'}} // Set a default border width for the image
        />
        <div>
          <p onClick={() => handleClick()} className={!isOnPopup ? "font-bold cursor-pointer transition duration-300 ease-in-out hover:text-blue-500" : null}>
            {user?.firstName?.charAt(0)?.toUpperCase() + user?.firstName?.slice(1) + " " + user?.lastName?.charAt(0)?.toUpperCase() + user?.lastName?.slice(1)}
          </p>
          <p className="text-gray-400 text-sm">
            @
            {user?.occupation}
          </p>
        </div>
      </div>
  )
}
