import React, { useEffect, useState } from 'react';
import JobCard from '../../components/common/JobCard';
import EventCard from '../../components/common/EventCard';
import PostCard from '../../components/common/PostCard';
import ResourceCard from '../../components/common/ResourceCard';
import { Helmet } from 'react-helmet';
import { getPosts } from '../../functions/firebase/firestorePosts';
import { useAuth } from '../../context/AuthContext';
import { where } from 'firebase/firestore';
import { getResources, getSavedResources } from '../../functions/firebase/firestoreResources';
import { getEvents, getSavedEvents } from '../../functions/firebase/firestoreEvents';
import { getJobs, getSavedJobs } from '../../functions/firebase/firestoreJobs';
import Announcements from '../../components/common/Announcements';
import SearchFilter from '../jobs/SearchFilter';
import { getUser } from '../../functions/firebase/firestoreUsers';
import { realTimeEvents, realTimeJobs, realTimePosts, realTimeResources } from '../../functions/realtime/realtimeService';
import { useLocation, useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';


// saved/posts
// saved/events
// saved/jobs
// saved/resources 

function Saved() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let selectedTab = searchParams.get("selectedTab");
  if (!selectedTab) {
    selectedTab = "posts";
  }

  const auth = useAuth();

  const [searchTerm, setSearchTerm] = useState('');
  
  const [savedPosts, setSavedPosts] = useState([]);
  const [savedResources, setSavedResources] = useState([]);
  const [savedEvents, setSavedEvents] = useState([]);
  const [savedJobs, setSavedJobs] = useState([]);
  const [moreSavedItems, setMoreSavedItems] = useState(true);
  const [lastDoc, setLastDoc] = useState(null);

  async function fetchSavedPosts() {
    const limit = 5;
    const postsResponse = await getPosts("createdAt", where("saved", "array-contains", auth.currentUser.uid), false, limit, lastDoc);
  
    if (!postsResponse.lastDocReceived || postsResponse.lastDocReceived === lastDoc) {
      setMoreSavedItems(false);
      return;
    }
  
    setLastDoc(postsResponse.lastDocReceived);
  
    console.log(postsResponse.posts);
  
    // Promise.all to get all the users attached to each post 
    const newPosts = await Promise.all(postsResponse.postsReceived.map(async (post) => {
      const postUser = await getUser(post.userId);
      post.user = postUser;
      console.log("post: ", post);
      return post;
    }));
  
    // TODO: for some reason posts are duplicating when fetching via paginiation, should be
    // checking this out in the future
    setSavedPosts((prevPosts) => {
      const postIds = new Set(
          prevPosts.map((post) => post.postId)
      ); // Create a Set of existing postIds
      const uniquePosts = newPosts.filter(
          (post) => !postIds.has(post.postId)
      ); // Filter out posts with existing postIds

      const updatedPosts = [...prevPosts, ...uniquePosts]; // Concatenate unique posts with existing posts
      updatedPosts.sort((a, b) => b.createdAt - a.createdAt); // Sort by createdAt if needed

      return updatedPosts;
   });
  }

  async function fetchSavedEvents() {
    const limit = 5;
    const eventData = await getSavedEvents(auth.currentUser.uid, limit, lastDoc);
    if (eventData.lastDoc === null || eventData.lastDoc === lastDoc) {
      setMoreSavedItems(false);
      return;
    }
    setLastDoc(eventData.lastDoc);
    setSavedEvents(eventData.events);
  }

  async function fetchSavedJobs() {
    const limit = 5;
    const jobData = await getSavedJobs(auth.currentUser.uid, limit, lastDoc);
    if (jobData.lastDoc === null || jobData.lastDoc === lastDoc) {
      setMoreSavedItems(false);
      return;
    }
    setLastDoc(jobData.lastDoc);
    setSavedJobs(jobData.jobs);
  }

  async function fetchSavedResources() {
    const limit = 5;
    const resourceData = await getSavedResources(auth.currentUser.uid, limit, lastDoc);
    if (resourceData.lastDoc === null || resourceData.lastDoc === lastDoc) {
      setMoreSavedItems(false);
      return;
    }
    setLastDoc(resourceData.lastDoc);
    setSavedResources(resourceData.resources);
  }
 
  /**
   * Initial mount of component call to fetch all the items
   */
  useEffect(() => {
    async function get() {
      if (!auth.currentUser) return;
  
      try {
        // Fetch all the items in a Promise.all

        switch (selectedTab) {
          case "posts":
            fetchSavedPosts();
            break;
          case "events":
            fetchSavedEvents();
            break;
          case "jobs":
            fetchSavedJobs();
            break;
          case "resources":
            fetchSavedResources();
            break;
        }

      } catch (error) {
        // Handle errors
        console.error("Error fetching data:", error);
      }
    }
  
    get();
  }, []);

  function handleChangeTab(tab) {
    const url = new URL(window.location.href);
    url.searchParams.set("selectedTab", tab.toLowerCase());

    // navigate to different tab of saved page
    window.location.href = url.toString();
  }

  return (
    <div className="flex font-dmsans">

      <Helmet>
        <title>UX Was Here - Saved</title>
      </Helmet>

      <div className="flex-1 lg:pt-8 pt-4 lg:px-14 md:px-6">
        <div className="flex flex-wrap lg:gap-x-4 gap-x-4">
          {['Posts', 'Events', 'Jobs', 'Resources'].map(
            (tab) => (
              <button
                key={tab}
                className={`px-2 text-lg ${
                  selectedTab === tab.toLowerCase()
                    ? 'border-b border-black text-black'
                    : 'text-[#959595]'
                }`}
                onClick={() => handleChangeTab(tab)}
              >
                {tab}
              </button>
            ),
          )}
        </div>
        <div className="my-5 relative items-center lg:max-w-sm">
          <SearchFilter searchTerm={searchTerm} setSearchTerm={setSearchTerm} name="Search resources"/>
        </div>

        <div className="mt-4 grid gap-4">
        {selectedTab === 'posts' && (
            <div className="">
              <div className="mb-4 mt-4">
                <p className="font-bold">
                  {savedPosts.length}
                  {' '}
                  <span className="font-normal text-sm">Posts</span>
                </p>
              </div>
                <InfiniteScroll
                    dataLength={savedPosts.length}
                    next={fetchSavedPosts}
                    hasMore={moreSavedItems}
                    loader={
                        <p className="mt-12 text-center text-m text-gray-700">
                            {
                                <b>Loading...</b>
                            }
                        </p>
                    }
                    endMessage={
                        <p className="mt-12 text-center text-xs text-gray-400">
                            <b>End of the Page</b>
                        </p>
                    }
                >
                    <div className="">
                        {savedPosts.map((post) => (
                            <PostCard key={post.postId} post={post} />
                        ))}
                    </div>
                </InfiniteScroll>
            </div>
          )}
          {selectedTab === 'events' && (
            <div className="space-y-4">
              <div className="mt-4">
                <p className="font-bold">
                  {savedEvents.length}
                  {' '}
                  <span className="font-normal text-sm">Events</span>
                </p>
              </div>
                <InfiniteScroll
                    dataLength={savedEvents.length}
                    next={fetchSavedEvents}
                    hasMore={moreSavedItems}
                    loader={
                        <p className="mt-12 text-center text-m text-gray-700">
                            {
                                <b>Loading...</b>
                            }
                        </p>
                    }
                    endMessage={
                        <p className="mt-12 text-center text-xs text-gray-400">
                            <b>End of the Page</b>
                        </p>
                    }
                >
                    <div className="space-y-4">
                        {savedEvents.map((event) => (
                            <EventCard key={event.id} event={event} />
                        ))}
                    </div>
                </InfiniteScroll>
            </div>
          )}
          {selectedTab === 'jobs' && (
            <div className="space-y-4">
              <div className="mt-4">
                <p className="font-bold">
                  {savedJobs.length}
                  {' '}
                  <span className="font-normal text-sm">Jobs</span>
                </p>
              </div>
              <InfiniteScroll
                    dataLength={savedJobs.length}
                    next={fetchSavedJobs}
                    hasMore={moreSavedItems}
                    loader={
                        <p className="mt-12 text-center text-m text-gray-700">
                            {
                                <b>Loading...</b>
                            }
                        </p>
                    }
                    endMessage={
                        <p className="mt-12 text-center text-xs text-gray-400">
                            <b>End of the Page</b>
                        </p>
                    }
                >
                    <div className="space-y-4">
                        {savedJobs.map((job) => (
                            <JobCard key={job.id} job={job} />
                        ))}
                    </div>
                </InfiniteScroll>
            </div>
          )}
          {selectedTab === 'resources' && (
            <div className="space-y-4">
              <div className="mt-4">
                <p className="font-bold">
                  {savedResources.length}
                  {' '}
                  <span className="font-normal text-sm">Resources</span>
                </p>
              </div>
              <InfiniteScroll
                    dataLength={savedResources.length}
                    next={fetchSavedResources}
                    hasMore={moreSavedItems}
                    loader={
                        <p className="mt-12 text-center text-m text-gray-700">
                            {
                                <b>Loading...</b>
                            }
                        </p>
                    }
                    endMessage={
                        <p className="mt-12 text-center text-xs text-gray-400">
                            <b>End of the Page</b>
                        </p>
                    }
                >
                    <div className="space-y-4">
                        {savedResources.map((resource) => (
                            <ResourceCard key={resource.id} resource={resource} />
                        ))}
                    </div>
                </InfiniteScroll>
            </div>
          )}
        </div>
      </div>
      <Announcements />
    </div>
  );
}

export default Saved;
