import React from "react";

import { Link } from 'react-router-dom';
import CommunityLayout from '../../components/CommunityLayout';

function TermsOfService() {
  return (
    <CommunityLayout children={
    <div className="font-dmsans flex-col flex items-center">
      <div className="flex flex-col justify-center items-center flex-grow text-sm md:text-base lg:text-lg">
        <div className="border-b border-gray-300 mx-auto w-full md:w-3/4 lg:w-2/3 space-y-10">
          <div className="border-b border-gray-300 w-auto">
            <b className="text-2xl">Terms of Service</b>
          </div>

          <p className="space-y-4">
            <div>Last Updated: March 17th, 2024</div>
            <div>Welcome to UX Was Here!</div>
            <div>Thank you for choosing UX Was Here. Our Terms of Service (the "Terms") govern your access to and use of the UX Was Here website (the “Website”) located at <a href="https://www.uxwashere.com/">https://www.uxwashere.com/</a>, <a href="https://www.beta.uxwashere.com/">https://www.beta.uxwashere.com/</a>, and form a binding contractual agreement between you, the user of the Website, and UX Was Here, registered in Vancouver, BC, Canada.</div>
            <div>By accessing or using our Website, you acknowledge that you have read, understood, and agree to be bound by these Terms and our Privacy Policy. If you do not agree with any part of these Terms, you must not use our Website.</div>

            <div>1. Definitions</div>
            <ul>
              <li>"Website" refers to the UX Was Here website and its associated services and features.</li>
              <li>"Services" encompasses all functionalities, tools, and content provided by UX Was Here via the Website.</li>
              <li>"Content" includes, but is not limited to, text, images, videos, designs, and other material available on the Website.</li>
              <li>"User-Generated Content" means any content, including text, images, and videos, that users post, upload, or otherwise contribute to the Website.</li>
            </ul>

            <div>2. Eligibility</div>
            <p>To access or use our Website, you must be at least 13 years of age. By using our Website, you affirm that you are at least 13 years old and possess the legal authority to enter into these Terms.</p>

            <div>3. User-Generated Content</div>
            <p>You retain all rights to content you post on our Website but grant UX Was Here a non-exclusive, transferable, sublicensable, royalty-free, global license to use, store, display, reproduce, modify, create derivative works, perform, and distribute your User-Generated Content on UX Was Here solely for the purposes of operating, developing, providing, and using the UX Was Here Services.</p>
            <p>Content that is unlawful, libelous, defamatory, obscene, pornographic, harassing, threatening, invasive of privacy or publicity rights, abusive, illegal, or otherwise objectionable is strictly prohibited.</p>
            <p>UX Was Here reserves the right, but is not obligated, to monitor and edit or remove any User-Generated Content.</p>

            <div>4. Account Termination</div>
            <p>UX Was Here may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.</p>

            <div>5. Data Storage and Deletion</div>
            <p>Your data is stored securely in accordance with our Privacy Policy. Should you wish to request the deletion of your data, please contact our support team for assistance.</p>

            <div>6. Dispute Resolution</div>
            <p>Disputes arising under these Terms will be resolved in accordance with the laws of Vancouver, BC, Canada, and will be subject to confidential binding arbitration.</p>

            <div>7. Limitation of Liability</div>
            <p>UX Was Here shall not be liable for any indirect, incidental, special, consequential or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, through your use of the Website or Services.</p>

            <div>8. Third-Party Services</div>
            <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by UX Was Here. UX Was Here has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party web sites or services.</p>

            <div>9. Changes to Terms of Service</div>
            <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. We will provide at least 30 days' notice before any new terms taking effect. By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms.</p>

            <div>10. Governing Law</div>
            <p>These Terms shall be governed and construed in accordance with the laws of Vancouver, BC, Canada, without regard to its conflict of law provisions.</p>

            <div>11. Contact Us</div>
            <p>For any questions about these Terms, please contact us via support.</p>

            <p>By using UX Was Here, you acknowledge that you have read these terms in full and agree to be bound by them.</p>
          </p>
        </div>
      </div>
    </div>
}/>

  );
}
export default TermsOfService;