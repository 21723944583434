import React, { useEffect, useState } from 'react'
import { useUser } from '../../context/UserContext';
import { getSensitiveUserData } from '../../functions/firebase/firestoreUsers';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet } from 'react-helmet';

export default function Feedback() {

  const user = useUser();
  
  const [userFormData, setUserFormData] = useState({
      email: "",
      firstName: "",
      lastName: "", 
      message: "",
  });

  const [capVal, setCapVal] = useState(null);

  useEffect(() => {
      const get = async () => {
          const sensitiveInfo = await getSensitiveUserData(user.uid);
          setUserFormData({
              ...userFormData,
              email: sensitiveInfo.email,
              firstName: user?.firstName?.charAt(0)?.toUpperCase() + user?.firstName?.slice(1),
              lastName: user?.lastName?.charAt(0)?.toUpperCase() + user?.lastName?.slice(1),
          });
      }
      if (user) {
          get();
      }
  }, []);

  const handleOnChange = (e) => {
      const { value, name } = e.target;
      setUserFormData(prevState => ({
          ...prevState,
          [name]: value,
      }));
  }

  const handleOnChangeCaptcha = (value) => {
    setCapVal(value);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      Swal.showLoading();

      // send the email using email js
      await emailjs.send(import.meta.env.VITE_REACT_APP_EMAIL_JS_SERVICE_ID, import.meta.env.VITE_REACT_APP_EMAIL_JS_TEMPLATE_ID, {
        firstName: userFormData.firstName,
        lastName: userFormData.lastName,
        email: userFormData.email,
        message: userFormData.message,
      }, import.meta.env.VITE_REACT_APP_EMAIL_JS_USER_ID);

      Swal.fire({title: "Success", icon: "success", text: "Thank you for your feedback! We will get back to you shortly."});
      setUserFormData({...userFormData, message: ''});
      
    } catch (error) {
      console.error('Error sending email:', error);
      Swal.fire({ title: 'Failed', icon: 'error', text: 'Unable to send feedback: ' + error });
    }
  };

  function verifyEmailFormat(email) {
    // Regular expression pattern for basic email format validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email against the pattern
    return emailPattern.test(email);
  }

  return (
    <div className="max-w-md mx-auto mt-8 p-4 bg-white shadow-md rounded-md">

      <Helmet>
        <title>UX Was Here - Feedback</title>
      </Helmet>

      <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
      <form className="flex flex-col" onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="firstName" className="block text-gray-700 font-semibold mb-1">First Name <span className={userFormData.firstName === '' ? "text-red-500" : "text-black"}>*</span></label>
          <input type="text" id="firstName" className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500" name="firstName" value={userFormData.firstName} onChange={handleOnChange}/>
        </div>
        <div className="mb-4">
          <label htmlFor="lastName" className="block text-gray-700 font-semibold mb-1">Last Name <span className={userFormData.lastName === '' ? "text-red-500" : "text-black"}>*</span></label>
          <input type="text" id="lastName" className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500" name="lastName" value={userFormData.lastName} onChange={handleOnChange}/>
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700 font-semibold mb-1">Email <span className={!verifyEmailFormat(userFormData.email) ? "text-red-500" : "text-black"}>*</span></label>
          <input type="email" id="email" className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500" name="email" value={userFormData.email} onChange={handleOnChange} />
        </div>
        <div className="mb-6">
          <label htmlFor="message" className="block text-gray-700 font-semibold mb-1">Message <span className={userFormData.message === '' ? "text-red-500" : "text-black"}>*</span></label>
          <textarea id="message" rows="7" className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500" name="message" value={userFormData.message} onChange={handleOnChange} />
        </div>
        <div className="flex flex-row">
          <ReCAPTCHA
              sitekey='6LdlDTopAAAAADmDrrVp8-mZxwPLPl1fyIRa50UF'
              onChange={(value) => handleOnChangeCaptcha(value)}
          />
          <span className={!capVal ? "text-red-500 font-semibold mb-1" : "text-black font-semibold mb-1"}>*</span>
        </div>
        <div className="self-start mt-8 w-full">
          <button disabled={!capVal || userFormData.message === '' || !verifyEmailFormat(userFormData.email) || userFormData.firstName === '' || userFormData.lastName === ''} type="submit" className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-8 rounded-md focus:outline-none focus:shadow-outline w-full disabled:bg-gray-500 disabled:text-gray-300">Submit</button>
        </div>
      </form>
    </div>
  )
}
