import React, { useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useUser } from "../../../context/UserContext";
import { RiPencilLine } from "react-icons/ri";
import { updateUser } from "../../../functions/firebase/firestoreUsers";
import * as yup from "yup";
import { useToast } from "../../../context/ToastContext";

/**
 * Form for editing the user's first name in the settings page.
 * The field is enabled and the "Save" and "Cancel" buttons are
 * displayed when the user clicks the edit button.
 */
export default function FirstNameForm() {
  const user = useUser();
  const [isEditing, setIsEditing] = useState(false);
  const { showSuccessToast, showErrorToast } = useToast();
  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleSubmit = async (values) => {
    try {
      const updatedUser = { ...user, lastName: values.lastName };

      await updateUser(updatedUser);

      toggleEdit();
      showSuccessToast("Last name updated successfully");
    } catch (error) {
      showErrorToast("Failed to update last name");
      console.log(error);
    }
  };

  // Styles
  const errorStyle = "text-red-500 text-xs pt-1";
  const inputStyle =
    "mt-1 p-2 w-full border rounded border-[#939393] text-gray-500 focus:border-primaryBlue focus:ring-primaryBlue relative w-full disabled:px-0 disabled:bg-transparent disabled:text-slate-400 disabled:border-0 disabled:shadow-none";
  const labelStyle = "z-10 mb-1";
  const buttonStyle =
    "bg-blue-500 text-white rounded-lg p-2 w-32 mt-3 hover:bg-blue-700";
  const buttonOutlineStyle =
    "bg-white-500 text-blue-500 rounded-lg p-2 w-32 mt-3 hover:bg-blue-100 outline outline-1 ";
  const editButtonStyle = "rounded-lg p-2 mt-3 hover:bg-blue-100";
  const iconSize = "24";

  return (
    <div>
      <Formik
        initialValues={{
          lastName: user.lastName,
        }}
        validationSchema={yup.object().shape({
          lastName: yup.string().required("Last name is required"),
        })}
        onSubmit={handleSubmit}
      >
        {({ resetForm, initialValues }) => (
          <Form>
            <div className="relative mt-4 mb-6">
              <div className="flex items-end gap-2">
                {/* Input field */}
                <div className="relative w-full">
                  <label className={labelStyle}>Last Name</label>
                  <Field
                    type="text"
                    name="lastName"
                    className={inputStyle}
                    placeholder={user.lastName}
                    disabled={!isEditing}
                  />
                </div>
                {/* Edit button */}
                {!isEditing && (
                  <button
                    type="button"
                    className={editButtonStyle}
                    onClick={toggleEdit}
                  >
                    {/* Edit icon */}
                    <RiPencilLine size={iconSize} />
                  </button>
                )}
              </div>
              <div className={errorStyle}>
                <ErrorMessage name="lastName" />
              </div>
              {/* Expandable buttons to save or cancel changes. */}
              {isEditing && (
                <div className="flex gap-x-3 justify-end">
                  <button
                    type="button"
                    className={buttonOutlineStyle}
                    onClick={() => {
                      toggleEdit();
                      resetForm();
                      window.location.reload();
                    }}
                  >
                    Cancel
                  </button>
                  <button type="submit" className={buttonStyle}>
                    Save
                  </button>
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
