import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastContext = React.createContext();

/**
 * Enables toasts on the application. To use, import them first, then create const for the exported functions 
 * 
 * ********
 * import { useToast } from '../context/ToastContext';
 * const { showSuccessToast, showErrorToast, showLoadingToast } = useToast();
 * ********
 * 
 * They can then be called with 
 * 
 * ********
 * showSuccessToast('Successfully Did Something')
 * ********
 */
export const ToastProvider = ({ children }) => {
  return (
    <ToastContext.Provider value={{}}>
      {children}
      <ToastContainer
      position="bottom-left"
      autoClose={3000}
      hideProgressBar
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light" />
    </ToastContext.Provider>
  );
};

  const toastErrorID = 'errorToastID';
  const toastSuccessID = 'successToastID';
  const toastLoadingID = 'loadingToastID';
/**
 * Wrapper for default toast options
 */
export const useToast = () => {
  const context = React.useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  const showToast = (message, options = {}) => {
    toast(message, options);
  };

  /**
 * Shows or updates an Error Toast, replacing the current error toast if there are multiple in succession
 */
  const showErrorToast = (message = 'A Problem has Occured', customOptions = {}) => {
    const options = {
      type: 'error',
      toastId: toastErrorID,
      ...customOptions,
    };

    if (toast.isActive(toastErrorID)) {
      toast.update(toastErrorID, {
        render: message,
        type: 'error',
        autoClose: 3000
      });
    } else {
      toast(message, options);
    }

  };

  /**
 * Shows or updates an Success Toast, replacing the current success toast if there are multiple in succession
 */
  const showSuccessToast = (message, customOptions = {}) => {
    const options = {
      type: 'success',
      toastId: toastSuccessID,
      ...customOptions,
    };
    if (toast.isActive(toastSuccessID)) {
      toast.update(toastSuccessID, {
        render: message,
        type: 'success',
        autoClose: 3000
      });
    } else {
      toast(message, options);
    }
  };

  /**
 * Shows or updates an Loading Toast, replacing the current loading toast if there are multiple in succession
 */
  const showLoadingToast = (message = 'Loading...', customOptions = {}) => {
    const options = {
      type: 'info',
      toastId: toastLoadingID,
      draggable: false,
      ...customOptions,
    };
    if (toast.isActive(toastLoadingID)) {
      toast.update(toastLoadingID, {
        render: message,
        type: 'info',
        autoClose: 3000,
      });
    } else {
      toast(message, options);
    }
  };


  return {
    showToast,
    showErrorToast,
    showSuccessToast,
    showLoadingToast,
  };
};
