import React, { useRef, useState } from "react";
import ShareItem from "../util/ShareItem";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { useAdminToggle } from "../../context/AdminContext";
import { Tooltip } from "react-tooltip";
import Swal from "sweetalert2";
import {
  deleteEvent,
  toggleEventSave,
} from "../../functions/firebase/firestoreEvents";
import EventForm from "../../pages/events/includes/EventForm";
import { useAuth } from "../../context/AuthContext";
import moment from "moment-timezone";

function EventCard({ event, isAdmin = false }) {
  const auth = useAuth();

  const [isSaved, setIsSaved] = useState(
    event.saved?.includes(auth?.currentUser?.uid)
  );
  const { id } = useParams();
  const [modalIsOpen, setIsOpen] = useState(false);

  const handleToggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const [showEventForm, setShowEventForm] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  const shareEvent = () => {
    setIsOpen(true);
  };

  function handleEditEvent() {
    setShowEventForm((prev) => !prev);
  }

  function fireGTMEventForMoreInfo() {
    window.dataLayer.push({
      event: "eventMoreInfoClicked",
      eventName: event.title,
    });
  }

  function handleDeleteEvent() {
    Swal.fire({
      title: "Are you sure you want to delete?",
      text: "This cannot be undone and the event will be removed forever!",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Removing event posting",
          text: "Please wait...",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        try {
          Swal.isLoading(true);
          await deleteEvent(event.id);
          Swal.fire({
            title: "Successfully deleted event!",
            text: "The event was successfully deleted",
            icon: "success",
          });
        } catch (error) {
          console.error(error);
        } finally {
          Swal.isLoading(false);
        }
      }
    });
  }

  function displayDateRange() {
    const timeZoneMap = {
      "(GMT-12:00) International Date Line West": "Etc/GMT+12",
      "(GMT-11:00) Midway Island, Samoa": "Pacific/Midway",
      "(GMT-10:00) Hawaii": "Pacific/Honolulu",
      "(GMT-09:00) Alaska": "America/Anchorage",
      "(GMT-08:00) Pacific Time (US and Canada)": "America/Los_Angeles",
      "(GMT-07:00) Mountain Time (US and Canada)": "America/Denver",
      "(GMT-07:00) Chihuahua, La Paz, Mazatlan": "America/Chihuahua",
      "(GMT-07:00) Arizona": "America/Phoenix",
      "(GMT-06:00) Central Time (US and Canada)": "America/Chicago",
      "(GMT-06:00) Saskatchewan": "America/Regina",
      "(GMT-06:00) Guadalajara, Mexico City, Monterrey": "America/Mexico_City",
      "(GMT-06:00) Central America": "America/Guatemala",
      "(GMT-05:00) Eastern Time (US and Canada)": "America/New_York",
      "(GMT-05:00) Indiana (East)": "America/Indiana/Indianapolis",
      "(GMT-05:00) Bogota, Lima, Quito": "America/Bogota",
      "(GMT-04:00) Atlantic Time (Canada)": "America/Halifax",
      "(GMT-04:00) Caracas, La Paz": "America/Caracas",
      "(GMT-04:00) Santiago": "America/Santiago",
      "(GMT-03:30) Newfoundland and Labrador": "America/St_Johns",
      "(GMT-03:00) Brasilia": "America/Sao_Paulo",
      "(GMT-03:00) Buenos Aires, Georgetown": "America/Argentina/Buenos_Aires",
      "(GMT-03:00) Greenland": "America/Godthab",
      "(GMT-02:00) Mid-Atlantic": "Atlantic/South_Georgia",
      "(GMT-01:00) Azores": "Atlantic/Azores",
      "(GMT-01:00) Cape Verde Islands": "Atlantic/Cape_Verde",
      "(GMT) Greenwich Mean Time: Dublin, Edinburgh, Lisbon, London":
        "Europe/London",
      "(GMT) Casablanca, Monrovia": "Africa/Casablanca",
      "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague":
        "Europe/Belgrade",
      "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb": "Europe/Sarajevo",
      "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris": "Europe/Paris",
      "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna":
        "Europe/Berlin",
      "(GMT+01:00) West Central Africa": "Africa/Lagos",
      "(GMT+02:00) Bucharest": "Europe/Bucharest",
      "(GMT+02:00) Cairo": "Africa/Cairo",
      "(GMT+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius":
        "Europe/Helsinki",
      "(GMT+02:00) Athens, Istanbul, Minsk": "Europe/Athens",
      "(GMT+02:00) Jerusalem": "Asia/Jerusalem",
      "(GMT+02:00) Harare, Pretoria": "Africa/Johannesburg",
      "(GMT+03:00) Moscow, St. Petersburg, Volgograd": "Europe/Moscow",
      "(GMT+03:00) Kuwait, Riyadh": "Asia/Riyadh",
      "(GMT+03:00) Nairobi": "Africa/Nairobi",
      "(GMT+03:00) Baghdad": "Asia/Baghdad",
      "(GMT+03:30) Tehran": "Asia/Tehran",
      "(GMT+04:00) Abu Dhabi, Muscat": "Asia/Dubai",
      "(GMT+04:00) Baku, Tbilisi, Yerevan": "Asia/Baku",
      "(GMT+04:30) Kabul": "Asia/Kabul",
      "(GMT+05:00) Ekaterinburg": "Asia/Yekaterinburg",
      "(GMT+05:00) Islamabad, Karachi, Tashkent": "Asia/Karachi",
      "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi": "Asia/Kolkata",
      "(GMT+05:45) Kathmandu": "Asia/Kathmandu",
      "(GMT+06:00) Astana, Dhaka": "Asia/Dhaka",
      "(GMT+06:00) Sri Jayawardenepura": "Asia/Colombo",
      "(GMT+06:00) Almaty, Novosibirsk": "Asia/Almaty",
      "(GMT+06:30) Yangon Rangoon": "Asia/Yangon",
      "(GMT+07:00) Bangkok, Hanoi, Jakarta": "Asia/Bangkok",
      "(GMT+07:00) Krasnoyarsk": "Asia/Krasnoyarsk",
      "(GMT+08:00) Beijing, Chongqing, Hong Kong SAR, Urumqi": "Asia/Shanghai",
      "(GMT+08:00) Kuala Lumpur, Singapore": "Asia/Singapore",
      "(GMT+08:00) Taipei": "Asia/Taipei",
      "(GMT+08:00) Perth": "Australia/Perth",
      "(GMT+08:00) Irkutsk, Ulaanbaatar": "Asia/Irkutsk",
      "(GMT+09:00) Seoul": "Asia/Seoul",
      "(GMT+09:00) Osaka, Sapporo, Tokyo": "Asia/Tokyo",
      "(GMT+09:00) Yakutsk": "Asia/Yakutsk",
      "(GMT+09:30) Darwin": "Australia/Darwin",
      "(GMT+09:30) Adelaide": "Australia/Adelaide",
      "(GMT+10:00) Canberra, Melbourne, Sydney": "Australia/Sydney",
      "(GMT+10:00) Brisbane": "Australia/Brisbane",
      "(GMT+10:00) Hobart": "Australia/Hobart",
      "(GMT+10:00) Vladivostok": "Asia/Vladivostok",
      "(GMT+10:00) Guam, Port Moresby": "Pacific/Guam",
      "(GMT+11:00) Magadan, Solomon Islands, New Caledonia": "Asia/Magadan",
      "(GMT+12:00) Fiji Islands, Kamchatka, Marshall Islands": "Pacific/Fiji",
      "(GMT+12:00) Auckland, Wellington": "Pacific/Auckland",
      "(GMT+13:00) Nuku'alofa": "Pacific/Tongatapu",
    };

    // const startDate = new Date(event.startDate);
    // if (event.title === "Test") {
    //   console.log(startDate);
    // }
    const startDate = moment().format(event.startDate);
    const endDate = event.endDate ? moment().format(event.endDate) : null;

    // const startDate = event.startDate
    // const endDate = event.endDate ? event.endDate : null;
    const timeZone = event.timeZone;

    const startOptions = { month: "short", day: "numeric" };
    const endOptions = { month: "short", day: "numeric", year: "numeric" };

    try {
      // Use the user's time zone for formatting
      const formattedStartDate = startDate.toLocaleString("en-US", {
        ...startOptions,
        timeZone: timeZoneMap[timeZone],
      });

      let formattedEndDate = null;
      if (endDate != null) {
        formattedEndDate = endDate.toLocaleString("en-US", {
          ...endOptions,
          timeZone: timeZoneMap[timeZone],
        });
      }

      const displayDateRange = endDate
        ? `${formattedStartDate} - ${formattedEndDate}`
        : `${formattedStartDate}`;

      return displayDateRange;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  const maxLocationCharCount = 32;
  let eventAddress = event.address?.slice(0, maxLocationCharCount);
  if (event.address?.length > maxLocationCharCount) {
    eventAddress = eventAddress + "...";
  }

  return (
    <div className="bg-white border gap-4 flex flex-col rounded lg:p-3 p-2 relative">
      <div className="flex sm:flex-row flex-col lg:gap-6 gap-4">
        <div className=" rounded overflow-hidden sm:max-w-[200px] sm:min-w-[200px] max-h-[150px] h-[150px] flex justify-center self-center">
          <a
            href={event.link}
            className="cursor-pointer h-full flex justify-center items-center"
          >
            {event.imageURL ? (
              <img
                src={event.imageURL}
                alt="Event image"
                className="object-cover rounded"
              />
            ) : null}
          </a>
        </div>
        <div className="w-full">
          <div className="h-full w-full flex lg:gap-4 gap-2 sm:justify-between justify-center items-center">
            <div className="h-full w-full gap-4 sm:gap-0 flex flex-col justify-between">
              <div className="">
                <div className="text-xl font-bold mb-2">{event.title}</div>
                <div className="flex gap-x-2 items-center text-sm">
                  <img src="/assets/images/icons/schedule-black.svg" alt="" />
                  {displayDateRange()}
                  {event.startTime &&
                    " @ " +
                      format(
                        new Date(`2000-01-01T${event.startTime}`),
                        "h:mm a"
                      ) +
                      "-" +
                      format(new Date(`2000-01-01T${event.endTime}`), "h:mm a")}
                </div>
                <div className="mt-1 flex gap-x-2 items-center text-sm">
                  <img src="/assets/images/icons/location-black.svg" alt="" />
                  <Tooltip id="full-address-tooltip" />
                  <p
                    data-tooltip-id={`${
                      event.address.length > maxLocationCharCount &&
                      "full-address-tooltip"
                    }`}
                    data-tooltip-content={event.address}
                  >
                    {eventAddress}
                  </p>
                </div>
                <div className="mt-1 flex gap-x-2 items-center text-sm">
                  <img src="/assets/images/icons/map.svg" alt="" />
                  {event.city}, {event.country}
                </div>
              </div>
              <div className="flex md:flex-row md:gap-0 gap-2 flex-col gap-x-2 mb-2">
                {/* <div className={() => calendarOpen(prev => !prev)}>
                  <AddToCalendar
                      children={"Add to calendar"}
                      event={{
                        name: event.title,
                        location: event.location,
                        details: "You have not registered yet, please register here: " + event.link,
                        timeZone: event.timeZone,
                        options: "['Apple','Google','iCal','Microsoft365','Outlook.com','Yahoo']",

                        // startDate: event.startDate,
                        // endDate: event.endDate ? event.endDate : "",
                        // startTime: event.startTime,
                        // endTime: event.endTime,

                        startsAt: "2018-12-06T17:00:00-05:00",
                        endsAt: "2018-12-06T18:00:00-05:00"
                      }}
                      open={true}
                  />
                </div> */}
              </div>
            </div>
            <div className="h-full flex flex-col justify-between">
              <div
                className={`w-full flex ${
                  !isAdmin ? "flex-col gap-4 h-full" : "flex-row gap-1 mr-2 mt-2"
                } justify-between`}
              >
                {!isAdmin ? (
                  <>
                    <button
                      onClick={async () => {
                        if (!auth?.currentUser?.emailVerified) {
                          openModal();
                          return;
                        }

                        // update client saved
                        setIsSaved((prev) => !prev);

                        // update backend saved
                        await toggleEventSave(event, auth.currentUser.uid);
                      }}
                      className="flex justify-end"
                    >
                      <Tooltip id="save-tooltip" />
                      <img
                        data-tooltip-id="save-tooltip"
                        data-tooltip-content="Save"
                        src={`/assets/images/icons/${
                          isSaved ? "save-active" : "save"
                        }.svg`}
                        alt=""
                      />
                    </button>

                    <div>
                      <a
                        target="_blank"
                        href={event.link}
                        className="mb-1 px-2 py-1 w-[101px] rounded-lg bg-[#3D3D3D] text-white text-sm flex justify-center items-center hover:shadow-lg transition-all duration-300 ease-in-out"
                        onClick={() => {
                          fireGTMEventForMoreInfo(); 
                        }}
                      >
                        More info{" "}
                        <img
                          src="/assets/images/icons/arrow-out-white.svg"
                          alt=""
                        />
                      </a>

                      <button
                        onClick={shareEvent}
                        className="px-2 py-1 w-[101px] rounded-lg bg-[#3190E8] text-white text-sm flex justify-center items-center hover:shadow-lg transition-all duration-300 ease-in-out"
                        data-tooltip-id="share-tooltip"
                        data-tooltip-content="Share"
                      >
                        Share
                        <img
                          src="/assets/images/icons/share-white.svg"
                          alt="Share"
                        />
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <img
                      onClick={handleEditEvent}
                      className="cursor-pointer absolute right-10"
                      src="/assets/images/icons/edit.svg"
                    />
                    <img
                      onClick={handleDeleteEvent}
                      className="cursor-pointer absolute right-2"
                      src="/assets/images/icons/delete.svg"
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <ShareItem
          id={id ? id : event.id}
          setIsOpen={setIsOpen}
          modalIsOpen={modalIsOpen}
        />
      </div>
      {showEventForm && (
        <EventForm
          cancel={() => setShowEventForm((prev) => !prev)}
          setShowEventPostForm={setShowEventForm}
          event={event}
          isEditing={true}
          isAdmin={isAdmin}
        />
      )}
    </div>
  );
}

export default EventCard;
