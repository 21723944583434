
import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { firestore, storage } from "../../../firebaseConfig";
import { Timestamp, addDoc, arrayRemove, arrayUnion, collection, deleteDoc, doc, getCountFromServer, getDoc, getDocs, limit, onSnapshot, orderBy, query, runTransaction, serverTimestamp, startAfter, updateDoc, where } from "firebase/firestore";
import { formatDistanceToNow } from "date-fns";

/**
 * Creates a resource in the resource collection on firestore. Uploads the image (if any) to cloud firestore.
 * 
 * @param {JSON} resource - the resource to upload
 * @returns the reference to the document uploaded
 */
export const createResource = async (resource) => {
    try {
        // obtain only nessesary fields
        const {title, description, duration, type,
            userId, image, inReview, link} = resource;

        // add the document to the resource collection on firestore
        const resourceCollection = collection(firestore, "resources");
        let docRef = await addDoc(resourceCollection, {
            title: title,
            description: description,
            duration: duration,
            type: type,
            userId: userId,
            link: link,
            likes: [],
            saved: [],
            inReview: inReview,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp()
        });

        // image upload to cloud storage and get download url to reference from firestore
        let imageURL = null;
        if (image !== undefined && image !== null) {
            console.log(image);
            const storageRef = ref(storage, `images2/resources/${docRef.id}`);
            await uploadBytes(storageRef, image);
            imageURL = await getDownloadURL(storageRef);

            docRef = await updateDoc(docRef, {
                imageURL: imageURL,
            });
        }

        // return a reference to the doc uploaded
        return docRef;
    } catch (error) {
        console.error("Failed to create resource: " + error);
        throw new Error(error);
    }
}

export const getSavedResources = async (userId, lim, lastDoc) => {
    try {
        const resourcesRef = collection(firestore, "resources");
        let q = query(resourcesRef, where("saved", "array-contains", userId), limit(lim));

        if (lastDoc !== null) {
           q = query(q, startAfter(lastDoc));
        }

        const savedResourcesSnapshot = await getDocs(q);
        const savedResources = [];
        savedResourcesSnapshot.forEach(resourceDoc => {
            savedResources.push({
                ...resourceDoc.data(),
                id: resourceDoc.id,
            });
        });

        const last = savedResources[savedResources.length - 1];

        return {
            resources: savedResources,
            lastDoc: last,
        };
    } catch (error) {
        throw new Error("Could not get saved resources: " + error);
    }
}

function createResourceQueryWithoutLimit(startAfterDoc, search, sortBy, order, selectedCategory) {
    const resourcesRef = collection(firestore, 'resources');

    // Initialize query
    let q = resourcesRef;

    // Apply category filter if selectedCategory is provided
    if (selectedCategory && (selectedCategory === "All") || (selectedCategory === "Published Resources")) {
        q = query(q, where("inReview", "==", false));
    }
    if (selectedCategory && (selectedCategory !== "All" && selectedCategory !== "Published Resources")) {
        if (selectedCategory === "Pending Requests") {
            q = query(q, where("inReview", "==", true));
        } else {
            q = query(q, where("inReview", "==", false), where("type", "==", selectedCategory.toLowerCase()));
        }
    }

    // Apply search filter if search is provided
    if (search) {
        // Use the same property for search filter and first sorting order
        q = query(
            q,
            where(search.fieldPath, ">=", search.fieldValue),
            where(search.fieldPath, '<=', search.fieldValue + '\uf8ff'),
            orderBy(search.fieldPath) // Matching the property used in the search filter
        );
    }

    console.log("sortBy: ", sortBy);
    console.log("order: ", order);
    // Apply sorting
    if (order != null && sortBy != null) {
        q = query(
            q,
            orderBy(sortBy, order)
        );
    }

    // Apply pagination
    if (startAfterDoc) {
        q = query(q, startAfter(startAfterDoc));
    }

    return q;
}

/**
 * Obtain all the resources by most recently created.
 * 
 * @return the resources as an array 
 */
export const getResources = async (startAfterDoc, search, sortBy, order, selectedCategory, lim) => {
    try {
        let q = createResourceQueryWithoutLimit(startAfterDoc, search, sortBy, order, selectedCategory);

        if (lim && lim > 0) {
            q = query(q, limit(lim));
        }

        const querySnapshot = await getDocs(q);
        
        const resources = [];
        querySnapshot.forEach(resource => {
            // turn the "createdAt timestamp into a readable date"
            let date = null;
            if (resource.data().createdAt && resource.data().createdAt instanceof Timestamp) {
                date = formatDistanceToNow(new Date(resource.data().createdAt.toDate()), { addSuffix: true });
            } else {
                date = "Just now";
            };

            // add resource to list of resources
            const currentDoc = {
                id: resource.id,
                ...resource.data(),
                date: date
            }
            resources.push(currentDoc);
        });

        resources.sort((a, b) => b.createdAt - a.createdAt);

        const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1] || null;

        return {
            resources: resources,
            lastDoc, lastDoc
        };
    } catch (error) {
        console.error("Failed to get resources: " + error);
        throw new Error(error);
    }
}

export const getResourceCount = async (startAfterDoc, search, sortBy, order, selectedCategory) => {
    try {
        let q = createResourceQueryWithoutLimit(startAfterDoc, search, sortBy, order, selectedCategory);
        const countSnapshot = await getCountFromServer(q);
        const count = countSnapshot.data().count;
        return count;
    } catch (error) {
        console.error(error);
        return -1;
    }

}

/**
 * Obtains the resource that has the given id.
 * 
 * @param {string} id - the id of the resource we want to obtain 
 * @returns the resource that has the given id
 */
export const getResource = async (id) => {
    try {
        const resourceRef = doc(firestore, "resources", id);
        const resourceDoc = await getDoc(resourceRef);
        if (resourceDoc === null) {
            throw new Error("No document exists with id: " + id);
        }
        const resource = {
            ...resourceDoc.data(),
            id: resourceDoc.id
        }
        return resource;
    } catch (error) {
        console.error("Failed to get resource with id: " + id  + ": " + error);
        throw new Error(error);
    }
}

/**
 * Updates the resource with the given resource info passed in (assumes the id is part of it).
 * 
 * @param {JSON} resource - the updated resource info
 */
export const updateResource = async (resource) => {
    try {
        // only obtain the fields we actually want to update
        let {title, description, duration, type, imageURL, likes, saved, 
            id, image, inReview, link} = resource;

        if (!id) {
            throw new Error("Cannot update a resource with an undefined id!");
        }

        // replace the image with the current one
        if (image) {
            const storageRef = ref(storage, `images2/resources/${id}`);
            try {
                await getDownloadURL(storageRef);
                await deleteObject(storageRef);
            } catch (error) {
                console.error(error);
            }
            
            await uploadBytes(storageRef, image);
            imageURL = await getDownloadURL(storageRef);
        }

        // upload new data to firestore
        const updateData = {
            title: title, 
            description: description,
            duration: duration,
            type: type,
            link: link,
            inReview: inReview,
        }
        if (imageURL) {
            updateData.imageURL = imageURL;
        }
        if (saved) {
            updateData.saved = saved;
        }
        if (likes) {
            updateData.likes = likes;
        }
        console.log(updateData);
        const resourceRef = doc(firestore, "resources", id);
        await updateDoc(resourceRef, updateData);

    } catch (error) {
        console.error("Failed to update resource: " + error);
        throw new Error(error);
    }
}

/**
 * Deletes a resource from firebase. It attempts to first delete the image from cloud firestore
 * and then the data from firebase. If the image fails to delete, then the entire delete
 * operation is rolled back.
 * 
 * @param {JSON} resource - the resource to delete
 */
export const deleteResource = async (id) => {
    try {
        if (id === null) {
            throw new Error("Cannot delete a resource with a null id!");
        }

        await runTransaction(firestore, async (transaction) => {
            const storageRef = ref(storage, `images2/resources/${id}`);
            try {
                const imageURL = await getDownloadURL(storageRef);
                if (imageURL) {
                    await deleteObject(storageRef);
                }
            } catch (error) {
                console.log("No image to delete");
            }
            const postRef = doc(firestore, "resources", id);
            await deleteDoc(postRef);
        });
    } catch (error) {
        console.error("Failed to delete resource: " + error);
        throw new Error(error);
    }
}

/**
 * Toggle the saved field in the resource with your id.
 * 
 * @param {JSON} resource - resource to be saved
 * @param {string} userId - the id to save the resource under
 */
export const toggleResourceSave = async (resource, userId) => {
    try {
        if (resource.id === null) {
            throw new Error("Cannot toggle saved for a resource with no id!");
        }
        if (resource.saved === null) {
            throw new Error("Cannot toggle saved for a resource with no saved field!");
        }

        const resourceRef = doc(firestore, "resources", resource.id);
        const resourceDoc = await getDoc(resourceRef);
        if (resourceDoc.exists()) {
            // figure out to remove or add the userId to saved (toggle)
            let updateAction = null;
            if (resourceDoc.data().saved?.includes(userId)) {
                updateAction = arrayRemove(userId);
            } else {
                updateAction = arrayUnion(userId);
            }

            // toggle 
            await updateDoc(resourceRef, {
                saved: updateAction
            });
        } else {
            throw new Error("Failed to toggle resource saved because the resource doesn't exist!");
        }
    } catch (error) {
        throw new Error("Failed to toggle saved on resource due to the following: " + error);
    }
}

/**
 * Toggle the like field in the resource with your id.
 * 
 * @param {JSON} resource - resource to be liked
 * @param {string} userId - the id to like the resource under
 */
export const toggleResourceLike = async (resource, userId) => {
    try {
        if (resource.id === null) {
            throw new Error("Cannot toggle saved for a resource with no id!");
        }
        if (resource.likes === null) {
            throw new Error("Cannot toggle saved for a resource with no likes field!");
        }

        const resourceRef = doc(firestore, "resources", resource.id);
        const resourceDoc = await getDoc(resourceRef);
        if (resourceDoc.exists()) {
            // figure out to remove or add the userId to saved (toggle)
            let updateAction = null;
            if (resourceDoc.data().likes?.includes(userId)) {
                updateAction = arrayRemove(userId);
            } else {
                updateAction = arrayUnion(userId);
            }

            // toggle 
            await updateDoc(resourceRef, {
                likes: updateAction
            });
        } else {
            throw new Error("Failed to toggle resource likes because the resource doesn't exist!");
        }
    } catch (error) {
        throw new Error("Failed to toggle likes on resource due to the following: " + error);
    }
}

export const listenForResourceChanges = (onUpdate) => {
    const resourceCollection = collection(firestore, "resources");

    const unsubscribe = onSnapshot(resourceCollection, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
            // Check if the change is not a local change and has actual data changes
            const data = change.doc.data();
            let date = null;
            if (data.createdAt && data.createdAt instanceof Timestamp) {
                date = formatDistanceToNow(new Date(data.createdAt.toDate()), { addSuffix: true });
            } else {
                date = "Just now";
            }
            const updateResource = {
                id: change.doc.id,
                ...data,
                date: date,
            };
            switch (change.type) {
                case "modified":
                    onUpdate(updateResource, "modified");
                break;
                case "removed":
                    onUpdate(updateResource, "removed");
                break;
                case "added":
                    onUpdate(updateResource, "added");
                break;
            }
        });
    });

    return unsubscribe;
}