import React from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import Swal from 'sweetalert2';

export default function ForgotPassword({setForm}) {
  const navigate = useNavigate();
  const {passwordReset} = useAuth();

  /**
   * The function to send off the email to obtain a new password.
   */
    async function handleSubmitForgotPassword(values) {
        try {
            Swal.showLoading();
            await passwordReset(values.email);
            Swal.fire({title: "Success", text: "Sent an email to reset your password", "icon": 'success'});
        } catch (error) {
            Swal.close();
            console.error(error);
        }
    }
  
  const errorStyle = 'text-red-500 text-xs pt-1';
  return (
    <div className="max-w-[244px] mx-auto">
      <h1 className="text-center text-2xl font-bold mb-8">Forgot password</h1>

      <div className="mb-4 text-[#959595] text-xs font-bold">
        Please enter your email so we can send you a request to create a new password
      </div>

      <Formik
        initialValues={{
            email: "",
        }}
        validationSchema={""}
        onSubmit={handleSubmitForgotPassword}
      >
        {() => (
          <Form>
            <div className="relative mb-4">
              <label className="absolute -top-2 left-5 px-1 bg-white text-[#959595] text-xs font-medium">
                Email
              </label>
              <Field
                type="email"
                name="email"
                className="w-full p-2 border border-[#d8d8d8] rounded-xl focus:ring-primaryBlue focus:border-primaryBlue"
              />
              <div className={errorStyle}>
                <ErrorMessage name="email" />
              </div>
            </div>

            <button
              type="submit"
              className="w-full text-sm bg-primaryBlue text-white p-2 rounded-full mb-5 hover:shadow-md transition-all duration-300 ease-in-out"
            >
              Continue
            </button>
          </Form>
        )}
      </Formik>

      <div className="mb-4 text-[#959595] text-xs font-bold">
        Return to login?
        {' '}
        <button
          type="button"
          onClick={() => setForm("login")}
          className="text-primaryBlue font-normal"
        >
          Go
        </button>
      </div>
    </div>
  );
}
