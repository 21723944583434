import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function NotFound() {

  const navigate = useNavigate();

  function returnToHome() {
    navigate("/");
  }

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center">
      <h1 className="text-black font-bold">404 Not Found</h1>
      <button onClick={returnToHome} className="bg-blue-500 hover:bg-blue-700 duration-300 transition-colors text-white px-4 py-2 rounded-md mt-4">Back To Home</button>
    </div>
  )
}
