import React, { useState } from 'react';

export default function ServerSearch({initialValue = "", name, handleSearch }) {

  const [value, setValue] = useState(initialValue);

  function handleChange(e) {
    setValue(e.target.value);
  }

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      handleSearch(value);
    }
  }

  return (
    <div className="relative items-center lg:max-w-sm max-w-md sm:w-auto w-full">
      <input
        type="text"
        placeholder={name}
        className="ps-10 pe-4 rounded-md text-sm border-gray-300 w-full focus:ring-primaryBlue"
        value={value}
        onChange={handleChange} // Use handleChange for input changes
        onKeyDown={handleKeyDown} // Trigger search on Enter key press
      />
      <img
        className="absolute top-2 left-2"
        src="/assets/images/icons/search.svg"
        alt=""
      />
    </div>
  );
}