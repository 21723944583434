import React, { useEffect, useRef, useState } from 'react'
import JobForm from './JobForm'
import Swal from 'sweetalert2';
import { deleteJob } from '../../functions/firebase/firestoreJobs';
import EventForm from '../events/includes/EventForm';
import { deleteEvent } from '../../functions/firebase/firestoreEvents';
import { deleteResource } from '../../functions/firebase/firestoreResources';
import ResourceForm from '../resources/includes/ResourceForm';
import { getUser } from '../../functions/firebase/firestoreUsers';

export default function PostingRequest({isAdmin = false, job, type = "job"}) {
    const jobFormRef = useRef(null);

    const [showPosting, setShowPosting] = useState(false);
    const [userDetails, setUserDetails] = useState(null);
    
    useEffect(() => {
        const get = async () => {
            if (type === "resource") {
                const user = await getUser(job.userId);
                console.log(user);
                user.firstName = user?.firstName?.charAt(0)?.toUpperCase() + user?.firstName?.slice(1);
                user.lastName = user?.lastName?.charAt(0)?.toUpperCase() + user?.lastName?.slice(1);
                setUserDetails(user);
            }
        }
        get();
    }, []);

    function onCancel() {
        setShowPosting(false);
    }

    function handleDelete() {
        // NOTE: this code is pretty ugly, and if time in the future,
        // make it so that we make a helper function where we pass in the
        // minor changes as this is basically the same functionality
        // for each branch
        if (type === "job") {
            Swal.fire({
                title: "Are you sure you want to delete?",
                text: "This cannot be undone and the posting will be removed forever!",
                icon: "warning",
                reverseButtons: true,
                showCancelButton: true,
                confirmButtonText: "Delete",
            }).then(async result => {
                if (result.isConfirmed) {
                      Swal.fire({
                          title: 'Removing job request',
                          text: 'Please wait...',
                          allowOutsideClick: false,
                          didOpen: () => {
                          Swal.showLoading();
                      }
                    });
                    try {
                        Swal.showLoading();
                        await deleteJob(job.id);
                        Swal.fire({
                            title: "Successfully deleted job posting request!",
                            text: "The job posting request was successfully deleted",
                            icon: "success",
                        }).then(() => {
                            window.location.reload();
                        });
                    } catch (error) {
                        console.error(error);
                    } finally {
                        Swal.close();
                    }
                }
            });
        } else if (type === "event") {
            Swal.fire({
                title: "Are you sure you want to delete?",
                text: "This cannot be undone and the event will be removed forever!",
                icon: "warning",
                reverseButtons: true,
                showCancelButton: true,
                confirmButtonText: "Delete",
            }).then(async result => {
                if (result.isConfirmed) {
                      Swal.fire({
                          title: 'Removing event request',
                          text: 'Please wait...',
                          allowOutsideClick: false,
                          didOpen: () => {
                          Swal.showLoading();
                      }
                    });
                    try {
                        Swal.showLoading();
                        await deleteEvent(job.id);
                        Swal.fire({
                            title: "Successfully deleted event request!",
                            text: "The event request was successfully deleted",
                            icon: "success",
                        });
                    } catch (error) {
                        console.error(error);
                    } finally {
                        Swal.close();
                    }
                }
            });
        } else if (type === "resource") {
            Swal.fire({
                title: "Are you sure you want to delete?",
                text: "This cannot be undone and the resource will be removed forever!",
                icon: "warning",
                reverseButtons: true,
                showCancelButton: true,
                confirmButtonText: "Delete",
            }).then(async result => {
                if (result.isConfirmed) {
                      Swal.fire({
                          title: 'Removing resource request',
                          text: 'Please wait...',
                          allowOutsideClick: false,
                          didOpen: () => {
                          Swal.showLoading();
                      }
                    });
                    try {
                        Swal.showLoading();
                        await deleteResource(job.id);
                        Swal.fire({
                            title: "Successfully deleted resource request!",
                            text: "The resource request was successfully deleted",
                            icon: "success",
                        });
                    } catch (error) {
                        console.error(error);
                    } finally {
                        Swal.close();
                    }
                }
            });
        }
        
      }

    let slicedLink = job.link ? job.link.length > 30 ? job.link.slice(0, 30) + '...' : job.link : "";

    return (
        <div className="rounded-md border border-gray-300 bg-white p-4">
            <div className="flex md:flex-row md:gap-0 gap-4 flex-col md:justify-between">
                <div>
                    <h1 className="text-bold text-lg">Request from: {type === "resource" ? userDetails?.firstName + " " + userDetails?.lastName : job.email}</h1>
                    <div className="font-bold">{type === "job" ? "Job link: " : type === "event" ? "Event link: " : type === "resource" ? "Resource link: " : ""}
                    {
                        <a className="font-normal underline text-blue-700 hover:text-blue-900" target={'_blank'} href={job.link}>{slicedLink}</a> 
                    }</div>
                </div>
                <div className="flex flex-col gap-1 md:w-fit w-52">
                    <button onClick={() => setShowPosting(!showPosting)} className="bg-gray-700 text-white rounded-md px-6 py-1 hover:bg-black">{!showPosting ? "Review Request" : "^ Shrink request" }</button>
                    <button onClick={handleDelete} className="hover:text-red-900 text-red-500 font-semibold">delete request</button>
                </div>
            </div>
            {showPosting && type === "job" && <JobForm isAdmin={isAdmin} isEditing={true} cancel={onCancel} job={job}/>}
            {showPosting && type === "event" && <EventForm isAdmin={isAdmin} isEditing={true} cancel={onCancel} event={job}/>}
            {showPosting && type === "resource" && <ResourceForm isAdmin={isAdmin} isEditing={true} cancel={onCancel} resource={job}/>}
        </div>
    )
}
