export const MentionsParser = ({length = null, content, handleClick}) => {
    const mentionsRegex = /@\[([^\]]+)\]\(([^)]+)\)/g;
    let lastIndex = 0;
    const parts = [];

    let match;
    while ((match = mentionsRegex.exec(content)) !== null) {
        const [mention, username, uid] = match;

        const startIndex = match.index;
        const endIndex = startIndex + mention.length;

        // Add text before the mention
        parts.push(content.substring(lastIndex, startIndex));

        parts.push(
            <span
                onClick={() => handleClick ? handleClick(uid) : null} // Pass the mention index to handleOpenProfile
                key={startIndex}
                className="text-blue-500 cursor-pointer hover:underline"
            >
                {username}
            </span>
        );

        lastIndex = endIndex;
    }

    // Add remaining text after the last mention
    parts.push(content.substring(lastIndex));

    console.log(parts);

    return <>{length ? parts.slice(0, length) : parts}</>;
};