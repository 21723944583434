import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";

/**
 * The main navigation links for the sidenav.
 *
 * @param {State} isOpen the state of the sidenav
 * @param {State} onClose the state of the sidenav
 * @returns a fragment containing the navigation links
 */
export default function SidenavMain({isAdmin, isOpen, onClose}) {
  return (
    <>
      <li>
        <NavLink
          onClick={onClose}
          to={`${isAdmin ? "/admin" : ""}/explore`}
          className={({ isActive }) =>
            isActive
              ? "bg-primarySlateMedium text-gray-900 pl-3 py-2 rounded flex items-center gap-x-2 hover:bg-primarySlateMedium transition-all duration-300 ease-in-out"
              : "pl-3 py-2 rounded flex items-center gap-x-2 hover:bg-primarySlateMedium transition-all duration-300 ease-in-out"
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.29 7.00048L14.73 2.89048C13.1036 1.70317 10.8964 1.70317 9.27 2.89048L3.72 7.00048C2.64544 7.77462 2.0061 9.01612 2 10.3405V17.7705C2.06002 20.1637 4.04665 22.0564 6.44 22.0005H17.56C19.9534 22.0564 21.94 20.1637 22 17.7705V10.3305C21.9914 9.01185 21.3567 7.77576 20.29 7.00048ZM20.5 17.7705C20.4404 19.3354 19.1251 20.5568 17.56 20.5005H6.44C4.87698 20.5512 3.56502 19.333 3.5 17.7705V10.3405C3.50534 9.4904 3.91817 8.69448 4.61 8.20048L10.16 4.10048C11.2561 3.30006 12.7439 3.30006 13.84 4.10048L19.39 8.21048C20.0812 8.6959 20.4948 9.48583 20.5 10.3305V17.7705ZM7.5 15.7505H16.5C16.9142 15.7505 17.25 16.0863 17.25 16.5005C17.25 16.9147 16.9142 17.2505 16.5 17.2505H7.5C7.08579 17.2505 6.75 16.9147 6.75 16.5005C6.75 16.0863 7.08579 15.7505 7.5 15.7505Z"
              fill="#3D3D3D"
            />
          </svg>{" "}
          Explore
        </NavLink>
      </li>
      <li>
        <NavLink
          onClick={onClose}
          to={`${isAdmin ? "/admin" : ""}/events`}
          className={({ isActive }) =>
            isActive
              ? "bg-primarySlateMedium text-gray-900 pl-3 py-2 rounded flex items-center gap-x-2 hover:bg-primarySlateMedium transition-all duration-300 ease-in-out"
              : "pl-3 py-2 rounded flex items-center gap-x-2 hover:bg-primarySlateMedium transition-all duration-300 ease-in-out"
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M16.5 8.16H7.5C7.08579 8.16 6.75 8.49579 6.75 8.91C6.75 9.32421 7.08579 9.66 7.5 9.66H16.5C16.9142 9.66 17.25 9.32421 17.25 8.91C17.25 8.49579 16.9142 8.16 16.5 8.16Z"
              fill="#3D3D3D"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.89 3.57H17C19.7614 3.57 22 5.80858 22 8.57V17.57C22 20.3314 19.7614 22.57 17 22.57H7C5.67392 22.57 4.40215 22.0432 3.46447 21.1055C2.52678 20.1679 2 18.8961 2 17.57V8.57C2 5.80858 4.23858 3.57 7 3.57H7.09V1.75C7.09 1.33579 7.42579 1 7.84 1C8.25421 1 8.59 1.33579 8.59 1.75V3.57H15.39V1.75C15.39 1.33579 15.7258 1 16.14 1C16.5542 1 16.89 1.33579 16.89 1.75V3.57ZM17 21.07C18.933 21.07 20.5 19.503 20.5 17.57V8.57C20.5 6.637 18.933 5.07 17 5.07H7C5.067 5.07 3.5 6.637 3.5 8.57V17.57C3.5 19.503 5.067 21.07 7 21.07H17Z"
              fill="#3D3D3D"
            />
          </svg>{" "}
          Events
        </NavLink>
      </li>
      <li>
        <NavLink
          onClick={onClose}
          to={`${isAdmin ? "/admin" : ""}/jobs`}
          className={({ isActive }) =>
            isActive
              ? "bg-primarySlateMedium text-gray-900 pl-3 py-2 rounded flex items-center gap-x-2 hover:bg-primarySlateMedium transition-all duration-300 ease-in-out"
              : "pl-3 py-2 rounded flex items-center gap-x-2 hover:bg-primarySlateMedium transition-all duration-300 ease-in-out"
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M14 6.5V4.5H10V6.5H14ZM4 8.5V19.5H20V8.5H4ZM20 6.5C21.11 6.5 22 7.39 22 8.5V19.5C22 20.61 21.11 21.5 20 21.5H4C2.89 21.5 2 20.61 2 19.5L2.01 8.5C2.01 7.39 2.89 6.5 4 6.5H8V4.5C8 3.39 8.89 2.5 10 2.5H14C15.11 2.5 16 3.39 16 4.5V6.5H20Z"
              fill="#3D3D3D"
            />
          </svg>{" "}
          Jobs
        </NavLink>
      </li>
      <li>
        <NavLink
          onClick={onClose}
          to={`${isAdmin ? "/admin" : ""}/resources`}
          className={({ isActive }) =>
            isActive
              ? "bg-primarySlateMedium text-gray-900 pl-3 py-2 rounded flex items-center gap-x-2 hover:bg-primarySlateMedium transition-all duration-300 ease-in-out"
              : "pl-3 py-2 rounded flex items-center gap-x-2 hover:bg-primarySlateMedium transition-all duration-300 ease-in-out"
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.95 9.11C21.6908 9.10827 22.3718 9.51614 22.72 10.17C23.0816 10.8105 23.0624 11.5978 22.67 12.22L18.1 19.07C17.6249 19.8375 16.6947 20.1916 15.8295 19.9344C14.9643 19.6772 14.3786 18.8724 14.4 17.97V15.12H12.26C11.5441 15.1228 10.8812 14.7428 10.522 14.1235C10.1628 13.5041 10.162 12.74 10.52 12.12L15.16 4C15.5169 3.38183 16.1762 3.00073 16.89 3C17.9946 3 18.89 3.89543 18.89 5V9.11H20.95ZM16.8 18.24L21.37 11.39C21.486 11.2356 21.5028 11.0282 21.413 10.8572C21.3232 10.6862 21.143 10.5822 20.95 10.59H18.39C17.8377 10.59 17.39 10.1423 17.39 9.59V4.98C17.39 4.84564 17.336 4.71692 17.24 4.62286C17.1441 4.5288 17.0143 4.47731 16.88 4.48C16.6969 4.48062 16.5301 4.58535 16.45 4.75L11.81 12.87C11.7251 13.0251 11.7285 13.2135 11.8189 13.3655C11.9092 13.5175 12.0732 13.6105 12.25 13.61H14.89C15.4423 13.61 15.89 14.0577 15.89 14.61V17.96C15.8927 18.1772 16.0353 18.3679 16.2429 18.4318C16.4506 18.4956 16.6757 18.4182 16.8 18.24Z"
              fill="black"
            />
            <path
              d="M8.75 5.85H3.95C3.53579 5.85 3.2 6.18579 3.2 6.6C3.2 7.01421 3.53579 7.35 3.95 7.35H8.75C9.16421 7.35 9.5 7.01421 9.5 6.6C9.5 6.18579 9.16421 5.85 8.75 5.85Z"
              fill="black"
            />
            <path
              d="M8.3 11.33C8.29461 10.918 7.96196 10.5854 7.55 10.58H2.75C2.33579 10.58 2 10.9158 2 11.33C2 11.7442 2.33579 12.08 2.75 12.08H7.55C7.74891 12.08 7.93968 12.001 8.08033 11.8603C8.22098 11.7197 8.3 11.5289 8.3 11.33Z"
              fill="black"
            />
            <path
              d="M8.75 16.35H3.95C3.53579 16.35 3.2 16.6858 3.2 17.1C3.2 17.5142 3.53579 17.85 3.95 17.85H8.75C9.16421 17.85 9.5 17.5142 9.5 17.1C9.5 16.6858 9.16421 16.35 8.75 16.35Z"
              fill="black"
            />
          </svg>{" "}
          Resources
        </NavLink>
      </li>
      <li>
        <NavLink
          onClick={onClose}
          to={`${isAdmin ? "/admin" : ""}/saved`}
          className={({ isActive }) =>
            isActive
              ? "bg-primarySlateMedium text-gray-900 pl-3 py-2 rounded flex items-center gap-x-2 hover:bg-primarySlateMedium transition-all duration-300 ease-in-out"
              : "pl-3 py-2 rounded flex items-center gap-x-2 hover:bg-primarySlateMedium transition-all duration-300 ease-in-out"
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7 3H17C18.1 3 19 3.9 19 5V21L12 18L5 21V5C5 3.9 5.9 3 7 3ZM12 15.82L17 18V5H7V18L12 15.82Z"
              fill="#3D3D3D"
            />
          </svg>{" "}
          Saved
        </NavLink>
      </li>
    </>
  );
}
