import React, { useEffect, useState } from 'react';
import { deleteNotification, toggleNotificationRead } from '../../../functions/firebase/firestoreNotifications';
import Swal from 'sweetalert2';
import { getUser } from '../../../functions/firebase/firestoreUsers';
import { getPost, getPostComment } from '../../../functions/firebase/firestorePosts';
import { useHover } from '@uidotdev/usehooks';
import { MentionsParser } from '../../../components/common/MentionsParser';


export default function NotificationCard({notification}) {

    const [sender, setSender] = useState(null);
    const [showOptions, setShowOptions] = useState(false);
    const [message, setMessage] = useState("");
    const [hover, setHover] = useState(false);

    async function constructNotificationMessage() {
        let dataInfo = "";
        let dataLength = -1;
        let actionInfo = "";
        const maxDataInfoLength = 100;
        if (notification.type === "post") { 
            if (notification.action === "like") {
                actionInfo = "liked your post: ";
            }
            const data = await getPost(notification.postId); 
            dataInfo = `"` + data.title.slice(0, maxDataInfoLength) + `"`;
            dataLength = data.title.length;
        } else if (notification.type === "comment") {
            if (notification.action === "comment") {
                actionInfo = "commented on your post: ";
            }
            const data = await getPostComment(notification.postId, notification.commentId);
            dataInfo = `"` + data.content.slice(0, maxDataInfoLength) + `"`;
            dataLength = data.content.length;
        } else if (notification.type === "reply") {
            if (notification.action === "comment") {
                actionInfo = "replied on comment: ";
            }
            const data = await getPostComment(notification.postId, notification.commentId);
            dataInfo = `"` + data.content.slice(0, maxDataInfoLength) + `"`;
            dataLength = data.content.length;
        } else if (notification.type === "mention") {
            actionInfo = "has mentioned you in a post";
            // const data = await getPostComment(notification.postId, notification.commentId);
            // dataInfo = `"` + data.content.slice(0, maxDataInfoLength) + `"`;
            // dataLength = data.content.length;
            dataInfo = "";
            dataLength = 0;
        }
        setMessage(`${actionInfo}${dataInfo}${dataLength >= maxDataInfoLength ? "..." : ""}`);
    }

    useEffect(() => {
        const get = async () => {
            notification.link = `/explore/${notification.postId}`;

            constructNotificationMessage();

            getUser(notification?.senderId).then(incomingSender => {

                incomingSender.firstName = incomingSender.firstName?.charAt(0)?.toUpperCase() + incomingSender.firstName?.slice(1);

                incomingSender.lastName = incomingSender.lastName?.charAt(0)?.toUpperCase() + incomingSender.lastName?.slice(1);

                setSender(incomingSender);
            });
        }
        get();
    }, []);

    const customClass = {
        confirmButton: "max-w-[200px]",
    }


    async function handleDelete(event, element) {
        try {
            Swal.fire({
                title: "Are you sure you want to delete this notification?",
                text: "The notification will be deleted forever and this cannot be undone!",
                icon: "warning",
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonColor: "red",
                confirmButtonText: "Delete all",
                customClass: customClass,
            }).then(async result => {
                if (result.isConfirmed) {
                    await deleteNotification(notification.id);
                }
            });
        } catch (error) {
            console.error(error);
        }
        event.stopPropagation();
    }

    async function handleNotificationClick() {
        if (!notification.hasRead) {
            await toggleNotificationRead(notification);
        }
        window.location.href = `/explore/${notification.postId}`;
    }

    function handleClickOptions(event, element) {
        setShowOptions(prev => !prev);
        event.stopPropagation();
    }

    async function handleMarkRead(event, element) {
        try {
            event.stopPropagation();
            setShowOptions(false);
            await toggleNotificationRead(notification);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (!hover) {
            setShowOptions(false);
        }
    }, [hover]);

    const maxMessageSize = 55;

    return (
        <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={handleNotificationClick} className="flex min-h-[110px] max-h-[110px] items-center gap-3 border-b border-gray-300 py-3 hover:bg-gray-50 relative cursor-pointer">
          <div className="flex-shrink-0">
            <div className="w-12 h-12 rounded-full bg-gray-300 flex items-center justify-center overflow-hidden">
              {sender?.profilePictureURL
                ? <img className="w-full h-full rounded-full" src={sender.profilePictureURL} alt={`${sender?.firstName} ${sender?.lastName} profile picture`} />
                : <div className="rounded-full bg-gray-320 w-full h-full"></div> // Grey fallback with correct size
              }
            </div>
          </div>
          <div className="flex-grow min-w-0">
            <p className="text-sm font-medium text-gray-900">
              <strong>{sender?.firstName} {sender?.lastName}</strong>
              <span className="text-gray-900">{" "}<MentionsParser length={maxMessageSize} content={message} handleClick={null}/>{message.length >= maxMessageSize ? "..." : ""}</span>
            </p>
            <p className="text-xs text-blue-500 pt-1">{notification?.date ? notification.date : ""}</p>
          </div>
      
            {
                hover && 
                <div onClick={handleClickOptions} className="z-50 cursor-pointer ring-2 ring-gray-400 ring-opacity-70 absolute right-4 bottom-8 rounded-full bg-gray-500 opacity-70 hover:ring-gray-400 text-white w-8 h-8 text-center flex flex-row items-center justify-center gap-0.5 hover:bg-gray-800 transition-colors duration-300">
                    <div className="bg-white rounded-full w-1.5 h-1.5"></div>
                    <div className="bg-white rounded-full w-1.5 h-1.5"></div>
                    <div className="bg-white rounded-full w-1.5 h-1.5"></div>
                </div> 
            }

            {
                !notification.hasRead &&
                <div className="absolute top-3 right-2 rounded-full w-3 h-3 bg-blue-500"></div>
            }

            {
                showOptions &&
                <div className="z-50 bg-white border border-gray-300 rounded-md p-2 absolute -bottom-12 right-1 w-40 space-y-1">
                 
                    <button onClick={handleMarkRead} className="hover:bg-gray-300 w-full flex justify-left gap-1">
                        <img src="/assets/images/icons/check.svg" /> {notification.hasRead ? "Mark as unread" : "Mark as read"}
                    </button>
                
                    <hr/>
                    <button onClick={handleDelete} className="hover:bg-gray-300 w-full flex justify-left gap-1">
                        <img src="/assets/images/icons/delete.svg" /><span>Delete</span>
                    </button>
                </div>
            }
        </div>
    );
}