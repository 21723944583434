import React from 'react'


export default function Announcements() {
  return (
    <div
    data-testid="announcement"
    className="p-2 hidden md:block mt-10 border w-full max-w-[258px] h-[395px] bg-white rounded relative"
    >
        <h1 className="font-bold text-lg">Announcements</h1>
        <br />
        <p>Current release: Beta V 1.6.1</p>
        <br />
        <p>Kindly report any bugs or errors if noticed.</p>
        <br />
        <a href="https://beta.uxwashere.com/explore/d1hi2VsiLH1ZiAfOKAWB" className="underline text-blue-700 hover:text-blue-900">View Release Notes</a>

        <p className="absolute bottom-2 left-2">Last updated: April 11th 2024</p>
    </div>
  );
}