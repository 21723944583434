import React, { useEffect, useState } from 'react';
import {
  ErrorMessage, Field, Form, Formik,
} from 'formik';
import { redirect, useNavigate } from 'react-router-dom';
import { personalInfoValidationSchema } from '../../../functions/validationSchemas';
import { createUser, getUser } from "../../../functions/firebase/firestoreUsers";
import { useAuth } from '../../../context/AuthContext';
import { useUser } from '../../../context/UserContext';
import { useToast } from '../../../context/ToastContext';
import iso from 'iso-3166-1-alpha-2';

export default function PersonalInfoForm({ setForm }) {
  const navigate = useNavigate();
  const auth = useAuth();
  const { showSuccessToast, showErrorToast, showLoadingToast } = useToast();

  const handleSubmit = async (values) => {
    try {
      console.log(auth);
      const user = values;
      user.email = auth.currentUser.email;
      user.uid = auth.currentUser.uid;
      user.profilePictureURL = "";
      user.bio = "";
      user.lastOnline = Date.now();
      await createUser(values, user.uid);
      showSuccessToast('User Info Updated!');
      navigate('/explore');
    } catch (error) {
      showErrorToast();
      console.log('could not create user due to the following: ' + error);
    }
  };

  const countryList = iso.getCodes().map(code => iso.getCountry(code));

  // styles
  const errorStyle = 'text-red-500 text-xs pt-1';
  const inputStyle = 'text-xs mt-1 p-2 w-full border rounded border-[#939393] text-[#000000] focus:border-primaryBlue focus:ring-primaryBlue';
  return (
    <>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            country: '',
            phoneNumber: '',
            role: [],
            occupation: '',
            newsletter: false,
            jobAlerts: false,
          }}
          validationSchema={personalInfoValidationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form className="w-3/4 mx-auto border-gray-300 border sm:w-full">
            <h1 className="sm:px-24 px-10 text-center text-xl py-4 font-medium mb-3 bg-[#E8E8E8]">
          Tell us a little bit about you!
        </h1>
              <div className="overflow-auto max-w-[347px] mx-auto sm:max-h-[537px] max-h-[437px] px-4 form-scroll">
                <label className="block text-sm font-medium">
                  What is your full name?
                  {' '}
                  <span className="text-xs font-normal">
                    (
                    <span className="italic text-red-400">Required</span>
                    {' '}
                    )
                  </span>
                </label>
                <div className="flex space-x-4 mb-4">
                  <div className="flex-1">
                    <Field
                      id="firstName"
                      name="firstName"
                      type="text"
                      placeholder="First name"
                      className={inputStyle}
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className={errorStyle}
                    />
                  </div>
                  <div className="flex-1">
                    <Field
                      id="lastName"
                      name="lastName"
                      type="text"
                      placeholder="Last name"
                      className={inputStyle}
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className={errorStyle}
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="country"
                    className="block text-sm font-medium"
                  >
                    Country / Region
                    {' '}
                    <span className="text-xs font-normal">
                      (
                      <span className="italic text-red-400">Required</span>
                      {' '}
                      )
                    </span>
                  </label>
                  <Field
                    as="select"
                    id="country"
                    name="country"
                    className={inputStyle}
                  >
                    <option value="" disabled defaultValue>
                      Select an option
                    </option>
                    {' '}
                    {
                      countryList.map((country, index) => {
                        return <option value={country}>{country}</option>
                      })
                    }
                    <option value="Other">Other</option>
                  </Field>
                  <ErrorMessage
                    name="country"
                    component="div"
                    className={errorStyle}
                  />
                </div>

                {/* Phone number */}
                <div className="mb-4">
                  <label
                    htmlFor="phoneNumber"
                    className="block text-sm font-medium"
                  >
                    Phone number
                    {' '}
                    <span className="text-xs font-normal">
                      (
                      <span className="italic text-[#959595]">Optional</span>
                      {' '}
                      )
                    </span>
                  </label>
                  <Field
                    id="phoneNumber"
                    name="phoneNumber"
                    type="text"
                    placeholder="Phone number"
                    className={inputStyle}
                  />
                  <ErrorMessage
                    name="phoneNumber"
                    component="div"
                    className={errorStyle}
                  />
                </div>
                {/* Roles checkboxes */}
                <div className="mb-6">
                  <p className="text-sm font-medium mb-2">
                    What do you do? Choose one or more.
                    {' '}
                    <span className="text-xs font-normal">
                      (
                      <span className="italic text-red-400">Required</span>
                      {' '}
                      )
                    </span>
                  </p>
                  <div className="grid grid-cols-2 gap-x-2">
                    {[
                      'UX Designer',
                      'UI Designer',
                      'UX Researcher',
                      'UX Manager/Lead',
                      'Product Designer',
                      'Interaction Designer',
                      'Visual Designer',
                      'UX Writer',
                      'Student ( UX or related)',
                      'UX Instructor',
                    ].map((role) => (
                      <div key={role} className="relative">
                        <Field
                          type="checkbox"
                          name="role"
                          value={role}
                          className="hidden"
                          id={role}
                        />
                        <label
                          htmlFor={role}
                          className="block text-[#959595] mt-2 px-2 py-1 border rounded hover:border-primaryBlue cursor-pointer transition-all duration-300 ease-in-out"
                        >
                          <span className="text-xs">{role}</span>
                        </label>
                      </div>
                    ))}
                  </div>
                  <ErrorMessage
                    name="role"
                    component="div"
                    className={errorStyle}
                  />
                </div>

                {/* Occupation Radio buttons */}
                <div className="mb-6 border-b pb-10 border-[#c4c4c4]">
                  <p className="text-sm font-medium mb-2">
                    Occupation
                    {' '}
                    <span className="text-xs font-normal">
                      (
                      <span className="italic text-[#959595]">Optional</span>
                      {' '}
                      )
                    </span>
                  </p>
                  <div className="grid grid-cols-2 gap-x-2">
                    {[
                      'Early career',
                      'Developing professional',
                      'Skilled practitioner',
                      'Specialized expert',
                      'Leading professional',
                      'Legendary mentor',
                    ].map((journey) => (
                      <div key={journey} className="relative">
                        <Field
                          type="radio"
                          name="occupation"
                          value={journey}
                          className="hidden"
                          id={journey}
                        />
                        <label
                          htmlFor={journey}
                          className="block text-[#959595] mt-2 px-2 py-1 border rounded hover:border-primaryBlue cursor-pointer transition-all duration-300 ease-in-out"
                        >
                          <span className="text-xs">{journey}</span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="mb-4">
                  <label className="block mt-2 text-sm">
                    <Field
                      type="checkbox"
                      name="newsletter"
                      className="mr-2 mb-[3px] border-[#bbbbbb] w-[0.9rem] h-[0.9rem] rounded checked:bg-primaryBlue accent-primaryBlue focus:ring-0"
                    />
                    Newsletters
                  </label>
                  <p className="text-xs text-[#6B6B6B] pt-2">
                    Yes, I want to discover unique insights in UX design and
                    explore connections that inspire creativity. Sign me up for
                    "UX was here" newsletter!
                  </p>

                  <label className="block mt-6 text-sm">
                    <Field
                      type="checkbox"
                      name="jobAlerts"
                      className="mr-2 mb-[3px] border-[#bbbbbb] w-[0.9rem] h-[0.9rem] rounded checked:bg-primaryBlue accent-primaryBlue focus:ring-0"
                    />
                    Job Alerts
                  </label>
                  <p className="text-xs text-[#6B6B6B] pt-2">
                    Keep me in the loop! Send me tailored job alerts in the
                    field of UX design, and let me explore opportunities that
                    match my creativity and passion.
                  </p>
                </div>
              </div>
              <div className="mt-6 bg-[#E8E8E8] p-4 flex justify-end items-center gap-2">
                <button
                  type="button"
                  onClick={async () => {
                    try {
                      await auth.signOut();
                      setForm("login");
                    } catch (error) {
                      console.error(error);
                    }
                  }}
                  className="border border-[#959595] text-primaryBlue text-sm py-2 px-4 rounded-full hover:shadow-md transition-all duration-300 ease-in-out"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-primaryBlue text-white text-sm py-2 px-4 rounded-full hover:shadow-md transition-all duration-300 ease-in-out"
                >
                  Let's rock!
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </>
  );
}
