import { ErrorMessage, Field } from "formik";
import ReactQuill from "react-quill";

/**
 * Helper function to generate a number input.
 * 
 * @param {String} displayName - display name of the number input
 * @param {String} inputName - the name of the number input
 * @param {Object} formValues - used to obtain all the formik values for the form
 * @param {Function} setFieldValue - updates the formik state of the field value
 * @param {Object} jobPostingValidationSchema - schema to check whether we should show error message 
 * @param {Number} min - min value you can enter
 * @param {Number} max - max value you can enter
 * 
 * @returns Number input in jsx form
 */
export const NumberInput = ({id, displayName, inputName, formValues, setFieldValue, jobPostingValidationSchema, min = 0, max = 50}) => {
    const hasError = jobPostingValidationSchema && jobPostingValidationSchema.fields[inputName] && jobPostingValidationSchema.fields[inputName].tests.length > 0;

    const handleChange = (e) => {
      let value = parseInt(e.target.value);
      // Check if the entered value is a number and within the range 1-10
      if (!isNaN(value) && value >= min && value <= max) {
        setFieldValue(inputName, value);
      } else {
        // Handle values outside the desired range
        // For example, setting the value to 1 if it's less than 1 or 10 if it's greater than 10
        setFieldValue(inputName, Math.min(Math.max(value, min), max));
      }
    };
  
    return (
      <div>
        <label className="z-10 mb-1 flex-1">
          {displayName}
          {/* <ErrorMessage name={inputName} component="div" className={""} /> */}
        </label>
        <br/>
        <Field id={id}
          className="flex items-center border border-gray-300 rounded-md py-1 px-3 w-full"
          name={inputName}
          value={formValues[inputName] !== null && formValues[inputName]}
          onChange={handleChange}
          type="number"
        />
        {hasError && (
        <ErrorMessage name={inputName} component="div" className="text-red-500 text-sm" />
      )}
      </div>
    );
  }

  /**
   * Helper function to generate a select input.
   * 
   * @param {String} displayName - display name of the select input
   * @param {String} inputName - name of the select input
   * @param {JSX} options - jsx for input options
   *  
   * @returns JSX for the select input generated
   */
  export const GenerateSelectInput = ({id, displayName, inputName, options, jobPostingValidationSchema}) => {
    const hasError = jobPostingValidationSchema && jobPostingValidationSchema.fields[inputName] && jobPostingValidationSchema.fields[inputName].tests.length > 0;
  
    return (
      <div>
        <label className="z-10 mb-1 flex-1">
          {displayName}
          :
        </label>
        <br />
        <Field id={id}
          as="select"
          className="border border-gray-300 rounded-md py-1 px-3 focus:outline-none focus:border-blue-500 w-full"
          name={inputName}
        >
          {options}
        </Field>
        { hasError && (
        <ErrorMessage name={inputName} component="div" className="text-red-500 text-sm" />
      )}
      </div>
    );
  }

  /**
   * Generates a form input. 
   */
  export const GenerateFormInput = ({id, displayName, inputName, jobPostingValidationSchema, type="text"}) => {
    let hasError = jobPostingValidationSchema && jobPostingValidationSchema.fields[inputName] && jobPostingValidationSchema.fields[inputName].tests.length > 0;

    if (type === "date") {
      hasError = jobPostingValidationSchema && jobPostingValidationSchema.fields[inputName] && jobPostingValidationSchema.fields[inputName] !== "yyyy-mm-dd" > 0;
    };

    return (
      <>
        <label className="z-10 mb-1 flex-1">
          {displayName}
          :
        </label>
        <br />
        <Field id={id}
          className='border border-gray-300 rounded-md py-1 px-3 focus:outline-none focus:border-blue-500 w-full'
          name={inputName}
          type={type}
        />
        {hasError && (
          <ErrorMessage name={inputName} component="div" className="text-red-500 text-sm" />
        )}
      </>
    );
  }

  /**
 * Rich text editor handler.
 * 
 * @param {String} displayName label name for the rich text editor box 
 * @param {String} inputName - name to connecting this to the variable name in the form
 * @param {Object} formValues - formik values so that we can update the file
 * @param {Function} setFieldValue - formik set field value
 * @param {Boolean} touched - check to see if the rich text has been touched yet so we can display
 *  any error message
 * @param {Function} update the state of touched
 * 
 * @returns the jsx for the rich text handler
 */
  export const GenerateRichText = ({id, displayName, inputName, formValues, setFieldValue, touched, setTouched}) => {

    const value = formValues[inputName];
    const strippedValue = value.replace(/<[^>]*>/g, '').trim();
    const hasError = touched && strippedValue === '';
  
    function handleChange(e) {
      setTouched(true);
      setFieldValue(inputName, e);
    }
  
    return (
      <>
        <label>{displayName}</label>
        <ReactQuill id={id}
          className="h-40 mb-12"
          theme="snow"
          value={value}
          onChange={handleChange}
          onChangeSelection={() => setTouched(true)}
        />
        {hasError && inputName === 'description' && touched && (
          <div className="text-red-500 text-sm">Description cannot be blank</div>
        )}
      </>
    );
  }