import { useEffect, useMemo, useState } from 'react';
import eventsData from '../../data/hardcodedEvents.json';
import EventCard from '../../components/common/EventCard';
import { useLocation, useParams } from 'react-router-dom';
import Announcements from '../../components/common/Announcements';
import LoginModal from '../../components/util/LoginModal';
import { useAuth } from '../../context/AuthContext';
import CardPosting from '../../components/CardPosting';
import { Helmet } from 'react-helmet';
import { getEvent, getEventCount, getEvents, listenForEventChanges } from '../../functions/firebase/firestoreEvents';
import PostingRequest from '../jobs/PostingRequest';
import FilterComponentList from '../jobs/FilterComponentList';
import { updateLocationFilters } from '../jobs/FilterService';
import ServerSearch from '../jobs/ServerSearch';
import countriesData from '../../countries.json';


function Events({isAdmin}) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // put together search where filter
  let search = null;
  const searchValue = searchParams.get("search"); 
  if (searchValue) {
    // Preprocess search field value into an array of characters
    search = {
      fieldPath: "title",
      op: "array-contains-any",
      fieldValue: searchValue,
    };
  }

  // put together jobs per page count filter
  let eventsPerPage = searchParams.get("count") || 10;

  // put together order by date filter
  let sortBy = searchParams.get("sortBy") || "createdAt";
  let order = searchParams.get("order") || "desc";

  // put together selected category
  let selectedCategory = searchParams.get("category") || (isAdmin ? "Pending Requests" : "All");

  // put together job sites
  let eventTypes = searchParams.get("eventTypes");
  let eventTypesArray = eventTypes ? eventTypes.split(',') : [];

  // put together locations
  let eventLocations = searchParams.get("locations");
  let eventLocationsArray = eventLocations ? eventLocations.split(',') : [];

  const eventTypeItems = [
    {
      label: 'In Person',
      description: 'Events that have location requirement',
    },
    {
        label: 'Online',
        description: 'Events with no location requirement',
    },
    {
        label: "Hybrid",
        description: "Combination of both"
    }
  ];

  let categories = isAdmin ? 
  [
    "Published Events",
    "Pending Requests",
  ] 
  : 
  [
    'All',
    'Design',
    'Content',
    'Research'
  ];

  const { id } = useParams();
  const auth = useAuth();

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState(eventLocationsArray);
  const [locationItems, setLocationItems] = useState([]);
  const [showLocationDropdown, setShowLocationDropdown] = useState(false);
  const [selectedEventTypes, setSelectedEventTypes] = useState(eventTypesArray);
  const [showEventTypeDropdown, setShowEventTypeDropdown] = useState(false);
  const [events, setEvents] = useState([]);
  const [moreEvents, setMoreEvents] = useState(true);
  const [lastDoc, setLastDoc] = useState(null);
  const [eventCount, setEventCount] = useState(0);
  const [pendingRequestCount, setPendingRequestCount] = useState(0);
  const [locationFiltersActive, setLocationFiltersActive] = useState(false);
  const [eventTypeFiltersActive, setEventTypeFiltersActive] = useState(false);

  /**
   * Close change the filter that is visible to the one clicked on
   * @param {} setDropdown 
   */
  const toggleFilterDropdown = (setDropdown) => {
    // close all by default
    setShowEventTypeDropdown(false);
    setShowLocationDropdown(false);

    // open the one passed in
    setDropdown();
  }
  
  function openSignInModal() {
    setShowLoginModal(true);
  }


  useEffect(() => {
    if (showLocationDropdown || showEventTypeDropdown) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [showLocationDropdown, showEventTypeDropdown]);

  useEffect(() => {
    const get = async () => {
      if (!auth.currentUser) {
        openSignInModal();
      }
      let incomingEventData = null;
  
      if (id) {
        console.log(id);
        incomingEventData = [await getEvent(id)];
        setEvents(incomingEventData.events);
      } else {
        incomingEventData = await getEvents(lastDoc, search, sortBy, order, selectedCategory, selectedEventTypes, selectedLocations, eventsPerPage > 50 ? 50 : eventsPerPage);

        const count = await getEventCount(lastDoc, search, sortBy, order, selectedCategory, selectedEventTypes, selectedLocations);
        setEventCount(count);

        const requestCount = await getEventCount(lastDoc, null, null, null, "Pending Requests", [], []);
        setPendingRequestCount(requestCount);
        let countries = countriesData.countries;
        updateLocationFilters(incomingEventData.events, setLocationItems, selectedCategory, isAdmin, "Country", countries);

        setEvents(incomingEventData.events);
        setLastDoc(incomingEventData.lastDoc);
      }
    };
    get();

    // const unsubscribe = realTimeEvents(setBothEvents);
  
    // return () => {
    //   unsubscribe();
    // };

    const urlParams = new URLSearchParams(window.location.search);
    const locationsParam = urlParams.get('locations');
    const eventTypes = urlParams.get('eventTypes');
    if (locationsParam && locationsParam.length > 0) {
      setLocationFiltersActive(true);
    }

    if (eventTypes && eventTypes.length > 0) {
      setEventTypeFiltersActive(true);
    }
  }, []);

  async function fetchMoreEvents() {
    if (moreEvents) {
      try {
        const incomingEventData = await getEvents(
          lastDoc,
          search,
          sortBy,
          order,
          selectedCategory,
          selectedEventTypes,
          selectedLocations,
          eventsPerPage > 50 ? 50 : eventsPerPage
        );
  
        // Check if there are no more events or the number of events fetched is less than the specified count
        if (
          incomingEventData.lastDoc === null ||
          incomingEventData.lastDoc === lastDoc
        ) {
          setMoreEvents(false);
          return;
        }
        
        let countries = countriesData.countries;
        updateLocationFilters(
          incomingEventData.events,
          setLocationItems,
          selectedCategory,
          isAdmin,
          "Country",
          countries
        );
  
        setLastDoc(incomingEventData.lastDoc);
        setEvents((prevEvents) => {
          return [...prevEvents, ...incomingEventData.events];
        });
      } catch (error) {
        console.error(error);
      }
    }
  }

  function handleSearch(searchString) {
    const url = new URL(window.location.href);
    url.searchParams.set('search', searchString);
    
    // Reload the page with the new URL
    window.location.href = url.toString();
  }

  function handleEventsPerPageChange(e) {
    const url = new URL(window.location.href);
    url.searchParams.set("count", e.target.value);

    // Reload the page with the new URL
    window.location.href = url.toString();
  }

  function handleSortBy(e) {
    const options = {
      type: e.target.value.split(' ')[0],
      order: e.target.value.split(' ')[1],
    };
    
    const url = new URL(window.location.href);
    url.searchParams.set("sortBy", options.type);
    url.searchParams.set("order", options.order);

    // Reload the page with the new URL
    window.location.href = url.toString();
  }

  function handleCategory(category) {
    const url = new URL(window.location.href);
    url.searchParams.set("category", category);

    // Reload the page with the new URL
    window.location.href = url.toString();
  }

  function handleEventType(clearAll = false) {
    const eventTypesJoined = clearAll ? '' : selectedEventTypes.join(",");
    
    const url = new URL(window.location.href);
    url.searchParams.set("eventTypes", eventTypesJoined);

    // Reload the page with the new URL
    window.location.href = url.toString();
  }

  function handleLocation(clearAll = false) {
    const eventLocations = clearAll ? '' : selectedLocations.join(",");
    
    const url = new URL(window.location.href);
    url.searchParams.set("locations", eventLocations);

    // Reload the page with the new URL
    window.location.href = url.toString();
  }


  return (
    <div className="flex font-dmsans">

      <Helmet>
        <title>UX Was Here - Events</title>
      </Helmet>

       <div className="flex-1 lg:pt-8 py-4 lg:px-14 md:px-6">
      <h2 className="text-2xl pb-4 font-bold">Events</h2>
      {!id && <>
        <div className="flex flex-wrap lg:gap-x-10 gap-x-4">
          {categories.map((category) => (
            <button
              key={category}
              className={`px-2 text-lg ${
                selectedCategory === category
                  ? 'border-b border-black text-black'
                  : 'text-[#959595]'
              }`}
              onClick={() => handleCategory(category)}
            >
              {category}
              {" "}
              {category === 'Pending Requests' && <span className="text-orange-700">{pendingRequestCount}</span>}
            </button>
          ))}
        </div>
    
        <div className="my-5 flex flex-wrap gap-y-4 gap-x-4 sm:items-center">
          <div className="flex sm:flex-nowrap flex-wrap sm:gap-4 gap-2">

            <FilterComponentList id={1}
              filterActive={locationFiltersActive}
              name="Location" icon={locationFiltersActive ? "/assets/images/icons/location-pin-white.svg" : "/assets/images/icons/location-pin.svg"}
              selectedItems={selectedLocations} items={locationItems} setSelectedItems={setSelectedLocations} toggleDropdown={() => toggleFilterDropdown(() => setShowLocationDropdown(!showLocationDropdown))} showItemDropdown={showLocationDropdown} setShowItemDropdown={setShowLocationDropdown} isLocation={true}
              handleApply={handleLocation} 
              type="multicol"
            />

            <FilterComponentList id={2}
              filterActive={eventTypeFiltersActive}
              name="Event Type" icon={eventTypeFiltersActive ? "/assets/images/icons/bus-white.svg" : "/assets/images/icons/bus.svg"}
              selectedItems={selectedEventTypes} items={eventTypeItems} setSelectedItems={setSelectedEventTypes} toggleDropdown={() => toggleFilterDropdown(() => setShowEventTypeDropdown(!showEventTypeDropdown))} showItemDropdown={showEventTypeDropdown} setShowItemDropdown={setShowEventTypeDropdown} 
              handleApply={handleEventType}
            />
          </div>
          <div className="my-5 flex flex-wrap gap-y-4 gap-x-4 sm:items-center">
            <div>
              <span>Display: </span>
              <select className="bg-white rounded-md border-gray-300" onChange={(e) => {
                handleEventsPerPageChange(e)
              }}>
                <option value="10" selected={eventsPerPage === "10"}>10</option>
                <option value="20" selected={eventsPerPage === "20"}>20</option>
                <option value="50" selected={eventsPerPage > 50 ? "50" : eventsPerPage === "50"}>50</option>
              </select>
            </div>
            <div>
              <span className="">Sort by: </span>
              <select className="bg-white rounded-md border-gray-300" onChange={(e) => {
                handleSortBy(e);
              }}>
                <option value={"createdAt desc"} selected={sortBy === "createdAt" && order === "desc"}>Added to UwH: New</option>
                <option value={"createdAt asc"} selected={sortBy === "createdAt" && order === "asc"}>Added to UwH: Old</option>
              </select>
            </div>
          </div>
          
        </div>
        </>
        }
        <div className="mt-4">
          <p className="font-bold">
            {eventCount}
            {' '}
            <span className="font-normal text-sm">Events</span>
          </p>
        </div>
        <div className="mt-4 grid gap-y-4">
          {/* Event posting button with form attached */}
          <CardPosting formType="event"/>
        </div>
        <div className="mt-4 grid gap-y-4">
        
            {events.map((event) => (
              selectedCategory === "Pending Requests" ? 
              <PostingRequest isAdmin={isAdmin} key={event.id} job={event} type="event"/> : 
              <EventCard isAdmin={isAdmin} key={event.id} event={event} /> 
            ))}

            {
              moreEvents ? <button className="bg-blue-500 hover:bg-blue-900 transition-colors duration-300 py-2 px-4 rounded-md text-white text-base w-52 place-self-center" onClick={fetchMoreEvents}>Load more events</button> : <div className="text-center">End of page</div>
            }
          </div>
      </div>
      <Announcements />
      <LoginModal modalIsOpen={showLoginModal} setIsOpen={setShowLoginModal} />
    </div>
  );
}

export default Events;
