import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useAuth } from "../../../context/AuthContext";
import { useToast } from "../../../context/ToastContext";
import { RiPencilLine } from "react-icons/ri";
import * as yup from "yup";
import { updateUserSensitiveData } from "../../../functions/firebase/firestoreUsers";
import { RiEyeFill, RiEyeCloseFill } from "react-icons/ri";

export default function EmailForm({ userData, sensitiveUserData }) {
  const auth = useAuth();
  const { showSuccessToast, showErrorToast, showLoadingToast } = useToast();
  const { signInWithEmailPassword, updateNewEmail } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("********");
  const [showPassword, setShowPassword] = useState(false);

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (values) => {
    try {
      if (!currentPassword) {
        console.log("Current password is not set");
        showErrorToast("Please enter your current password.");
        return;
      }

      try {
        await signInWithEmailPassword(auth.currentUser.email, currentPassword);
        console.log("Sign in successful");
      } catch (error) {
        console.log("Sign in failed with error: ", error);
        throw new Error("wrongCurrentPassword");
      }

      showLoadingToast("Validating Email...");
      await updateNewEmail(values.email);
      const updatedSensitiveUserData = {
        ...sensitiveUserData,
        email: values.email,
      };
      await updateUserSensitiveData(userData.uid, updatedSensitiveUserData);
      toggleEdit();
      showSuccessToast("Email updated successfully");
    } catch (error) {
      if (error.message === "wrongCurrentPassword") {
        setCurrentPassword("");
        showErrorToast("Incorrect current password. Please try again.");
      } else {
        console.error("Error updating email:", error);
        showErrorToast("An error occurred while updating email.");
      }
    }
  };

  // Styles
  const errorStyle = "text-red-500 text-xs pt-1";
  const inputStyle =
    "mt-1 p-2 w-full border rounded border-[#939393] text-[#C2C2C2] focus:border-primaryBlue focus:ring-primaryBlue relative w-full disabled:px-0 disabled:text-[#C2C2C2] disabled:bg-transparent disabled:border-0 disabled:shadow-none";

  const labelStyle = "z-10 mb-1 text-[#C2C2C2]";
  const buttonStyle =
    "bg-blue-500 text-white rounded-lg p-2 w-32 mt-3 hover:bg-blue-700 text-[#C2C2C2]";
  const buttonOutlineStyle =
    "bg-white-500 text-blue-500 rounded-lg p-2 w-32 mt-3 hover:bg-blue-100 outline outline-1";
  const editButtonStyle = "rounded-lg p-2 mt-3 text-[#C2C2C2]"; //Can add hover:bg-blue-100 later when we use this button
  const iconSize = "24";

  return (
    <div>
      <Formik
        initialValues={{
          email: sensitiveUserData.email || "",
          currentPassword: "",
        }}
        validationSchema={yup.object().shape({
          email: yup
            .string()
            .email("Invalid email format")
            .required("Email is required"),
          currentPassword: yup
            .string()
            .required("Current password is required"),
        })}
        onSubmit={handleSubmit}
      >
        {({ resetForm, handleChange }) => (
          <Form>
            <div className="relative mt-4 mb-6">
              {isEditing && (
                <>
                  {/* Current Password input */}
                  <div className="relative w-full mt-4">
                    <label className={labelStyle}>Current Password</label>
                    <div className="relative">
                      <Field
                        type={showPassword ? "text" : "password"}
                        name="currentPassword"
                        className={inputStyle}
                        onChange={(e) => {
                          handleChange(e);
                          setCurrentPassword(e.target.value);
                        }}
                      />
                      <button
                        type="button"
                        onClick={toggleShowPassword}
                        className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500 hover:text-gray-700 focus:outline-none"
                      >
                        {showPassword ? <RiEyeCloseFill /> : <RiEyeFill />}
                      </button>
                    </div>
                    <ErrorMessage
                      name="currentPassword"
                      className={errorStyle}
                      component="div"
                    />
                  </div>
                </>
              )}
              <div className="flex items-end gap-2">
                {/* Input field */}
                <div className="relative w-full">
                  <label className={labelStyle}>Email</label>
                  <Field
                    type="email"
                    name="email"
                    className={inputStyle}
                    placeholder={sensitiveUserData.email}
                    disabled={!isEditing}
                  />
                </div>
                {/* Edit button */}
                {!isEditing && (
                  <button
                    type="button"
                    className={editButtonStyle}
                    // onClick={toggleEdit}
                  >
                    <RiPencilLine size={iconSize} />
                  </button>
                )}
              </div>
              {isEditing && (
                <>
                  {/* Error message for email */}
                  <div className={errorStyle}>
                    <ErrorMessage name="email" />
                    {emailExists && (
                      <div className="text-red-500 text-xs pt-1">
                        Email already exists.
                      </div>
                    )}
                  </div>
                  {/* Buttons */}
                  <div className="flex gap-x-3 justify-end">
                    <button
                      type="button"
                      className={buttonOutlineStyle}
                      onClick={() => {
                        toggleEdit();
                        resetForm();
                        setCurrentPassword("");
                      }}
                    >
                      Cancel
                    </button>
                    <button type="submit" className={buttonStyle}>
                      Save
                    </button>
                  </div>
                </>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
