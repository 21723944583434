import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { FaRegUserCircle } from "react-icons/fa";
import { IoMdCard } from "react-icons/io";
import { FaRegBell } from "react-icons/fa";
import { IoMdHelpCircleOutline } from "react-icons/io";
import { IoMdArrowBack } from "react-icons/io";

/**
 * The navigation links for the sidenav in the settings page.
 *
 * @param {State} isOpen the state of the sidenav
 * @param {State} onClose the state of the sidenav
 * @returns a fragment containing the navigation links
 */
export default function SidenavSettings({ isOpen, onClose }) {
  const textC2C2C2 = "text-[#C2C2C2]";
  return (
    <>
      <li>
        <NavLink
          onClick={onClose}
          to="/settings"
          className={({ isActive }) =>
            isActive
              ? "bg-primarySlateMedium text-gray-900 pl-3 py-2 rounded flex items-center gap-x-2 hover:bg-primarySlateMedium transition-all duration-300 ease-in-out"
              : "pl-3 py-2 rounded flex items-center gap-x-2 hover:bg-primarySlateMedium transition-all duration-300 ease-in-out"
          }
          end
        >
          <FaRegUserCircle size={24} />
          Account
        </NavLink>
      </li>
      <li>
        <NavLink
          onClick={onClose}
          to="/settings/payment"
          style={{ color: "#C2C2C2" }}
          className={({ isActive }) =>
            isActive
              ? "bg-primarySlateMedium text-gray-900 pl-3 py-2 rounded flex items-center gap-x-2 hover:bg-primarySlateMedium transition-all duration-300 ease-in-out"
              : "pl-3 py-2 rounded flex items-center gap-x-2 hover:bg-primarySlateMedium transition-all duration-300 ease-in-out"
          }
          end
        >
          <IoMdCard size={24} />
          Payment
        </NavLink>
      </li>
      <li>
        <NavLink
          onClick={onClose}
          to="/settings/notifications"
          style={{ color: "#C2C2C2" }}
          className={({ isActive }) =>
            isActive
              ? "bg-primarySlateMedium text-gray-900 pl-3 py-2 rounded flex items-center gap-x-2 hover:bg-primarySlateMedium transition-all duration-300 ease-in-out"
              : "pl-3 py-2 rounded flex items-center gap-x-2 hover:bg-primarySlateMedium transition-all duration-300 ease-in-out"
          }
          end
        >
          <FaRegBell size={24} />
          Notifications
        </NavLink>
      </li>
      <li>
        <NavLink
          onClick={onClose}
          to="/settings/help"
          style={{ color: "#C2C2C2" }}
          className={({ isActive }) =>
            isActive
              ? "bg-primarySlateMedium text-gray-900 pl-3 py-2 rounded flex items-center gap-x-2 hover:bg-primarySlateMedium transition-all duration-300 ease-in-out"
              : "pl-3 py-2 rounded flex items-center gap-x-2 hover:bg-primarySlateMedium transition-all duration-300 ease-in-out"
          }
          end
        >
          <IoMdHelpCircleOutline size={24} />
          Help
        </NavLink>
      </li>
    </>
  );
}
