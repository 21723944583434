import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// Config for accessing the firestore DEVELOPER backend
const firebaseConfig = {
  apiKey: "AIzaSyBmKN0xtFyL3DsPN8OEH3VPDBTnmkcrsi0",
  authDomain: "ux-was-here.firebaseapp.com",
  projectId: "ux-was-here",
  storageBucket: "ux-was-here.appspot.com",
  messagingSenderId: "1006556528513",
  appId: "1:1006556528513:web:fed8c596d58e0991ebb280",
  measurementId: "G-F4RGQ19BEC"
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firestore, storage, and Auth
const firestore = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { app, analytics, firestore, storage, auth };