import React, { useEffect, useState } from 'react';
import UserModal from '../common/UserModal';
import { useUser } from '../../context/UserContext';

function ChatBox({
  chat, messages, setMessages, onClose, isChatPanelOpen,
}) {

  const [message, setMessage] = useState('');
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);


  const sendMessage = (e) => {
    e.preventDefault();
    if (message.trim() === '') return;
    setMessages([...messages, { sender: 'You', message }]);
    setMessage('');
  };

  return (
    <>
      <div
        className={`border border-[#DBDBDB] rounded-t-md fixed z-50 bottom-0 ${
          isChatPanelOpen ? 'md:right-96 right-0' : 'right-0'
        } sm:max-w-[364px] w-full ${
          isCollapsed ? 'h-[60px]' : 'h-auto'
        } bg-white`}
      >
        <div
          onClick={() => (isCollapsed ? setIsCollapsed(false) : null)}
          className={`flex items-center justify-between p-3 border-b ${
            isCollapsed ? 'cursor-pointer' : ''
          }`}
        >
          <div
            className="cursor-pointer"
            onClick={() => (isCollapsed ? null : setIsUserModalOpen(true))}
          >
            <div className="flex items-center gap-2">
              <div className="w-10 h-10 bg-gray-700 rounded-full" />
              <div className="">
                <p className="font-bold text-sm">{chat?.name}</p>
                <p className="text-gray-500 text-xs">Student @ SFU</p>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-x-2">
            {!isCollapsed && (
              <button onClick={() => setIsCollapsed((prev) => !prev)}>
                <img src="/assets/images/icons/minimize.svg" alt="Minimize" />
              </button>
            )}
            <button onClick={onClose}>
              <img src="/assets/images/icons/close-default.svg" alt="Close" />
            </button>
          </div>
        </div>
        {!isCollapsed && (
          <>
            <div className="chat-scroll h-[278px] overflow-y-auto relative">
              {messages?.map((msg, index) => (
                <div
                  key={index}
                  className={`w-full px-3 py-1 ${msg.sender === 'You' ? 'self-end' : ''}`}
                >
                  {msg.time && (
                  <p className="pb-1 text-xs text-center w-full text-gray-400">
                    {msg.time}
                  </p>
                  )}
                  <div className={`w-full flex items-center gap-2 mb-1 ${msg.sender === 'You' ? 'justify-end' : 'justify-start'}`}>
                    {msg.sender !== 'You' && (
                      <div className="flex-shrink-0 w-10 h-10 bg-gray-700 rounded-full" />
                    )}
                    <div
                      className={`text-xs w-3/4 rounded-lg py-2 px-4 ${
                        msg.sender === 'You'
                          ? 'bg-[#F1F5FC] self-end'
                          : 'bg-[#F5F5F5]'
                      }`}
                    >
                      <p>{msg.message}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <form onSubmit={sendMessage} className="border-t p-2 bg-[#F1F5FC] flex items-center gap-2">
              <div className="flex gap-1">
                <button>
                  <img src="/assets/images/icons/image.svg" alt="" />
                </button>
                <button>
                  <img src="/assets/images/icons/gif.svg" alt="" />
                </button>
                <button>
                  <img src="/assets/images/icons/emoji.svg" alt="" />
                </button>
              </div>
              <input
                type="text"
                className="flex-grow border border-[#3D3D3D] rounded p-1"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <button className="bg-primaryBlue text-white px-2 py-1 h-[33px] rounded">
                <img src="/assets/images/icons/pencil-white.svg" alt="" />
              </button>
            </form>
          </>
        )}
      </div>
    </>
  );
}

export default ChatBox;
