export function updateLocationFilters(items, setLocationItems, selectedCategory, admin, itemName, countries) {
  const jobLocations = items.reduce((acc, item) => {
      // Check if the item's country already exists in the accumulator
      if (item.country in acc) {
          // If it does, increment the count
          acc[item.country]++;
      } else {
          // If it doesn't, add it to the accumulator with a count of 1
          acc[item.country] = 1;
      }
      return acc;
  }, {});

  // Map the locations and their counts
  const newLocationItems = countries.map(country => ({
        label: `${country}`,
        // description: `(${count} ${itemName} available)`,
        //count, // Include count as an individual property if needed separately
    }));

  // Update location filters
  setLocationItems(newLocationItems);
}