import React, { useEffect, useState } from 'react';
import Header from './Header';
import Sidenav from './Sidenav';
import ChatPanel from './chat/ChatPanel';
import ChatBox from './chat/ChatBox';
import NotificationsPanel from '../pages/notifications/NotificationsPanel';
import { realtimeNotifications } from '../functions/realtime/realtimeService';
import { useAuth } from '../context/AuthContext';
import { chats, messages, requests } from '../data/chats';

function CommunityLayout({ children }) {
  const [isSidenavOpen, setSidenavOpen] = useState(false);
  const [isChatPanelOpen, setChatPanelOpen] = useState(false);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const [notificationsPanelOpen, setNotificationsPanelOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [currentMessages, setCurrentMessages] = useState([]);
  const auth = useAuth();

  useEffect(() => {
    if (selectedChatId == null) {
      setCurrentMessages([]);
      return;
    }
    setCurrentMessages(messages[selectedChatId]);
  }, [selectedChatId]);

  useEffect(() => {
    if (notificationsPanelOpen && auth?.currentUser) {
      const unsubscribe = realtimeNotifications(auth.currentUser.uid, setNotifications);
      return () => {
        unsubscribe();
      }
    }
  }, [notificationsPanelOpen, auth?.currentUser]);

  function togglePanel(type) {
    setChatPanelOpen(type === "chat");
    setNotificationsPanelOpen(type === "notifications");
  }

  return (
    <div className="min-h-screen bg-primarySlateLight">
      <Header
        unreadNotificationCount={unreadNotificationCount}
        isChatPanelOpen={isChatPanelOpen}
        toggleSidenav={() => setSidenavOpen(true)}
        toggleChatPanel={() => togglePanel("chat")}
        toggleNotificationsPanel={() => togglePanel("notifications")}
      />
      <div className="relative pt-16 max-w-7xl mx-auto sm:flex">
        <Sidenav isOpen={isSidenavOpen} onClose={() => setSidenavOpen(false)} />
        <div className="flex-1 p-4">{children}</div>
        <NotificationsPanel
          notifications={notifications}
          setUnreadNotificationCount={setUnreadNotificationCount}
          isNotificationsPanelOpen={notificationsPanelOpen}
          close={() => setNotificationsPanelOpen(false)}
        />
        <ChatPanel
          toggleChatPanel={() => setChatPanelOpen(!isChatPanelOpen)}
          isChatPanelOpen={isChatPanelOpen}
          chats={chats}
          requests={requests} 
          onSelectChatId={setSelectedChatId}
        />
        {selectedChatId !== null && (
          <ChatBox
            chat={chats.find((chat) => chat.id === selectedChatId)}
            messages={currentMessages}
            setMessages={setCurrentMessages}
            onClose={() => setSelectedChatId(null)}
            isChatPanelOpen={isChatPanelOpen}
          />
        )}
      </div>
    </div>
  );
}

export default CommunityLayout;