import React from 'react';

function ExploreTabs({ activeTab, setActiveTab }) {
  const tabs = ['All', 'Ranting', 'Feedback', 'Education', 'Collaboration', 'Job Opportunity', 'Funny'];

  return (
    <div className="flex flex-row gap-4 max-w-full flex-wrap">
        {tabs.map((tab) => (
          <button
            key={tab}
            className={`text-xs md:text-sm lg:text-m sm:px-3 px-1 py-1 border inline-block rounded ${activeTab === tab ? 'bg-primarySlateMedium' : 'bg-white'}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>
  );
}

export default ExploreTabs;