import React from 'react';
import UpdatedFooter from './includes/UpdatedFooter';
import UpdatedHeader from './includes/UpdatedHeader';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';



export default function BetaPage() {
    useEffect(() => {
        // Google Analytics script
        const scriptTag = document.createElement('script');
        scriptTag.src = 'https://www.googletagmanager.com/gtag/js?id=G-ETDGNX9LSZ';
        scriptTag.async = true;

        document.head.appendChild(scriptTag);

        const scriptTag2 = document.createElement('script');
        scriptTag2.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-ETDGNX9LSZ');
        `;

        document.head.appendChild(scriptTag2);
    }, []);



    /**Onclick for toggling the accordions
     * 
     * @param {string} elementID 
     */


    const accordionBtn = (elementID) => {
        const btn = document.getElementById(elementID);

        if (btn) {
            btn.classList.toggle('active');
            var content = btn.nextElementSibling;
            if (content.style.maxHeight) {
                content.style.maxHeight = null;
            } else {
                content.style.maxHeight = content.scrollHeight + 'px'; // Panel is closed, need to open it
            }
        }

    }


    return (
        <div>
            <link rel="stylesheet" href="/assets/css/BetaStyles.css" />
            <div className='sticky top-0'><UpdatedHeader /></div>

            <div className='md:px-44 h-screen'>
            <div className="container mx-auto max-sm:px-4  place-content-evenly ">
                <div className="flex flex-col md:flex-row md:pb-16 gap-x-28 max-sm:pt-36 md:pt-0 slg:pt-32 lg:pt-48 vxl:pt-80 ">
                <div className="md:basis-1/2">
                    
                    <div className='slg:space-y-4 lg:space-y-16 max-sm:space-y-8 max-sm:pb-16'>
                    <p className='vxl:text-7xl slg:text-4xl lg:text-5xl max-sm:text-3xl max-sm:text-center font-medium '>Share, inspire, and connect with UX enthusiasts around the globe.</ p>
                    <p className='vxl:text-6xl slg:text-2xl lg:text-4xl max-sm:text-xl max-sm:text-center font-thin pt-4'>A dedicated platform designed for UX Enthusiasts – By UX Enthusiasts</p>
                    <p className='vxl:text-6xl slg:text-2xl lg:text-4xl max-sm:text-xl max-sm:text-center font-thin pt-4'>No design experience required!</p>
                    <div className='pt-4 max-sm:text-center'>
                        <Link className='container' to={{ pathname: '/login', search: new URLSearchParams({page: "signup"}).toString() }}><button className="bg-blue-600 hover:bg-blue-700 text-white text-2xl h-16 w-72 py-2 px-6 rounded">Sign up for free</button></Link>
                        <p className=' max-sm:text-center text-xs pt-10 text-gray-600'>Currently in Limited Beta Release</p>
                    </div>
                    </div>
                </div>
                <div className="md:basis-1/2 w-full self-center">
                    <img src="/assets/images/Betalanding/Community Home Page.png" alt="Explore Page" />
                </div>
                </div>  
            </div>
            </div>
            <div className='md:px-44'>
            <div className="container m-auto w-auto pt-32 max-sm:px-4">
                <div className='flex flex-col md:flex-row place-content-center md:gap-x-16 max-sm:gap-x-4'>
                <div className="md:basis-1/2">
                    <div className='space-y-8'>
                    <p>
                    <p className='text-gray-400 max-sm:text-center'>Explore Page</p>
                    <h4 className=' font-bold md:text-4xl max-sm:text-3xl text-gray-900 max-sm:text-center'>Community Forum</h4>
                    </p>   
                    <h3 className='text-zinc-700 max-sm:text-center md:text-3xl max-sm:text-xl max-sm:px-8'>Join lively discussions, ask questions, and share your knowledge with a community that thrives on
                        collaboration.</h3>
                    <h3 className='text-zinc-700 max-sm:text-center md:text-3xl max-sm:text-xl max-sm:px-8'>UX Was Here makes sharing easy, empowering you to share your unique ideas with the community.</h3>
                    </div>
                </div>
                <div className="md:basis-1/2 w-full">
                    <img src="/assets/images/Betalanding/Community Home Page.png" alt="Explore Page" />
                </div>
                </div>
            </div>
            </div>
            <div className='md:px-44'>
            <div className="container  flex flex-row m-auto w-auto pt-32 max-sm:px-4">
                <div className="flex flex-col md:flex-row place-content-center  md:gap-x-16 max-sm:gap-x-4">
                <div className="md:basis-1/2 w-full max-sm:hidden">
                    <img src="/assets/images/Betalanding/Community Events Page.png" alt="Events Page" />
                </div>
                <div className="md:basis-1/2">
                <div className='space-y-8'>
                    <p>
                    <p className='text-gray-400 max-sm:text-center'>Events Page</p>
                    <h4 className=' font-bold md:text-4xl max-sm:text-3xl text-gray-900 max-sm:text-center'>Curated Events Just For You</h4>
                    </p>
                    <h3 className='text-zinc-700 max-sm:text-center md:text-3xl max-sm:text-xl max-sm:px-8'>Stay up-to-date with exciting UX related workshops, talks, and networking events tailored to inspire and
                        connect.</h3>
                    <h3 className='text-zinc-700 max-sm:text-center md:text-3xl max-sm:text-xl max-sm:px-8'>We gather and curate all the events for you. All you have to do is join!</h3>
                </div>
                </div>
                <div className="md:basis-1/2 w-full md:hidden">
                    <img src="/assets/images/Betalanding/Community Events Page.png" alt="Events Page" />
                </div>
                </div>
            </div>
            </div>
            <div className='md:px-44'>
            <div className="container m-auto w-auto pt-32 max-sm:px-4">
                <div className="flex flex-col md:flex-row place-content-center  md:gap-x-16 max-sm:gap-x-4">
                <div className="md:basis-1/2">
                    <div className='space-y-8'>
                    <p>
                    <p className='text-gray-400  max-sm:text-center'>Jobs Page</p>
                    <h4 className=' font-bold md:text-4xl max-sm:text-3xl text-gray-900 max-sm:text-center'>Your Go To Source For Leveling Up </h4>
                    </p>
                    <h3 className='text-zinc-700 max-sm:text-center md:text-3xl max-sm:text-xl max-sm:px-8'>Explore curated job listings that match your skills, or post open roles to reach the brightest UX
                        talents. </h3>
                    <h3 className='text-zinc-700 max-sm:text-center md:text-3xl max-sm:text-xl max-sm:px-8'>We know how hard it is to find UX related jobs when you are new to the field. On UX Was Here, you will
                        have access to the latest job postings, all under one tab.</h3>
                    </div>
                </div>
                
                <div className="md:basis-1/2 w-full">
                    <img src="/assets/images/Betalanding/Community Jobs Page.png" alt="Jobs Page" />
                </div>
                </div>
            </div>
            </div>
            <div className='md:px-44'>
            <div className="container m-auto w-auto pt-32 max-sm:px-4">
                <div className="flex flex-col md:flex-row place-content-center  md:gap-x-16 max-sm:gap-x-4">
                
                <div className="md:basis-1/2 w-full max-sm:hidden">
                    <img src="/assets/images/Betalanding/Community Home Page - Messaging.png" alt="Messaging Page" />
                </div>
                <div className="md:basis-1/2">
                    <div className='space-y-8'>
                    <p>
                    <p className='text-gray-400  max-sm:text-center'>Messaging</p>
                    <h4 className=' font-bold md:text-4xl max-sm:text-3xl text-gray-900  max-sm:text-center'>Network With Likeminded People</h4>
                    </p>
                    <h3 className='text-zinc-700 max-sm:text-center md:text-3xl max-sm:text-xl max-sm:px-8'>Engage in insightful conversations with fellow UX enthusiasts through private chats and group
                        discussions. </h3>
                    <h3 className='text-zinc-700 max-sm:text-center md:text-3xl max-sm:text-xl max-sm:px-8'>No imposter syndrome here. At UX Was Here, we’re redefining UX collaboration - together. </h3>
                    </div>
                </div>
                <div className="md:basis-1/2 w-full md:hidden">
                    <img src="/assets/images/Betalanding/Community Home Page - Messaging.png" alt="Messaging Page" />
                </div>
                </div>
            </div>
            </div>

            <div className='md:px-44'>
            <div className="container m-auto w-auto pt-32 max-sm:px-4">
                <div className="accordion-open">
                    <button id="accordion1" onClick={() => { accordionBtn("accordion1") }} class="accordion-button" aria-expanded="false">What is UX Was Here?</button>
                    <div className="accordion-content">
                        <p>UX Was Here is a community-based platform that combines explore, jobs, messaging, posts, and events all under one page. It was created to connect UX enthusiasts with one another and take on global UX initiatives as a community.</p>
                    </div>
                </div>
                <div className="accordion-open">
                    <button className="accordion-button" id="accordion2" onClick={() => { accordionBtn("accordion2") }} aria-expanded="false">Who uses UX Was Here?</button>
                    <div className="accordion-content">
                        <p>UX Was Here is used by any UX enthusiast regardless of skill or background. We are a community that welcomes everybody with open arms. Some of our members have worked from successful startups to Fortune 500 companies.</p>
                    </div>
                </div>
                <div className="accordion-open">
                    <button className="accordion-button" id="accordion3" onClick={() => { accordionBtn("accordion3") }} aria-expanded="false">How to use UX Was Here?</button>
                    <div className="accordion-content">
                        <p>UX Was Here has everything you will need to network/learn/education about UX. You can use it to post questions about your portfolio or case study. You can look for UX events that interest you. You can also look for jobs using our job page. Possibilities are endless!</p>
                    </div>
                </div>
                <div className="accordion-open">
                    <button className="accordion-button" id="accordion4" onClick={() => { accordionBtn("accordion4") }} aria-expanded="false">Is it free?</button>
                    <div className="accordion-content">
                        <p>Yes, it is free! We don't ask for credit card information either.</p>
                    </div>
                </div>
            </div>
            </div>


            <div className='md:px-44'>
            <div className="container m-auto w-auto pt-12 pb-16 max-sm:px-4">
                <div className="flex flex-col md:flex-row place-content-start  md:gap-x-16 max-sm:gap-x-4">
                <div className="md:basis-full md:w-1/2 max-w-md">
                    <div className='space-y-8'>
                    <p className='md:text-4xl max-sm:text-3xl text-gray-900 font-bold max-sm:text-center'>Ready to make a difference in the world?</p>
                    <p className='md:text-3xl max-sm:text-xl font-slim md:pb-6 max-sm:pt-6 max-sm:px-2 text-zinc-700 max-sm:text-center '>Become part of the narrative and leave your mark on UX design</p>
                    <div className='max-sm:text-center'>
                        <Link to={{ pathname: '/login', search: new URLSearchParams({page: "signup"}).toString() }}>
                            <button className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded text-2xl h-16 w-72">Sign me up!</button>
                        </Link>
                        <p className=' text-xs pt-10 text-gray-600  max-sm:text-center '>Currently in Limited Beta Release</p>
                    </div>
                    </div>
                </div>
                
                <div className="md:basis-1/2 w-full">
                </div>
                </div>
            </div>
            </div>


            <UpdatedFooter />
        </div>

    );
}
