import { useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import { useUser } from "../../context/UserContext";
import Login from "../../pages/login/Login"
import Rodal from "rodal";


/**
 * A modal that asks the user to sign in. This is called if the user trys to do actions 
 * such as liking a post or comment.
 */
export default function LoginModal({modalIsOpen, setIsOpen}) {

    const auth = useAuth();
    const user = useUser();

    // bg-white px-4 md:py-24 py-16 rounded-2xl shadow-primaryCardShadow w-full max-w-[551px]
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          padding: '20px 25px', // Adjust the padding values as needed
          borderRadius: '10px', // Optional: Add border-radius for a rounded appearance
          width: '300px', // Optional: Set a maximum width for the modal content
        },
      };

    async function closeModal() {
        // sign out if they created an account but didn't fill in the personal info sheet
        if (auth && !user) {
            try {
                await auth.signOut();
            } catch (error) {   
                console.error(error);
            }
        }
        setIsOpen(false);
    }

    return (
        <>
            <Rodal
            customStyles={customStyles}
                onClose={closeModal}
                visible={modalIsOpen}
            >
                <Login isPopup={true} closePopup={closeModal}/>
            </Rodal>
        </>
    );
}