import React, { useEffect, useMemo, useState } from "react";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import PostCard from "./PostCard";
import { getPosts } from "../../functions/firebase/firestorePosts";
import { useAuth } from "../../context/AuthContext";
import {
  getSensitiveUserData,
  getUser,
} from "../../functions/firebase/firestoreUsers";
import EditProfile from "./EditProfile";
import { useToast } from "../../context/ToastContext";
import { FaInstagram, FaLinkedin, FaFacebook, FaGlobe } from "react-icons/fa";
import { where } from "firebase/firestore";
import {
  realTimePosts,
  realTimePostUserData,
} from "../../functions/realtime/realtimeService";
import Swal from "sweetalert2";
import ChatBox from "../chat/ChatBox";

import { messages, chats, requests } from "../../data/chats";

function UserModal({ handleClose, user }) {
  const [isFolowing, setIsFollowing] = useState(false);
  const [userPosts, setUserPosts] = useState([]);
  const { showSuccessToast, showErrorToast, showLoadingToast } = useToast();
  const auth = useAuth();

  const [userData, setUserData] = useState(null);
  const [hasFetchedPosts, setHasFetchedPosts] = useState(false);

  useEffect(() => {
    const get = async () => {
      try {
        // let posts = JSON.parse(localStorage.getItem("posts"));
     
        let posts = null;
        if (posts === null) {
          // fetch posts from db if not cached
          const { postsReceived, lastDocReceived } = await getPosts(
            "createdAt",
            where("userId", "==", user.uid)
          );
          if (postsReceived === null) {
            setUserPosts([]);
            setHasFetchedPosts(true);
            return;
          }
          const postsWithUser = await Promise.all(
            postsReceived.map(async (post) => {
              const userData = await getUser(post.userId);
              return { ...post, user: userData };
            })
          );
          localStorage.setItem("posts", JSON.stringify(postsWithUser));
          setUserPosts(postsWithUser);
          setHasFetchedPosts(true);
        }
      } catch (error) {
        showErrorToast();
        console.log("An error has occured", error);
      }
    };
    get();

    // get sensitive user info for authenticated user so it can be displayed on their edit profile
    const getSensitiveUserInfo = async () => {
      const data = await getSensitiveUserData(auth.currentUser.uid);
      console.log(data);
      setUserData({
        ...user,
        phoneNumber: data.phoneNumber,
        email: data.email,
        country: data.country,
      });
    };
    if (user.uid === auth?.currentUser?.uid) {
      getSensitiveUserInfo();
    }
  }, []);

  useEffect(() => {
    if (hasFetchedPosts) {
      // update the user's info on their post without needing to refresh the page
      if (auth.currentUser) {
        realTimePostUserData(auth, setUserPosts);
      }

      // listener for real time post changes
      const unsubscribe = realTimePosts(setUserPosts);

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [hasFetchedPosts]);

  /**
   * Profile modal toggle state.
   */
  const [viewProfile, setViewProfile] = useState(true);
  const onOpenViewProfile = () => setViewProfile(true);
  const onCloseViewProfile = () => setViewProfile(false);

  /**
   * Edit profile modal toggle state.
   */
  const [editProfileVisible, setEditProfileVisible] = useState(false);
  const onCloseEditProfile = () => {
    setEditProfileVisible(false);
  };
  const onOpenEditProfile = () => setEditProfileVisible(true);

  /**
   * When both modals are closed, we need to call the handleClose method passed in.
   */
  useEffect(() => {
    if (!viewProfile && !editProfileVisible) {
      handleClose();
    }
  }, [viewProfile, editProfileVisible]);

  /**
   * Opens the edit profile modal.
   */
  function handleToggleEditProfile() {
    if (editProfileVisible) {
      onCloseEditProfile();
      onOpenViewProfile();
    } else {
      onCloseViewProfile();
      onOpenEditProfile();
    }
  }

    function warnInsecureSite(e, url) {
        e.preventDefault();
        if (url.startsWith("http://")) {
            Swal.fire({
                title: "Insecure site: " + url,
                icon: "warning",
                text: "Please proceed with caution",
                showCancelButton: true,
                confirmButtonColor: "#3085d6", // Blue color for the button
                cancelButtonColor: "#7F7F7F", // Optional: Color for cancel button
                confirmButtonText: "Proceed!",
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location = url;
                }
            });
        } else {
            window.location = url;
        }
    }

    const [chatPanelOpen, setChatPanelOpen] = useState(false);

    useEffect(() => {
        console.log(chatPanelOpen);
    }, [chatPanelOpen]);

    function handleOpenDM() {
        console.log("test");
        setChatPanelOpen(true);
    }

  return (
    <>
      {/* Edit profile Rodal */}
      {userData && (
        <EditProfile
          onCloseEditProfile={onCloseEditProfile}
          user={userData}
          editProfileVisible={editProfileVisible}
          handleToggleEditProfile={handleToggleEditProfile}
        />
      )}

            {/* profile Rodal */}
            <Rodal
                visible={viewProfile}
                onClose={() => {
                    onCloseViewProfile();
                }}
            >
                <div className="font-roboto lg:px-4 pt-2">
                    <div className="flex flex-wrap gap-3 items-center justify-between">
                        <img
                            className="rounded-full bg-gray-100 w-24 h-24"
                            src={
                                user?.profilePictureURL
                                    ? user?.profilePictureURL
                                    : "./assets/images/community/placeholder-user.svg"
                            }
                            alt="User"
                        />
                        <div className="flex-shrink-0 flex sm:flex-col flex-row gap-2">
                            {auth.currentUser && auth.currentUser.uid !== user.uid && <button onClick={() => handleOpenDM()} className="transition-all duration-300 ease-in-out hover:shadow-lg block sm:w-[140px] w-[110px] text-sm py-2 rounded-lg bg-[#2264E4] text-white">
                            Send Message
                            </button>}
                            {auth.currentUser &&
                                auth.currentUser.uid === user?.uid && (
                                    <button
                                        onClick={handleToggleEditProfile}
                                        className="transition-all duration-300 ease-in-out hover:shadow-lg block sm:w-[140px] w-[110px] text-sm py-2 rounded-lg bg-[#2264E4] text-white"
                                    >
                                        Edit Profile
                                    </button>
                                )}

              {/* {auth.currentUser && auth.currentUser.uid !== user.uid &&
              <button
              className={`transition-all duration-300 ease-in-out hover:shadow-lg block sm:w-[140px] w-[110px] text-sm py-2 rounded-lg ${
                isFolowing
                  ? 'bg-[#D2D2D2] text-[#3D3D3D]'
                  : 'bg-[#2264E4] text-white'
              }`}
              onClick={() => setIsFollowing((prev) => !prev)}
            >
              {isFolowing ? (
                <div className="flex gap-x-1 items-center justify-center">
                  Following
                  {' '}
                  <span>
                    <img src="/assets/images/icons/check.svg" alt="" />
                  </span>
                </div>
              ) : (
                'Follow'
              )}
            </button> 
            } */}
                        </div>
                    </div>
                    <h1 className="text-xl font-bold mt-5 flex items-center">
                        {user?.firstName?.charAt(0)?.toUpperCase() + user?.firstName?.slice(1) + " " + user?.lastName?.charAt(0)?.toUpperCase() + user?.lastName?.slice(1)}
                        <span className="flex flex-row gap-2 ml-3">
                            {user?.linkedinLink ? (
                                <button
                                    onClick={(e) =>
                                        warnInsecureSite(e, user.linkedinLink)
                                    }
                                >
                                    <FaLinkedin
                                        size={32}
                                        color="#0077B5"
                                        className="cursor-pointer"
                                    />
                                </button>
                            ) : null}
                            {user?.instagramLink ? (
                                <button
                                    onClick={(e) =>
                                        warnInsecureSite(e, user.instagramLink)
                                    }
                                >
                                    <FaInstagram
                                        size={32}
                                        color="#E1306C"
                                        className="cursor-pointer"
                                    />
                                </button>
                            ) : null}
                            {user?.websiteLink ? (
                                <button
                                    onClick={(e) =>
                                        warnInsecureSite(e, user.websiteLink)
                                    }
                                >
                                    <FaGlobe
                                        size={32}
                                        color="#1877F2"
                                        className="cursor-pointer"
                                    />
                                </button>
                            ) : null}
                        </span>
                    </h1>
                    <p className="text-[#3D3D3D] text-xs mb-4">
                        {user?.username} {user?.location}
                    </p>
                    <p className="mb-2 text-xs font-medium flex items-center gap-x-1">
                        {/* <span>
            <img
              className="w-5"
              src="/assets/images/community/sfu.png"
              alt=""
            />
          </span> */}{" "}
            {user?.description}{" "}
            <span>
              {user?.occupation}{" "}
              {/* •
            {' '}
            <a href={user?.resume} className="underline font-bold">
              View resume
            </a> */}
            </span>
          </p>
          <div className="flex flex-wrap items-center lg:w-3/4 gap-2 my-4">
            {user?.role?.slice(0, 3).map((role, index) => (
              <span
                key={index}
                className="border-2 border-gray-200 shadow rounded-3xl px-6 py-3 text-xs"
              >
                {role}
              </span>
            ))}

            {user?.role?.slice(3, 6).map((role, index) => (
              <span
                key={index}
                className="h-fit border-2 border-gray-200 shadow rounded-3xl px-3 py-2 text-xs"
              >
                {role}
              </span>
            ))}

            {user?.role?.length > 6 && (
              <span className="relative group cursor-pointer h-fit border-2 border-gray-200 shadow rounded-3xl px-3 py-2 text-xs">
                +{user?.role?.length - 6}
                <div className="absolute left-0 mt-1 w-48 p-4 flex flex-wrap gap-2 bg-white border rounded shadow-lg opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out">
                  {user?.role?.slice(6).map((role, index) => (
                    <span
                      key={index}
                      className="h-fit border-2 border-gray-200 shadow rounded-3xl px-3 py-2 text-xs"
                    >
                      {role}
                    </span>
                  ))}
                </div>
              </span>
            )}
          </div>
          <div className="text-[#3D3D3D] text-xs font-medium whitespace-pre-wrap">
            {user?.bio && (
              <div className="m-0 p-0 font-bold text-sm text-[#000000]">
                Bio <br />
                <span data-testid="user-bio" className="m-0 p-0 font-normal">
                  {decodeURIComponent(user.bio)}
                </span>
              </div>
            )}
          </div>
          <div className="mt-8 mb-4" data-testid="recent">
            <p className="font-bold text-sm">Recent Activity</p>
            <div className="mt-1 flex gap-x-1">
              <img src="/assets/images/icons/schedule.svg" alt="" />
              <span className="text-[#959595] text-xs font-medium">
                {auth.currentUser !== null ? "now" : user?.lastOnline}
              </span>
            </div>
          </div>

                    <div className="">
                        {userPosts &&
                            userPosts.map((post) => (
                                <PostCard
                                    key={post.postId}
                                    post={post}
                                    isOnPopup={true}
                                />
                            ))}

            {userPosts.length === 0 && !hasFetchedPosts && (
              <div className="text-sm">Loading user posts...</div>
            )}

                        {userPosts.length === 0 && hasFetchedPosts && (
                            <div className="text-sm">No posts yet</div>
                        )}
                    </div>
                </div>
            </Rodal>

            
          
            <ChatBox
                chat={chats.find((chat) => chat.id === 1)}
                messages={messages[0]}
                setMessages={() => {}}
                onClose={() => setChatPanelOpen(false)}
                isChatPanelOpen={chatPanelOpen}
            /> 
            
        </>
    );
}

export default UserModal;
