import React, { useEffect, useMemo, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { jobPostingValidationSchema } from '../../functions/validationSchemas';

import iso from 'iso-3166-1-alpha-2';

import 'react-quill/dist/quill.snow.css';
import Swal from 'sweetalert2';
import { createJob, updateJobPosting } from '../../functions/firebase/firestoreJobs';
import { auth } from '../../../firebaseConfig';
import { getSensitiveUserData, getUser } from '../../functions/firebase/firestoreUsers';
import "./swal.css"; 
import ImageUploader from '../../components/util/ImageUploader';
import { NumberInput, GenerateSelectInput, GenerateFormInput, GenerateRichText } from '../../functions/formik';
import { format } from 'date-fns';
import emailjs from 'emailjs-com';
import { useUser } from '../../context/UserContext';
import countriesData from '../../countries.json';


const JobForm = ({isAdmin, cancel, job, isEditing = false}) => {
  const [touched, setTouched] = useState(false);
  const user = useUser();

  const previousJobImage = job?.imageURL || null;

  const decodedDescription = decodeURIComponent(job?.description);
  const decodedInternalNotes = decodeURIComponent(job?.internalNotes);

  function getMonthFromNowDate() {
    const currentDate = new Date();
    const oneMonthFromNow = new Date(currentDate);
    oneMonthFromNow.setMonth(currentDate.getMonth() + 1);
  
    // Format the date as "yyyy-mm-dd"
    const yyyymmddDate = oneMonthFromNow.toISOString().split('T')[0];
    return yyyymmddDate;
  }

  // get the country list and put Canada + US at the top
  const getCountryList = (countriesAtFront = null) => {
    const countryList = countriesData["countries"].sort();
    if (countriesAtFront !== null) {
      countriesAtFront.reverse().forEach(country => {
        const index = countryList.findIndex((countryInList) => country === countryInList);
        if (index !== -1) {
          const countryRemoved = countryList.splice(index, index + 1)[0];
          countryList.unshift(countryRemoved);
        }
      });
      return countryList;
    } else {
      return countryList;
    }
  };
  const countryList = getCountryList();

  // Initial form values
  let initialValues = {
    title: job?.title || "",
    type: job?.type || "Full-time", 
    city: job?.city || "",
    country: job?.country || countryList.at(0),
    deadline: job?.deadline || getMonthFromNowDate(),
    minYearsExperience: job?.minYearsExperience || null,
    maxYearsExperience: job?.maxYearsExperience || null,
    link: job?.link || "",
    jobSite: job?.jobSite || "Onsite",
    category: job?.category || "",
    minSalary: job?.minSalary || "",
    maxSalary: job?.maxSalary || "",
    imageURL: job?.imageURL || null,
    description: decodedDescription !== "undefined" ? decodedDescription : "", // rich text
    internalNotes: decodedInternalNotes !== "undefined" ? decodedInternalNotes : "", // rich text
  };

  /**
   * Submits a new post to the database.
   * 
   * @param {Object} values - the values for the job that we are submitting
   */
  function submitPosting(values, setValues) {
    Swal.fire({
      title: "Confirmation",
      text: "Your posting will be sent to be reviewed by our admins. We will look over this as soon as we can, and if the post is approved, it will be available for everyone to see.",
      cancelButtonText: "Cancel",
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: "Confirm Request",
      fontFamily: "DMsans",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          Swal.fire({
            title: 'Submitting job request',
            text: 'Please wait...',
            allowOutsideClick: false,
            didOpen: () => {
            Swal.showLoading();
            }
          });
          const encodedDescription = encodeURIComponent(values.description).trim();
          const encodedInternalNotes = encodeURIComponent(values.internalNotes).trim();

          // get the auth user's email address
          const sensitiveUserInfo = await getSensitiveUserData(auth.currentUser.uid);
          const email = sensitiveUserInfo.email;
          console.log("email: ", email);
          
          const valuesToSubmit = {
            title: values.title,
            description: encodedDescription,
            internalNotes: encodedInternalNotes,
            country: values.country,
            deadline: values.deadline,
            link: values.link,
            thumbnail: values.thumbnail,
            minYearsExperience: values.minYearsExperience,
            maxYearsExperience: values.maxYearsExperience,
            minSalary: values.minSalary,
            maxSalary: values.maxSalary,
            type: values.type,
            city: values.city,
            jobSite: values.jobSite,
            category: values.category,
            inReview: true,
            userId: auth.currentUser.uid,
            email: email,
          };

          // create the job
          await createJob(valuesToSubmit);

          // send an email to the person who posted the job if it was successful if they are not an admin (no need to await this)
          if (!user.authorities?.includes("admin")) {
            console.log("Sending email");
            emailjs.send(import.meta.env.VITE_REACT_APP_EMAIL_JS_SERVICE_ID, import.meta.env.VITE_REACT_APP_EMAIL_JS_JOB_POSTING_TEMPLATE_ID, {
              email: email,
            }, import.meta.env.VITE_REACT_APP_EMAIL_JS_USER_ID);
          }

          Swal.fire({
            title: "Job request submitted successfully!",
            icon: "success"
          }).then(() => {
            // remove all data from the localstorage after successfully submitting a job so that the form is blank for the next posting.
            localStorage.removeItem(auth.currentUser.uid + "jobForm");
            // reload the page so that the form resets
            window.location.reload();
          });
          
        } catch (error) {
          console.error(error);
          Swal.close();
        }
      }
    })
  }

  /**
   * Edit the values of the job posting.
   * 
   * @param {Object} values - values that we are editing from formik
   */
  async function editPosting(values) {
    Swal.fire({
      title: "Just checking!",
      text: "You are about to edit a job posting. These changes will be applied, but you can edit this posting again whenever.",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Finish Editing",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Editing job posting',
          text: 'Please wait...',
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
        try {
          const encodedDescription = encodeURIComponent(values.description).trim();
          const encodedInternalNotes = encodeURIComponent(values.internalNotes).trim();
          const valuesToSubmit = {
            title: values.title,
            description: encodedDescription,
            internalNotes: encodedInternalNotes,
            country: values.country,
            deadline: values.deadline,
            link: values.link,
            thumbnail: values.thumbnail,
            minYearsExperience: values.minYearsExperience,
            maxYearsExperience: values.maxYearsExperience,
            minSalary: values.minSalary,
            maxSalary: values.maxSalary,
            type: values.type,
            city: values.city,
            jobSite: values.jobSite,
            category: values.category,
            jobId: job.id,
            inReview: false,
            imageURL: previousJobImage,
          };
          console.log("values to submit: ", valuesToSubmit);
          await updateJobPosting(valuesToSubmit);
          window.location.reload();
          // cancel();
        } catch (error) {
          console.error(error);
        } finally {
          Swal.close();
        }
      }
    });
  }

  /**
   * If the form validation doesn't pass, scroll user back to the top (errors automatically display when submitting the form too).
   * 
   * @param {Object} formData - data in the form 
   */
  function goBackToTopIfStillErrors(formData) {
    // Validate the formData against the schema to check for errors
    jobPostingValidationSchema.validate(formData)
      .catch(error => {
        // Scroll to the top if errors exist
        setTouched(true);
        window.scrollTo({
          top: 0,
          behavior: 'smooth', // Optional: for smooth scrolling behavior
        });
      });
  }

  return (
    <div>
        <Formik
          initialValues={initialValues}
          validationSchema={jobPostingValidationSchema}
          onSubmit={(values, actions) => {
            if (isAdmin) {
              if (isEditing) {
                editPosting(values);
              } else {
                confirmPosting(values);
              }
            } else {
              submitPosting(values);
            }
          }}
        >
        {({ isSubmitting, values, setFieldValue, setValues }) => {

          // Load cached values from localStorage
          useMemo(() => {
            if (!isEditing) {
              const cachedValues = JSON.parse(localStorage.getItem(auth.currentUser.uid + "jobForm"));
              if (!isEditing) {
                if (cachedValues) {
                  setValues(cachedValues);
                }
              }
            }
          }, []);

          // Save form values to localStorage upon dismount
          useEffect(() => {
            return () => {
              if (!isEditing) {
                delete values.thumbnail;
                localStorage.setItem(auth.currentUser.uid + "jobForm", JSON.stringify(values));
              }
            };
          }, [values]);

          return <Form>
            <h1 className="font-semibold text-lg mb-2">Job Posting Information</h1>
            <div className="flex flex-col mb-2">
              <div className="flex-1">
              <GenerateFormInput id={1} displayName={"Job Link"} inputName={"link"} jobPostingValidationSchema={jobPostingValidationSchema} />
              </div>
            </div>
            {/* ... (other existing code) */}
            <div className="flex md:flex-row flex-col gap-4 mb-2">
              <div className="flex-1">
                <GenerateFormInput id={2} displayName={"Job title"} inputName={"title"} jobPostingValidationSchema={jobPostingValidationSchema} />
              </div>
              <div className="flex-1">
                <GenerateSelectInput id={3} displayName={"Country"} inputName={"country"} options={countryList.map((country, index) => {
                    return <option selected={country === values["country"] ? true : undefined} value={country}>{country}</option>
                  })} jobPostingValidationSchema={jobPostingValidationSchema}/>
              </div>
            </div>

            <div className="flex md:flex-row flex-col gap-4 mb-2">
              <div className="flex-1">
                <GenerateFormInput id={4} displayName={"City"} inputName={"city"} jobPostingValidationSchema={jobPostingValidationSchema} />
              </div>
              <div className="flex-1">
                <GenerateSelectInput id={5} displayName={"Job Type"} inputName={"type"} 
                  options={["Full-time", "Part-time", "Internship", "Freelance", "Contract", "Volunteer"].map((type, index) => {
                    return <option selected={type === values["type"] ? true : undefined} value={type}>{type}</option>
                  })} jobPostingValidationSchema={jobPostingValidationSchema}
                />
              </div>
              <div className="flex-1">
                <GenerateFormInput id={6} displayName={"Deadline"} inputName={"deadline"} jobPostingValidationSchema={jobPostingValidationSchema} type={"date"} />
              </div>
            </div>
            <div className="flex md:flex-row flex-col gap-4 mb-2">
                <div className="flex-1">
                  <GenerateSelectInput id={7} displayName={"Job site"} inputName={"jobSite"} options={["Onsite", "Remote", "Hybrid"].map((jobSite, index) => {
                    return <option selected={jobSite === values["jobSite"] ? true : undefined} value={jobSite}>{jobSite}</option>
                  })} jobPostingValidationSchema={jobPostingValidationSchema} />
                </div>
                <div className="flex-1">
                <GenerateSelectInput id={8}
                  displayName={"Job category"}
                  inputName={"category"}
                  options={[
                    <option key="default" disabled value="">
                      Please select a category
                    </option>,
                    ...["Design jobs", "Research", "Content"].map((category, index) => (
                      <option
                        key={index}
                        selected={category === values["category"] ? true : undefined}
                        value={category}
                      >
                        {category}
                      </option>
                    ))
                  ]}
                  jobPostingValidationSchema={jobPostingValidationSchema}
                />
                </div>
            </div>
   
            <div className="flex flex-row gap-4 mb-2">
              <div>
                <NumberInput id={9} displayName={"Min Years Experience"} inputName={"minYearsExperience"} formValues={values} setFieldValue={setFieldValue} min={1} max={50} jobPostingValidationSchema={jobPostingValidationSchema} />
              </div>
              <div>
                <NumberInput id={10} displayName={"Max Years Experience"} inputName={"maxYearsExperience"} formValues={values} setFieldValue={setFieldValue} min={1} max={50} jobPostingValidationSchema={jobPostingValidationSchema} />
              </div>
            </div>

            <div className="flex flex-row gap-4 mb-2">
              <div>
                <GenerateFormInput id={11} displayName={"Min Salary"} inputName={"minSalary"} formValues={values} setFieldValue={setFieldValue} jobPostingValidationSchema={jobPostingValidationSchema} />
              </div>
              <div>
                <GenerateFormInput id={12} displayName={"Max Salary"} inputName={"maxSalary"} formValues={values} setFieldValue={setFieldValue} jobPostingValidationSchema={jobPostingValidationSchema} />
              </div>
            </div>

            <br/>
            <GenerateRichText id={13} displayName={"Job description"} inputName={"description"} formValues={values} setFieldValue={setFieldValue} touched={touched} setTouched={setTouched} />

            <div className="sm:mb-16 mb-24"></div>
            <GenerateRichText id={14} displayName={"Internal notes* only admins can see this"} inputName={"internalNotes"} formValues={values} setFieldValue={setFieldValue} touched={touched} setTouched={setTouched} />

            <div className="sm:mb-16 mb-24"></div>
            <ImageUploader id={15} displayName="Upload a thumbnail" inputName="thumbnail" formValues={values} setFieldValue={setFieldValue} jobPostingValidationSchema={jobPostingValidationSchema }/>

            <div className="flex flex-row justify-end gap-4 my-4">
              <button
                onClick={cancel}
                type="button"
                className="py-1 px-2 border border-blue-500 rounded-md text-blue-500 hover:bg-gray-300"
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={() => goBackToTopIfStillErrors(values)}
                className="py-1 px-8 rounded-md bg-blue-500 text-white hover:bg-blue-700"
              >
                {!isAdmin ? "Publish" : !isEditing ? "Confirm job posting" : "Confirm changes"}
              </button>
            </div>
            </Form>
        }}
      </Formik>
    </div>
  );
};

export default JobForm;