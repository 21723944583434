import { addDoc, arrayRemove, arrayUnion, collection, deleteDoc, doc, getCountFromServer, getDoc, getDocs, limit, onSnapshot, orderBy, query, serverTimestamp, startAfter, updateDoc, where } from "firebase/firestore";
import { firestore, storage } from "../../../firebaseConfig";
import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage";


/**
 * Uploads a new event.
 * 
 * @param {Object} event - the event information we want to upload. 
 */
export const createEvent = async (event) => {
    // obtain only wanted values from the event
    const {
        startTime, endTime, startDate, endDate, 
        thumbnail, title, city, country, 
        type, internalNotes, category, address, inReview,
        link, userId, timeZone, email,
    } = event;
    try {
        // upload image
        let imageURL = null;
        if (thumbnail) {
            const storageReference = ref(storage, `images2/events/${Date.now()}`);
            await uploadBytes(storageReference, thumbnail);
            imageURL = await getDownloadURL(storageReference);
        }

        const colleciton = collection(firestore, "events");
        await addDoc(colleciton, {
            startTime,
            endTime,
            startDate,
            endDate,
            imageURL,
            title,
            city,
            country,
            type,
            internalNotes,
            category,
            address,
            inReview,
            userId,
            link,
            timeZone,
            email,
            saved: [],
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
        });
    
    //GTM 
    window.dataLayer.push({
        event: 'createEvent',
        sharedItemDetails: {
            link: link
        }
    });

    } catch (error) {
        throw new Error("Could not create event: " + error);
    }
}

export const getSavedEvents = async (userId, lim, lastDoc) => {
    try {
        const eventsRef = collection(firestore, "events");
        let q = query(eventsRef, where("saved", "array-contains", userId), limit(lim));

        if (lastDoc !== null) {
            q =  query(q, startAfter(lastDoc));
        }

        const savedEventsSnapshot = await getDocs(q);
        const savedEvents = [];
        savedEventsSnapshot.forEach(eventDoc => {
            savedEvents.push({
                ...eventDoc.data(),
                id: eventDoc.id,
            });
        });

        const last = savedEventsSnapshot[savedEventsSnapshot.length - 1];

        return {
            events: savedEvents,
            lastDoc: last,
        };
    } catch (error) {
        throw new Error("Could not get saved resources: " + error);
    }
}

function createEventQueryWithoutLimit(startAfterDoc, search, sortBy, order, selectedCategory, selectedEventTypes, eventLocations) {
    const eventRef = collection(firestore, 'events');

    // Initialize query
    let q = eventRef;

    // Apply category filter if selectedCategory is provided
    if (selectedCategory && (selectedCategory === "All") || (selectedCategory === "Published Events")) {
        q = query(q, where("inReview", "==", false));
    }
    if (selectedCategory && (selectedCategory !== "All" && selectedCategory !== "Published Events")) {
        if (selectedCategory === "Pending Requests") {
            q = query(q, where("inReview", "==", true));
        } else {
            q = query(q, where("inReview", "==", false), where("category", "==", selectedCategory));
        }
    }

    // Apply job types filter
    if (selectedEventTypes.length != 0) {
        q = query(q, where("type", "in", selectedEventTypes));
    }

    // Apply job locations filter
    if (eventLocations.length != 0) {
        q = query(q, where("country", "in", eventLocations));
    }

    // Apply search filter if search is provided
    if (search) {
        // Use the same property for search filter and first sorting order
        q = query(
            q,
            where(search.fieldPath, ">=", search.fieldValue),
            where(search.fieldPath, '<=', search.fieldValue + '\uf8ff'),
            orderBy(search.fieldPath) // Matching the property used in the search filter
        );
    }

    // Apply sorting
    if (order != null && sortBy != null) {
        q = query(
            q,
            orderBy(sortBy, order)
        );
    }

    // Apply pagination
    if (startAfterDoc) {
        q = query(q, startAfter(startAfterDoc));
    }

    return q;
}

export const getEventCount = async (startAfterDoc, search, sortBy, order, selectedCategory, selectedEventTypes, eventLocations) => {
    try {
        let q = createEventQueryWithoutLimit(startAfterDoc, search, sortBy, order, selectedCategory, selectedEventTypes, eventLocations);
        const countSnapshot = await getCountFromServer(q);
        const count = countSnapshot.data().count;
        return count;
    } catch (error) {
        console.error(error);
        return -1;
    }
}

/**
 * Obtain all the events in the events collection.
 * 
 * @returns the events, empty array if there are none
 */
export const getEvents = async (startAfterDoc, search, sortBy, order, selectedCategory, selectedEventTypes, eventLocations, lim = null) => { 
    try {
        let q = createEventQueryWithoutLimit(startAfterDoc, search, sortBy, order, selectedCategory, selectedEventTypes, eventLocations);

        q = query(q, limit(lim));

        const eventDocs = await getDocs(q);
        if (!eventDocs.empty) {
            // Collect the results
            const events = eventDocs.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            // Obtain the last document for pagination
            const lastDoc = eventDocs.docs[eventDocs.docs.length - 1] || null;

            return {
                lastDoc: lastDoc,
                events: events,
            };
        }
        return {
            lastDoc: null,
            events: [],
        };
    } catch (error) {
        throw new Error("Could not get events: " + error);
    }
}

/**
 * Get an event with the corresponding id.
 * 
 * @param {String} id - id of the event we want to obtain 
 * @returns the event with the given id
 */
export const getEvent = async (id) => {
    try {
        const eventDoc = doc(firestore, "events", id);
        const eventSnapshot = await getDoc(eventDoc);
        if (eventSnapshot.exists()) {
            return {
                ...eventSnapshot.data(),
                id: eventSnapshot.id
            }
        } else {
            throw new Error("Event with id: " + id + " does not exist!");
        }
    } catch (error) {
        throw new Error("Could not get events: " + error);
    }
}

/**
 * Updates an event with the new information. 
 * 
 * @param {Object} event - event information we are updating to 
 */
export async function updateEvent(event) {
    try {
        console.log(event.imageURL); // exists
        console.log(event.thumbnail); // undefined
        const eventDoc = doc(firestore, "events", event.id);

        let imageURL = event.imageURL; // Store the current imageURL

        // Delete previous image if it exists when uploading a new thumbnail
        if (imageURL && event.thumbnail === null) {
            // Get the reference from the imageURL
            const previousImageRef = ref(storage, imageURL);
        
            // Delete the previous image
            await deleteObject(previousImageRef);
        }

        if (event.thumbnail) {
            const storageReference = ref(storage, `images2/events/${Date.now()}`);
            await uploadBytes(storageReference, event.thumbnail);
            imageURL = await getDownloadURL(storageReference);
        }

        const updatedEvent = {
            timeZone: event.timeZone,
            startTime: event.startTime,
            endTime: event.endTime,
            startDate: event.startDate,
            endDate: event.endDate,
            title: event.title,
            city: event.city,
            country: event.country,
            type: event.type,
            internalNotes: event.internalNotes,
            category: event.category,
            address: event.address,
            inReview: event.inReview,
        };
        console.log(updatedEvent);

        if (event.thumbnail === null && imageURL) {
            updatedEvent.imageURL = null;
        } else {
            updatedEvent.imageURL = imageURL;
        }

        // //GTM share
        window.dataLayer.push({
            event: 'updateEvent',
            sharedItemDetails: {
                link: event.link
            }
        });

        await updateDoc(eventDoc, {
            ...updatedEvent,
            updatedAt: serverTimestamp(),
        });
    } catch (error) {
        throw new Error("Failed to update event: " + error);
    }
}

/**
 * Deletes an event with the corresponding id.
 * 
 * @param {String} id - id of the event to delete 
 */
export const deleteEvent = async (id) => {
    try {
        const eventDoc = doc(firestore, "events", id);
        const eventSnapshot = await getDoc(eventDoc);

        if (eventSnapshot.exists()) {
            const eventData = eventSnapshot.data();

            // Check if the event has an associated image
            if (eventData.imageURL) {
                const imageRef = ref(storage, eventData.imageURL);

                // Delete the image from storage
                await deleteObject(imageRef);
            }

            // Delete the event document from Firestore
            await deleteDoc(eventDoc);
        } else {
            throw new Error("Job document does not exist.");
        }
    } catch (error) {
        throw new Error("Failed to delete event: " + error);
    }
}

export const listenForEventChanges = (onChange) => {
    const eventCollection = collection(firestore, "events");

    const unsubscribe = onSnapshot(eventCollection, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
            // Retrieve the changed post data
            const data = change.doc.data();
            const changeEvent = {
                id: change.doc.id,
                ...data,
            };
            onChange(changeEvent, change.type);
        });
    });

    return unsubscribe;
}

/**
 * Toggle the saved field in the event with your id.
 * 
 * @param {JSON} event - event to be saved
 * @param {string} userId - the id to save the event under
 */
export const toggleEventSave = async (event, userId) => {
    try {
        if (event.id === null) {
            throw new Error("Cannot toggle saved for an event with no id!");
        }
        if (event.saved === null) {
            throw new Error("Cannot toggle saved for an event with no saved field!");
        }

        const eventRef = doc(firestore, "events", event.id);
        const eventDoc = await getDoc(eventRef);
        if (eventDoc.exists()) {
            // figure out to remove or add the userId to saved (toggle)
            let updateAction = null;
            if (eventDoc.data().saved?.includes(userId)) {
                updateAction = arrayRemove(userId);
            } else {
                updateAction = arrayUnion(userId);
            }

            // toggle 
            await updateDoc(eventRef, {
                saved: updateAction
            });
        } else {
            throw new Error("Failed to toggle event saved because the event doesn't exist!");
        }
    } catch (error) {
        throw new Error("Failed to toggle saved on event due to the following: " + error);
    }
}