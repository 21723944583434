import Swal from "sweetalert2";

export const adjustIfStringTooLong = (str) => {
    // Check if the bio is too long without spaces or newlines
    const maxLineLength = 40; // Set the maximum line length
    if (str.length > maxLineLength && !/\s/.test(str)) {
        // Break the bio into lines
        const words = [];
        while (str.length > maxLineLength) {
            words.push(str.substr(0, maxLineLength));
            str = str.substr(maxLineLength);
        }
        words.push(str);
        str = words.join(' '); // Join words with spaces
    }
    return str;
}