import React, { useEffect, useRef, useState } from "react";
import { useUser } from "../../context/UserContext";
import { useAuth } from "../../context/AuthContext";
import {
  addPostComment,
  replyToComment,
} from "../../functions/firebase/firestorePosts";
import { useToast } from "../../context/ToastContext";
import EmojiButton from "./EmojiButton";
import LoginModal from "../util/LoginModal";
import { getUser } from "../../functions/firebase/firestoreUsers";
import { createNotification } from "../../functions/firebase/firestoreNotifications";
import Mentions from "./Mentions";

/**
 * The UI to either to create a comment. Can be used for creating a
 * comment reply if the commentToReplyTo field is passed in.
 */
export default function CommentPoster({
  post,
  commentToReplyTo = null,
  setComments,
  addCommentReplyOnClient,
  highLevelCommentId,
}) {
  const auth = useAuth();
  const user = useUser();

  const { showSuccessToast, showErrorToast, showLoadingToast } = useToast();
  const [emojiIsActive, setEmojiIsActive] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);



  const fileInputRef = useRef(null);

  function openLoginModal() {
    setShowLoginModal(true);
  }

  function handleFileUpload() {
    if (!auth || !auth.currentUser?.emailVerified) {
      openLoginModal();
      return;
    }
    fileInputRef.current.click();
  }

  /**
   * State of the current comment being made
   */
  const [comment, setComment] = useState({
    content: "",
    userId: auth?.currentUser?.uid,
    replies: [],
    image: null,
    imagePreview: null,
  });

  

  useEffect(() => {
    async function getCommentToReplyToUser() {
      const user = await getUser(commentToReplyTo.userId);
      user.firstName =
        user?.firstName?.charAt(0)?.toUpperCase() + user?.firstName?.slice(1);
      if (user !== null) {
        setComment({
          ...comment,
          content: `@[${user.firstName}](${user.uid})`,
        });
      }
    }
    if (commentToReplyTo?.isReply) {
      getCommentToReplyToUser();
    }
  }, []);

  /**
   * Updates the state of the current comment being made.
   *
   * @param {Event} e
   */
  function handleCommentChange(e) {
    if (!auth || !auth.currentUser?.emailVerified) {
      openLoginModal();
    }

    if (!auth?.currentUser || !auth.currentUser.emailVerified) {
      e.target.value = "";
    } else {
      if (e.target.type === "file") {
        // handle file change logic
        const selectedFile = e.target.files[0];
        if (selectedFile) {
          const reader = new FileReader();
          reader.onloadend = () => {
            const preview = reader.result;
            setComment({
              ...comment,
              image: selectedFile,
              imagePreview: preview,
            });
          };
          reader.readAsDataURL(selectedFile);
        }
        return;
      }

      // default file change logic
      const { name, value } = e.target;
      setComment({ ...comment, [name]: value });
    }
  }

  const handleRemoveImage = () => {
    setComment({ ...comment, imagePreview: null, image: null });
  };

  function addCommentNotification(type, toId, commentId = null) {
    // Call createCommentNotification if the commenter is not the post owner
    if (auth?.currentUser.uid !== null) {
      // don't want to send a notification if its your own action to yourself
      if (toId === auth.currentUser.uid) {
        return;
      }

      createNotification({
        senderId: auth.currentUser.uid,
        toId: toId,
        postId: post.postId,
        commentId: commentId,
        type: type,
        action: "comment",
      });
    }
  }

  /**
   *
   * Displays the comment and uploads it to the database
   * when the user presses enter on the input field to write the comment.
   *
   * @param {Event} e - the key down event
   */
  async function handleSubmitComment(e) {
    //console.log("Attempting to submit comment", comment);

    if (!auth || !auth.currentUser?.emailVerified) {
      openLoginModal();
      return;
    }

    function extractIds(inputString) {
      var pattern = /\(([\w]+)\)/g;
      var ids = [];
      var match;
      while ((match = pattern.exec(inputString)) !== null) {
        ids.push(match[1]);
      }
      return ids;
    }

    const mentionIds = extractIds(comment.content);
    const isMention = mentionIds.length > 0;
    console.log(mentionIds);

    function showCommentOnClient() {
      setComments((prevComments) => {
        const comments = [...prevComments];
        temp.user = user;
        temp.content = decodeURIComponent(temp.content);
        comments.unshift(temp);
        return comments;
      });
    }

    const temp = comment;

    setEmojiIsActive(false);

    try {
      if (comment.content.trim() !== "") {
        temp.content = temp.content.trim();
        comment.imagePreview = null;
        setComment({ ...comment, content: "" });

        if (commentToReplyTo !== null) {
          // replying to top level comment or reply
          const commentId = await replyToComment(
            post.postId,
            temp,
            commentToReplyTo,
            highLevelCommentId
          );
          temp.commentId = commentId;
          temp.user = user;
          console.log("after replying: ", temp);
          addCommentReplyOnClient(temp, commentToReplyTo);

          // add top level comment
          addCommentNotification(
            "reply",
            commentToReplyTo.userId,
            temp.commentId
          );

          // send over a notification for all mentions in the comment
          if (isMention) {
            mentionIds.forEach((id) => {
              addCommentNotification("mention", id, temp.commentId);
            });
          }
        } else {
          // adding a top level comment
          const commentId = await addPostComment(post.postId, temp);
          temp.commentId = commentId;
          showCommentOnClient();

          // send over a someone commented on your post notification
          addCommentNotification("comment", post.userId, temp.commentId);

          // send over a mention notification for everyone mentioned
          if (isMention) {
            mentionIds.forEach((id) => {
              addCommentNotification("mention", id, temp.commentId);
            });
          }
        }
      }
    } catch (error) {
      console.error(error);

      // add back the comment if error
      temp.content = decodeURIComponent(temp.content.trim());
      setComment(temp);
      showSuccessToast("Failed to added comment!");
    }
  }

  function addEmojiToContent(emojiPicker) {
    const emoji = emojiPicker;
    setComment((prevComment) => {
      const updatedContent = prevComment.content + emoji;
      return { ...prevComment, content: updatedContent };
    });
  }

  return (
    <div
      data-testid="comment-poster-component"
      className="flex flex-col items-top gap-2 my-4"
    >
      <div className="flex flex-row gap-2">
        <img
          className="w-8 h-8 rounded-full mt-1"
          src={
            user?.profilePictureURL
              ? user.profilePictureURL
              : "/assets/images/icons/user.svg"
          }
        />
        <div className="flex items-top justify-between flex-col sm:flex-row flex-1 rounded-lg sm:border sm:border-gray-300">
          <Mentions
            value={comment.content}
            setValue={(newContent) => {
              //console.log(comment);
              setComment({
                ...comment,
                content: newContent,
              });
            }}
          />
          <div className="flex flex-row gap-x-2 mx-2 sm:mt-1 mt-2 justify-end">
            <EmojiButton
              setEmojiIsActive={setEmojiIsActive}
              emojiIsActive={emojiIsActive}
              handleEmojiClicked={addEmojiToContent}
            />

            <button
              data-testid="upload-image-button"
              className="w-6 h-6 rounded mt-1"
            >
              <img
                onClick={handleFileUpload}
                src="/assets/images/icons/image.svg"
                alt=""
              />

              <input
                data-testid="upload-image-input"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleCommentChange}
                ref={fileInputRef}
              />
            </button>

            <button
              onClick={handleSubmitComment}
              className="bg-blue-500 cursor-pointer w-20 h-8 text-white hover:bg-blue-700 rounded "
            >
              {commentToReplyTo === null ? "Post" : "Reply"}
            </button>
          </div>
        </div>
      </div>

      {comment.imagePreview !== null && (
        <div className="relative inline-block w-full h-auto">
          <img
            data-testid="comment-image-preview"
            src={comment.imagePreview}
            alt="Image Preview"
            className="w-full h-auto"
          />
          <button
            onClick={handleRemoveImage}
            className="absolute top-0 right-0 p-2 text-white rounded-full"
            style={{ fontSize: "0.8rem" }}
          >
            <img
              className="bg-gray-300 rounded-full"
              src="./assets/images/icons/cancel.svg"
              style={{ width: "20px", height: "20px" }}
            />
          </button>
        </div>
      )}

      <LoginModal modalIsOpen={showLoginModal} setIsOpen={setShowLoginModal} />
    </div>
  );
}
