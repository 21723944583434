import { listenForEventChanges } from "../firebase/firestoreEvents";
import { listenForJobChanges } from "../firebase/firestoreJobs";
import { listenForNotificationChanges } from "../firebase/firestoreNotifications";
import { listenForCommentChanges, listenForPostChanges } from "../firebase/firestorePosts";
import { listenForResourceChanges } from "../firebase/firestoreResources";
import { getUser, listenForUserChanges } from "../firebase/firestoreUsers";


/**
 * Helper function to update realtime posts on the client side.
 * 
 * @param {Function} setPosts - the set state of the post state variable to update  
 * @param {*} auth - authed user
 * @param {*} user - authed user data
 * @param {*} showErrorToast - toast to be displayed in case of error
 * 
 * @returns unsubscribe function for cleanup when component unmounts
 */
export function realTimePosts(setPosts, auth, user, showErrorToast) {

    /**
     * Update the state of posts to support the newly modified one coming in.
     * 
     * @param {Object} updatedPost - the newly updated post
     */
    function updateStateForModified(updatedPost) {
        setPosts((prevPosts) => {
            const updatedPosts = prevPosts.map((post) =>
                post.postId === updatedPost.postId ? updatedPost : post
            );
            updatedPosts.sort((a, b) => {
            // Convert Firestore Timestamps to JavaScript Date objects for comparison
            const dateA = a.createdAt;
            const dateB = b.createdAt;
            // Sort by descending order
            return dateB - dateA;
            });
            localStorage.setItem("posts", JSON.stringify(updatedPosts));
            return updatedPosts;
        });
    }

    /**
     * Update the state of posts to support the newly added post coming in.
     * 
     * @param {Object} addedPost - the newly added post
     */
    function updateStateForAdded(addedPost) {
        setPosts((prevPosts) => {
            // Create a new array with the added post
            const updatedPosts = [...prevPosts, addedPost];
            // Sort the updatedPosts by createdAt timestamp
            updatedPosts.sort((a, b) => {
            // Convert Firestore Timestamps to JavaScript Date objects for comparison
            const dateA = a.createdAt;
            const dateB = b.createdAt;
        
            return dateB - dateA; // Sort by descending order
            });

            localStorage.setItem("posts", JSON.stringify(updatedPosts));
            return updatedPosts;
        });
    }

    /**
     * Update the state of posts to support the newly deleted post coming in.
     * 
     * @param {Object} removedPost - the newly deleted post
     */
    function updateStateForRemoved(removedPost) {
        setPosts((prevPosts) => {
            const updatedPosts = prevPosts.filter((post) => post.postId !== removedPost.postId);
            localStorage.setItem("posts", JSON.stringify(updatedPosts));
            return updatedPosts;
        });
    }

    const unsubscribe = listenForPostChanges(async (updatedPost, changeType) => {
        if (updatedPost) {
            try {
            // fetch the updated post user for the post.
            let postUser = null;
            if (auth?.currentUser?.uid === updatedPost.userId) {
                postUser = user;
            } else {
                postUser = await getUser(updatedPost.userId);
            }
            updatedPost.user = postUser;
    
            switch (changeType) {
                case "added":
                    // updateStateForAdded(updatedPost, setPosts);
                    break; 
                case "modified":
                    updateStateForModified(updatedPost);
                    break;
                case "removed":
                    // do this b/c can't longer scroll if there is less than 2 posts meaning you cant fetch more
                    updateStateForRemoved(updatedPost);
                    break;
            }
    
            } catch (error) {
                showErrorToast();
                console.error(error);
            }
        }
    });
    return unsubscribe;
}

/**
 * When the user changes their account details via edit profile, we want to update that on the post realtime which is done here.
 * 
 * @param {Object} auth - authenticated user to update 
 * @param {*} setUserPosts - set state function to update the state variable of the posts with the updated user data
 */
export function realTimePostUserData(auth, setUserPosts) {
    listenForUserChanges(auth.currentUser.uid, (user) => {
        setUserPosts((prevPosts) => {
          return prevPosts.map(post => {
            if (post.userId === auth?.currentUser.uid) {
              post.user = user;
            }
            return post;
          });
        });
    });
}

export function realTimeComments(setCommentsList, post, showErrorToast) {

    /**
     * Updates the state of the added comment along with the cached version
     * 
     * @param {Object} addedComment - comment that was added 
     */
    const updateStateForAdded = async (addedComment) => {
        try {
            setCommentsList((prevCommentList) => {
                let updatedComments = [...prevCommentList, addedComment];
                return updatedComments;
            });
        } catch (error) {
            console.error(error);
            showErrorToast();
        }
    }

    /**
     * Updates the state for the newly modified comment along with the cached values.
     * 
     * @param {Object} modifiedComment - comment modified 
     */
    const updateStateForModified = async (modifiedComment) => {
        try {
            setCommentsList((prevCommentList) => {
                let modifiedComments = prevCommentList.map(prevComment => prevComment.commentId === modifiedComment.commentId ? modifiedComment : prevComment);
                return modifiedComments;
            });
        } catch (error) {
            console.error(error);
            showErrorToast();
        }
    }

    /**
     * Updates the state for the newly removed comment along with the cached values.
     * 
     * @param {Object} removedComment - newly removed comment 
     */
    const updateStateForRemoved = async (removedComment) => {
        try {
        setCommentsList((prevCommentList) => {
            let updatedComments = prevCommentList.filter(prevComment => prevComment.commentId !== removedComment.commentId);
            return updatedComments;
        });
        } catch (error) {
            console.error(error);
            // showErrorToast();
        }
    }

    const unsubscribe = listenForCommentChanges(post.postId, async (updatedComment, typeChange) => {
        try {
            // update the state of the comment based on the change
            if (updatedComment) {
                // add all the user data to the updated comment along with its replies
                // so you can see who made it. 
                const commentUser = await getUser(updatedComment.userId);
                updatedComment.user = commentUser;
                await updatedComment.replies.map(async (reply) => {
                    try {
                        reply.user = await getUser(reply.userId);
                    } catch (error) {
                        console.error(error);
                    }
                    return reply;
                });
    
                switch (typeChange) {
                    case "added":
                    // updateStateForAdded(updatedComment);
                    break;
                    case "modified":
                    updateStateForModified(updatedComment);
                    break;
                    case "removed":
                    updateStateForRemoved(updatedComment);
                    break;
                }
            }
        } catch (error) {
            // showErrorToast();
            console.log(error);
        }
    });
    return unsubscribe;
}

// TODO: figure out how to get working for updating user comment data realtime
export function realTimeCommentUserData(auth, setCommentsList) {
    listenForUserChanges(auth.currentUser.uid, (user) => {
        setCommentsList((prevCommentsList) => {
          return prevCommentsList.map(comment => {
            if (comment.userId === auth?.currentUser.uid) {
              comment.user = user;
            }
            return comment; 
          });
        });
    });
}

export function realTimeJobs(setJobs) {

    console.log("job updated");

    function onModified(changedJob) {
        setJobs(prevJobs => {
            return prevJobs.map(prevJob => (prevJob.jobId === changedJob.jobId ? changedJob : prevJob));
        });
    }

    function onRemoved(removedJob) {
        setJobs(prevJobs => {
            return prevJobs.filter(prevJob => prevJob.jobId !== removedJob.jobId);
        });
    }

    function onAdded(addedJob) {
        setJobs(prevJobs => {
            return [...prevJobs, addedJob];
        });
    }

    const unsubscribe = listenForJobChanges(async (changedJob, changeType) => {
        try {
            switch (changeType) {
                case "added":
                    // onAdded(changedJob);
                    break;
                case "modified":
                    onModified(changedJob);
                    break;
                case "removed":
                    onRemoved(changedJob);
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.error(error);
        }
    });

    return unsubscribe;
}

export function realTimeEvents(setEvents) { 

    function onModified(changedEvent) {
        console.log("Modified: " + changedEvent);
        setEvents(prevEvents => { 
            const events = prevEvents.map(prevEvent => prevEvent.id === changedEvent.id ? changedEvent : prevEvent);
            events.sort((a, b) => {
               return b.updatedAt - a.updatedAt;
            });
            return events;
        });
    }

    function onRemoved(removedEvent) {
        setEvents(prevEvents => {
            return prevEvents.filter(prevEvent => prevEvent.id !== removedEvent.id);
        });
    }

    function onAdded(addedEvent) {
        setEvents(prevEvents => {
            return [...prevEvents, addedEvent];
        });
    }

    const unsubscribe = listenForEventChanges(async (changedEvent, changeType) => {
        try {
            switch (changeType) {
                case "added":
                    // onAdded(changedJob);
                    break;
                case "modified":
                    onModified(changedEvent);
                    break;
                case "removed":
                    onRemoved(changedEvent);
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.error(error);
        }
    });

    return unsubscribe;
}

export function realTimeResources(setResources) { 

    function onModified(changedResource) {
        console.log("Modified: " + changedResource);
        setResources(prevResources => { 
            const resources = prevResources.map(prevResource => prevResource.id === changedResource.id ? changedResource : prevResource);
            resources.sort((a, b) => {
               return b.updatedAt - a.updatedAt;
            });
            return resources;
        });
    }

    function onRemoved(removedResource) {
        setResources(prevResource => {
            return prevResource.filter(prevResource => prevResource.id !== removedResource.id);
        });
    }

    function onAdded(addedResource) {
        setResources(prevResource => {
            return [...prevResource, addedResource];
        });
    }

    const unsubscribe = listenForResourceChanges(async (changedResource, changeType) => {
        try {
            switch (changeType) {
                case "added":
                    // onAdded(changedResource);
                    break;
                case "modified":
                    onModified(changedResource);
                    break;
                case "removed":
                    onRemoved(changedResource);
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.error(error);
        }
    });

    return unsubscribe;
}

export function realtimeNotifications(authId, setNotifications) {

    function onAdded(addedNotification) {
        console.log(addedNotification);
        setNotifications(prevNotifications => {
            const combinedNotifications = [...prevNotifications, addedNotification];
            return combinedNotifications.sort((a, b) => {
                return b.createdAt - a.createdAt;
            });
        });
    }

    function onRemoved(removedNotification) {
        setNotifications(prevNotifications => {
            return prevNotifications.filter(prevNotification => prevNotification.id !== removedNotification.id);
        })
    }

    function onModified(modifiedNotification) {
        setNotifications(prevNotifications => {
            return prevNotifications.map(prevNotification => prevNotification.id === modifiedNotification.id ? modifiedNotification : prevNotification);
        });
    }

    const unsubscribe = listenForNotificationChanges(authId, async (notification, changeType) => {
        switch (changeType) {
            case "added":
                onAdded(notification);
                break;
            case "modified":
                onModified(notification);
                break;
            case "removed":
                onRemoved(notification);
        }
    });

    return unsubscribe;
}