import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet,
  useNavigate,
  useLocation,
} from "react-router-dom";
import LandingPage from "./pages/landing/LandingPage";
import BetaPage from "./pages/landing/BetaPage";
import Login from "./pages/login/Login";
import CommunityLayout from "./components/CommunityLayout";
import Explore from "./pages/explore/Explore";
import Events from "./pages/events/Events";
import Jobs from "./pages/jobs/Jobs";
import Resources from "./pages/resources/Resources";
import Saved from "./pages/saved/Saved";
import { AuthProvider, useAuth } from "./context/AuthContext";
import UserProvider, { useUser } from "./context/UserContext";
import PersonalInfoForm from "./pages/login/includes/PersonalInfoForm";
import ForgotPassword from "./pages/login/includes/ForgotPassword";
import { ToastProvider } from "./context/ToastContext";
// import Story from "./pages/landing/Story";
import "react-tooltip/dist/react-tooltip.css";
import NotFound from "./pages/notfound/NotFound";
import ExploreAdmin from "./pages/explore/ExploreAdmin";
import SavedAdmin from "./pages/saved/SavedAdmin";
import Settings from "./pages/settings/Settings";
import Payment from "./pages/settings/Payment";
import Notifications from "./pages/settings/Notifications";
import Help from "./pages/settings/Help";
import PrivacyPolicy from "./pages/privacytos/PrivacyPolicy";
import Story from "./pages/landing/Story";
import TermsOfService from "./pages/privacytos/TermsOfService";
import JobPage from "./pages/jobs/JobPage";
import Feedback from "./pages/feedback/Feedback";

/**
 * Ensures that the user has filled in the sign up form before viewing any of the
 * pages if they are authenticated.
 *
 * @param {Components} children to either be rendered or not
 *
 * @returns the children to be rendered if all is well or some message
 */
function PrivateRoute({ children }) {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const auth = useAuth();
  const user = useUser();
  const navigate = useNavigate();
  const RedirectSite = () => {
    useEffect(() => {
      window.location.href = "pages/landing/ourstory.html";
    }, []);
    return <></>;
  };

  useEffect(() => {
    console.log(location);
    console.log(location.pathname);
    if (auth && !user) {
      try {
        const logout = async () => {
          await auth.signOut();
          console.log("logged out user because not done creating account!");
        };
        logout();
      } catch (error) {
        console.error(error);
      }
    }
  }, [navigate]);

  useEffect(() => {
    setLoading(false);
  }, []);

  return loading ? <div>Loading...</div> : children;
}

function AdminRoute({ children }) {
  const user = useUser();
  console.log(user);
  if (user?.authorities?.find(authoritiy => authoritiy === "admin")) {
    console.log(user.uid);
    return children;
  } else {
    return <div>You do not have permission to access this route</div>
  }
}

function App() {
  return (
    <AuthProvider>
      {/* <Helmet>
            <title>UX Was Here</title>
            <meta name="description" content="Join the community of UX Designers today!" data-rh="true"/>
            <meta property="og:title" content="The job" />
            <meta property="og:url" content="http://localhost:5173/jobs/9GaOBKDQE5MpPgIwTboL" />
            <meta property="og:image" content="/assets/images/logo.png" />
            <meta property="og:type" content="website" />
            <meta property="og:description" content="Join the community of UX Designers today!"/>
          </Helmet> */}
      <UserProvider>
        <ToastProvider>
            <Router>
              <Routes>
                <Route path="/" element={<BetaPage />} />
                <Route path="/login" element={<Login />} />
                <Route path="forgotpassword" element={<ForgotPassword />} />
                <Route path="/story" element={<Story />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/tos" element={<TermsOfService />} />
                <Route path="*" element={<NotFound />} />

                <Route
                  path="/admin/explore"
                  element={
                    <AdminRoute>
                      <CommunityLayout>
                        <ExploreAdmin />
                      </CommunityLayout>
                    </AdminRoute>
                  }
                />

                <Route
                  path="/explore/:id?"
                  element={
                    <PrivateRoute>
                      <CommunityLayout>
                        <Explore />
                      </CommunityLayout>
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/feedback"
                  element={
                    <PrivateRoute>
                      <CommunityLayout>
                        <Feedback />
                      </CommunityLayout>
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/events/:id?"
                  element={
                    <PrivateRoute>
                      <CommunityLayout>
                        <Events isAdmin={false} />
                      </CommunityLayout>
                    </PrivateRoute>
                  }
                />

                  <Route
                    path="/admin/events"
                    element={(
                      <AdminRoute>
                        <CommunityLayout>
                          <Events isAdmin={true} />
                        </CommunityLayout>
                      </AdminRoute>
                  )}
                  />

                <Route
                  path="/jobs/:id?"
                  element={
                    <PrivateRoute>
                      <CommunityLayout>
                        <JobPage isAdmin={false} />
                      </CommunityLayout>
                    </PrivateRoute>
                  }
                />

                  <Route
                    path="/admin/jobs"
                    element={(
                      <AdminRoute>
                        <CommunityLayout>
                          <JobPage isAdmin={true} />
                        </CommunityLayout>
                      </AdminRoute>
                    )}
                  />

                <Route
                  path="/resources/:id?"
                  element={
                    <PrivateRoute>
                      <CommunityLayout>
                        <Resources isAdmin={false} />
                      </CommunityLayout>
                    </PrivateRoute>
                  }
                />

                  <Route
                    path="/admin/resources"
                    element={(
                      <AdminRoute>
                        <CommunityLayout>
                          <Resources isAdmin={true} />
                        </CommunityLayout>
                      </AdminRoute>
                    )}
                  />

                <Route
                  path="/saved"
                  element={
                    <PrivateRoute>
                      <CommunityLayout>
                        <Saved />
                      </CommunityLayout>
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/admin/saved"
                  element={
                    <AdminRoute>
                      <CommunityLayout>
                        <SavedAdmin />
                      </CommunityLayout>
                    </AdminRoute>
                  }
                />

                <Route
                  path="/settings"
                  element={
                    <PrivateRoute>
                      <CommunityLayout>
                        <Settings />
                      </CommunityLayout>
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/settings/payment"
                  element={
                    <PrivateRoute>
                      <CommunityLayout>
                        <Payment />
                      </CommunityLayout>
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/settings/notifications"
                  element={
                    <PrivateRoute>
                      <CommunityLayout>
                        <Notifications />
                      </CommunityLayout>
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/settings/help"
                  element={<PrivateRoute>
                    <CommunityLayout>
                      <Help />
                    </CommunityLayout>
                  </PrivateRoute>}
                />
              </Routes>
            </Router>
        </ToastProvider>
      </UserProvider>
    </AuthProvider>
  );
}

export default App;