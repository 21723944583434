import React, { useState } from 'react';
import {
  ErrorMessage, Field, Form, Formik,
} from 'formik';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { signupValidationSchema } from '../../../functions/validationSchemas';
import { useAuth } from '../../../context/AuthContext';
import { useToast } from '../../../context/ToastContext';

function SignupForm({ setForm }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { signUpWithEmailPassword, emailVerification, signInWithGoogle, 
    signInWithFacebook, signInWithLinkedIn } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const { showSuccessToast, showErrorToast, showLoadingToast } = useToast();

  /**
   * Handles the sign in functionality for signing in with google.
   */
  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
      showSuccessToast('Successfuly Signed In');
    } catch (error) {
      showErrorToast("Failed to Sign In");
      console.log("Failed to sign in with google");
    }
  }

  /**
   * Handles the sign in functionality for signing in with facebook.
   */
  const handleFacebookSignIn = async () => { 
    try {
      await signInWithFacebook();
      showSuccessToast('Successfuly Signed In');
    } catch (error) {
      showErrorToast("Failed to Sign In");
      console.log("Failed to sign in with facebook" );
    }
  }

  /**
   * Handles the sign in functionality for signing in with linkedin.
   */
  const handleLinkedInSignIn = async () => {
      try {
        await signInWithLinkedIn();
      } catch (error) {
        alert("Failed to sign in with linkedin due to the following: " + error);
      }
  }

  /**
   * When the form is submitted, the user is signed up with 
   * email and password and then taken to fill out the personal info form. 
   * 
   * If there is an issue with the email domain or if the email already exists,
   * then there will be an error
   * 
   * @param {Object} values - contains the user signup data
   */
  const handleSubmit = async (values) => {
    console.log(values);
    try {
      showLoadingToast('Validating Email...');
      let response;

      response = await fetch('https://api-omyn23yr4a-uc.a.run.app/auth/emailVerification', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: values.email }),
      });

      if (response.status == 200) {
        await signUpWithEmailPassword(values.email, values.password);
        emailVerification();
        showSuccessToast('Account Successfully Created!');
      } else {
        showErrorToast();
      }
    } catch (error) {
        showErrorToast();
        console.log('Failed to sign up with email and password due to the following: ' + error);
    }
  };

  // styles
  const errorStyle = 'text-red-500 text-xs pt-1';
  // const socialBtnStyle = 'border border-[#BEBEBE] rounded-md w-[38px] h-[32px] hover:shadow-md transition-all duration-300 ease-in-out';
  const socialBtnStyle = 'mr-5 border border-[#BEBEBE] rounded-md w-[38px] h-[32px] hover:shadow-md transition-all duration-300 ease-in-out';
  return (
    <div className="max-w-[244px] mx-auto">
      <h1 className="text-center text-2xl font-bold mb-8">Sign up</h1>

      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={signupValidationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <div className="relative mb-4">
              <label className="absolute -top-2 left-5 px-1 bg-white text-[#959595] text-xs font-medium">
                Email
              </label>
              <Field
                data-testid="email-input"
                type="email"
                name="email"
                className="w-full p-2 border border-[#d8d8d8] rounded-xl focus:ring-primaryBlue focus:border-primaryBlue"
              />
              <div data-testid="error-email" className={errorStyle}>
                <ErrorMessage name="email" />
              </div>
            </div>

            <div className="relative mb-6">
              <label className="absolute z-10 -top-2 left-5 px-1 bg-white text-[#959595] text-xs font-medium">
                Password
              </label>
              <div className="relative">
                <Field
                  data-testid="password-input"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  className="w-full p-2 border border-[#d8d8d8] rounded-xl focus:ring-primaryBlue focus:border-primaryBlue"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2"
                >
                  {showPassword ? (
                    <FaRegEyeSlash color="#939393" />
                  ) : (
                    <FaRegEye color="#939393" />
                  )}
                </button>
              </div>
              <div data-testid="error-password" className={errorStyle}>
                <ErrorMessage name="password" />
              </div>
            </div>

            <div className="relative mb-6">
              <label className="absolute z-10 -top-2 left-5 px-1 bg-white text-[#959595] text-xs font-medium">
                Confirm Password
              </label>
              <div className="relative">
                <Field
                  data-testid="confirm-password-input"
                  type={showPassword ? "text" : "password"}
                  name="confirmPassword"
                  className="w-full p-2 border border-[#d8d8d8] rounded-xl focus:ring-primaryBlue focus:border-primaryBlue"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2"
                >
                  {showPassword ? (
                    <FaRegEyeSlash color="#939393" />
                  ) : (
                    <FaRegEye color="#939393" />
                  )}
                </button>
              </div>
              <div data-testid="error-confirm-password" className={errorStyle}>
                <ErrorMessage name="confirmPassword" />
              </div>
            </div>

            <button
              data-testid="button-signup"
              type="submit"
              className="w-full text-sm bg-primaryBlue text-white p-2 rounded-full mb-5 hover:shadow-md transition-all duration-300 ease-in-out"
            >
              Continue
            </button>
          </Form>
        )}
      </Formik>

      <div className="">
        <div className="relative before:absolute before:content-[''] before:w-[calc(50%-3rem)] before:h-[1px] before:bg-[#959595] before:left-0 before:top-1/2 before:-translate-y-1/2 after:absolute after:content-[''] after:w-[calc(50%-3rem)] after:h-[1px] after:bg-[#959595] after:right-0 after:top-1/2 after:-translate-y-1/2 text-center text-sm text-[#959595] font-medium mb-5">
          Sign up with
        </div>

        <div className="flex justify-left my-8">
          {/* <button type="button" className={socialBtnStyle}>
            <img
              onClick={handleFacebookSignIn}
              src="/assets/images/icons/facebook.png"
              alt="facebook icon"
              className="w-full h-full object-contain object-center mx-auto p-1"
            />
          </button> */}
          <button type="button" className={socialBtnStyle}>
            <img
              onClick={handleGoogleSignIn}
              src="/assets/images/icons/google.png"
              alt="google icon"
              className="w-full h-full object-contain object-center mx-auto p-1"
            />
          </button>

          {/* Not yet supported */}
          {/* <button onClick={handleLinkedInSignIn} type="button" className={socialBtnStyle}>
            <img
              src="/assets/images/icons/linkedin.png"
              alt="linkedin icon"
              className="w-full h-full object-contain object-center mx-auto p-1"
            />
          </button> */}
          {/* <button type="button" className={socialBtnStyle}>
            <img
              src="/assets/images/icons/apple.png"
              alt="apple icon"
              className="w-full h-full object-contain object-center mx-auto p-1"
            />
          </button> */}
        </div>
      </div>

      <div className="mb-4 text-[#959595] text-xs font-bold">
        Already have an account?{" "}
        <button
          type="button"
          onClick={() => setForm("login")}
          className="text-primaryBlue font-normal"
        >
          Sign in
        </button>
      </div>

      <div className="text-[#959595] text-xs">
        By creating your account, you agree to the
        <a href="/tos" className="font-bold underline">
          {' '}
          Terms of Service
        </a>{" "}
        and
        <a href="/privacy" className="font-bold underline">
          {' '}
          Privacy Policy
        </a>
      </div>

      <div className="mt-4 flex gap-x-1">
        <img
          src="/assets/images/icons/security.svg"
          alt="shield icon"
          className="pb-1"
        />
        <p className="text-xs font-light text-[#959595]">
          We do not sell your personal information to anyone
        </p>
      </div>
    </div>
  );
}

export default SignupForm;
