import React, { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Help() {
  return (
    <div>
      <Helmet>
        {/* TODO: check this */}
        <title>UX was Here - Help</title>
      </Helmet>
      <div className="flex-1 lg:pt-8 pt-4 lg:px-14 md:px-6">
        <h2 className="text-2xl pb-4 font-bold">Help</h2>
        <p>Coming soon</p>
      </div>
    </div>
  );
}
