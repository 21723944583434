import React, { useEffect, useRef, useState } from "react";
import he from "he";
import { useAdminToggle } from "../../context/AdminContext";
import { useUser } from "../../context/UserContext";
import JobForm from "../../pages/jobs/JobForm";
import Swal from "sweetalert2";
import { deleteJob, toggleJobSave } from "../../functions/firebase/firestoreJobs";
import "./richText.css";
import { Tooltip } from "react-tooltip";
import Modal from "react-modal";
import ShareItem from "../util/ShareItem";
import LoginModal from "../util/LoginModal";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import ShareMetaTags from "../util/ShareMetaTags";
import { useAuth } from "../../context/AuthContext";

function JobCard({ job, isAdmin }) {
  const { id } = useParams();
  const auth = useAuth();

  const [loginModalOpen, setloginModalOpen] = useState(false);
  const [hasDetailsBeenViewed, setHasDetailsBeenViewed] = useState(false);
  const [showDetails, setShowDetails] = useState(id ? true : false);
  const [isSaved, setIsSaved] = useState(job.saved?.includes(auth?.currentUser?.uid));
  const gtmEventFired = useRef(false);
  const [showPostingForm, setShowPostingForm] = useState(false);
  const user = useUser();

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function openLoginModal() {
    setloginModalOpen(true);
  }

  function fireJobOpenedGTM() {
    // GTM event for showing job details
    window.dataLayer.push({
      event: "view_job_details",
      job_id: job.jobId,
      job_title: job.title,
    });
  }

  function toggleDetailsAndFireGTM() {
    if (!hasDetailsBeenViewed) {
      fireJobOpenedGTM(); // Fire GTM event only on the first click
      setHasDetailsBeenViewed(true); // Prevent future GTM fires for this card
    }
    setShowDetails(prev => !prev); // Toggle details visibility
  }
  
  function handleDeleteJob() {
    Swal.fire({
      title: "Are you sure you want to delete?",
      text: "This cannot be undone and the posting will be removed forever!",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Removing job posting",
          text: "Please wait...",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        try {
          Swal.isLoading(true);
          await deleteJob(job.id);
          Swal.fire({
            title: "Successfully deleted job posting request!",
            text: "The job posting request was successfully deleted",
            icon: "success",
          }).then(() => {
            window.location.reload();
          });
        } catch (error) {
          console.error(error);
        } finally {
          Swal.isLoading(false);
        }
      }
    });
  }

  function toggleEditJob() {
    setShowPostingForm((prev) => !prev);
  }

  function fireJobApplyGTMEvent() {
    window.dataLayer.push({
      event: "jobApply",
      job_id: job.jobId,
      job_title: job.title,
    });
  }
  
  function fireJobSaveGTMEvent() {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "jobSave",
    job_id: job.jobId,
    job_title: job.title,
    saved: isSaved, // This boolean flag will help track the toggle action: save or unsave.
  });
}

async function handleSaveJob() {
  if (!auth.currentUser?.emailVerified) {
    openLoginModal();
    return;
  }

  // Fire GTM event only if it hasn't been fired already for this interaction
  if (!gtmEventFired.current) {
    fireJobSaveGTMEvent(); // Fires GTM event
    gtmEventFired.current = true; // Prevents further GTM event firings in this interaction
  }

  // Update client side
  setIsSaved((prev) => !prev);

  // Update backend
  await toggleJobSave(job, auth.currentUser.uid); 

  // Reset the GTM event fired flag here if necessary
  // gtmEventFired.current = false; 
}

useEffect(() => {
  // Reset on component unmount or other condition
  return () => {
    gtmEventFired.current = false;
  };
}, []); // Adjust dependencies based on when you need to reset the flag


  const jobTitleShort = job.title.length > 35 ? job.title.slice(0, 35) : null;

  return (
    <>
      {id && (
        <ShareMetaTags
          title={"UX Was Here Jobs - " + job.title}
          description={job.description}
          imageURL={job.imageURL}
          imageAlt="Job Description Image"
          url={location.href}
        />
      )}

      <div data-testid="job-card-component" className="bg-white border rounded lg:p-3 p-2 flex flex-col">
        <div className="flex flex-row h-full w-full justify-center items-center">
          <div className="rounded overflow-hidden w-full h-full items-center flex sm:flex-row flex-col gap-4">
            <div
              className="min-w-[175px] sm:h-auto min-h-[75px] cursor-pointer items-center flex justify-center"
              onClick={() => {
                if (!hasDetailsBeenViewed) {
                  fireJobOpenedGTM();
                  setHasDetailsBeenViewed(true); // Mark as viewed
                }
                setShowDetails((prev) => !prev); // Toggle details visibility regardless
              }}
            >
              <img
                src={job.imageURL}
                alt={job.company}
                className="sm:max-h-[109px] max-h-[109px] max-w-[142px] w-auto mx-auto rounded"
                style={{}}
              />
            </div>

            <div className="w-full sm:justify-between justify-center gap-4 lg:gap-0 flex flex-row">
              <div
                className="hover:underline cursor-pointer"
                onClick={() => {
                  if (!hasDetailsBeenViewed) {
                    fireJobOpenedGTM();
                    setHasDetailsBeenViewed(true); // Mark as viewed
                  }
                  setShowDetails((prev) => !prev); // Toggle details visibility regardless
                }}
              >
                <div className="text-lg font-bold">
                  {jobTitleShort ? (
                    <>
                      <Tooltip id="job-title-tooltip" />
                      <div
                        data-tooltip-id="job-title-tooltip"
                        data-tooltip-content={job.title}
                      >
                        {jobTitleShort}...
                      </div>
                    </>
                  ) : (
                    <div>{job.title}</div>
                  )}
                </div>
                <div className="mt-2 space-y-1 text-sm text-[#3D3D3D]">
                  <p className="flex gap-x-1">
                    <img src="/assets/images/icons/location-black.svg" alt="" />{" "}
                    {job.city + ", " + job.country}
                  </p>
                  <p className="flex gap-x-1">
                    <img src="/assets/images/icons/suitcase-black.svg" alt="" />{" "}
                    {job.type + ", " + job.jobSite}
                  </p>
                  <p className="flex gap-x-1">
                    <img src="/assets/images/icons/calendar-black.svg" alt="" />{" "}
                    Apply by {job.deadline}
                  </p>
                </div>
              </div>

              <div className="h-full flex flex-col mt-1">
                <div className="w-full flex justify-end gap-x-1 mb-3">
                  {!isAdmin ? (
                    <>
                      <Tooltip id="share-tooltip" />
                      <button
                        onClick={openModal}
                        data-tooltip-id="share-tooltip"
                        data-tooltip-content="Share"
                      >
                        <img src="/assets/images/icons/share.svg" alt="Share" />
                      </button>

                      <button onClick={handleSaveJob} className="">
                        <Tooltip id="save-tooltip" />
                        <img
                          data-tooltip-id="save-tooltip"
                          data-tooltip-content="Save"
                          src={`/assets/images/icons/${
                            isSaved ? "save-active" : "save"
                          }.svg`}
                          alt=""
                        />
                      </button>
                    </>
                  ) : (
                    <>
                      <img
                        onClick={toggleEditJob}
                        className="cursor-pointer"
                        src="/assets/images/icons/edit.svg"
                      />
                      <img
                        onClick={handleDeleteJob}
                        className="cursor-pointer"
                        src="/assets/images/icons/delete.svg"
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {showPostingForm && (
          <JobForm
            job={job}
            isAdmin={isAdmin}
            cancel={() => setShowPostingForm(false)}
            isEditing={true}
          />
        )}
        {showDetails && job.description && !showPostingForm && (
          <div className="flex flex-col">
            <div class="ql-editor">
              <div
                dangerouslySetInnerHTML={{
                  __html: decodeURIComponent(job.description),
                }}
              ></div>
            </div>
            <a
              target="_blank"
              href={job.link}
              className="mb-1 px-2 py-1 sm:w-[120px] w-full rounded-lg bg-[#3D3D3D] text-white text-sm flex justify-center items-center hover:shadow-lg transition-all duration-300 ease-in-out ml-auto"
              onClick={fireJobApplyGTMEvent} // Add this line to integrate the GTM event push
            >
              Apply{" "}
              <img src="/assets/images/icons/arrow-out-white.svg" alt="" />
            </a>
          </div>
        )}
        <ShareItem
          id={id ? id : job.id}
          setIsOpen={setIsOpen}
          modalIsOpen={modalIsOpen}
        />
        <LoginModal
          setIsOpen={setloginModalOpen}
          modalIsOpen={loginModalOpen}
        />
        <div></div>
      </div>
    </>
  );
}

export default JobCard;