import React, { createContext, useContext, useState } from 'react';

// Create a context
const AdminToggleContext = createContext();

// Create a provider component to wrap your app and provide the context
const AdminToggleContextProvider = ({ children }) => {
  const [admin, setAdmin] = useState(false);

  function toggleAdminView() {
    setAdmin(!admin);
  }

  return (
    <AdminToggleContext.Provider value={{ toggleAdminView, admin }}>
      {children}
    </AdminToggleContext.Provider>
  );
};

// Custom hook to use the global toggle state and setter anywhere in your components
const useAdminToggle = () => {
  const context = useContext(AdminToggleContext);
  if (!context) {
    throw new Error('useAdminToggle must be used within a AdminToggleProvider');
  }
  return context;
};

export { AdminToggleContextProvider, useAdminToggle };