import React, { useState } from 'react'
import Picker from "@emoji-mart/react"
import data from "@emoji-mart/data";
import LoginModal from '../util/LoginModal';
import { useAuth } from '../../context/AuthContext';

export default function EmojiButton({emojiIsActive, setEmojiIsActive, handleEmojiClicked, isComment = true}) {

  const auth = useAuth();
  
  const [showLoginModal, setShowLoginModal] = useState(false);

  function openLoginModal() {
    setShowLoginModal(true);
  }

  function handleClicked(e) {
    handleEmojiClicked(e.native);
    setEmojiIsActive(false);
  }

  return (
    <>
      <button
        data-testid="emoji-button"
        type="button"
        className="relative w-6 h-6 rounded mt-1 z-0"
      >
        <img
          onClick={() => {
            if (!auth || !auth.currentUser?.emailVerified) {
              openLoginModal();
              return;
            }
            setEmojiIsActive((prev) => !prev);
          }}
          src="/assets/images/icons/emoji.svg"
          alt="emoji"
        />
        {emojiIsActive && (
          <div
            className={`absolute ${
              isComment ? "-right-32" : "left-6 -top-96"
            } sm:right-0`}
          >
            <Picker
              data={data}
              previewPosition="none"
              onEmojiSelect={handleClicked}
            />
          </div>
        )}
      </button>
      <LoginModal modalIsOpen={showLoginModal} setIsOpen={setShowLoginModal} />
    </>
  );
}
