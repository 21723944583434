import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.jsx';
import './index.css';
import { hydrate, render } from "react-dom";
import { HelmetProvider } from 'react-helmet-async';
import 'core-js';
import 'regenerator-runtime/runtime';
 
const root = document.getElementById('root');

// if (root.hasChildNodes()) {
//   hydrate(<HelmetProvider><App /></HelmetProvider>, root);
// } else {
//   render(<HelmetProvider><App /></HelmetProvider>, root);
// }

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </React.StrictMode>,
);
