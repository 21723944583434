import React, { useRef } from "react";
import { useState, useEffect } from "react";
import Rodal from "rodal";
import { updateUser } from "../../functions/firebase/firestoreUsers";
import Swal from "sweetalert2";
import { useUser } from "../../context/UserContext";
import { useToast } from "../../context/ToastContext";
import { ErrorMessage, Field, Form, Formik, useFormikContext } from "formik";
import { editProfileValidationSchema } from "../../functions/validationSchemas";
import { adjustIfStringTooLong } from "../../functions/util";

export default function EditProfile({
  user,
  editProfileVisible,
  onCloseEditProfile,
  handleToggleEditProfile,
}) {
  const fileRef = useRef(null);
  const formikData = useFormikContext();
  const { showSuccessToast, showErrorToast, showLoadingToast } = useToast();

  if (!user) {
    return <div>No user</div>;
  }

  /**
   * Updates the user profile
   */
  async function handleUpdateProfile(values) {
    try {
      let updatedUser = { ...values };
      console.log(updatedUser.bio);

      let trimmedBio = updatedUser.bio.trim();
      trimmedBio = adjustIfStringTooLong(trimmedBio);
      updatedUser.bio = encodeURIComponent(trimmedBio);

      updatedUser.uid = user.uid;
      updatedUser.prevProfilePictureURL = user.profilePictureURL;

      //Show loading bar
      Swal.fire({
        title: "Updating User",
        text: "Please wait...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      console.log(updatedUser);
      await updateUser(updatedUser);
      showSuccessToast("Profile Updated");

      handleToggleEditProfile();
    } catch (error) {
      showErrorToast();
      console.error(error);
    } finally {
      Swal.close();
    }
  }

  const errorStyle = "text-red-500 text-xs pt-1";
  return (
     <Rodal visible={editProfileVisible} onClose={handleToggleEditProfile}>
      <h1 className="font-bold text-xl">Edit profile settings</h1>
      <Formik
          initialValues={{
            bio: decodeURIComponent(user.bio),
            firstName: user.firstName?.charAt(0)?.toUpperCase() + user?.firstName?.slice(1),
            lastName: user.lastName?.charAt(0)?.toUpperCase() + user?.lastName?.slice(1),
            phoneNumber: user.phoneNumber || '',
            profilePicture: null,
            profilePictureURL: user.profilePictureURL || '',
            role: user.role,
            occupation: user.occupation || '',
            linkedinLink: user.linkedinLink || '',
            instagramLink: user.instagramLink || '',
            websiteLink: user.websiteLink || '',
          }}
          validationSchema={editProfileValidationSchema}
          onSubmit={handleUpdateProfile}
        >
        {(formikProps) => {
          const formikData = formikProps; // Get formikProps

          return <Form className="my-10">
              <div className="flex md:flex-row flex-col">
                <div className="flex flex-col md:order-none order-2">
                  <h1 className="text-lg font-bold">Basic Info</h1>
                  <br />
                  <label htmlFor="firstName" className="z-10 mb-1">
                    First Name (Required)
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className={errorStyle}
                    />
                  </label>
                  <Field
                    id="firstName"
                    className="border border-gray-300 rounded-md py-1 px-3 focus:outline-none focus:ring focus:border-blue-500 mb-3"
                    type="text"
                    name="firstName"
                  />

                  <label htmlFor="lastName" className="z-10 mb-1">
                    Last Name (Required)
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className={errorStyle}
                    />
                  </label>
                  <Field
                    id="lastName"
                    className="border border-gray-300 rounded-md py-1 px-3 focus:outline-none focus:ring focus:border-blue-500 mb-2"
                    type="text"
                    name="lastName"
                  />

                  <label htmlFor="occupation" className="z-10 mb-1">
                    Occupation
                    <ErrorMessage
                      name="occupation"
                      component="div"
                      className={errorStyle}
                    />
                  </label>
                  <Field
                    id="occupation"
                    className="border border-gray-300 rounded-md py-1 px-3 focus:outline-none focus:ring focus:border-blue-500 mb-2"
                    type="text"
                    name="occupation"
                  />

                  <label htmlFor="phoneNumber" className="z-10 mb-1">
                    Phone Number
                    <ErrorMessage
                      name="phoneNumber"
                      component="div"
                      className={errorStyle}
                    />
                  </label>
                  <Field
                    id="phoneNumber"
                    name="phoneNumber"
                    type="text"
                    className="border border-gray-300 rounded-md py-1 px-3 focus:outline-none focus:ring focus:border-blue-500 mb-2"
                  />
                </div>

                {
                formikData.values.profilePictureURL && (
                  <img
                    src="/assets/images/icons/delete.svg"
                    type="button"
                    className="z-20 absolute md:right-10 cursor-pointer hover:text[#FFFFFF]"
                    onClick={(e) => {
                      formikData.setFieldValue("profilePicture", null);
                      formikData.setFieldValue("profilePictureURL", null);
                      fileRef.current.value = "";
                    }}
                  />
                )}

                <label
                  htmlFor="fileInput"
                  className="relative bg-gray-700 flex md:justify-center items-center md:w-72 md:h-72 w-48 h-48 rounded-full ml-16 cursor-pointer justify-left"
                >
                  <input
                    id="fileInput"
                    data-testid="fileInput"
                    ref={fileRef}
                    type="file"
                    accept="image/*"
                    name="file"
                    className="hidden"
                    onChange={(e) => {
                      const selectedFile = e.target.files[0];
                      if (selectedFile) {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                          const preview = reader.result;
                          console.log(preview);
                          formikData.setFieldValue(
                            "profilePicture",
                            selectedFile
                          );
                          formikData.setFieldValue(
                            "profilePictureURL",
                            preview
                          );
                        };
                        reader.readAsDataURL(selectedFile);
                      }
                    }}
                  />
                  <div className="relative md:w-72 md:h-72 w-48 h-48   rounded-full overflow-hidden">
                    {/* Image */}
                    {formikData.values.profilePictureURL && (
                      <img
                        data-testid="profile-picture-URL"
                        className="w-full h-full object-cover"
                        src={formikData.values.profilePictureURL}
                        alt="Profile"
                      />
                    )}

                    {/* Dark overlay for image */}
                    {formikData.values.profilePictureURL && (
                      <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 opacity-0 hover:opacity-100 flex items-center justify-center rounded-full cursor-pointer transition-opacity duration-300">
                        {/* Upload text for image */}
                        <span className="text-white font-semibold text-center">
                          Change profile photo
                        </span>
                      </div>
                    )}
                    {/* For when there is no image */}
                    {!formikData.values.profilePictureURL && (
                      <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-0 hover:bg-opacity-50 flex items-center justify-center rounded-full cursor-pointer transition-opacity duration-300">
                        {/* Upload text for no image */}
                        <span className="text-white font-semibold text-center">
                          Upload profile photo
                        </span>
                      </div>
                    )}
                  </div>
                </label>
              </div>

              <br />
              <div className="flex flex-col">
                <h1 className="text-lg font-bold">Socials</h1>
                <br />
                <label htmlFor="linkedinLink" className="z-10 mb-1">
                  LinkedIn
                  <ErrorMessage
                    name="linkedinLink"
                    component="div"
                    className={errorStyle}
                  />
                </label>
                <Field
                  id="linkedinLink"
                  className="border border-gray-300 rounded-md py-1 px-3 focus:outline-none focus:ring focus:border-blue-500 mb-2 md:w-3/4 w-full"
                  type="text"
                  name="linkedinLink"
                />

                <label htmlFor="instagramLink" className="z-10 mb-1">
                  Instagram
                  <ErrorMessage
                    name="instagramLink"
                    component="div"
                    className={errorStyle}
                  />
                </label>
                <Field
                  id="instagramLink"
                  className="border border-gray-300 rounded-md py-1 px-3 focus:outline-none focus:ring focus:border-blue-500 mb-2 md:w-3/4 w-full"
                  type="text"
                  name="instagramLink"
                />

                <label htmlFor="websiteLink" className="z-10 mb-1">
                  Personal Website
                  <ErrorMessage
                    name="websiteLink"
                    component="div"
                    className={errorStyle}
                  />
                </label>
                <Field
                  id="websiteLink"
                  className="border border-gray-300 rounded-md py-1 px-3 focus:outline-none focus:ring focus:border-blue-500 mb-2 md:w-3/4 w-full"
                  type="text"
                  name="websiteLink"
                />
              </div>

              <br />
              <h1 className="font-bold text-lg">Additional Info</h1>
              <br />

              <label className="z-10 mb-1">
                Roles
                <ErrorMessage
                  name="role"
                  component="div"
                  className={errorStyle}
                />
              </label>
              <div className="grid grid-cols-2 gap-x-2">
                {[
                  "UX Designer",
                  "UI Designer",
                  "UX Researcher",
                  "UX Manager/Lead",
                  "Product Designer",
                  "Interaction Designer",
                  "Visual Designer",
                  "UX Writer",
                  "Student ( UX or related)",
                  "UX Instructor",
                ].map((r) => (
                  <div key={r} className="relative">
                    <Field
                      type="checkbox"
                      name="role"
                      value={r}
                      checked={formikData?.values?.role?.includes(r)}
                      className="hidden"
                      id={r}
                      onChange={(e) => {
                        const { checked, value } = e.target;
                        const currentRoles = formikData.values.role || [];
                        let updatedRoles;
                        if (checked) {
                          updatedRoles = [...currentRoles, value];
                        } else {
                          updatedRoles = currentRoles.filter(
                            (role) => role !== value
                          );
                        }
                        formikData.setFieldValue("role", updatedRoles);
                      }}
                    />
                    <label
                      htmlFor={r}
                      className="block text-[#959595] mt-2 px-2 py-1 border rounded hover:border-primaryBlue cursor-pointer transition-all duration-300 ease-in-out"
                    >
                      <span className="text-xs">{r}</span>
                    </label>
                  </div>
                ))}
              </div>
              <br />

              <label htmlFor="bio" className="z-10 mb-1">
                Bio
                <ErrorMessage
                  name="firstName"
                  component="div"
                  className={errorStyle}
                />
              </label>
              <Field
                as="textarea"
                id="bio"
                name="bio"
                rows="7"
                placeholder="Enter a short description about yourself"
                className="border-gray-300 focus:ring-0 text-xs px-2 w-full rounded-md resize-none mb-3 break-words"
                value={formikData.values.bio}
                onChange={(e) =>
                  formikData.setFieldValue("bio", e.target.value)
                }
              />
            <button data-testid="submit-edit-profile" className="bg-blue-500 text-white rounded-lg p-2 w-32 mt-5 hover:bg-blue-700" type="submit">Submit</button> 
          </Form>
        }}
      </Formik>
    </Rodal>
  );
}
