import * as yup from "yup";
import { isValidPhoneNumber } from "libphonenumber-js";

const validateFormat = (value) => {
  // Return true if empty (optional phone number)
  if (!value) {
    return true;
  }
  // 'US' for the US standard phone number format (user friendly)
  return isValidPhoneNumber(value, "US");
};

const socialLinkSchema = yup
  .string()
  .matches(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/, "Please enter a valid URL")
  .nullable();
/**
 * Validation schema for edit profile form.
 */
export const editProfileValidationSchema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  phoneNumber: yup
    .string()
    .test("phone", "Invalid phone number", validateFormat),
  occupation: yup.string().nullable(),
  bio: yup.string().nullable(),
  linkedinLink: socialLinkSchema,
  instagramLink: socialLinkSchema,
  websiteLink: socialLinkSchema,
  role: yup
    .array()
    .of(yup.string())
    .min(1, "At least one role must be selected")
    .required("Role is required"),
});

/**
 * Validation schema for login form.
 */
export const loginValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
});

/**
 * Sign up form validation schema.
 */
export const signupValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  password: yup
    .string()
    .min(8, "Passwords must be at least 8 characters")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .min(8, "Confirm password must be at least 8 characters")
    .matches(
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9\s]).*$/,
      "Password must contain at least one uppercase letter, one number, and one special character"
    )
    .oneOf([yup.ref("password"), null], "Password must match")
    .required("Confirm password is required"),
});

/**
 * Personal info form validation schema.
 */
export const personalInfoValidationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required("First name is required")
    .min(2, "First name is too short")
    .max(50, "First name is too long"),
  lastName: yup
    .string()
    .required("Last name is required")
    .min(2, "Last name is too short")
    .max(50, "Last name is too long"),
  country: yup.string().required("Country is required"),
  phoneNumber: yup
    .string()
    .test("phone", "Invalid phone number", validateFormat),
  role: yup
    .array()
    .of(yup.string())
    .min(1, "At least one role must be selected")
    .required("Role is required"),
  occupation: yup.string().nullable(),
  newsletter: yup.boolean().nullable(),
  jobAlerts: yup.boolean().nullable(),
});

// const initialValues = {
//   title: "",
//   type: "",
//   city: "",
//   country: "",
//   deadline: "",
//   yearsExperience: "",
//   link: "",
//   description: {
//       company: "",
//       responsibilities: "",
//       qualifications: "",
//       other: {
//           interviewProcess: "", // rich text
//           benefits: "", // rich text
//       }
//   }
// };

// Validation schema using Yup
export const jobPostingValidationSchema = yup.object().shape({
  title: yup.string().max(100).required("Job title is required"),
  type: yup.string().required("Job type is required"),
  city: yup.string().max(100),
  link: yup.string().max(400).required("Job posting link is required"),
  deadline: yup.string().required("Deadline is required"),
  country: yup.string().required("Country is required"),
  jobSite: yup.string().required("Job site is required"),
  category: yup.string().required("Job category is required"),
  description: yup
    .string()
    .trim()
    .required("Description is required")
    .test(
      "not-empty-rich-text",
      "Description cannot be empty or contain only formatting",
      (value) => {
        // Remove HTML tags and trim whitespace for validation
        const strippedValue = value.replace(/<[^>]*>/g, "").trim();

        // Check if the stripped value has meaningful content
        return strippedValue !== "";
      }
    ),
  // other fields in your job schema
});

export const eventsFormValidationSchema = yup.object().shape({
  dayType: yup.string(),
  optionalTime: yup.bool(),
  title: yup.string().max(100).required("Event title required"),
  link: yup.string().max(400).required("Event posting link is required"),
  type: yup.string().required("Event type is required"),
  city: yup.string().max(100).required("Event city is required"),
  country: yup.string().max(100).required("Event country is required"),
  startDate: yup.date().required("Date is required"),
  category: yup.string().required("Event category is required"),
  address: yup.string().required("Address is required"),
  timeZone: yup.string().required("Time Zone is required"),
  startTime: yup.string().required("Start time is required"),
  endTime: yup.string().required("End time is required"),
});

export const updatePasswordValidationSchema = yup.object().shape({
  newPassword: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .required("Required")
    .oneOf([yup.ref("confirmPassword"), null], "Passwords must match"),

  confirmPassword: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .required("Required")
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

export const resourcesFormValidationSchema = yup.object().shape({
  title: yup.string().required("Resource title is required"),
  type: yup.string().required("Resource type is required"),
  description: yup.string().required("Resource description is required"),
  duration: yup.string().required("Resource duration is required"),
  link: yup.string().required("Resource must have a link"),
});
