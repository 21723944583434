import React, { useEffect, useState } from "react";
import { getSensitiveUserData } from "../../functions/firebase/firestoreUsers";
import { updateUserSensitiveData } from "../../functions/firebase/firestoreUsers";
import { updateUser } from "../../functions/firebase/firestoreUsers";
import { EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useUser } from "../../context/UserContext";
import { useAuth } from "../../context/AuthContext";
import { Helmet } from "react-helmet";
import { useToast } from "../../context/ToastContext";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { TextInputForm } from "./includes/TextInputForm";
import FirstNameForm from "./includes/FirstNameForm";
import LastNameForm from "./includes/LastNameForm";
import PasswordForm from "./includes/PasswordForm";
import { set } from "date-fns";
import EmailForm from "./includes/EmailForm";

//TODO testing
// import PasswordForm1 from "./includes/PasswordForm1";

export default function Settings() {
  const [userData, setUserData] = useState({});
  const [sensitiveUserData, setSensitiveUserData] = useState({});
  const auth = useAuth();
  const user = useUser();
  const { showSuccessToast, showErrorToast, showLoadingToast } = useToast();

  useEffect(() => {
    // Check if user authenticated is
    if (!auth.currentUser || !auth.currentUser.uid) {
      return;
    }

    // Set the user data
    setUserData({ ...user });

    // Fetch sensitive user data
    const fetchSensitiveUserData = async () => {
      try {
        const sensitiveData = await getSensitiveUserData(auth.currentUser.uid);
        setSensitiveUserData(sensitiveData);
      } catch (error) {
        showErrorToast("Failed to Update");
        console.error("Failed to fetch sensitive user data:", error);
      }
    };
    fetchSensitiveUserData();
  }, [user]);

  return (
    <div>
      <Helmet>
        {/* TODO: check this */}
        <title>UX was Here - Settings</title>
      </Helmet>
      <div className="flex-1 lg:pt-8 pt-4 lg:px-14 md:px-6">
        <h2 className="text-2xl pb-4 font-bold">Account Settings</h2>

        <FirstNameForm />
        <LastNameForm />
        <EmailForm userData={userData} sensitiveUserData={sensitiveUserData} />
        <PasswordForm />
      </div>
    </div>
  );
}
