import React, { useEffect, useMemo, useReducer, useRef, useState } from "react";
import Comments from "../../pages/explore/includes/Comments";
import { getUser } from "../../functions/firebase/firestoreUsers";
import {
  addPostComment,
  getPostComments,
  updatePostComment,
  updatePost,
  deletePost,
  listenForCommentChanges,
  togglePostLike,
  replyToComment,
  listenForCommentCount,
  getNonReplyCommentCount,
  togglePostSave,
} from "../../functions/firebase/firestorePosts";
import { useAuth } from "../../context/AuthContext";
import Swal from "sweetalert2";
import UserModal from "./UserModal";
import NewPost from "../../pages/explore/includes/NewPost";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useToast } from "../../context/ToastContext";
import {
  realTimeCommentUserData,
  realTimeComments,
} from "../../functions/realtime/realtimeService";
import ShareItem from "../util/ShareItem";
import LoginModal from "../util/LoginModal";
import CommentPoster from "./CommentPoster";
import { createNotification } from "../../functions/firebase/firestoreNotifications";
import UserCard from "../user/UserCard";

function PostCard({ post, isOnPopup = false }) {
  //console.log("POST CARD POST", post)
  const tagColors = {
    Ranting: "bg-[#ed7377]",
    Feedback: "bg-[#69aeeb]",
    Education: "bg-[#b167ea]",
    Collaboration: "bg-[#4b9486]",
  };

  const { id } = useParams();
  const auth = useAuth();
  const likeButtonRef = useRef(null);

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [loadedComments, setLoadedComments] = useState(false);
  const [isSaved, setIsSaved] = useState(
    post?.saved?.includes(auth?.currentUser?.uid)
  );
  const [commentCount, setCommentCount] = useState(0);
  const [commentsList, setCommentsList] = useState([]);
  const { showSuccessToast, showErrorToast, showLoadingToast } = useToast();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [currentNonReplyCommentCount, setCurrentNonReplyCommentCount] =
    useState(0);
  const [editPostVisible, setEditPostVisible] = useState(false);
  const [moreComments, setMoreComments] = useState(true);
  const [lastDoc, setLastDoc] = useState(null);
  const commentCountPerFetch = 5;
  const [nonReplyCommentCount, setNonReplyCommentCount] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
//console.log("commentsLis in COMMENTS", commentsList);
  function handleOpenModal() {
    setModalVisible(true);
  }

  function closeModal() {
    setModalVisible(false);
  }

  useMemo(() => {
    const unsub = listenForCommentCount(post.postId, (count) => {
      setCommentCount(count);
    });

    return () => {
      unsub();
    };
  }, []);

  useEffect(() => {
    if (!loadedComments && showComments) {
      getCommentCountForNonReplies();
      fetchComments();

      // add the snapshot listener for any changes in comments to update on the client
      const unsubscribe = realTimeComments(
        setCommentsList,
        post,
        showErrorToast
      );

      // only load comments once, not again when they are re-toggled
      setLoadedComments(true);

      // on unmount make sure to unsubscribe to the listener to prevent memory leaks
      return () => {
        unsubscribe();
      };
    }
  }, [showComments]);

  const showSharePopup = () => {
    setIsOpen(true);
  };

  function openLoginModal() {
    setShowLoginModal(true);
  }

  async function getCommentCountForNonReplies() {
    try {
      const nonReplyCommentCount = await getNonReplyCommentCount(post.postId);
      setNonReplyCommentCount(nonReplyCommentCount);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchComments() {
    try {
      if (moreComments) {
        const { commentsReceived, lastDocReceived } = await getPostComments(
          post.postId,
          "createdAt",
          false,
          commentCountPerFetch,
          lastDoc
        );
        setCurrentNonReplyCommentCount((prev) => prev + commentCountPerFetch);

        console.log("lastDoc receieved: ", lastDocReceived);
        // Attach all the users' info to the comment
        await Promise.all(
          commentsReceived.map(async (comment) => {
            comment.user = await getUser(comment.userId);
          })
        );

        if (!lastDocReceived) {
          setMoreComments(false);
          return;
        }

        // Combine the new comments with the existing ones
        setCommentsList((prevComments) => {
          const combinedComments = [...prevComments, ...commentsReceived];
          return combinedComments;
        });

        // Set the last document for the next fetch
        setLastDoc(lastDocReceived);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function getMore() {
    fetchComments();
  }

  /**
   * Function to toggle the post like by adding it to the likes array or removing it.
   * First we update the state on the database, then the client side state is updated.
   */
  async function handleLikePost() {
    if (!auth?.currentUser) {
      openLoginModal();
      return;
    } else if (!auth?.currentUser.emailVerified) {
      showErrorToast("Please Verify Your Email");
      return;
    }
    try {
      const updatedPost = post;

      const alreadyLiked = updatedPost.likes.includes(auth.currentUser.uid);
      // update visuals on front end after db change
      if (alreadyLiked) {
        post.likes.pop(auth.currentUser.uid);
      } else {
        post.likes.push(auth.currentUser.uid);
      }
      // update backend
      likeButtonRef.current.disabled = true;
      await togglePostLike(post.postId, auth.currentUser.uid);
      likeButtonRef.current.disabled = false;

      // add a notification to the person who made the post that they got a like
      if (auth?.currentUser.uid !== null) {
        // don't want to send a notification if the post is your own when you write a comment on it.
        if (post.userId === auth.currentUser.uid) {
          return;
        }

        // only send notification for liking not unliking
        if (alreadyLiked) {
          return;
        }

        createNotification({
          senderId: auth.currentUser.uid,
          toId: post.userId,
          postId: post.postId,
          type: "post",
          action: "like",
          commentId: null,
        });
      }
    } catch (error) {
      showErrorToast("Problem Updating Likes");
      console.error(error);
    }
  }

  /**
   * Deletes the post. Shows a confirmation popup first before incase of mistake.
   */
  const handleDeletePost = async () => {
    const result = await Swal.fire({
      title: "Delete Post?",
      icon: "warning",
      text: "Are you sure you want to delete this post? This cannot be undone!",
      showCancelButton: true,
      confirmButtonColor: "#DC2626", // Red color for the delete button
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
    });
    if (result.isConfirmed) {
      try {
        await deletePost(post);
        showSuccessToast("Successfully Deleted Post");
      } catch (error) {
        showErrorToast("Problem with Deleting Post");
        console.error(error);
      }
    }
  };

  /**
   * Toggles saving for this post for the authenticated user.
   */
  async function handleToggleSave() {
    if (!auth.currentUser) {
      openLoginModal();
      return;
    }

    // update client side
    setIsSaved((prev) => !prev);

    // update backend
    await togglePostSave(post, auth.currentUser.uid);
  }

  function openUserProfile() {
    handleOpenModal();
  }

  return (
    <div
      className="mb-6 sm:px-6 px-2 sm:py-8 py-6 border rounded bg-white"
      // data-testid="post-card"
    >
      {/* post creation modal */}
      <NewPost
        user={post.user}
        incomingPost={post}
        isEditing={true}
        visible={editPostVisible}
        setVisible={setEditPostVisible}
      />

      <div className="relative">
        {auth?.currentUser?.uid === post.userId && (
          <>
            {/* Edit Post Button */}
            <button
              onClick={() => setEditPostVisible(true)}
              className="absolute top-2 right-10 text-gray-400 focus:outline-none z-10"
            >
              <img src="/assets/images/icons/edit.svg" alt="Edit" />
            </button>

            {/* Delete Button */}
            <div className="relative">
              {/* Your post content */}
              <button
                onClick={handleDeletePost}
                className="absolute top-2 right-2 text-gray-400 focus:outline-none z-10"
              >
                <img src="/assets/images/icons/delete.svg" alt="Delete" />
              </button>
            </div>
          </>
        )}
      </div>

      <div className="flex flex-row gap-2">
        <UserCard user={post.user} handleClick={openUserProfile} />
        <span className="font-normal text-gray-400 text-sm mt-0.5">
          • {post?.date?.toLocaleString()}
          {Math.abs(post?.createdAt - post?.updatedAt) > 0.001 && " (Edited)"}
        </span>
      </div>

      <div className="flex flex-wrap gap-2 mb-4 relative">
        {post.tags.slice(0, 3).map(
          (tag) =>
            tag !== "I’m not sure!" && (
              <span
                key={tag}
                className={`${
                  tagColors[tag]
                    ? `${tagColors[tag]} text-white`
                    : "bg-gray-200"
                } rounded px-2 py-1 text-xs`}
              >
                {tag}
              </span>
            )
        )}
        {post.tags.length > 3 && (
          <span className="bg-gray-200 rounded px-2 py-1 text-xs cursor-pointer hover:bg-gray-300 group">
            +{post.tags.length - 3}
            <div className="absolute top-7 left-0 mt-2 w-48 p-4 flex flex-wrap gap-2 bg-white border rounded shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 ease-in-out">
              {post.tags.slice(3).map(
                (tag) =>
                  tag !== "I’m not sure!" && (
                    <span
                      key={tag}
                      className={`w-fit mt-1 ${
                        tagColors[tag]
                          ? `${tagColors[tag]} text-white`
                          : "bg-gray-200"
                      } rounded px-2 py-1 text-xs`}
                    >
                      {tag}
                    </span>
                  )
              )}
            </div>
          </span>
        )}
      </div>
      <h2 className="mb-2 font-semibold">{post.title}</h2>
      <div className="mb-4" style={{ whiteSpace: "pre-wrap" }}>
        {post.content}
      </div>
      {post.postURL && (
        <img src={post.postURL} alt="Post content" className="rounded mb-4" />
      )}
      <div className="w-fit font-semibold text-xs flex items-center border py-2 px-3 rounded-2xl sm:gap-x-8 gap-x-2 mt-4">
        <button
          ref={likeButtonRef}
          onClick={handleLikePost}
          className="flex items-center space-x-1"
          data-testid={`like-button-${post.postId}`}
        >
          <span className="">
            <img
              src={
                post.likes?.includes(auth?.currentUser?.uid)
                  ? "/assets/images/icons/like-blue.svg"
                  : "/assets/images/icons/like.svg"
              }
              alt="like icon"
            />
          </span>
          <span data-testid={`likes-count-${post.postId}`}>
            {post.likes.length !== 0 ? post.likes.length : ""}{" "}
            {post.likes.length !== 0
              ? post.likes.length === 1
                ? "Like"
                : "Likes"
              : "Like"}
          </span>
        </button>

        <button
          className="flex items-center space-x-1"
          onClick={() => setShowComments((prev) => !prev)}
          data-testid={`comments-button-${post.postId}`}
        >
          <span className="text-gray-600">
            <img
              src={
                showComments
                  ? "/assets/images/icons/comment-blue.svg"
                  : "/assets/images/icons/comment.svg"
              }
              alt="comment icon"
            />
          </span>
          <span>{commentCount !== 0 ? commentCount : null} Comments</span>
        </button>

        <button
          onClick={showSharePopup}
          className="flex items-center space-x-1"
          data-testid={`share-button-${post.postId}`}
        >
          <span className="text-gray-600">
            <img src="/assets/images/icons/share.svg" alt="share icon" />
          </span>
          <span>Share</span>
        </button>

        <button
          onClick={handleToggleSave}
          className="flex items-center space-x-1"
        >
          <span className="text-gray-600">
            <img
              src={`/assets/images/icons/${
                isSaved ? "save-active" : "save"
              }.svg`}
              alt="comment icon"
            />
          </span>
          <span className={isSaved ? "text-primaryBlue" : ""}>
            {isSaved ? "Saved" : "Save"}
          </span>
        </button>
      </div>

      {showComments && (
        <>
          <CommentPoster post={post} setComments={setCommentsList} />
          <Comments
            post={post}
            comments={commentsList}
            setComments={setCommentsList}
          />
          {nonReplyCommentCount > currentNonReplyCommentCount ? (
            <button
              className="text-blue-500 hover:text-blue-900 transiton-colors duration-300 ml-10"
              onClick={getMore}
            >
              Load more comments
            </button>
          ) : null}
        </>
      )}
      <ShareItem
        id={id ? id : post.postId}
        setIsOpen={setIsOpen}
        modalIsOpen={modalIsOpen}
      />
      <LoginModal modalIsOpen={showLoginModal} setIsOpen={setShowLoginModal} />
      {modalVisible && <UserModal user={post.user} handleClose={closeModal} />}
    </div>
  );
}

export default PostCard;
