import React, { useEffect } from 'react';
import UpdatedFooter from './includes/UpdatedFooter';
import UpdatedHeader from './includes/UpdatedHeader';

import "./BetaStyles.css";

export default function Story() {
    return (
        <div>
            <div className='sticky top-0'><UpdatedHeader /></div>
            <div className="container max-w-fit md:px-40 max-sm:px-6 md:pt-44 max-sm:pt-32 pb-24 min-h-screen mx-auto vxl:pt-80">
                <div className="flex flex-col md:flex-row max-sm:text-center place-content-center gap-x-4 mx-auto">
                <div className="md:basis-1/2 md:pr-20">
                    <div className='space-y-4 '>
                    <p className='md:text-5xl vxl:text-7xl max-sm:text-3xl font-medium'>Our Story</p>
                    <p className='vxl:text-2xl'>Inspired by the playful graffiti mark <b>"Kilroy was here,"</b> our journey began with a simple observation in a crowded company cafeteria. Serving approximately 1,500 people every day, lunchtime turned into chaos, as employees scrambled to select food and make payments.</p>
                    <p className='vxl:text-2xl'>The experience was fraught with frustration, inefficiency, and a shared desire for something better.</p>
                    <p className='vxl:text-2xl'>That's when I realized: <b>"I'm a UX! I can help with this!"</b> Using my skills, I decided to take action and address this everyday problem that resonated with so many.</p>
                    <p className='vxl:text-2xl'>Working tirelessly after hours, I crafted a solution and eagerly put it to the test. In a guerilla-style experiment, I rearranged the food lines, creating a more efficient flow. I left only one clue behind—a mark that proclaimed, <b>"UX was here!"</b></p>
                    <p className='vxl:text-2xl'>The next day, the results were phenomenal. The once chaotic experience transformed, sparking conversations, empowering employees to share their concerns, and even forging a new internal team dedicated to improving the livelihood of our colleagues.</p>
                    <p className='vxl:text-2xl'>This simple yet profound experiment became one of the proudest moments in my career. It wasn't just about improving a lunch line; it was a demonstration of how one person's determination and love for helping others could create a chain reaction of positive change.</p>
                    <p className='vxl:text-2xl'>And so, our platform was born—a space designed to connect, empower, and inspire UX professionals. Here, we carry on the spirit of <b>"UX was here,"</b> championing creativity, collaboration, and solutions that make a real difference.</p>
                    </div>
                </div>
                <div className="md:basis-1/2 w-full max-sm:pt-16 vxl:self-center vxl:scale-150 vxl:pl-52">
                    <img src="/assets/images/Betalanding/Kilroy.png" alt="An image of the iconic Kilroy was here graffiti" />
                </div>
                </div>
            </div>

            <UpdatedFooter />
        </div>
    );

}