import {
  app,
  analytics,
  firestore,
  auth,
  storage,
} from "../../../firebaseConfig";
import {
  doc,
  setDoc,
  getDoc,
  getDocs,
  serverTimestamp,
  addDoc,
  collection,
  query,
  orderBy,
  updateDoc,
  onSnapshot,
  where,
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

/**
 * Creates a new user in the firestore database with the properties passed in
 *
 * @param {Object} user - all the user information required to create the user
 */
export const createUser = async (user, userId) => {
  try {
    const userSensitiveRef = doc(firestore, "sensitive-users2", userId);
    const userRef = doc(firestore, "users2", userId);

    const userData = {
      firstName: user.firstName?.toLowerCase(),
      lastName: user.lastName?.toLowerCase(),
      occupation: user.occupation,
      role: user.role,
      jobAlerts: user.jobAlerts,
      newsletter: user.newsletter,
      bio: user.bio,
      lastOnline: user.lastOnline,
      profilePictureURL: user.profilePictureURL,
      uid: user.uid,
    };

    const sensitiveUserData = {
      phoneNumber: user.phoneNumber,
      email: user.email,
      country: user.country,
    };

    await setDoc(userRef, userData);
    console.log("user data added!");
    await setDoc(userSensitiveRef, sensitiveUserData);
    console.log("sensitive user data added!");
  } catch (error) {
    console.error("Error adding user with custom ID to Firestore:", error);
    throw error; // Rethrow the error for further analysis
  }
};

/**
 * Get the sensitive user data based on the uid (there are rules in place that only allow for this to happen for the authenticated user info).
 *
 * @param {String} uid - id of the user to get the sensitive info from
 * @returns
 */
export const getSensitiveUserData = async (uid) => {
  try {
    const userRef = doc(firestore, "sensitive-users2", uid);
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      return userDoc.data();
    } else {
      return null;
    }
  } catch (error) {
    alert(error);
  }
};

/**
 * Gets the user from the users collection with the uid passed in.
 *
 * @param {string} uid - id of the user that we want to obtain
 * @returns
 */
export const getUser = async (uid) => {
  try {
    if (!uid) {
      throw new Error("User ID is undefined or null.");
    }

    const userRef = doc(firestore, "users2", uid);
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      userData.uid = userDoc.id;
      return userData;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw new Error("Failed to get user data from Firestore.");
  }
};

/**
 * Gets users based on the where filter passed in. If there is no where filter,
 * this will obtain all users.
 * 
 * @param {Function} whereFilter - to query specific users if provided 
 * @returns 
 */
export const getUsers = async (firstNameSearch = null, lastNameSearch = null, lim = null) => {
  try {
    const usersCollection = collection(firestore, "users2");
    let q = usersCollection;

    // TODO: (Quick fix) Append in two more where clauses for lowercase first name & last name
    // TODO: (Big fix) store name on backend all in lowercase 
    // Apply search filters if first name or last name search is provided
    if (firstNameSearch != null) {
        q = query(
            q,
            where("firstName", ">=", firstNameSearch),
            where("firstName", "<=", firstNameSearch + "\uf8ff"),
            orderBy("firstName")
        );
    }

    if (lastNameSearch != null) {
        q = query(
            q,
            where("lastName", ">=", lastNameSearch),
            where("lastName", "<=", lastNameSearch + "\uf8ff"),
            orderBy("lastName")
        );
    }

    if (lim != null) {
      q = query(q, limit(lim));
    }

    const usersSnapshot = await getDocs(q);
    const users = [];
    usersSnapshot.docs.forEach(doc => {
        users.push({
          uid: doc.id,
          ...doc.data(),
        });
    });
    
    return users;
  } catch (error) {
    throw new Error("Could not fetch users: " + error);
  }
}

/**
 * Updates the user. If they have a profile picture, then it will upload that 
 * profile picture and then get the download url.
 *
 * @param {Object} user - user to update
 */
export const updateUser = async (user) => {
  // make sure we only update these fields
  const {
    bio,
    firstName,
    lastName,
    profilePicture,
    role,
    occupation,
    linkedinLink,
    instagramLink,
    websiteLink,
    uid,
    phoneNumber,
    prevProfilePictureURL,
  } = user;
  let profilePictureURL = user.profilePictureURL;
  try {
    const userRef = doc(firestore, "users2", uid);
    const sensitiveUserRef = doc(firestore, "sensitive-users2", uid);

    // If there's a new profile picture being uploaded
    if (profilePicture !== null) {
      const storageReference = ref(storage, `images2/profilePicture/${uid}`);
      await uploadBytes(storageReference, profilePicture);
      profilePictureURL = await getDownloadURL(storageReference);

      // If there's an existing profile picture, delete it
      if (prevProfilePictureURL) {
        const previousImageRef = ref(storage, prevProfilePictureURL);
        await deleteObject(previousImageRef);
      }
    }

    const updatedUser = {
      bio: bio,
      firstName: firstName?.toLowerCase(),
      lastName: lastName?.toLowerCase(),
      profilePicture: null,
      profilePictureURL: profilePictureURL,
      role: role,
      occupation: occupation || "",
      linkedinLink: linkedinLink || "",
      instagramLink: instagramLink || "",
      websiteLink: websiteLink || "",
    };

    const updatedUserSensitive = {
      phoneNumber: phoneNumber || "",
    };

    // Only set the profilePictureURL if it's not null
    if (profilePictureURL !== null) {
      updatedUser.profilePictureURL = profilePictureURL;
    }

    console.log("THE BIG TEST");
    // Update the appropriate user info
    await Promise.all([
      updateDoc(userRef, updatedUser),
      updateDoc(sensitiveUserRef, updatedUserSensitive),
    ]);
  } catch (error) {
    console.error(error);
    throw new Error("Failed to update user: " + error);
  }
};

/**
 * Update the sensitive user data (changing email for now)
 * @param {Object} user - user who is updating their sensitive data (email)
 */
export const updateUserSensitiveData = async (userId, sensitiveUserData) => {
  try {
    const userRef = doc(firestore, "sensitive-users2", userId);
    const updatedData = {
      email: sensitiveUserData.email,
      phoneNumber: sensitiveUserData.phoneNumber,
      country: sensitiveUserData.country,
    };
    await updateDoc(userRef, updatedData);
  } catch (error) {
    console.error(error);
    throw new Error("Failed to update user sensitive data: " + error);
  }
};

/**
 * Listener for whenever the user data changes with the id passed in. the onChange callback is provided
 * for implementing your own logic with the changed user data.
 *
 * @param {String} userId - id of the user we want to listen to
 * @param {Function} onChange - pass the changed user data into the onChange callback
 * @returns
 */
export const listenForUserChanges = async (userId, onChange) => {
  const userDocRef = doc(firestore, "users2", userId);

  const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
    if (docSnapshot.exists()) {
      const userData = docSnapshot.data();
      onChange(userData);
    } else {
      onChange(null);
    }
  });

  return unsubscribe;
};

/**
 * Update the user's first and last name
 */
export const updateFirstName = async (userId, firstName, lastName) => {
  try {
    const userRef = doc(firestore, "users2", userId);
    await updateDoc(userRef, {
      firstName: firstName,
      lastName: lastName,
    });
  } catch (error) {
    console.error(error);
    throw new Error("Failed to update First name: " + error);
  }
};
