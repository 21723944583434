import React, { useState } from 'react'
import { Formik } from 'formik';
import { MdOutlineFileUpload } from 'react-icons/md';


/**
   * Image handler.
   * 
   * @param {String} displayName label name for the thumbnail box 
   * @param {String} inputName - name to connecting this to the variable name in the form
   * @param {Object} formValues - formik values so that we can update the file
   * @param {Function} setFieldValue - formik set field value
   * @returns the jsx for the form handler
   */
export default function ImageUploader({displayName, inputName, formValues, setFieldValue }) {

    const [isHovered, setIsHovered] = useState(false);

    const handleDragOver = (e) => {
      e.preventDefault();
    };
  
    const handleDrop = (e) => {
      e.preventDefault();
      const file = e.dataTransfer.files[0];
      setFieldValue(inputName, file); // Set the value to trigger Formik's change handler
    };
  
    const handleFileChange = (e) => {
      const file = e.target.files[0];
      if (file) {
        setFieldValue(inputName, file); // Set the value to trigger Formik's change handler
      }
    };
  
    const handleClearFile = () => {
      setFieldValue("thumbnail", null);
      setFieldValue("imageURL", null);
    };
  
    const thumbnail = formValues[inputName];
    console.log("thumbnail: " + thumbnail);
    console.log("imageURL: ", formValues.imageURL);
  
    return (
      <>
        <label className="z-10 mb-1 flex-1">
          {displayName}:
          {/* <ErrorMessage name={inputName} component="div" className={""} /> */}
        </label>
        <div
          className="border border-gray-300 text-center h-52 flex flex-col justify-center items-center relative"
          onDragOver={(e) => handleDragOver(e)}
          onDrop={(e) => handleDrop(e)}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <input
            type="file"
            id={inputName}
            name={inputName}
            className="hidden"
            onChange={handleFileChange}
          />
          {thumbnail || formValues.imageURL ? (
            <div
              className="relative overflow-hidden"
            >
              <img
                src={thumbnail ? URL.createObjectURL(thumbnail) : formValues.imageURL}
                alt="Thumbnail Preview"
                className="h-full w-full object-cover"
              />
              {isHovered && (
                <label htmlFor={inputName} className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                  <p className="text-lg text-white cursor-pointer">Click here to replace image</p>
                </label>
              )}
              <button type="button" onClick={handleClearFile} className="absolute top-2 right-2 text-red-500">
                <img src="/assets/images/icons/delete.svg" alt="Delete" />
              </button>
            </div>
          ) : (
            <label htmlFor={inputName} className="cursor-pointer flex flex-col items-center">
              <MdOutlineFileUpload className="text-6xl text-gray-500" />
              <p className="mt-2">
                Drag your file into the box to upload or{' '}
                <span className="text-blue-500 border-b border-b-blue-500">
                  click here to browse your files
                </span>
              </p>
            </label>
          )}
        </div>
      </>
    );
}
