import React, { useEffect, useState } from "react";
import CommentItem from "./CommentItem";
import { getPostComment } from "../../../functions/firebase/firestorePosts";
import { getUser } from "../../../functions/firebase/firestoreUsers";

export default function HighLevelCommet({ comment, setComments, post }) {

  const repliesPerFetch = 5;

  const [mappedReplies, setMappedReplies] = useState([]);
  const [showReplies, setShowReplies] = useState(false);
  const [replyOffset, setReplyOffset] = useState(0);

  // used for update the proper count for replies if the client added their
  // own reply without needing to refresh
  const [newReplyCount, setNewReplyCount] = useState(0);

  // 1, 2, 3, 4, 5, 6, 7, 8, 9, 10
  const mapReplyIdsToReplies = async () => {
    const replies = comment.replies;

    // return if we are at the end of fetching replies
    if (replyOffset >= replies.length) {
      return;
    }

    try {
      // get the next set of replies
      let newMappedReplies = await Promise.all(
        replies
          .slice(replyOffset, replyOffset + repliesPerFetch)
          .map(async (replyId) => {
            try {
              const currentReply = await getPostComment(post.postId, replyId);
              currentReply.user = await getUser(currentReply.userId);
              return currentReply;
            } catch (error) {
              console.error(
                "Could not get reply with ID " + replyId + ": " + error
              );
              return null;
            }
          })
      );

      // filter out any failed replies and reverse the order for most recent first
      newMappedReplies = newMappedReplies.filter((reply) => reply !== null);

      // append on the next set of replies obtained
      setMappedReplies((prev) => {
        return [...prev, ...newMappedReplies];
      });
    } catch (error) {
      console.error("Could not get replies for comment: " + error);
    } finally {
      // increment the reply offset
      setReplyOffset(replyOffset + repliesPerFetch);
    }
  };

  function addCommentReplyOnClient(reply, commentToReplyTo) {
    // adding our first reply, we want this to be seen by default
    if (mappedReplies.length === 0) {
      setShowReplies(true);
    }
    setMappedReplies((prev) => [...prev, reply]);
    setNewReplyCount((prev) => prev + 1);
  }

  const repliesEmpty = comment.replies.length === 0 && newReplyCount === 0;
  return (
    <div>
      <CommentItem
        key={comment.commentId}
        post={post}
        comment={comment}
        setComments={setComments}
        addCommentReplyOnClient={addCommentReplyOnClient}
      />
      {!repliesEmpty && (
        <>
          <button
            onClick={() => {
              if (!showReplies) {
                mapReplyIdsToReplies();
              }
              setShowReplies((prev) => !prev);
            }}
            className="text-blue-500 duration-300 transition-colors hover:text-blue-900"
          >
            <div className={`ml-16 ${showReplies ? "mb-2" : "mb-0"}`}>
              {!showReplies ? "Show replies " : "Hide replies "}(
              {comment.replies.length + newReplyCount})
            </div>
          </button>

          <div className="ml-6">
            {showReplies && (
              <div className="mt-4">
                {mappedReplies.map((reply, replyIndex) => (
                  <div key={replyIndex}>
                    <CommentItem
                      highLevelCommentId={comment.commentId}
                      key={replyIndex}
                      addCommentReplyOnClient={addCommentReplyOnClient}
                      post={post}
                      parent={comment}
                      comment={reply}
                      setComments={setComments}
                    />
                  </div>
                ))}
                {mappedReplies.length < comment.replies.length && (
                  <button
                    className="ml-10 text-blue-500 hover:text-blue-900 transition-colors duration-300 mb-2"
                    onClick={() => {
                      mapReplyIdsToReplies();
                    }}
                  >
                    Show more replies [
                    {comment.replies.length -
                      mappedReplies.length +
                      newReplyCount}
                    ]
                  </button>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
