import React from 'react'
import Jobs from './Jobs';
import { useAdminToggle } from '../../context/AdminContext';


/**
 * Admin page filter configuration setup here.
 * @returns 
 */
export default function JobPage({isAdmin}) {
    
    const jobSiteItems = [
        {
            label: 'Remote',
            description: 'Jobs with no location requirement',
        },
        {
            label: 'Onsite',
            description: 'Jobs that have location requirement',
        },
        {
            label: "Hybrid",
            description: "Combination of both"
        }
    ];

    const jobTypeItems = [
        {
            label: 'Full-time',
        },
        {
            label: 'Part-time',
        },
        {
            label: 'Internship',
        },
        {
            label: 'Freelance',
        },
        {
            label: 'Contract',
        },
        {
            label: 'Volunteer',
        },
    ];

    let pageFilters = [];
    if (isAdmin) {
        pageFilters = ["Published Jobs", "Pending Requests"]
    } else {
        pageFilters = ["All jobs", "Design jobs", "Research", "Content"];
    }

    return (
        <>
            <Jobs isAdmin={isAdmin} pageFilters={pageFilters} jobSiteItems={jobSiteItems} jobTypeItems={jobTypeItems}/>
        </>
    )
}
