import React, { useEffect, useRef, useState } from "react";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import {
  createUser,
  getUser,
} from "../../../functions/firebase/firestoreUsers";
import { useAuth } from "../../../context/AuthContext";
import PostRodal from "./PostRodal";
import Swal from "sweetalert2";
import {
  createPost,
  updatePost,
} from "../../../functions/firebase/firestorePosts";
import { useToast } from "../../../context/ToastContext";
import { toast } from "react-toastify";
import { adjustIfStringTooLong } from "../../../functions/util";
import EmojiButton from "../../../components/common/EmojiButton";

function NewPost({
  user,
  incomingPost = null,
  isEditing,
  visible,
  setVisible,
}) {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const { showLoadingToast, showSuccessToast, showErrorToast } = useToast();
  const [emojiIsActive, setEmojiIsActive] = useState(false);
  const [focusedInput, setFocusedInput] = useState("content");

  const contentRefMobile = useRef(null);
  const contentRef = useRef(null);

  function adjustHeight(element) {
    console.log(element.scrollHeight);
    element.style.height = "inherit";
    element.style.height = "20vh";
  }

  /**
   * Post Rodal state.
   */
  const [post, setPost] = useState({
    title: "",
    content: "",
    tags: [String],
    image: null,
    imagePreview: null,
  });

  /**
   * If we are in editing mode, we want to assign the original post data to the
   * values when initializing this component.
   */
  useEffect(() => {
    try {
      if (isEditing && incomingPost !== null) {
        const newPostData = {};
        Object.entries(incomingPost).forEach(function (key, value) {
          newPostData[key] = value;
        });
        setPost({
          ...post,
          title: newPostData.title,
          content: newPostData.content,
          tags: newPostData.tags,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  /**
   * Add new tag data to the post.
   */
  useEffect(() => {
    setPost({ ...post, tags: selectedTags });
  }, [selectedTags]);

  /**
   * Reset the post values to the original if leaving edit.
   */
  useEffect(() => {
    if (visible && isEditing) {
      if (contentRefMobile?.current) {
        adjustHeight(contentRefMobile.current);
      }
      if (contentRef?.current) {
        adjustHeight(contentRef.current);
      }
    }
    if (!visible && isEditing) {
      setPost({
        ...post,
        content: incomingPost.content,
        title: incomingPost.title,
        tags: incomingPost.tags,
      });
      setSelectedTags(incomingPost.tags);
    }
  }, [visible]);

  const dropdownRef = useRef(null);

  const allTags = [
    "Ranting",
    "Feedback",
    "Education",
    "Funny",
    "Job Opportunity",
    "I’m not sure!",
  ];

  const tagColors = {
    Ranting: "bg-[#ed7377]",
    Feedback: "bg-[#69aeeb]",
    Education: "bg-[#b167ea]",
    Funny: "bg-[#ED8D64]",
    "Job Opportunity": "bg-[#4b9486]",
    "I’m not sure!": "bg-[#4a90e2]",
  };

  const handleTagSelect = (tag) => {
    if (!selectedTags.includes(tag)) {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const handleTagRemove = (tag) => {
    setSelectedTags(selectedTags.filter((t) => t !== tag));
  };

  const auth = useAuth();
  const fileInputRef = useRef(null);

  /**
   * Clicks the hidden image upload input field to upload the image.
   */
  function handleFileUpload() {
    console.log("File upload triggered");
    fileInputRef.current.click();
  }

  /**
   * Updates the state of the post whenever something changes.
   * @param {Object} e the element that changed
   */
  function handleOnChange(e) {
    if (e.target.type === "file") {
      // handle file change logic
      const selectedFile = e.target.files[0];
      console.log("selectedFile", selectedFile);
      if (selectedFile) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const preview = reader.result;
          setPost({ ...post, image: selectedFile, imagePreview: preview });
        };
        reader.readAsDataURL(selectedFile);
      }
    } else {
      // handle text change logic
      const { name, value } = e.target;
      setPost({ ...post, [name]: value });

      if (name === "content") {
        adjustHeight(e.target); //added this-Elvis
      }
    }
  }

  /**
   * Function for submitting the new or edited post and updating it on the firestore database
   * and uploading the image specifically, on the firebase storage.
   *
   * @param {Event} e - submit event
   */
  const handleSubmitPost = async (e) => {
    e.preventDefault();

    const trimmedContent = post.content.trim();
    console.log(trimmedContent);
    if (trimmedContent.length === 0) {
      toast("Content must not be blank!");
      return;
    }
    try {
      Swal.showLoading();
      if (isEditing) {
        const postData = {
          ...incomingPost,
          title: post.title,
          content: adjustIfStringTooLong(trimmedContent),
          tags: post.tags,
        };

          
        await updatePost(postData);

      
        showSuccessToast("Successfully Updated Post");
      } else {
        const postData = { ...post };
        postData.content = post.content;

        await createPost(post, auth.currentUser.uid);

        showSuccessToast("Successfully Created Post");

        setTimeout(() => {
          window.location.reload();
        }, 750);
      }

      // clear the post data for next time if we created one
      if (!isEditing) {
        setSelectedTags([]);
        setPost({
          title: "",
          content: "",
          tags: [],
          image: null,
          imagePreview: null,
        });
      }
    } catch (error) {
      console.log("ERROR", error);
      showErrorToast("Problem Submitting Post");
    } finally {
      Swal.close();
      setVisible(false);
    }
  };

  const handleRemoveImage = () => {
    setPost({ ...post, imagePreview: null, image: null });
  };

  function addEmojiToContent(emojiPicker) {
    if (focusedInput === "title") {
      setPost((prevPost) => ({
        ...prevPost,
        title: prevPost.title + emojiPicker,
      }));
    } else if (focusedInput === "content") {
      setPost((prevPost) => ({
        ...prevPost,
        content: prevPost.content + emojiPicker,
      }));
    }
  }

  return (
    <>
      {/* Post Rodal */}
      <div className="sm:block hidden">
        <PostRodal
          contentRef={contentRef}
          handleTagRemove={handleTagRemove}
          handleTagSelect={handleTagSelect}
          tagColors={tagColors}
          allTags={allTags}
          setDropdownVisible={setDropdownVisible}
          selectedTags={selectedTags}
          setPost={setPost}
          post={post}
          dropdownRef={dropdownRef}
          isDropdownVisible={isDropdownVisible}
          user={user}
          visible={visible}
          setVisible={setVisible}
          handleOnChange={handleOnChange}
          handleSubmitPost={handleSubmitPost}
          handleRemoveImage={handleRemoveImage}
          handleFileUpload={handleFileUpload}
          fileInputRef={fileInputRef}
          isEditing={isEditing}
          adjustHeight={adjustHeight}
          setEmojiIsActive={setEmojiIsActive}
          emojiIsActive={emojiIsActive}
          handleEmojiClicked={addEmojiToContent}
          setFocusedInput={setFocusedInput}
        />
      </div>

      {/* Post Mobile View */}
      <div
        className={`sm:hidden block fixed top-0 left-0 w-full h-full bg-white z-50 transform transition-transform duration-300 max-w-full ${
          visible ? "translate-y-0" : "translate-y-full"
        }`}
        // onClick={() => setVisible(false)}
      >
        <div className="relative z-20 mt-20">
          <button
            onClick={() => {
              setEmojiIsActive(false);
              setVisible(false);
            }}
            className="absolute top-5 left-5"
          >
            <img src="/assets/images/icons/close-default.svg" alt="" />
          </button>
          <div className="font-din text-center font-bold shadow-md p-4 w-full">
            {!isEditing ? "Create Post" : "Edit Post"}
          </div>

          <div className="font-roboto p-4">
            <div className="flex items-center gap-x-2">
              <div className="bg-gray-100 w-11 min-w-[44px] h-11 rounded-full flex-shrink-0">
                <img
                  className="rounded-full bg-gray-100 w-12 h-12"
                  src={
                    user?.profilePictureURL
                      ? user?.profilePictureURL
                      : "./assets/images/community/placeholder-user.svg"
                  }
                  alt="User"
                />
              </div>
              <div>
                <p className="text-sm font-bold">
                  {user?.firstName?.charAt(0)?.toUpperCase() + user?.firstName?.slice(1) + " " + user?.lastName?.charAt(0)?.toUpperCase() + user?.lastName?.slice(1)},
                  {' '}
                  <span className="font-normal text-[#959595]">
                    {user?.careerJourney}
                  </span>
                </p>
                <div
                  className="text-[#939393] flex flex-wrap py-1 items-center gap-x-1 cursor-pointer"
                  onClick={() => setDropdownVisible((prev) => !prev)}
                >
                  <img src="/assets/images/icons/globe.svg" alt="" />
                  <p className="font-bold text-sm">Tags</p>
                  <img
                    className={`${
                      isDropdownVisible ? "rotate-180" : "rotate-0"
                    } transition-all duration-300 ease-in-out`}
                    src="/assets/images/icons/arrow-down.svg"
                    alt=""
                  />

                  <div className="flex flex-wrap gap-2 relative">
                    {selectedTags.slice(0, 3).map((tag) => (
                      <span
                        key={tag}
                        className={`${
                          tagColors[tag]
                            ? `${tagColors[tag]} text-white`
                            : "bg-gray-200"
                        } rounded px-2 py-1 text-xs`}
                      >
                        {tag}
                      </span>
                    ))}
                    {selectedTags.length > 3 && (
                      <span className="bg-gray-200 rounded px-2 py-1 text-xs cursor-pointer hover:bg-gray-300 group">
                        +{selectedTags.length - 3}
                        <div className="absolute top-7 left-0 mt-2 w-48 p-4 flex flex-wrap gap-2 bg-white border rounded shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 ease-in-out">
                          {selectedTags.slice(3).map((tag) => (
                            <span
                              key={tag}
                              className={`w-fit mt-1 ${
                                tagColors[tag]
                                  ? `${tagColors[tag]} text-white`
                                  : "bg-gray-200"
                              } rounded px-2 py-1 text-xs`}
                            >
                              {tag}
                            </span>
                          ))}
                        </div>
                      </span>
                    )}
                  </div>
                </div>
                <div ref={dropdownRef} className="relative w-full">
                  {isDropdownVisible && (
                    <div className="z-10 absolute top-100 left-0 w-[198px] bg-[#FAFAFA] shadow rounded-md">
                      <img
                        onClick={() => setDropdownVisible(false)}
                        className="ml-auto cursor-pointer"
                        src="/assets/images/icons/close-default.svg"
                        alt=""
                      />
                      <div className="flex flex-wrap border-b rounded-md px-2 py-1">
                        {selectedTags.length === 0 && (
                          <span className="text-[#959595] text-xs p-2">
                            Select tags
                          </span>
                        )}
                        {selectedTags.map((tag) => (
                          <span
                            key={tag}
                            className={`${
                              tagColors[tag]
                                ? `${tagColors[tag]} text-white`
                                : "bg-gray-200"
                            } rounded px-2 py-1 text-xs flex w-max m-1`}
                          >
                            {tag}
                            <button
                              type="button"
                              onClick={() => handleTagRemove(tag)}
                              className="ml-1 cursor-pointer"
                            >
                              <img
                                src="/assets/images/icons/close-default-white.svg"
                                alt=""
                              />
                            </button>
                          </span>
                        ))}
                      </div>
                      <div className="rounded-md z-10 mt-2">
                        {allTags
                          .filter((tag) => !selectedTags.includes(tag))
                          .map((tag) => (
                            <div
                              key={tag}
                              onClick={() => handleTagSelect(tag)}
                              className="font-bold text-xs cursor-pointer py-2 px-4 rounded hover:bg-[#959595] hover:text-white transition-all duration-300 ease-in-out"
                            >
                              {tag}
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <form className="mt-4" onSubmit={handleSubmitPost}>
              <div className="">
                <input
                  data-testid="title-input"
                  onFocus={() => setFocusedInput("title")}
                  required
                  onChange={handleOnChange}
                  type="text"
                  id="title"
                  name="title"
                  value={post.title}
                  placeholder="Add Title"
                  className="focus:ring-0 mt-1 px-2 py-1 w-full border-none rounded-md placeholder:text-[#959595] font-semibold"
                />
              </div>
              <div className="mb-4 pb-2 border-b border-[#959595]">
                <textarea
                  onFocus={() => setFocusedInput("content")} // Update focusedInput state when content input is focused
                  ref={contentRef}
                  required
                  onChange={(e) => {
                    handleOnChange(e);
                    adjustHeight(e.target);
                  }}
                  value={post.content}
                  data-testid="content-input"
                  id="content"
                  name="content"
                  placeholder="What’s on your mind?"
                  className="focus:ring-0 px-2 w-full border-none rounded-md"
                  style={{
                    whiteSpace: "pre-wrap",
                    height: "20vh",
                    overflowY: "auto", // Always show vertical scrollbar if content exceeds
                  }}
                />

                {!isEditing && post.imagePreview !== null && (
                  <div className="relative inline-block">
                    <img
                      src={post.imagePreview}
                      alt="Image Preview"
                      style={{
                        width: "100%",
                        maxWidth: "27.5vh",
                        height: "auto",
                        maxHeight: "27.5vh",
                      }}
                    />
                    <button
                      onClick={handleRemoveImage}
                      className="absolute top-0 right-0 p-2 text-white rounded-full"
                      style={{ fontSize: "0.8rem" }}
                    >
                      <img
                        src="./assets/images/icons/cancel.svg"
                        style={{ width: "20px", height: "20px" }}
                      />
                    </button>
                  </div>
                )}
              </div>

              <div className="flex items-center mt-2">
                <div className="">
                  {/* image upload */}
                  <input
                    data-testid="file-input"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleOnChange}
                    ref={fileInputRef}
                  />

                  {!isEditing && (
                    <button
                      data-testid="image-button"
                      onClick={handleFileUpload}
                      type="button"
                      className="p-1 rounded-lg hover:shadow-md transition-all duration-300 ease-in-out"
                    >
                      <img src="/assets/images/icons/image.svg" alt="" />
                    </button>
                  )}

                  <EmojiButton
                    setEmojiIsActive={setEmojiIsActive}
                    emojiIsActive={emojiIsActive}
                    isComment={false}
                    handleEmojiClicked={addEmojiToContent}
                  />

                  {/* <button
                    type="button"
                    className="p-1 rounded-lg hover:shadow-md transition-all duration-300 ease-in-out ml-1"
                  >
                    <img src="/assets/images/icons/poll.svg" alt="" />
                  </button> */}
                </div>
                <button
                  type="submit"
                  data-testid="post-button"
                  className="absolute top-3 right-4 text-sm ml-auto px-3 py-1 shadow-lg bg-blue-500 text-white rounded-md"
                >
                  {!isEditing ? "Post" : "Save changes"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewPost;
