import React, { useEffect, useState } from "react";
import { NavLink, useParams, useLocation } from "react-router-dom";
import { useUser } from "../context/UserContext";

// TODO: look into these
import SidenavAdminPanel from "./SidenavAdminPanel";
import SidenavMain from "./SidenavMain";
import SidenavSettings from "./SidenavSettings";

function Sidenav({ isOpen, onClose }) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  // const location = useLocation();

  const location = useLocation();
  const isAdmin = location.pathname.split("/")[1] === "admin" ? true : false;

  useEffect(() => {
    if (isOpen) {
      setIsMobileMenuOpen(true);
    } else {
      setIsMobileMenuOpen(false);
    }
  }, [isOpen]);

  const { id } = useParams();
  const user = useUser();

  function setAdminView() {
    let url = location.pathname;
    if (isAdmin) {
      // Remove "/admin" at the front of the URL if it is admin
      url = url.replace(/^\/admin/, "");
    } else {
      // Add "/admin" at the front of the URL if it is not admin
      url = "/admin" + url;
    }
    // Reload the page with the new URL
    window.location.href = url.toString();
  }

  function handleRemoveParam() {
    const parts = window.location.href.split("/");
    window.location.href = parts.splice(0, parts.length - 1).join("/");
  }

  function closeMobileMenu() {
    setIsMobileMenuOpen(false);
    onClose();
  }

  return (
    <div>
      {isMobileMenuOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-50"
          onClick={closeMobileMenu}
        ></div>
      )}
      <nav
        className={`font-dmsans sm:h-[calc(100vh-67.8px)] h-full transform ${
          isOpen ? "translate-y-0" : "-translate-y-full"
        } 
      sm:translate-y-0 fixed sm:sticky top-0 left-0 w-2/3 border border-gray-300 sm:border-transparent sm:w-[196px] font-medium bg-primarySlateLight text-gray-600 transition-transform 
      sm:transition-none sm:z-20 z-50`}
      >
        <button
          onClick={closeMobileMenu}
          className="text-gray-600 absolute top-4 right-4 sm:hidden z-60"
        >
          ✕
        </button>
        <div className="h-full flex flex-col justify-between px-4 py-16 sm:py-16">
          {/* <ul className="space-y-1">
            {id ? (
              <button
                onClick={handleRemoveParam}
                className="py-2 px-3 flex items-center font-semibold gap-2 hover:bg-primarySlateMedium"
              >
                <img
                  className="inline"
                  src="/assets/images/icons/back-arrow.svg"
                />
                <span className="align-middle">
                  Return to {window.location.href.split("/").at(3)}
                </span>
              </button>
            ) : (
              <>
                {location.pathname.includes("/settings") ? (
                  <SidenavSettings isOpen={isOpen} onClose={onClose} />
                ) : (
                  <SidenavMain isOpen={isOpen} onClose={onClose} />
                )}
              </>
            )}
          </ul> */}
          {/* <SidenavAdminPanel isOpen={isOpen} onClose={onClose} /> */}

          <ul className="space-y-1">
            {id ? (
              <button
                onClick={handleRemoveParam}
                className="py-2 px-3 flex items-center font-semibold gap-2 hover:bg-primarySlateMedium"
              >
                <img
                  className="inline"
                  src="/assets/images/icons/back-arrow.svg"
                />
                <span className="align-middle">
                  Return to {window.location.href.split("/").at(3)}
                </span>
              </button>
            ) : (
              <>
                {location.pathname.includes("/settings") ? (
                  <SidenavSettings isOpen={isOpen} onClose={onClose} />
                ) : (
                  <SidenavMain isAdmin={isAdmin} isOpen={isOpen} onClose={onClose} />
                )}
              </>
            )}
          </ul>

          <ul className="space-y-1">
            {!isAdmin && (
              <>
                <li>
                  <NavLink
                    onClick={onClose}
                    to="/feedback"
                    className={({ isActive }) =>
                      isActive
                        ? "bg-primarySlateMedium text-gray-900 pl-3 py-2 rounded flex items-center gap-x-2 hover:bg-primarySlateMedium transition-all duration-300 ease-in-out"
                        : "pl-3 py-2 rounded flex items-center gap-x-2 hover:bg-primarySlateMedium transition-all duration-300 ease-in-out"
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M20 2H4C2.9 2 2.01 2.9 2.01 4L2 22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM20 16H5.17L4 17.17V4H20V16ZM10.5 14H18V12H12.5L10.5 14ZM14.36 8.13C14.56 7.93 14.56 7.62 14.36 7.42L12.59 5.65C12.39 5.45 12.08 5.45 11.88 5.65L6 11.53V14H8.47L14.36 8.13Z"
                        fill="#3D3D3D"
                      />
                    </svg>{" "}
                    Feedback
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={onClose}
                    to="https://uwh.canny.io/"
                    target="_blank"
                    className={({ isActive }) =>
                      isActive
                        ? "bg-primarySlateMedium text-gray-900 pl-3 py-2 rounded flex items-center gap-x-2 hover:bg-primarySlateMedium transition-all duration-300 ease-in-out"
                        : "pl-3 py-2 rounded flex items-center gap-x-2 hover:bg-primarySlateMedium transition-all duration-300 ease-in-out"
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M20.5 3L20.34 3.03L15 5.1L9 3L3.36 4.9C3.15 4.97 3 5.15 3 5.38V20.5C3 20.78 3.22 21 3.5 21L3.66 20.97L9 18.9L15 21L20.64 19.1C20.85 19.03 21 18.85 21 18.62V3.5C21 3.22 20.78 3 20.5 3ZM10 5.47L14 6.87V18.53L10 17.13V5.47ZM5 6.46L8 5.45V17.15L5 18.31V6.46ZM19 17.54L16 18.55V6.86L19 5.7V17.54Z"
                        fill="#3D3D3D"
                      />
                    </svg>{" "}
                    Roadmap
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={onClose}
                    to="https://uwh.canny.io/changelog"
                    target="_blank"
                    className={({ isActive }) =>
                      isActive
                        ? "bg-primarySlateMedium text-gray-900 pl-3 py-2 rounded flex items-center gap-x-2 hover:bg-primarySlateMedium transition-all duration-300 ease-in-out"
                        : "pl-3 py-2 rounded flex items-center gap-x-2 hover:bg-primarySlateMedium transition-all duration-300 ease-in-out"
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M21.6886 18.6685L16.3886 13.3685H15.3986L12.8586 15.9085V16.8985L18.1586 22.1985C18.5486 22.5885 19.1786 22.5885 19.5686 22.1985L21.6886 20.0785C22.0786 19.6985 22.0786 19.0585 21.6886 18.6685ZM18.8586 20.0885L14.6186 15.8485L15.3286 15.1385L19.5686 19.3785L18.8586 20.0885Z"
                        fill="#3D3D3D"
                      />
                      <path
                        d="M17.3586 10.6885L18.7686 9.27853L20.8886 11.3985C22.0586 10.2285 22.0586 8.32854 20.8886 7.15854L17.3486 3.61854L15.9386 5.02854V2.20854L15.2386 1.49854L11.6986 5.03854L12.4086 5.74854H15.2386L13.8286 7.15854L14.8886 8.21853L11.9986 11.1085L7.86855 6.97854V5.55854L4.84855 2.53854L2.01855 5.36854L5.04855 8.39854H6.45855L10.5886 12.5285L9.73855 13.3785H7.61855L2.31855 18.6785C1.92855 19.0685 1.92855 19.6985 2.31855 20.0885L4.43855 22.2085C4.82855 22.5985 5.45855 22.5985 5.84855 22.2085L11.1486 16.9085V14.7885L16.2986 9.63854L17.3586 10.6885ZM9.37855 15.8385L5.13855 20.0785L4.42855 19.3685L8.66855 15.1285L9.37855 15.8385Z"
                        fill="#3D3D3D"
                      />
                    </svg>{" "}
                    Changelog
                  </NavLink>
                </li>
              </>
            )}
            {user?.authorities?.filter(
              (authoritiy) => authoritiy === "admin"
            ) && (
              <li>
                <button
                  className="hover:bg-gray-500 transition-colors bg-gray-700 text-white text-sm rounded px-4 py-2 mt-4"
                  onClick={setAdminView}
                >
                  {!isAdmin ? "Enter Admin Panel" : "Exit Admin Panel"}
                </button>
              </li>
            )}
          </ul>

        </div>
        {/* </nav> */}
      </nav>
    </div>
  );
}

export default Sidenav;
