import React, { useEffect, useRef, useState } from 'react';

export default function FilterComponentList({
  id,
  name,
  icon,
  selectedItems,
  setSelectedItems,
  filterActive,
  items,
  toggleDropdown,
  showItemDropdown,
  setShowItemDropdown,
  type = 'large',
  isLocation = false,
  handleApply = () => {},
  clearAllLocations
}) {

  const [originalState, setOriginalState] = useState(selectedItems);

  if (type === 'large') {
    items = items.sort((a, b) => {
      if (a.label > b.label) {
        return 1;
      } else if (a.label < b.label) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  if (isLocation) {
    items.sort((a, b) => a.label.localeCompare(b.label));
  }

  const filterRef = useRef(null);

  const offset = ((id - 1) * 70).toString();

  return (
    <div className={`relative`} ref={filterRef}>
      {showItemDropdown && (
        <div
          onClick={() => setShowItemDropdown(false)}
          className="fixed inset-0 opacity-50 bg-black z-40"
        ></div>
      )}

      {/* Dropdown button */}
      <button
        className={`h-[38px] text-sm flex items-center gap-1 border py-2 px-4 rounded hover:shadow transition-all duration-300 ease-in-out 
              ${
                filterActive
                  ? 'bg-[#4079E2] text-white'
                  : 'border-[#B6B6B6] bg-white text-black'
              }`}
        onClick={() => {
          toggleDropdown(!showItemDropdown) 
        }}
      >
        <img src={icon} alt="Filter Image" width="20" height="20" />
        {name}
      </button>

      {/* Dropdown */}
      {showItemDropdown && (
        <div
          className={
            type === 'small'
              ? `border p-3 absolute top-10 lg:left-0 left-0 sm:right-0 z-50 w-[258px] rounded bg-white`
            : type === 'multicol'
              ? `border p-2 absolute top-[-180px] md:top-10 lg:left-0 left-0 z-50 sm-right-0 w-[350px] md:w-[750px] rounded bg-white`
              : `border p-2 absolute top-10 lg:left-0 left-0 z-50 sm-right-0 w-[268px] rounded bg-white`
          }
          style={{ left: `calc(-${offset}px)` }}
        >
          <div
            className={
              type === 'small'
                ? 'flex flex-wrap gap-x-2 gap-y-2 border-b-2 pb-5'
                : type === 'multicol'
                ? 'border-b-2 pb-4 flex flex-col gap-y-2 grid grid-cols-2 md:grid-cols-4 sm:h-auto overflow-y-scroll h-[70vh] sm:overflow-visible'
                : 'border-b-2 pb-4 flex flex-col gap-y-2'
            }
          >
            {items.map((location) => (
              <label
                key={location.label}
                className={
                  type === 'small'
                    ? `text-xs py-2 px-2 rounded border ${
                        selectedItems.includes(location.label)
                          ? 'bg-[#4079E2] text-white'
                          : 'bg-white border-gray-300 hover:bg-gray-100'
                      } cursor-pointer`
                  : type === 'multicol'
                    ? `py-4 px-4 rounded border ${
                      selectedItems.includes(location.label)
                        ? 'bg-[#4079E2] text-white'
                        : 'bg-white text-[#3D3D3D] border-gray-300 hover:bg-gray-100'
                    } cursor-pointer mx-1`
                    : `py-4 px-4 rounded border ${
                        selectedItems.includes(location.label)
                          ? 'bg-[#4079E2] text-white'
                          : 'bg-white text-[#3D3D3D] border-gray-300 hover:bg-gray-100'
                      } cursor-pointer`
                }
              >
                <input
                  type="checkbox"
                  className="hidden"
                  checked={selectedItems.includes(location.label)}
                  onChange={() => {
                    if (selectedItems.includes(location.label)) {
                      setSelectedItems((prev) =>
                        prev.filter((l) => l !== location.label)
                      );
                    } else {
                      setSelectedItems((prev) => [...prev, location.label]);
                    }
                  }}
                />
                <div className="font-normal text-xs">
                  <span className={type === 'small' ? 'font-normal' : 'font-bold'}>{location.label}</span>
                  <br />
                  {location?.description}
                </div>
              </label>
            ))}
          </div>
          <div className="mt-2 text-xs flex justify-between">
            <button
              onClick={() => {
                handleApply(true)
              }}
              className="text-[#959595] font-bold hover:underline transition-all duration-300 ease-in-out"
            >
              Clear all
            </button>
            <button
              onClick={() => handleApply()}
              className="px-5 py-3 bg-[#4079E2] text-white rounded hover:bg-blue-600 transition duration-300 ease-in-out"
            >
              Apply
            </button>
          </div>
        </div>
      )}
    </div>
  );
}