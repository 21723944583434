import React, { useState, useEffect } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import { getUsers } from '../../functions/firebase/firestoreUsers';
import "./Mentions.css";

export default function Mentions({ value, setValue }) {

    const fetchUsers = async (query, callback) => {
        console.log("fetching users");
        if (!query) {
            return;
        }

        query = query.toLowerCase();
    
        try {
            const limit = 7;
            let firstName, lastName;
    
            // Check if the query contains a space character
            if (query.includes('.')) {
                const [first, ...rest] = query.split('.');
                firstName = first;
                lastName = rest.join(' ');
            } else {
                // If there's no space, treat the entire query as the first name
                firstName = query;
            }
    
            // Call getUsers with first and last names
            const fetchedUsers = await getUsers(firstName, lastName, limit);
    
            // Format fetched users
            const usersMentionFormat = fetchedUsers.map((user) => ({
                id: user.uid,
                display: user.firstName?.charAt(0).toUpperCase() + user.firstName?.slice(1) + ' ' + user.lastName?.charAt(0).toUpperCase() + user.lastName?.slice(1),
                avatar: user.profilePictureURL,
                bio: user.occupation,
                markup: `<span class="mention">${user.firstName}</span>`, // Wrap the mention with a span with a custom class
            }));
    
           // console.log(usersMentionFormat);
            callback(usersMentionFormat);
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };

    return (
      <div className="w-full relative" data-testid="add-comment-mentions-input">
        <MentionsInput
          className="w-full h-full px-4 py-2 border-none rounded-md focus:outline-none focus:border-purple-700 border border-black"
          onChange={(e) => setValue(e.target.value)}
          value={value}
        >
          <Mention
            className="absolute right-0"
            trigger="@"
            data={fetchUsers}
            renderSuggestion={(suggestion, search, highlightedDisplay) => (
              <div className="flex flex-row p-2 cursor-pointer hover:bg-gray-100 sm:w-96 w-52">
                <img
                  className="w-8 h-8 rounded-full mr-2"
                  src={
                    suggestion.avatar
                      ? suggestion.avatar
                      : "/assets/images/icons/user.svg"
                  }
                  alt="User Avatar"
                />
                <div>
                  <span className="font-lg text-gray-700">
                    {suggestion.display}
                  </span>
                  <p className="text-sm text-gray-500">{suggestion.bio}</p>{" "}
                  {/* Show bio */}
                </div>
              </div>
            )}
          />
        </MentionsInput>
      </div>
    );
}