import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import UserProfileDropdown from './UserProfileDropdown';
import { useAuth } from '../context/AuthContext';
import { useUser } from '../context/UserContext';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../context/ToastContext';
import { IoMdArrowBack } from 'react-icons/io';

function Header({ isChatPanelOpen, toggleSidenav, toggleChatPanel, toggleNotificationsPanel, isSignUpPage = false, unreadNotificationCount }) {
  
  const [showSearchOverlay, setShowSearchOverlay] = useState(false);
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const [showNotificationsDropdown, setShowNotificationsDropdown] = useState(false);
  const { showSuccessToast, showErrorToast, showLoadingToast } = useToast();

  const navigate = useNavigate();
  const auth = useAuth();
  const user = useUser();

  const toggleSearchOverlay = () => {
    setShowSearchOverlay(!showSearchOverlay);
  };

  const toggleNotifications = () => {
    toggleNotificationsPanel();
    setShowNotificationsDropdown((prev) => !prev);
    setShowProfileDropdown(false);
  };

  const toggleUserProfile = () => {
    if (!auth.currentUser) {
      showErrorToast('Not Logged In');
      navigate("/login");
      return;
    }
    setShowProfileDropdown((prev) => !prev);
    setShowNotificationsDropdown(false);
  };

  function handleNavIconClick() {
    if (auth.currentUser) {
      navigate("/explore");
    } else {
      navigate("/");
    }     
  }

  return (
    <>
      <header
        className={`fixed h-16 w-full z-40 flex sm:gap-x-12 ${
          !isSignUpPage ? "justify-between" : "justify-start gap-2"
        } items-center md:px-12 sm:px-6 px-2 py-3 shadow-sm bg-white`}
      >
        <div className="flex items-center">
          <button onClick={toggleSidenav} className="sm:hidden">
            <img src="/assets/images/icons/hamburger-menu.svg" alt="" />
          </button>
          <button onClick={handleNavIconClick}>
            <img
              src="/assets/images/logo.png"
              alt="UX was here logo"
              className="sm:w-[113px] w-[98px] h-auto"
            />
          </button>
        </div>

        {!isSignUpPage ? (
          <>
            <div className="relative hidden md:block items-center font-dmsans w-full lg:max-w-xl mx-auto">
              <input
                type="text"
                placeholder="Search for topics and discussions"
                className="ps-10 pe-4 rounded-md border-gray-300 w-full focus:ring-primaryBlue"
              />
              <img
                className="absolute top-2 left-2"
                src="/assets/images/icons/search.svg"
                alt=""
              />
            </div>
            <div className="w-full max-w-fit sm:space-x-2 space-x-1">
              <button
                onClick={toggleSearchOverlay}
                className="md:hidden p-2 rounded-full border border-gray-300 hover:border-gray-400 transition-all duration-300 ease-in-out"
              >
                <img src="/assets/images/icons/search.svg" alt="" />
              </button>
              <button
                onClick={toggleChatPanel}
                className={`p-2 rounded-full border ${
                  isChatPanelOpen ? "border-blue-400" : "border-gray-300"
                } hover:border-gray-400 transition-all duration-300 ease-in-out`}
              >
                <img
                  src={`/assets/images/icons/${
                    isChatPanelOpen ? "chat-active" : "chat"
                  }.svg`}
                  alt=""
                />
              </button>
              <button
                onClick={toggleNotifications}
                className="relative p-2 rounded-full border border-gray-300 hover:border-gray-400 transition-all duration-300 ease-in-out"
              >
                <img src="/assets/images/icons/bell.svg" alt="" />
                {unreadNotificationCount > 0 && (
                  <>
                    <div className="bg-blue-500 text-white text-xs rounded-full p-1 absolute -top-2 -right-2 size-6">
                      {unreadNotificationCount}
                    </div>
                  </>
                )}
              </button>
              <button
                data-testid="profile-icon"
                onClick={toggleUserProfile}
                className="p-2 rounded-full border border-gray-300 hover:border-gray-400 transition-all duration-300 ease-in-out w-11 h-11"
              >
                <img
                  src={
                    user?.profilePictureURL
                      ? user?.profilePictureURL
                      : "/assets/images/icons/user.svg"
                  }
                  alt=""
                  className="w-full h-full rounded-full"
                />
              </button>
            </div>
          </>
        ) : (
          <button
            onClick={() => navigate(-1)}
            className="sm:text-lg text-sm flex flex-row items-center gap-2 hover:underline"
          >
            <IoMdArrowBack />
            Return to previous page<span></span>
          </button>
        )}
      </header>

      <div
        className={`fixed top-0 left-0 h-full w-full z-50 bg-white p-4 transform transition-transform duration-300 ${
          showSearchOverlay ? "translate-y-0" : "-translate-y-full"
        }`}
      >
        <button
          onClick={toggleSearchOverlay}
          className="absolute top-4 right-4"
        >
          ✕
        </button>
        <div className="relative mt-10">
          <input
            type="text"
            placeholder="Search for topics and discussions"
            className="ps-10 pe-4 rounded-md border-gray-300 w-full focus:ring-primaryBlue"
            autoFocus
          />
          <img
            className="absolute top-1/2 transform -translate-y-1/2 left-3"
            src="/assets/images/icons/search.svg"
            alt=""
          />
        </div>
      </div>

      {showProfileDropdown && user !== null && <UserProfileDropdown />}
    </>
  );
}

export default Header;
