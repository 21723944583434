import { formatDistanceToNow } from "date-fns";
import { firestore } from "../../../firebaseConfig"
import { Timestamp, addDoc, arrayRemove, arrayUnion, collection, deleteDoc, doc, getCountFromServer, getDoc, getDocs, onSnapshot, query, serverTimestamp, updateDoc, where } from "firebase/firestore";
import { getUser } from "./firestoreUsers";


export const getUnreadNotificationCount = async (authId) => {
    try {
        const notificationCollection = collection(firestore, "notifications");
        let q = query(notificationCollection, where("toId", "==", authId), where("hasRead", "==", false));
        const totalSnapshot = await getCountFromServer(q);
        const count = totalSnapshot.data().count;
        return count;
    } catch (error) {
        throw new Error("Failed to obtain the count of unread notifications: " + error);
    }
}

/**
 * Creates a notification for a user when a new comment is added to their post.
 * 
 * @param {String} userId - ID of the user who will receive the notification
 * @param {String} postId - ID of the post that received a new comment
 * @param {String} commenterId - ID of the user who made the comment
 * @param {String} commentContent - Content of the new comment
 */
export const createNotification = async (notification) => {
    const {senderId, toId, postId, commentId, type, action} = notification;
    try {
        await addDoc(collection(firestore, 'notifications'), {
            updatedAt: serverTimestamp(),
            createdAt: serverTimestamp(),
            hasRead: false,
            senderId: senderId,
            toId: toId,
            postId: postId,
            type: type,
            action: action,
            commentId: commentId,
        });

        console.log("Notification created successfully.");
    } catch (error) {
        console.error("Error creating notification:", error);
    }
};

export const getNotifications = async (id) => {
    try {
        const notificationsCollection = collection(firestore, 'notifications');
        const q = query(notificationsCollection, where('toId', '==', id));
        const yourNotifications = await getDocs(q);
    
        const notificationsToReturn = [];
        yourNotifications.docs.forEach((doc) => {
            const createdAt = doc.data().createdAt;
            let date = null;
            if (createdAt && createdAt instanceof Timestamp) {
                date = formatDistanceToNow(new Date(createdAt.toDate()), { addSuffix: true });
            } else {
                date = "Just now";
            }

            notificationsToReturn.push({
                ...doc.data(),
                id: doc.id,
                date: date,
            });
        });

        return notificationsToReturn;
    } catch (error) {
        throw new Error("Could not fetch notifications: " + error);
    }
}

export const deleteNotification = async (id) => {
    try {
        const notificationDocRef = doc(firestore, "notifications", id);
        await deleteDoc(notificationDocRef);
    } catch (error) {
        throw new Error("Could not delete notification: " + error);
    }
}

export const clearNotifications = async (notifications) => {
    try {
        // delete all these docs
        await Promise.all(notifications.forEach(async notification => {
            const notificationRef = doc(firestore, "notifications", notification.id);
            await deleteDoc(notificationRef);
        }));
    } catch (error) {
        throw new Error("Could not clear notifications: " + error);
    }
}

export const listenForNotificationChanges = (authId, onUpdate) => {
    const notificationCollection = collection(firestore, "notifications");
    const q = query(notificationCollection, where("toId", "==", authId));
    
    const unsubscribe = onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach(change => {
            const createdAt = change.doc.data().createdAt;
            let date = null;

            if (createdAt && createdAt instanceof Timestamp) {
                date = formatDistanceToNow(new Date(createdAt.toDate()), { addSuffix: true });
            } else {
                date = "Just now";
            }

            const notification = {
                ...change.doc.data(),
                id: change.doc.id,
                date: date,
            }

            onUpdate(notification, change.type);
        });
    });

    return unsubscribe;
}


export const toggleNotificationRead = async (notification) => {
    try {
        const hasRead = notification.hasRead;
        const docRef = doc(firestore, "notifications", notification.id);
        await updateDoc(docRef, {
            hasRead: !hasRead
        });
    } catch (error) {
        throw new Error("Could not toggle notification read: " + error);
    }
}