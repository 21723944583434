/* eslint-disable linebreak-style */
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import JobCard from "../../components/common/JobCard";
import {
  confirmJobPostings,
  getJob,
  getJobCount,
  getJobs,
} from "../../functions/firebase/firestoreJobs";
import PostingRequest from "./PostingRequest";
import FilterComponentList from "./FilterComponentList";
import Announcements from "../../components/common/Announcements";
import { useAuth } from "../../context/AuthContext";
import CardPosting from "../../components/CardPosting";
import LoginModal from "../../components/util/LoginModal";
import ServerSearch from "./ServerSearch";
import { updateLocationFilters } from "./FilterService";
import Swal from "sweetalert2";
import { orderBy, query, startAfter, where } from "firebase/firestore";
import { connectStorageEmulator } from "firebase/storage";
import { getCountryCallingCode } from "libphonenumber-js";
import countriesData from '../../countries.json';
import { clear } from "@testing-library/user-event/dist/cjs/utility/clear.js";


function Jobs({ isAdmin, pageFilters, jobSiteItems, jobTypeItems }) {
  const { id } = useParams();
  const auth = useAuth();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  
  // put together search where filter
  let search = null;
  const searchValue = searchParams.get("search");
  if (searchValue) {
    // Preprocess search field value into an array of characters
    search = {
      fieldPath: "title",
      op: "array-contains-any",
      fieldValue: searchValue,
    };
  }

  // put together jobs per page count filter
  let jobsPerPage = searchParams.get("count") || 10;

  // put together order by date filter
  let sortBy = searchParams.get("sortBy") || "createdAt";
  let order = searchParams.get("order") || "desc";

  // put together selected category
  let selectedCategory =
    searchParams.get("category") || (isAdmin ? "Pending Requests" : "All jobs");

  // put together job sites
  let jobSites = searchParams.get("jobSites");
  let jobSitesArray = jobSites ? jobSites.split(",") : [];

  // put together job types
  let jobTypes = searchParams.get("jobTypes");
  let jobTypesArray = jobTypes ? jobTypes.split(",") : [];

  // put together locations
  let jobLocations = searchParams.get("locations");
  let jobLocationsArray = jobLocations ? jobLocations.split(",") : [];

  const [selectedJobSites, setSelectedJobSites] = useState(jobSitesArray);
  const [selectedTypes, setSelectedTypes] = useState(jobTypesArray);
  const [selectedLocations, setSelectedLocations] = useState(jobLocationsArray);

  const [locationItems, setLocationItems] = useState([]);
  const [showLocationDropdown, setShowLocationDropdown] = useState(false);
  const [showJobSiteDropdown, setShowJobSiteDropdown] = useState(false);
  const [showTypeDropdown, setShowTypeDropdown] = useState(false);
  const [filteredJobs, setFilteredJobs] = useState(null);

  const [lastDoc, setLastDoc] = useState(null);
  const [moreJobs, setMoreJobs] = useState(true);
  const [showSignInModal, setShowSignInModal] = useState(false);

  const [jobCount, setJobCount] = useState(0);
  const [pendingRequestCount, setPendingRequestCount] = useState(0);

  const [locationFiltersActive, setLocationFiltersActive] = useState(false);
  const [sitesFiltersActive, setSitesFiltersActive] = useState(false);
  const [jobTypeFiltersActive, setJobTypeFiltersActive] = useState(false);

  function openSignInModal() {
    setShowSignInModal(true);
  }

  /**
   * Close change the filter that is visible to the one clicked on
   * @param {} setDropdown
   */
  const toggleFilterDropdown = (setDropdown) => {
    // close all by default
    setShowJobSiteDropdown(false);
    setShowLocationDropdown(false);
    setShowTypeDropdown(false);

    // open the one passed in
    setDropdown();
  };

  useEffect(() => {
    if (showLocationDropdown || showJobSiteDropdown || showTypeDropdown) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [showLocationDropdown, showJobSiteDropdown, showTypeDropdown]);

  useEffect(() => {
    if (id) {
      // fetch the job with the corresponding param id
      const get = async () => {
        const jobs = [];
        const job = await getJob(id);
        jobs.push(job);
        setFilteredJobs(jobs);
      };
      get();
      if (!auth.currentUser) {
        openSignInModal();
      }
      return;
    }

    async function fetchJobs() {
      // fetch jobs initially once
      try {
        const incomingJobData = await getJobs(
          // const { lastDoc, jobs } = await getJobs(
          lastDoc,
          jobsPerPage > 50 ? 50 : jobsPerPage,
          search,
          sortBy,
          order,
          selectedCategory,
          selectedJobSites,
          selectedTypes,
          selectedLocations
        );

        const count = await getJobCount(
          lastDoc,
          search,
          sortBy,
          order,
          selectedCategory,
          selectedJobSites,
          selectedTypes,
          selectedLocations
        );
        console.log(count);

        const requestCount = await getJobCount(
          lastDoc,
          null,
          null,
          null,
          "Pending Requests",
          [],
          [],
          []
        );

        setFilteredJobs(incomingJobData.jobs);
        setJobCount(count);
        setPendingRequestCount(requestCount);

        const countries = countriesData.countries;
        updateLocationFilters(
          incomingJobData.jobs,
          setLocationItems,
          selectedCategory,
          isAdmin,
          "Country",
          countries
        );

        setLastDoc(incomingJobData.lastDoc);
        if (!incomingJobData.lastDoc) {
          setMoreJobs(false);
          return;
        }
      } catch (error) {
        console.error(`error getting jobs: ${error}`);
      }
    }
    fetchJobs();

    // listen for deletes and modify changes
    // const unsubscribe = realTimeJobs(setFilteredJobs);

    // // Detach the event listener when the component unmounts
    // return () => {
    //   unsubscribe();
    // };

    // Set filtered colors 
    const urlParams = new URLSearchParams(window.location.search);
    const locationsParam = urlParams.get('locations');
    const jobSitesParam = urlParams.get('jobSites');
    const jobTypesParam = urlParams.get('jobTypes');

    if (locationsParam && locationsParam.length > 0) {
      setLocationFiltersActive(true);
    }

    if (jobSitesParam && jobSitesParam.length > 0) {
      setSitesFiltersActive(true);
    }

    if(jobTypesParam && jobTypesParam.length > 0) {
      setJobTypeFiltersActive(true);
    }
  }, []);

  async function fetchMoreJobs() {
    if (moreJobs) {
      const incomingJobData = await getJobs(
        lastDoc,
        jobsPerPage > 50 ? 50 : jobsPerPage,
        search,
        sortBy,
        order,
        selectedCategory,
        selectedJobSites,
        selectedTypes,
        selectedLocations
      );
      
      const countries = countriesData.countries;
      updateLocationFilters(
        incomingJobData.jobs,
        setLocationItems,
        selectedCategory,
        isAdmin,
        "Country",
        countries
      );

      if (
        incomingJobData.lastDoc === null ||
        incomingJobData.lastDoc === lastDoc
      ) {
        setMoreJobs(false);
        return;
      }

      setLastDoc(incomingJobData.lastDoc);
      setFilteredJobs((prevJobs) => {
        return [...prevJobs, ...incomingJobData.jobs];
      });
    }
  }

  function handleSearch(searchString) {
    const url = new URL(window.location.href);
    url.searchParams.set("search", searchString);

    // Reload the page with the new URL
    window.location.href = url.toString();
  }

  function handleJobsPerPageChange(e) {
    const url = new URL(window.location.href);
    url.searchParams.set("count", e.target.value);

    // Reload the page with the new URL
    window.location.href = url.toString();
  }

  function handleSortBy(e) {
    const options = {
      type: e.target.value.split(" ")[0],
      order: e.target.value.split(" ")[1],
    };

    const url = new URL(window.location.href);
    url.searchParams.set("sortBy", options.type);
    url.searchParams.set("order", options.order);

    // Reload the page with the new URL
    window.location.href = url.toString();
  }

  function handleCategory(category) {
    const url = new URL(window.location.href);
    url.searchParams.set("category", category);
    // Reload the page with the new URL
    window.location.href = url.toString();
  }

  function handleApplyJobSite(clearAll = false) {
    const jobSitesJoined = clearAll ? '' : selectedJobSites.join(",");
    const url = new URL(window.location.href);
    url.searchParams.set("jobSites", jobSitesJoined);

    // Reload the page with the new URL
    window.location.href = url.toString();
  }

  function handleApplyJobType(clearAll = false) {
    const jobTypesJoined = clearAll ? '' : selectedTypes.join(",");
    
    const url = new URL(window.location.href);
    url.searchParams.set("jobTypes", jobTypesJoined);

    // Reload the page with the new URL
    window.location.href = url.toString();
  }

  function handleLocation(clearAll = false) {
    const jobLocations = clearAll ? '' : selectedLocations.join(",");
    const url = new URL(window.location.href);
    url.searchParams.set("locations", jobLocations);

    // Reload the page with the new URL
    window.location.href = url.toString();
  }

  function handleAcceptYourRequests() {
    Swal.fire({
      title: "Accept your job requests?",
      text: "All of these requests will be approved, please ensure they are appropriate before proceeding",
      showCancelButton: true,
      confirmButtonText: "Accept your job requests",
      reverseButtons: true,
      icon: "warning",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.showLoading();
        await confirmJobPostings(auth?.currentUser.uid);
        Swal.hideLoading();
        window.location.reload();
      }
    });
  }

  return (
    <div className="flex font-dmsans">
      {!id && (
        <Helmet>
          <title>UX Was Here - Jobs</title>
          <meta
            name="description"
            content="Find the latest jobs to apply for here"
          />
          <link rel="canonical" href="/jobs" />
        </Helmet>
      )}
      <div className="flex-1 lg:pt-8 pt-4 lg:px-14 md:px-6">
        <h2 className="text-2xl pb-4 font-bold">Jobs</h2>
        <div className="flex flex-wrap lg:gap-x-4 gap-x-4">
          {!id &&
            pageFilters.map((category, index) => (
              <button
                key={category}
                className={`px-2 text-lg ${
                  selectedCategory === category
                    ? "border-b border-black text-black"
                    : "text-[#959595]"
                }`}
                data-testid={`category-button-${category}`}
                onClick={() => handleCategory(category)}
              >
                {category}{" "}
                {category === "Pending Requests" && (
                  <span className="text-orange-700">{pendingRequestCount}</span>
                )}
              </button>
            ))}
        </div>

        {/* filter options */}
        {!id && (
          <div className="my-5 flex flex-wrap gap-y-4 gap-x-4 sm:items-center">

            <div className="flex sm:flex-nowrap flex-wrap sm:gap-4 gap-2">
              <FilterComponentList
                id={1}
                name="Location"
                filterActive={locationFiltersActive}
                icon={
                  locationFiltersActive
                    ? "/assets/images/icons/location-pin-white.svg"
                    : "/assets/images/icons/location-pin.svg"
                }
                selectedItems={selectedLocations}
                items={locationItems}
                setSelectedItems={setSelectedLocations}
                toggleDropdown={() =>
                  toggleFilterDropdown(() =>
                    setShowLocationDropdown(!showLocationDropdown)
                  )
                }
                showItemDropdown={showLocationDropdown}
                setShowItemDropdown={setShowLocationDropdown}
                isLocation
                handleApply={handleLocation}
                type="multicol"
              />

              <FilterComponentList
                id={2}
                name="Job site"
                filterActive={sitesFiltersActive}
                icon={
                  sitesFiltersActive
                    ? "/assets/images/icons/bus-white.svg"
                    : "/assets/images/icons/bus.svg"
                }
                selectedItems={selectedJobSites}
                items={jobSiteItems}
                setSelectedItems={setSelectedJobSites}
                toggleDropdown={() =>
                  toggleFilterDropdown(() =>
                    setShowJobSiteDropdown(!showJobSiteDropdown)
                  )
                }
                showItemDropdown={showJobSiteDropdown}
                setShowItemDropdown={setShowJobSiteDropdown}
                handleApply={handleApplyJobSite}
              />

              <FilterComponentList
                id={3}
                name="Job Type"
                filterActive={jobTypeFiltersActive}
                icon={
                  jobTypes
                    ? "/assets/images/icons/suitcase-white.svg"
                    : "/assets/images/icons/suitcase-black.svg"
                }
                selectedItems={selectedTypes}
                items={jobTypeItems}
                setSelectedItems={setSelectedTypes}
                toggleDropdown={() =>
                  toggleFilterDropdown(() =>
                    setShowTypeDropdown(!showTypeDropdown)
                  )
                }
                showItemDropdown={showTypeDropdown}
                setShowItemDropdown={setShowTypeDropdown}
                type="small"
                handleApply={handleApplyJobType}
              />
            </div>
          </div>
        )}

        <div className="my-5 flex flex-wrap gap-y-4 gap-x-4 sm:items-center">
          <div>
            <span className="">Display Per Page: </span>
            <select
              className="bg-white rounded-md border-gray-300"
              onChange={handleJobsPerPageChange}
            >
              <option value="10" selected={jobsPerPage === "10"}>
                10
              </option>
              <option value="20" selected={jobsPerPage === "20"}>
                20
              </option>
              <option
                value="50"
                selected={jobsPerPage > 50 ? "50" : jobsPerPage === "50"}
              >
                50
              </option>
            </select>
          </div>

          <div>
            <span className="">Sort by: </span>
            <select
              className="bg-white rounded-md border-gray-300"
              onChange={handleSortBy}
            >
              <option
                value="createdAt desc"
                selected={sortBy === "createdAt" && order === "desc"}
              >
                Added to UwH: New
              </option>
              <option
                value="createdAt asc"
                selected={sortBy === "createdAt" && order === "asc"}
              >
                Added to UwH: Old
              </option>
            </select>
            </div>
        </div>

        {isAdmin && selectedCategory === "Pending Requests" && (
          <button
            onClick={handleAcceptYourRequests}
            className="transition-colors duration-300 hover:bg-gray-300 hover:border-gray-400 bg-white border border-gray-300 rounded-md py-1 px-2 mb-4"
          >
            Accept all your requests
          </button>
        )}

        <div className="">
          <p className="font-bold">
            {jobCount} <span className="font-normal text-sm">Jobs</span>
          </p>
        </div>
        <div className="mt-4 grid gap-y-4">
          {/* Looking to hire button + form attached */}
          {!isAdmin ? <CardPosting formType="job" /> : null}

          <div className="space-y-4">
            {filteredJobs?.map((job) =>
              selectedCategory === "Pending Requests" ? (
                <PostingRequest
                  isAdmin={isAdmin}
                  key={job.id}
                  job={job}
                  type="job"
                />
              ) : (
                <JobCard isAdmin={isAdmin} key={job.id} job={job} />
              )
            )}
          </div>
          {moreJobs ? (
            <button
              className="bg-blue-500 hover:bg-blue-900 transition-colors duration-300 py-2 px-4 rounded-md text-white text-base w-52 place-self-center"
              onClick={fetchMoreJobs}
            >
              Load more jobs
            </button>
          ) : (
            <div className="text-center">End of page</div>
          )}
        </div>
      </div>
      <Announcements id={1} />
      <LoginModal
        modalIsOpen={showSignInModal}
        setIsOpen={setShowSignInModal}
      />
    </div>
  );
}

export default Jobs;
