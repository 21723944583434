import React, { useEffect, useMemo, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import 'react-quill/dist/quill.snow.css';
import Swal from 'sweetalert2';
import { useAuth } from '../../../context/AuthContext';
import { GenerateFormInput, GenerateRichText, GenerateSelectInput } from '../../../functions/formik';
import ImageUploader from '../../../components/util/ImageUploader';
import { eventsFormValidationSchema } from '../../../functions/validationSchemas';
import { createEvent, updateEvent } from '../../../functions/firebase/firestoreEvents';
import { getSensitiveUserData } from '../../../functions/firebase/firestoreUsers';
import iso from 'iso-3166-1-alpha-2';
import countriesData from '../../../countries.json';


const EventForm = ({cancel, event, isEditing = false, setShowEventPostForm, isAdmin = false}) => {
  const [touched, setTouched] = useState(false);
  const auth = useAuth();

  // get the country list and put Canada + US at the top
  const getCountryList = (countriesAtFront = null) => {
    const countryList = countriesData["countries"].sort();
    if (countriesAtFront !== null) {
      countriesAtFront.reverse().forEach(country => {
        const index = countryList.findIndex((countryInList) => country === countryInList);
        if (index !== -1) {
          const countryRemoved = countryList.splice(index, index + 1)[0];
          countryList.unshift(countryRemoved);
        }
      });
      return countryList;
    } else {
      return countryList;
    }
  };
  const countryList = getCountryList();

  const previousJobImage = event?.imageURL || null;

  const eventCategories = [
    'Design',
    'Research',
    'Content',
  ];

  const eventTypes = [
    "In Person",
    "Online",
    "Hybrid",
  ];

  const timeZones = [
    "(GMT-12:00) International Date Line West",
    "(GMT-11:00) Midway Island, Samoa",
    "(GMT-10:00) Hawaii",
    "(GMT-09:00) Alaska",
    "(GMT-08:00) Pacific Time (US and Canada)",
    "(GMT-07:00) Mountain Time (US and Canada)",
    "(GMT-07:00) Chihuahua, La Paz, Mazatlan",
    "(GMT-07:00) Arizona",
    "(GMT-06:00) Central Time (US and Canada)",
    "(GMT-06:00) Saskatchewan",
    "(GMT-06:00) Guadalajara, Mexico City, Monterrey",
    "(GMT-06:00) Central America",
    "(GMT-05:00) Eastern Time (US and Canada)",
    "(GMT-05:00) Indiana (East)",
    "(GMT-05:00) Bogota, Lima, Quito",
    "(GMT-04:00) Atlantic Time (Canada)",
    "(GMT-04:00) Caracas, La Paz",
    "(GMT-04:00) Santiago",
    "(GMT-03:30) Newfoundland and Labrador",
    "(GMT-03:00) Brasilia",
    "(GMT-03:00) Buenos Aires, Georgetown",
    "(GMT-03:00) Greenland",
    "(GMT-02:00) Mid-Atlantic",
    "(GMT-01:00) Azores",
    "(GMT-01:00) Cape Verde Islands",
    "(GMT) Greenwich Mean Time: Dublin, Edinburgh, Lisbon, London",
    "(GMT) Casablanca, Monrovia",
    "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",
    "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    "(GMT+01:00) West Central Africa",
    "(GMT+02:00) Bucharest",
    "(GMT+02:00) Cairo",
    "(GMT+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius",
    "(GMT+02:00) Athens, Istanbul, Minsk",
    "(GMT+02:00) Jerusalem",
    "(GMT+02:00) Harare, Pretoria",
    "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
    "(GMT+03:00) Kuwait, Riyadh",
    "(GMT+03:00) Nairobi",
    "(GMT+03:00) Baghdad",
    "(GMT+03:30) Tehran",
    "(GMT+04:00) Abu Dhabi, Muscat",
    "(GMT+04:00) Baku, Tbilisi, Yerevan",
    "(GMT+04:30) Kabul",
    "(GMT+05:00) Ekaterinburg",
    "(GMT+05:00) Islamabad, Karachi, Tashkent",
    "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    "(GMT+05:45) Kathmandu",
    "(GMT+06:00) Astana, Dhaka",
    "(GMT+06:00) Sri Jayawardenepura",
    "(GMT+06:00) Almaty, Novosibirsk",
    "(GMT+06:30) Yangon Rangoon",
    "(GMT+07:00) Bangkok, Hanoi, Jakarta",
    "(GMT+07:00) Krasnoyarsk",
    "(GMT+08:00) Beijing, Chongqing, Hong Kong SAR, Urumqi",
    "(GMT+08:00) Kuala Lumpur, Singapore",
    "(GMT+08:00) Taipei",
    "(GMT+08:00) Perth",
    "(GMT+08:00) Irkutsk, Ulaanbaatar",
    "(GMT+09:00) Seoul",
    "(GMT+09:00) Osaka, Sapporo, Tokyo",
    "(GMT+09:00) Yakutsk",
    "(GMT+09:30) Darwin",
    "(GMT+09:30) Adelaide",
    "(GMT+10:00) Canberra, Melbourne, Sydney",
    "(GMT+10:00) Brisbane",
    "(GMT+10:00) Hobart",
    "(GMT+10:00) Vladivostok",
    "(GMT+10:00) Guam, Port Moresby",
    "(GMT+11:00) Magadan, Solomon Islands, New Caledonia",
    "(GMT+12:00) Fiji Islands, Kamchatka, Marshall Islands",
    "(GMT+12:00) Auckland, Wellington",
    "(GMT+13:00) Nuku'alofa"
  ];

  const times = [
    "00:00",
    "00:30",
    "01:00",
    "01:30",
    "02:00",
    "02:30",
    "03:00",
    "03:30",
    "04:00",
    "04:30",
    "05:00",
    "05:30",
    "06:00",
    "06:30",
    "07:00",
    "07:30",
    "08:00",
    "08:30",
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
    "21:00",
    "21:30",
    "22:00",
    "22:30",
    "23:00",
    "23:30",
  ];

  // Initial form values
  let initialValues = {
    link: event?.link || "",
    type: event?.type || "In Person",
    title: event?.title || "",
    imageURL: event?.imageURL || null,
    country: event?.country || "Canada",
    category: event?.category || "",
    city: event?.city || "",
    internalNotes: event?.internalNotes || "",
    
    address: event?.address || "", // needs to have a character count show up below
    dayType: event?.endDate ? "multi" : "single",
    optionalTime: event?.endDate !== null && (event?.startTime !== null || event?.endTime !== null),
    startTime: event?.startTime || "",
    endTime: event?.endTime || "",
    startDate: event?.startDate || "",
    endDate: event?.endDate || null,
    timeZone: event?.timeZone || "",
  };

  /**
   * Submits a new post to the database.
   * 
   * @param {Object} values - the values for the event that we are submitting
   */
  function submitPosting(values, setValues) {
    Swal.fire({
      title: "Confirmation",
      text: "Your posting will be sent to be reviewed by our admins. We will look over this as soon as we can, and if the post is approved, it will be available for everyone to see.",
      cancelButtonText: "Cancel",
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: "Confirm Request",
      fontFamily: "DMsans",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          Swal.fire({
            title: 'Submitting event request',
            text: 'Please wait...',
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });

          const sensitiveUserData = await getSensitiveUserData(auth.currentUser.uid);

          const valuesToSubmit = {
            link: values.link,
            startTime: values.startTime,
            endTime: values.endTime,
            city: values.city,
            country: values.country,
            startDate: values.startDate,
            endDate: values.dayType === "multi" ? values.endDate : null,
            internalNotes: values.internalNotes,
            title: values.title,
            type: values.type,
            thumbnail: values.thumbnail,
            imageURL: previousJobImage, 
            category: values.category,
            address: values.address,
            timeZone: values.timeZone,
            inReview: true,
            userId: auth.currentUser.uid,
            email: sensitiveUserData.email,
          };
          console.log("VALUES TO SUBMIT");
          console.log(valuesToSubmit);

          await createEvent(valuesToSubmit);

          localStorage.removeItem(auth.currentUser.uid + "eventForm");

          Swal.fire({
            title: "Event request submitted successfully!",
            icon: "success"
          }).then((result) => {
            cancel();
          });
        } catch (error) {
          console.error(error);
          Swal.close();
        }
      }
    });
  }

  /**
   * Edit the values of the event posting.
   * 
   * @param {Object} values - values that we are editing from formik
   */
  async function editPosting(values) {
    Swal.fire({
      title: "Just checking!",
      text: "You are about to edit a event posting. These changes will be applied, but you can edit this posting again whenever.",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Finish Editing",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Editing event posting',
          text: 'Please wait...',
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
        try {

          const valuesToSubmit = {
            link: values.link,
            startTime: values.startTime,
            endTime: values.endTime,
            city: values.city,
            country: values.country,
            startDate: values.startDate,
            endDate: values.dayType === "multi" ? values.endDate : null,
            internalNotes: values.internalNotes,
            title: values.title,
            type: values.type,
            thumbnail: values.thumbnail,
            imageURL: previousJobImage, 
            category: values.category,
            address: values.address,
            timeZone: values.timeZone,
      
            inReview: false,
            userId: auth.currentUser.uid,
            id: event.id
          };
          console.log("VALUES TO SUBMIT");
          console.log(valuesToSubmit);
          await updateEvent(valuesToSubmit);

          if (setShowEventPostForm) {
            setShowEventPostForm(false);
          }
        } catch (error) {
          console.error(error);
        } finally {
          Swal.close();
        }
      }
    });
  }

  return (
    <div className="">
        <Formik
          initialValues={initialValues}
          validationSchema={eventsFormValidationSchema}
          onSubmit={(values, actions) => {
            //console.log(values);
      
            if (isAdmin) {
              if (isEditing) {
                editPosting(values);
              } else {
                confirmPosting(values);
              }
            } else {
              submitPosting(values);
            }
          }}
        >
        {({ isSubmitting, values, setFieldValue, setValues }) => {

          /**
          * If the form validation doesn't pass, scroll user back to the top (errors automatically display when submitting the form too).
          * 
          * @param {Object} formData - data in the form 
          */
          function goBackToTopIfStillErrors(formData) {
            // Validate the formData against the schema to check for errors
            eventsFormValidationSchema.validate(formData)
              .catch(error => {
                // if the end time is required but optionalTime is checked, we don't care
                console.error(error);
                // Scroll to the top if errors exist
                setTouched(true);
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth', // Optional: for smooth scrolling behavior
                });
              });
          }

          // Load cached values from localStorage
          useMemo(() => {
            if (!isEditing) {
              const cachedValues = JSON.parse(localStorage.getItem(auth.currentUser.uid + "eventForm"));
              if (!isEditing) {
                if (cachedValues) {
                  {/* console.log(cachedValues); */}
                  setValues(cachedValues);
                }
              }
            }
          }, []);

          // Save form values to localStorage upon dismount
          useEffect(() => {
            return () => {
              if (!isEditing) {
                delete values.thumbnail;
                localStorage.setItem(auth.currentUser.uid + "eventForm", JSON.stringify(values));
              }
            };
          }, [values]);

          function tConvert(time) {
            // Check correct time format and split into components
            time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

            if (time.length > 1) { // If time format correct
              time = time.slice(1); // Remove full string match value
              time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
              time[0] = +time[0] % 12 || 12; // Adjust hours
            }
            return time.join(''); // return adjusted time or original string
          }

          return <Form>
                <h1 className="font-semibold text-lg mb-2">Event form</h1>
                
                <div className="mb-2">
                    <GenerateFormInput id={1} displayName="Event link" inputName="link" jobPostingValidationSchema={eventsFormValidationSchema} />
                </div>

                <div className="flex md:flex-row flex-col mb-2 gap-4">
                    <div className="flex-1">
                        <GenerateFormInput id={1} displayName="Event title" inputName="title" jobPostingValidationSchema={eventsFormValidationSchema} />
                    </div>
                    <div className="flex-1">
                        <GenerateSelectInput id={1} displayName="Event category" inputName="category" options={[
                            <option key="default" disabled value="">
                                Please select a category
                            </option>,
                            ...
                            eventCategories.map((category, index) => <option key={index} selected={category === values["category"] ? true : undefined}
                            value={category}>{category}</option> )
                        ]} jobPostingValidationSchema={eventsFormValidationSchema}/>
                    </div>
                    <div className="flex-1">
                      <GenerateFormInput id={1} displayName="Address" inputName="address" jobPostingValidationSchema={eventsFormValidationSchema} />
                    </div>
                </div>

                <div className="flex md:flex-row flex-col mb-2 gap-4">
                    <div className="flex-1">
                        <GenerateFormInput id={1} displayName="City" inputName="city" jobPostingValidationSchema={eventsFormValidationSchema}/>
                    </div>
                    <div className="flex-1">
                        <GenerateSelectInput id={1} displayName="Country" inputName="country" options={
                            countryList.map((country, index) => <option key={index} selected={country === values["country"] ? true : undefined}
                            value={country}>{country}</option> )
                        } jobPostingValidationSchema={eventsFormValidationSchema}/>
                    </div>
                    <div className="flex-1">
                        <GenerateSelectInput id={1} displayName="Event type" inputName="type" options={
                            eventTypes.map((type, index) => <option key={index} selected={type === values["type"] ? true : undefined}
                            value={type}>{type}</option> )
                        } jobPostingValidationSchema={eventsFormValidationSchema}/>
                    </div>
                </div>

           
                <h2 className="mb-2">Is this a single or multiple day event?</h2>
                <div className="flex flex-row w-72" role="group" aria-labelledby="radio-group-label">
                  <div className="flex-1 flex gap-x-2">
                    <Field type="radio" name="dayType" value="single" id="single" className="self-center" selected={values.dayType === "single" ? true : undefined}/>
                    <label htmlFor="single">Single Day</label>
                  </div>
                  <div className="flex-1 flex gap-x-2">
                    <Field type="radio" name="dayType" value="multi" id="multi" className="self-center" selected={values.dayType === "multi" ? true : undefined} />
                    <label htmlFor="multi">Multi Day</label>
                  </div>
                </div>
            
                {
                  values.dayType === "single" ?
                  <div className="mt-2 flex md:flex-row flex-col mb-2 gap-4">
                      <div className="flex-1">
                          <GenerateFormInput id={1} displayName="Date" inputName="startDate" type="date" jobPostingValidationSchema={eventsFormValidationSchema} />
                      </div>
                        <div className="flex-1">
                          <GenerateSelectInput id={1} displayName="Start Time" inputName="startTime" options={
                              times.map((time, index) => {
                                const timeConvert = tConvert(time);
                                return <option key={index} selected={time === values["startTime"] ? true : undefined}
                              value={time}>{timeConvert}</option>})
                          } jobPostingValidationSchema={eventsFormValidationSchema}/>
                        </div>
                        <div className="flex-1">
                        <GenerateSelectInput id={1} displayName="End Time" inputName="endTime" options={
                            times.map((time, index) => {
                              if (values.startTime > time) {
                                return;
                              }
                              const timeConvert = tConvert(time);
                            return <option key={index} selected={time === values["endTime"] ? true : undefined}
                            value={time}>{timeConvert}</option>})
                        } jobPostingValidationSchema={eventsFormValidationSchema}/>
                      </div>
                  </div>
                : 
                <>
                  <div className="mt-2 flex md:flex-row flex-col mb-2 gap-4">
                      <div className="flex-1">
                          <GenerateFormInput id={1} displayName="Start Date" inputName="startDate" type="date" jobPostingValidationSchema={eventsFormValidationSchema} />
                      </div>
                      <div className="flex-1">
                          <GenerateFormInput id={1} displayName="End Date" inputName="endDate" type="date" jobPostingValidationSchema={eventsFormValidationSchema} />
                      </div>
                  </div>

                  {/* <div className="mt-2 flex md:flex-row flex-col mb-2 gap-4">
                      <h2 className="mb-2">Is the timing of this event required?</h2>
                      <div className="flex-1">
                        <Field type="checkbox" name="optionalTime" id="optionalTime" className="self-center" />
                      </div>
                  </div> */}
                  {
                    /* values.optionalTime && */
                    
                    <div className="mt-2 flex md:flex-row flex-col mb-2 gap-4">
                    <div className="flex-1">
                          <GenerateSelectInput id={1} displayName="Start Time" inputName="startTime" options={
                              times.map((time, index) => {
                                const timeConvert = tConvert(time);
                                return <option key={index} selected={time === values["startTime"] ? true : undefined}
                              value={time}>{timeConvert}</option>})
                          } jobPostingValidationSchema={eventsFormValidationSchema}/>
                        </div>
                        <div className="flex-1">
                        <GenerateSelectInput id={1} displayName="End Time" inputName="endTime" options={
                            times.map((time, index) => {
                              if (values.startTime > time) {
                                return;
                              }
                              const timeConvert = tConvert(time);
                            return <option key={index} selected={time === values["endTime"] ? true : undefined}
                            value={time}>{timeConvert}</option>})
                        } jobPostingValidationSchema={eventsFormValidationSchema}/>
                      </div>
                  </div>
                  }
                </>
                }
                <div className="flex-1">
                    <GenerateSelectInput id={1} displayName="Time Zone" inputName="timeZone"
                    options={[
                      <option key="default" disabled value="">
                          Please select a time zone
                      </option>,
                      ...
                      timeZones.map((timeZone, index) => 
                        <option key={index} selected={timeZone === values["timeZone"] ? true : undefined}
                        value={timeZone}>{timeZone}</option> )
                    ]} jobPostingValidationSchema={eventsFormValidationSchema}/>
                </div>

                <div className="mb-8"></div>
                <GenerateRichText id={14} displayName={"Internal notes* only admins can see this"} inputName={"internalNotes"} formValues={values} setFieldValue={setFieldValue} touched={touched} setTouched={setTouched} jobPostingValidationSchema={eventsFormValidationSchema} />

                <div className="sm:mb-16 mb-24"></div>
                <ImageUploader id={15} displayName="Upload a thumbnail" inputName="thumbnail" formValues={values} setFieldValue={setFieldValue} jobPostingValidationSchema={eventsFormValidationSchema}/>
                
                <br/>

                <div className="flex flex-row justify-end gap-4 my-4">
                    <button
                        onClick={cancel}
                        type="button"
                        className="py-1 px-2 border border-blue-500 rounded-md text-blue-500 hover:bg-gray-300"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        onClick={(e) => {
                          goBackToTopIfStillErrors(values);
                        }}
                        className="py-1 px-8 rounded-md bg-blue-500 text-white hover:bg-blue-700"
                    >
                        {!isAdmin ? "Publish" : !isEditing ? "Confirm event" : "Confirm changes"}
                    </button>
                </div>
            </Form>
        }}
      </Formik>
    </div>
  );
};

export default EventForm;
