import React, { createContext, useContext, useState, useEffect } from 'react'
import { useAuth } from './AuthContext';
import { getUser, listenForUserChanges } from '../functions/firebase/firestoreUsers';

const UserContext = createContext();
export const useUser = () => useContext(UserContext);

/**
 * Allows the user data to be shared among all sub components.
 * 
 * @param {children} - sub components that can use the user provider
 * @returns the user context with the provider attached
 */
export default function UserProvider({children}) {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const auth = useAuth();

    useEffect(() => {
        const getUserData = async (userId) => {
          try {
            // Assuming you have a function called `getUser` that fetches user data based on the ID
            const userData = await getUser(userId);
            setUser(userData);
            setLoading(false);
          } catch (error) {
            console.error(error);
            setLoading(false);
          }

          listenForUserChanges(userId, (userData) => {
            console.log('userChange');
            setUser(userData);
          }); 
        };
    
        if (auth.currentUser) {
          getUserData(auth.currentUser.uid);
        } else {
          setUser(null);
          setLoading(false);
        }
      }, [auth.currentUser]);

    return (
        <UserContext.Provider value={user}>
            {!loading && children}
        </UserContext.Provider>
    )
}
