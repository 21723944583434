import React, { useEffect, useRef, useState } from 'react';
import { useUser } from '../../../context/UserContext';
import { useAuth } from '../../../context/AuthContext';
import { updatePostComment, updatePostCommentLikes } from '../../../functions/firebase/firestorePosts';
import CommentPoster from '../../../components/common/CommentPoster';
import { ToastProvider, useToast } from '../../../context/ToastContext';
import LoginModal from '../../../components/util/LoginModal';
import UserModal from '../../../components/common/UserModal';
import { getUser } from '../../../functions/firebase/firestoreUsers';
import { MentionsParser } from '../../../components/common/MentionsParser';
 

export default function CommentItem({post, comment, parent = null, setComments, addCommentReplyOnClient, highLevelCommentId = null}) {
 
  const auth = useAuth();
  const {showErrorToast, showSuccessToast} = useToast();
  const [currentMentionClicked, setCurrentMentionClicked] = useState(null);

  const [showReplyInput, setShowReplyInput] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);

  function handleOpenModal() {
    setModalVisible(true);
  }

  function closeModal() {
    setCurrentMentionClicked(null);
    setModalVisible(false);
  }

  function openLoginModal() {
    setShowLoginModal(true);
  }

  function handleOpenProfile(uid) {
    getUser(uid).then(currentUser => {
      setCurrentMentionClicked(currentUser);
    });
  }

  const toggleLike = async () => {
    if (!auth || !auth.currentUser?.emailVerified) {
      openLoginModal();
      return;
    }

    // update comment on front end
    let updatedLikes = comment.likes ? [...comment.likes] : [];
    if (updatedLikes.includes(auth.currentUser.uid)) {
      updatedLikes = updatedLikes.filter((like) => like !== auth.currentUser.uid);
    } else {
      updatedLikes.push(auth.currentUser.uid);
    }
    comment.likes = updatedLikes;

    // update comment on backend
    try {
      await updatePostCommentLikes(post.postId, comment);
    } catch (error) {
      showErrorToast();
      console.log(error);
    }
  };

  return (
    <div className="flex items-start gap-x-2 mb-2">
      <img
        onClick={handleOpenModal}
        src={
          comment.user?.profilePictureURL
            ? comment.user?.profilePictureURL
            : "/assets/images/community/placeholder-user.svg"
        }
        alt={
          comment.user?.firstName?.charAt(0)?.toUpperCase() +
          comment.user?.firstName?.slice(1) +
          " " +
          comment.user?.lastName?.charAt(0)?.toUpperCase() +
          comment.user?.lastName?.slice(1)
        }
        className="w-8 h-8 rounded-full mt-1 cursor-pointer border border-transparent duration-300 ease-in-out hover:border-blue-500"
      />

      <div className="w-full">
        <div className="bg-[#EBEBEB] w-full py-2 px-4 rounded-md">
          <div className="flex gap-2">
            <p
              onClick={handleOpenModal}
              className="font-bold text-sm font-dmsans cursor-pointer transition duration-300 ease-in-out hover:text-blue-500"
            >
              {comment.user?.firstName?.charAt(0)?.toUpperCase() +
                comment.user?.firstName?.slice(1) +
                " " +
                comment.user?.lastName?.charAt(0)?.toUpperCase() +
                comment.user?.lastName?.slice(1)}
            </p>
            <span className="font-normal text-gray-400 text-sm">
              {comment?.date?.toLocaleString()}
            </span>
          </div>
          <p className="text-[#999999] text-xs">@{comment.user?.occupation}</p>

          <p>
            <MentionsParser
              content={comment.content}
              handleClick={handleOpenProfile}
            />
          </p>

          <div className="my-2">
            {comment.imageURL && (
              <img className="w-full h-auto" src={comment.imageURL} />
            )}
          </div>
        </div>

        {auth && (
          <div className="font-dmsans text-xs flex space-x-2 mt-1">
            <button
              className={
                comment.likes?.includes(auth?.currentUser?.uid)
                  ? "text-primaryBlue font-medium"
                  : ""
              }
              onClick={toggleLike}
            >
              {comment.likes?.length || ""}
              {comment.likes?.length !== 0
                ? comment.likes?.length === 1
                  ? " Like"
                  : " Likes"
                : "Like"}
            </button>
            <img src="/assets/images/icons/line.svg" alt="" />
            <button
              data-testid={`comment-item-reply-button`}
              onClick={() => setShowReplyInput(!showReplyInput)}
            >
              Reply
            </button>
          </div>
        )}
        {showReplyInput && (
          <CommentPoster
            highLevelCommentId={highLevelCommentId}
            post={post}
            commentToReplyTo={comment}
            setComments={setComments}
            addCommentReplyOnClient={addCommentReplyOnClient}
          />
        )}
      </div>

      <LoginModal modalIsOpen={showLoginModal} setIsOpen={setShowLoginModal} />
      {currentMentionClicked !== null && (
        <UserModal user={currentMentionClicked} handleClose={closeModal} />
      )}
      {modalVisible && (
        <UserModal user={comment.user} handleClose={closeModal} />
      )}
    </div>
  );
}