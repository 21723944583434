import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { getUser } from '../../functions/firebase/firestoreUsers';
import { deleteResource, toggleResourceLike, toggleResourceSave } from '../../functions/firebase/firestoreResources';
import { useAdminToggle } from '../../context/AdminContext';
import { Tooltip } from 'react-tooltip';
import ResourceForm from '../../pages/resources/includes/ResourceForm';
import Swal from 'sweetalert2';
import ShareItem from '../util/ShareItem';
import LoginModal from '../util/LoginModal';


function ResourceCard({ resource, isAdmin }) {
  const auth = useAuth();
  const [resourceUser, setResourceUser] = useState(null);
  const [isSaved, setIsSaved] = useState(resource.saved?.includes(auth?.currentUser?.uid));

  const [showResourceDropDown, setShowResourceDropDown] = useState(false);
  const [liked, setLiked] = useState(false);

  const [showShareModal, setShowShareModal] = useState(false);
  const likeButtonRef = useRef(null);

  const [showLoginModal, setShowLoginModal] = useState(false);

  /**
   * Initial component mount used to fetch the user info attached to the resource
   */
  useEffect(() => {
    async function get() {
      try {
        const resourceUser = await getUser(resource.userId);
        setResourceUser(resourceUser); 
      } catch (error) {
        console.error(error);
      }
    }
    get();

  }, []);

  function shareResource() {
    setShowShareModal(true);
  }

  function handleDelete() {
    Swal.fire({
      title: "Are you sure you want to delete?",
      text: "This cannot be undone and the resource will be removed forever!",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "Delete"
    }).then(async result => {
        if (result.isConfirmed) {
            Swal.fire({
              title: 'Removing resource posting',
              text: 'Please wait...',
              allowOutsideClick: false,
              didOpen: () => {
              Swal.showLoading();
            }
          });
            try {
                Swal.isLoading(true);
                await deleteResource(resource.id);
                Swal.fire({
                    title: "Successfully deleted resource!",
                    text: "The resource was successfully deleted",
                    icon: "success",
                });
            } catch (error) {
                console.error(error);
            } finally {
                Swal.isLoading(false);
            }
        }
    });
  }

  function handleEdit() {
    setShowResourceDropDown(prev => !prev);
  }

  const likeLength = resource.likes?.length ? resource.likes.length : 0;

    /**
   * Function to toggle the resource like by adding it to the likes array or removing it.
   * First we update the state on the database, then the client side state is updated.
   */
    async function handleLikeResource() {
      if (!auth?.currentUser) {
        openLoginModal();
        return;
      } else if (!auth?.currentUser.emailVerified) {
        showErrorToast("Please Verify Your Email");
        return;
      }
      try {
        const updatedResource = resource;
        const alreadyLiked = updatedResource.likes.includes(auth.currentUser.uid);
        // update visuals on front end after db change
        if (alreadyLiked) {
          resource.likes.pop(auth.currentUser.uid);
        } else {
          resource.likes.push(auth.currentUser.uid);
        }

        likeButtonRef.current.disabled = true;
        await toggleResourceLike(resource, auth.currentUser.uid);
        likeButtonRef.current.disabled = false;

      } catch (error) {
        showErrorToast("Problem Updating Likes");
        console.error(error);
      }
  }

  function openLoginModal() {
    setShowLoginModal(true);
  }

  return (
    <div className="flex flex-col bg-white border lg:p-3 p-2 rounded">
      <div className=" flex sm:flex-row flex-col lg:gap-6 gap-4">
        <a href={resource.link ? resource.link : ""} target='_blank' className="cursor-pointer rounded overflow-hidden w-full h-full sm:max-w-[220px]">
          <img
                src={resource.imageURL ? resource.imageURL : "/assets/images/community/placeholder-event.png"}
                alt=""
                className="w-full max-w-[180px] mx-auto h-auto object-cover rounded"
              />
        </a>
        <div className="flex-grow pl-4 flex-row">
          <div className="flex items-center justify-between gap-x-2">
            <div className="flex gap-x-1 text-sm mb-1">
              <img className="bg-gray-200 w-8 h-8 rounded-full px-[3px]" src={resourceUser?.profilePictureURL ? resourceUser.profilePictureURL : "/assets/images/icons/user.svg"} alt="Profile picture" />
              {resourceUser?.firstName?.charAt(0)?.toUpperCase() + resourceUser?.firstName?.slice(1) + " " + resourceUser?.lastName?.charAt(0)?.toUpperCase() + resourceUser?.lastName?.slice(1)}
              {' '}
              <span className="text-[#959595]">
                • {' '}
                {resource.date}
              </span>
            </div>
          </div>
          <h3 className="text-xl font-bold mb-2">{resource.title}</h3>
          <p className="text-xs mb-2">{resource.description}</p>
          <div className="flex justify-between">
            <div className="flex w-full gap-x-1 text-sm text-gray-500">
              <img src="/assets/images/icons/schedule-black.svg" alt="" />
              {' '}
              <p className="grid place-items-center">{resource.duration + " "}{resource?.type === "video" && "video" || resource?.type === "article" && "read" || resource?.type === "course" && "course" || ""}</p>
            </div>
            <div className="hidden sm:flex items-center gap-x-2">
              <button onClick={async () => {
                if (!auth || !auth?.currentUser?.emailVerified) {
                  openLoginModal();
                  return;
                }
              
                // update on the client
                setIsSaved((prev) => !prev)

                // update on the backend
                await toggleResourceSave(resource, auth.currentUser.uid);
                
              }} className="">
              </button>
            </div>
            <div className={`w-full flex ${!isAdmin ? "flex-row gap-4 h-full" : "flex-row gap-1 mr-2 mt-2"} justify-between`}>
              {
                !isAdmin ?
                  <div className="flex flex-row gap-2 w-full justify-end  font-semibold text-xs">
                    <button ref={likeButtonRef} onClick={handleLikeResource} className="flex items-center space-x-1">
                    <span>
                      <img src={`${!resource.likes?.includes(auth?.currentUser?.uid) ? "/assets/images/icons/like.svg" : "/assets/images/icons/like-blue.svg"}`} alt="" />
                    </span>
                    <span>
                      {likeLength > 0 ? likeLength + " " : 
                      ""}{likeLength < 2 ? "Like" : "Likes"}
                    </span>

                    </button>
                    <button onClick={shareResource} className="text-sm flex justify-center items-center hover:text- transition-all duration-300 ease-in-out" data-tooltip-id="share-tooltip" data-tooltip-content="Share">
                        <img data-tooltip-id="save-tooltip" data-tooltip-content="Share" src="/assets/images/icons/share.svg" alt="Share" />
                    </button>
                    <button onClick={async () => {
                      if (!auth || !auth?.currentUser?.emailVerified) {
                        openLoginModal();
                        return;
                      }

                      // update client saved
                      setIsSaved(prev => !prev);

                      // update backend saved
                      await toggleResourceSave(resource, auth.currentUser.uid);  

                    }} className="flex justify-end">
                    <Tooltip id="save-tooltip"/>
                    <img data-tooltip-id="save-tooltip" data-tooltip-content="Save"
                      src={`/assets/images/icons/${
                        isSaved ? 'save-active' : 'save'
                      }.svg`}
                      alt=""
                    />
                  </button>
                  <Tooltip id="share-tooltip"/>
                </div>
                : 
                <div className="flex flex-row gap-2 w-full justify-end">
                  <img onClick={handleEdit} className="cursor-pointer" src="/assets/images/icons/edit.svg"/>
                  <img onClick={handleDelete} className="cursor-pointer" src="/assets/images/icons/delete.svg"/>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      {
        showResourceDropDown && <ResourceForm isAdmin={isAdmin} cancel={() => setShowResourceDropDown(false)} resource={resource} isEditing={true} />
      }
      {
        showShareModal && <ShareItem id={resource.id} setIsOpen={setShowShareModal} modalIsOpen={showShareModal} />
      }
      {
        showLoginModal && <LoginModal modalIsOpen={showLoginModal} setIsOpen={setShowLoginModal} />
      }
    </div>
  );
}

export default ResourceCard;
