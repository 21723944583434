import React, { useEffect, useState } from 'react';
import ResourceCard from '../../components/common/ResourceCard';
import { Helmet } from 'react-helmet';
import { getResource, getResourceCount, getResources } from '../../functions/firebase/firestoreResources';
import CardPosting from "../../components/CardPosting";
import Announcements from '../../components/common/Announcements';
import PostingRequest from '../jobs/PostingRequest';
import { realTimeResources } from '../../functions/realtime/realtimeService';
import { useLocation, useParams } from 'react-router-dom';
import ServerSearch from '../jobs/ServerSearch';


function Resources({isAdmin}) {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  // put together search where filter
  let search = null;
  const searchValue = searchParams.get("search"); 
  if (searchValue) {
    // Preprocess search field value into an array of characters
    search = {
      fieldPath: "title",
      op: "array-contains-any",
      fieldValue: searchValue,
    };
  }

  // put together resources per page count filter and have an upper limit of 50
  let resourcesPerPage = searchParams.get("count") || 10;

  // put together order by date filter
  let sortBy = searchParams.get("sortBy") || "updatedAt";
  let order = searchParams.get("order") || "desc";

  // put together selected category
  let selectedCategory = searchParams.get("category") || (isAdmin ? "Pending Requests" : "All");

  const [resources, setResources] = useState([]);
  const [startAfterDoc, setStartAfterDoc] = useState(null);
  const [moreResources, setMoreResources] = useState(true);
  const [resourceCount, setResourceCount] = useState(0);
  const [requestCount, setRequestCount] = useState(0);

  const { id } = useParams();

  let categories = isAdmin ? 
  [
    "Published Resources",
    "Pending Requests",
  ] 
  : 
  [
    "All",
    'Course',
    'Video',
    'Article',
  ];
  

  /**
   * Initial component mount to fetch the resources from the database
   */
  useEffect(() => {
    async function get() {
      try {
        if (id) {
          const sharedResource = await getResource(id);
          setResources([sharedResource]);
        } else {
          let incomingResourceData = await getResources(startAfterDoc, search, sortBy, order, selectedCategory, resourcesPerPage > 50 ? 50 : resourcesPerPage);

          console.log(incomingResourceData.resources);

          const count = await getResourceCount(startAfterDoc, search, sortBy, order, selectedCategory, resourcesPerPage > 50 ? 50 : resourcesPerPage);

          const currentRequestCount = await getResourceCount(null, null, null, null, "Pending Requests", resourcesPerPage > 50 ? 50 : resourcesPerPage);

          setResourceCount(count);
          setRequestCount(currentRequestCount);
  
          setResources(incomingResourceData.resources);
          setStartAfterDoc(incomingResourceData.lastDoc);
        }
      } catch (error) {
        console.error(error);
      }
    }
    get(); 

    // const unsubscribe = realTimeResources(setResources);
    
    // return () => {
    //   unsubscribe();
    // };
  }, []);

  async function fetchMoreResources() {
    if (moreResources) {
      const incomingResourceData = await getResources(startAfterDoc, search, sortBy, order, selectedCategory, resourcesPerPage > 50 ? 50 : resourcesPerPage);

      if (incomingResourceData.lastDoc === null || incomingJobData.lastDoc === lastDoc) {
        setMoreResources(false);
        return;
      }

      setLastDoc(incomingResourceData.lastDoc);
      setResources(prevResources => {
          return [...prevResources, ...incomingResourceData.resources];
      });
    }
  }


  function handleSearch(searchString) {
    const url = new URL(window.location.href);
    url.searchParams.set('search', searchString);
    
    // Reload the page with the new URL
    window.location.href = url.toString();
  }

  function handleResourcesPerPage(e) {
    const url = new URL(window.location.href);
    url.searchParams.set("count", e.target.value);

    // Reload the page with the new URL
    window.location.href = url.toString();
  }

  function handleSortBy(e) {
    const options = {
      type: e.target.value.split(' ')[0],
      order: e.target.value.split(' ')[1],
    };
    
    const url = new URL(window.location.href);
    url.searchParams.set("sortBy", options.type);
    url.searchParams.set("order", options.order);

    // Reload the page with the new URL
    window.location.href = url.toString();
  }

  function handleCategory(category) {
    const url = new URL(window.location.href);
    url.searchParams.set("category", category);

    // Reload the page with the new URL
    window.location.href = url.toString();
  }

  return (
    <div className="flex font-dmsans">

      <Helmet>
        <title>UX Was Here - Resources</title>
      </Helmet>

      <div className="flex-1 lg:pt-8 py-4 px-4 lg:px-14 md:px-6">
        <h2 className="text-2xl pb-4 font-bold">Develop your digital skills and advance your career</h2>
        <div className="flex flex-wrap lg:gap-x-10 gap-x-8">
          {categories.map((category) => (
            <>
              <button
                key={category}
                className={`px-2 text-lg ${
                  selectedCategory === category
                    ? 'border-b border-black text-black'
                    : 'text-[#959595]'
                }`}
                onClick={() => handleCategory(category)}
              >
                {category}

                {" "}
                {category === 'Pending Requests' && <span className="text-orange-700">{requestCount}</span>}
              </button>
            </>
          ))}
        </div>

        <div className="mt-4 mb-6 relative items-center lg:max-w-sm">
          <ServerSearch initialValue={searchValue} name="Search resources" handleSearch={handleSearch}/>
        </div>

        <div className="my-5 flex flex-wrap gap-y-4 gap-x-4 sm:items-center">
          <span>Display:</span>
            <select className="bg-white rounded-md border-gray-300" onChange={(e) => {
              console.log(e.target.value);
              handleResourcesPerPage(e);
            }}>
              <option value="10" selected={resourcesPerPage === "10"}>10</option>
              <option value="20" selected={resourcesPerPage === "20"}>20</option>
              <option value="50" selected={resourcesPerPage > 50 ? "50" : resourcesPerPage === "50"}>50</option>
            </select>

            <span className="">Sort by:</span>
            <select className="bg-white rounded-md border-gray-300" onChange={handleSortBy}>
              <option value="updatedAt desc" selected={sortBy === 'updatedAt' && order === 'desc'}>Added to UwH: New</option>
              <option value="updatedAt asc" selected={sortBy === 'updatedAt' && order === 'asc'}>Added to UwH: Old</option>
            </select>
          </div>

          <div className="">
          <p className="font-bold">
            {resourceCount}
            {' '}
            <span className="font-normal text-sm">Resources</span>
          </p>
        </div>

          <div className="mt-4 grid gap-y-4">
          {/* Looking to hire button + form attached */}
          {
            !isAdmin ? <CardPosting formType="resource" /> : null 
          }

          <div className="space-y-4">
            {
              resources?.map((resource, index) => (
                  selectedCategory === "Pending Requests" ? 
                  <PostingRequest isAdmin={isAdmin} key={index} job={resource} type="resource"/> : 
                  <ResourceCard isAdmin={isAdmin} key={index} resource={resource} /> 
              ))
            }
          </div>
          {
            moreResources ? <button className="bg-blue-500 hover:bg-blue-900 transition-colors duration-300 py-2 px-4 rounded-md text-white text-base w-52 place-self-center" onClick={fetchMoreResources}>Load more resources</button> : <div className="text-center">End of page</div>
          }
        </div>
      </div>
      <Announcements />
    </div>
  );
}

export default Resources;