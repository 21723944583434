import React, { createContext, useState, useEffect, useContext } from "react";
import { auth } from "../../firebaseConfig";

import {
  sendPasswordResetEmail,
  sendEmailVerification,
  updatePassword,
  updateEmail,
  EmailAuthProvider,
} from "firebase/auth";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
} from "firebase/auth";

const AuthContext = createContext();

// custom hook to use auth where ever in any component assuming this is wrapped in the outermost component
export const useAuth = () => useContext(AuthContext);

/**
 * Provider for authentication which will allow easy use of auth in any component.
 *
 * @param { sub components that will have access to the auth provider } children
 * @returns Auth provider
 */
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // set the current user when the auth state changes
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const signInWithEmailPassword = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      return true; // Successful sign-in
    } catch (error) {
      throw new Error("Error signing in with email/password: " + error.message);
    }
  };

  const signUpWithEmailPassword = async (email, password) => {
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      return true; // Successful sign-up
    } catch (error) {
      throw new Error("Error signing up with email/password: " + error.message);
    }
  };

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      return true; // Successful sign-in
    } catch (error) {
      throw new Error("Error signing in with Google: " + error.message);
    }
  };

  const signInWithFacebook = async () => {
    const provider = new FacebookAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      return true; // Successful sign-in
    } catch (error) {
      throw new Error("Error signing in with Facebook: " + error.message);
    }
  };

  const signInWithLinkedIn = async () => {
    // LinkedIn OAuth configuration
    const linkedInProvider = new OAuthProvider("linkedin.com");
    linkedInProvider.addScope("r_liteprofile"); // Specify scopes if needed

    try {
      // Sign in with LinkedIn using Firebase's signInWithPopup method
      await signInWithPopup(auth, linkedInProvider);
      return true; // Successful sign-in
    } catch (error) {
      throw new Error("Error signing in with LinkedIn: " + error.message);
    }
  };

  const signOut = async () => {
    try {
      await auth.signOut();
      return true; // Successful sign-out
    } catch (error) {
      throw new Error("Error signing out: " + error.message);
    }
  };

  /**
   * Function to send an email to reset the password.
   */
  const passwordReset = async (email) => {
    try {
      console.log(email);
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      throw new Error(
        "Error sending the password reset email: " + error.message
      );
    }
  };

  /**
   * Function to send a verification email
   */
  const emailVerification = async () => {
    try {
      await sendEmailVerification(auth.currentUser);
    } catch (error) {
      throw new Error("Error sending the verification email: " + error.message);
    }
  };

  /**
   * Function to update the user's password
   */
  const updateNewPassword = async (newPassword) => {
    try {
      await updatePassword(auth.currentUser, newPassword);
    } catch (error) {
      throw new Error("Error updating the password: " + error.message);
    }
  };

  /**
   * Function to update the user's email
   */
  const updateNewEmail = async (newEmail) => {
    try {
      const user = auth.currentUser;

      if (user) {
        // Update the email
        await updateEmail(auth.currentUser, newEmail);
        await sendEmailVerification(user);
      } else {
        throw new Error("User not signed in");
      }
    } catch (error) {
      throw new Error("Error updating the email: " + error.message);
    }
  };

  const value = {
    currentUser,
    signInWithEmailPassword,
    signUpWithEmailPassword,
    signInWithGoogle,
    signInWithFacebook,
    signInWithLinkedIn,
    signOut,
    passwordReset,
    emailVerification,
    updateNewPassword,
    updateNewEmail,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
