import { addDoc, arrayRemove, arrayUnion, collection, deleteDoc, doc, endAt, endBefore, getCountFromServer, getDoc, getDocs, limit, onSnapshot, or, orderBy, query, serverTimestamp, startAfter, startAt, updateDoc, where, writeBatch } from "firebase/firestore";
import { firestore, storage } from "../../../firebaseConfig";
import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage";

function createJobQueryWithoutLimit(startAfterDoc, search, sortBy, order, selectedCategory, selectedJobSites, selectedJobTypes, jobLocations) {
    const jobsRef = collection(firestore, 'jobs');

    // Initialize query
    let q = jobsRef;

    // Apply category filter if selectedCategory is provided
    if (selectedCategory && (selectedCategory === "All jobs") || (selectedCategory === "Published Jobs")) {
        q = query(q, where("inReview", "==", false));
    }
    if (selectedCategory && (selectedCategory !== "All jobs" && selectedCategory !== "Published Jobs")) {
        if (selectedCategory === "Pending Requests") {
            q = query(q, where("inReview", "==", true));
        } else {
            q = query(q, where("inReview", "==", false), where("category", "==", selectedCategory));
        }
    }
    console.log(selectedJobSites);

    // Apply job sites filter if not empty
    if (selectedJobSites.length != 0) {
        q = query(q, where("jobSite", "in", selectedJobSites));
    }

    // Apply job types filter
    if (selectedJobTypes.length != 0) {
        q = query(q, where("type", "in", selectedJobTypes));
    }

    // Apply job locations filter
    if (jobLocations.length != 0) {
        q = query(q, where("country", "in", jobLocations));
    }

    // Apply search filter if search is provided
    if (search) {
        // Use the same property for search filter and first sorting order
        q = query(
            q,
            where(search.fieldPath, ">=", search.fieldValue),
            where(search.fieldPath, '<=', search.fieldValue + '\uf8ff'),
            orderBy(search.fieldPath) // Matching the property used in the search filter
        );
    }

    // Apply sorting
    if (order != null && sortBy != null) {
        q = query(
            q,
            orderBy(sortBy, order)
        );
    }

    // Apply pagination
    if (startAfterDoc) {
        q = query(q, startAfter(startAfterDoc));
    }

    return q;
}

/**
 * 
 * 
 * @returns job JSON data as an array
 */
export const getJobs = async (startAfterDoc, pageSize, search, sortBy, order, selectedCategory, selectedJobSites, selectedJobTypes, jobLocations) => {
    try {
        let q = createJobQueryWithoutLimit(startAfterDoc, search, sortBy, order, selectedCategory, selectedJobSites, selectedJobTypes, jobLocations);

        q = query(q, limit(pageSize));

        // Execute the query
        const querySnapshot = await getDocs(q);

        // Collect the results
        const jobs = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Obtain the last document for pagination
        const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1] || null;

        return {
            lastDoc,
            jobs,
        };
    } catch (error) {
        console.error('Error fetching jobs:', error);
        throw error;
    }
};

// get total job count from the server
export const getJobCount = async (startAfterDoc, search, sortBy, order, selectedCategory, selectedJobSites, selectedJobTypes, jobLocations) => {
    try {
        let q = createJobQueryWithoutLimit(startAfterDoc, search, sortBy, order, selectedCategory, selectedJobSites, selectedJobTypes, jobLocations);
        const countSnapshot = await getCountFromServer(q);
        const count = countSnapshot.data().count;
        return count;
    } catch (error) {
        console.error(error);
        return -1;
    }
}

export const getSavedJobs = async (userId, lim, lastDoc) => {
    try {
        const jobsRef = collection(firestore, "jobs");
        let q = query(jobsRef, where("saved", "array-contains", userId), limit(lim));

        if (lastDoc !== null) {
            q = query(q, startAfter(lastDoc));
        }

        const savedJobsSnapshot = await getDocs(q);
        const savedJobs = [];
        savedJobsSnapshot.forEach(jobDoc => {
            savedJobs.push({
                id: jobDoc.id,
                ...jobDoc.data(),
            });
        });

        const last = savedJobsSnapshot[savedJobsSnapshot.length - 1];

        return {
            jobs: savedJobs,
            lastDoc: last,
        };
    } catch (error) {
        throw new Error("Could not get saved jobs: " + error);
    }
}

/**
 * Fetch the job with the corresponding id from the firestore database.
 * 
 * @param {String} jobId - id of the job to obtain 
 * @returns the job that corresponds to the id
 */
export const getJob = async (jobId) => {
    try {
        const jobDoc = doc(firestore, "jobs", jobId);
        const jobData = await getDoc(jobDoc);
        if (jobData.exists()) {
            return {
                id: jobData.id,
                ...jobData.data()
            }
        } else {
            throw new Error("Job with id: " + id + " does not exist!");
        }
    } catch (error) {
        throw new Error(error);
        console.error(error);
    }
}

/**
 * Adds a job to the firestore database.
 * 
 * @param {Object} job - information holding the job posting
 */
export const createJob = async (job) => {
    try {
        const {
            thumbnail, title, description, country,  
            deadline, link, type, city,
            inReview, email, userId, internalNotes, 
            minYearsExperience, maxYearsExperience, minSalary, maxSalary, 
            jobSite, category,
        } = job;
        let imageURL = "";
        if (job.thumbnail) {
            const storageReference = ref(storage, `images2/jobs/${Date.now()}`);
            await uploadBytes(storageReference, thumbnail);
            imageURL = await getDownloadURL(storageReference);
        }
        const jobInfo = {
            title: title,
            description: description,
            country: country,
            deadline: deadline, 
            link: link,
            type: type,
            city: city,
            jobSite: jobSite,
            inReview: inReview,
            email: email,
            userId: userId,
            internalNotes: internalNotes,
            minYearsExperience: minYearsExperience,
            maxYearsExperience: maxYearsExperience,
            minSalary: minSalary,
            maxSalary: maxSalary,
            category: category,
            imageURL: imageURL,
            saved: [],
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
        }
        //GTM share
        window.dataLayer.push({
            event: 'createJob',
            sharedItemDetails: {
                title: title
            }
        });
        await addDoc(collection(firestore, "jobs"), jobInfo);
    } catch (error) {
        throw new Error("Failed to add job: " + error);
    }
}

export async function deleteJob(jobId) {
    try {
        console.log(jobId);
        const jobDoc = doc(firestore, "jobs", jobId);
        const jobSnapshot = await getDoc(jobDoc);

        if (jobSnapshot.exists()) {
            const jobData = jobSnapshot.data();

            // Check if the job has an associated image
            if (jobData.imageURL) {
                const imageRef = ref(storage, jobData.imageURL);

                // Delete the image from storage
                try {
                    await deleteObject(imageRef);
                } catch (error) {
                    console.error(error.message);
                }
            }

            // Delete the job document from Firestore
            await deleteDoc(jobDoc);
        } else {
            throw new Error("Job document does not exist.");
        }
    } catch (error) {
        throw new Error("Failed to delete job: " + error);
    }
}

export async function updateJobPosting(job) {
    try {
        console.log(job.imageURL); // exists
        console.log(job.thumbnail); // undefined
        const jobDoc = doc(firestore, "jobs", job.jobId);

        let imageURL = job.imageURL; // Store the current imageURL

        // Delete previous image if it exists when uploading a new thumbnail
        if (imageURL && job.thumbnail === null) {
            // Get the reference from the imageURL
            const previousImageRef = ref(storage, imageURL);
        
            // Delete the previous image
            await deleteObject(previousImageRef);
        }

        if (job.thumbnail) {
            const storageReference = ref(storage, `images2/jobs/${Date.now()}`);
            await uploadBytes(storageReference, job.thumbnail);
            imageURL = await getDownloadURL(storageReference);
        }

        const updatedJob = {
            description: job.description,
            internalNotes: job.internalNotes,
            title: job.title,
            country: job.country,
            city: job.city,
            type: job.type,
            link: job.link,
            minYearsExperience: job.minYearsExperience,
            maxYearsExperience: job.maxYearsExperience,
            minSalary: job.minSalary,
            maxSalary: job.maxSalary,
            deadline: job.deadline,
            jobSite: job.jobSite,
            category: job.category,
            inReview: job.inReview,
        };

        if (job.thumbnail === null && imageURL) {
            updatedJob.imageURL = null;
        } else {
            updatedJob.imageURL = imageURL;
        }

        //GTM update
        window.dataLayer.push({
            event: 'updateJob',
            sharedItemDetails: {
                link: job.link
            }
        });

        await updateDoc(jobDoc, {
            ...updatedJob,
            updatedAt: serverTimestamp(),
        });
    } catch (error) {
        throw new Error("Failed to update job posting: " + error);
    }
}

/**
 * Toggle the saved field in the job with your id.
 * 
 * @param {JSON} job - job to be saved
 * @param {string} userId - the id to save the job under
 */
export const toggleJobSave = async (job, userId) => {
    try {
        if (job.jobId === null) {
            throw new Error("Cannot toggle saved for a job with no id!");
        }
        if (job.saved === null) {
            throw new Error("Cannot toggle saved for a job with no saved field!");
        }

        const jobRef = doc(firestore, "jobs", job.id);
        const jobDoc = await getDoc(jobRef);
        if (jobDoc.exists()) {
            // figure out to remove or add the userId to saved (toggle)
            let updateAction = null;
            if (jobDoc.data().saved?.includes(userId)) {
                updateAction = arrayRemove(userId);
            } else {
                updateAction = arrayUnion(userId);
            }

            // toggle 
            await updateDoc(jobRef, {
                saved: updateAction
            });
        } else {
            throw new Error("Failed to toggle event saved because the event doesn't exist!");
        }
    } catch (error) {
        throw new Error("Failed to toggle saved on event due to the following: " + error);
    }
}

export const listenForJobChanges = (onChange) => {
    const jobCollection = collection(firestore, "jobs");

    const unsubscribe = onSnapshot(jobCollection, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
            // Retrieve the changed post data
            const data = change.doc.data();
            const changeJob = {
                jobId: change.doc.id,
                ...data,
            };
            onChange(changeJob, change.type);
        });
    });

    return unsubscribe;
}

export const confirmJobPostings = async (userId) => {
    try {
        // Query Firestore to find all job postings with inReview field set to true for the given user ID
        const jobPostingsRef = collection(firestore, 'jobs');
        const q = query(jobPostingsRef, where('userId', '==', userId), where('inReview', '==', true));
        const querySnapshot = await getDocs(q);

        // Update all found job postings
        await Promise.all(querySnapshot.docs.map(async (doc) => {
            await updateDoc(doc.ref, { inReview: false });
        }));

        // Optionally, you can return something upon successful completion
        return { success: true };
    } catch (error) {
        // Handle errors
        console.error('Error confirming job postings:', error);
        throw error; // Rethrow the error to be caught by the caller
    }
};