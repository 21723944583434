export const chats = [
  // {
  //   id: 1,
  //   name: 'John Deere',
  //   message: 'Test message - John',
  //   time: '10h',
  // },
  // {
  //   id: 2,
  //   name: 'Clayton Hunter',
  //   message: "Hey how's it going?",
  //   time: '10h',
  // },
];

export const requests = [
  {
    id: 1,
    name: 'Clayton Hunter',
    message: "It's important :)",
    time: '10h',
  },
  {
    id: 2,
    name: 'Anonymous',
    message: 'Can we talk?',
    time: '10h',
  },
];

export const messages = {
  1: [
    { sender: 'John Deere', message: 'Hi there!', time: '3:27 PM' },
    { sender: "You", message: "Hey John! Good to hear from you.", time: "" },
    { sender: "John Deere", message: "Been a while. How have you been?", time: "" },
    { sender: "You", message: "Been great! Just been a bit busy with work.", time: "3:32 PM" },
    { sender: "John Deere", message: "Same here. Let's catch up soon.", time: "3:34 PM" },
    { sender: "You", message: "Absolutely! How about this weekend?", time: "" },
  ],
  2: [
    { sender: 'Clayton Hunter', message: "Hey how's it going?", time: '6:20 PM' },
    { sender: "You", message: "Hey Clayton! I'm good, thanks for asking. How about you?", time: "" },
    { sender: "Clayton Hunter", message: "Not bad, just wrapping up some assignments. College keeps me on my toes!", time: "6:24 PM" },
    { sender: "You", message: "I can imagine! Which subjects are you currently taking?", time: "" },
    { sender: "Clayton Hunter", message: "A mix of computer science and design. Really enjoying the design courses!", time: "" },
    { sender: "You", message: "That's great to hear! Always good to have a blend of technical and creative.", time: "" },
  ],
};
