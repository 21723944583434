import React from 'react';

export default function UpdatedFooter() {
    return (
        <footer className="footer bg-zinc-700 text-white py-8">
            <div className="flex flex-col md:flex-row place-content-center gap-x-52">
                
                <section className="footer-section pr-5">
                    <h4 className="text-4xl font-bold">UX WAS HERE</h4>
                    <p>We are creating a dedicated platform design<br />for UX Enthusiasts - By UX Enthusiasts</p>

                    <a href="/privacy" className="block text-blue-500 hover:underline">Privacy Policy</a>
                    <a href="/tos" className="block text-blue-500 hover:underline">Terms of Service</a>
                </section>

                <section className="footer-section pr-5">
                    <h4 className="text-4xl font-bold">Get Involved</h4>
                    <a href="/feedback" className="block text-blue-500 hover:underline">Volunteer With Us</a>
                    <a href="https://uwh.canny.io/admin/roadmap/my-first-roadmap" className="block text-blue-500 hover:underline">Roadmap</a>
                    <a href="/feedback" className="block text-blue-500 hover:underline">Feedback</a>
                    <a href="https://uwh.canny.io/feature-requests" className="block text-blue-500 hover:underline">Feature Request</a>
                </section>


                <section className="footer-section pr-5">
                    <h4 className="text-4xl font-bold">Contact</h4>
                    <a href="/feedback" className="block text-blue-500 hover:underline">Send Us An Email</a>
                </section>

                <section className="footer-section pr-5">
                    <h4 className="text-4xl font-bold">Follow Us</h4>
                    <a href="https://www.linkedin.com/company/ux-was-here/?viewAsMember=true" className="block text-blue-500 hover:underline">Linkedin</a>
                    <a href="https://twitter.com/ux_was_here" className="block text-blue-500 hover:underline">Twitter</a>
                    <a href="https://www.linkedin.com/company/ux-was-here/?viewAsMember=true" className="block text-blue-500 hover:underline">Instagram</a>
                </section>
            </div>
        </footer>

    );
  }