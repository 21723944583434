import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useAuth } from "../../../context/AuthContext";
import { RiPencilLine } from "react-icons/ri";
import { useToast } from "../../../context/ToastContext";
import { updatePasswordValidationSchema } from "../../../functions/validationSchemas";

/**
 * Form for editing the user's email in the settings page.
 * The field is enabled and the "Save" and "Cancel" buttons are
 * displayed when the user clicks the edit button.
 *
 * Since the user's email is sensitive information and can not be retrieved
 * from the database, the initial state of the email is set to "********".
 * Additionally, the user must enter their current password to edit their email.
 */
export default function PasswordForm({}) {
  const auth = useAuth();
  const { signInWithEmailPassword, updateNewPassword } = useAuth();
  const { showSuccessToast, showErrorToast } = useToast();
  const [isEditing, setIsEditing] = useState(false);
  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };
  const [currentPassword, setCurrentPassword] = useState("********");

  const handleSubmit = async (values) => {
    try {
      //check if the current password is same as new password
      if (values.currentPassword === values.newPassword) {
        showErrorToast("Error: New password is same as current password");
        return;
      }

      // check if the current password is correct
      try {
        await signInWithEmailPassword(auth.currentUser.email, currentPassword);
      } catch (error) {
        console.log("error: ", error);
        throw new Error("wrongCurrentPassword");
      }

      await updateNewPassword(values.newPassword);

      toggleEdit();
      showSuccessToast("Password updated successfully");
    } catch (error) {
      if (error.message === "wrongCurrentPassword") {
        showErrorToast("Current password is incorrect");
      }
      showErrorToast("Failed to update password");
      console.log(error);
    }
  };

  // Styles
  const errorStyle = "text-red-500 text-xs pt-1";
  const inputStyle =
    "mt-1 p-2 w-full border rounded border-[#939393] text-gray-500 focus:border-primaryBlue focus:ring-primaryBlue relative w-full disabled:px-0 disabled:bg-transparent disabled:text-slate-400 disabled:border-0 disabled:shadow-none";
  const labelStyle = "z-10 mb-1";
  const buttonStyle =
    "bg-blue-500 text-white rounded-lg p-2 w-32 mt-3 hover:bg-blue-700";
  const buttonOutlineStyle =
    "bg-white-500 text-blue-500 rounded-lg p-2 w-32 mt-3 hover:bg-blue-100 outline outline-1 ";
  const editButtonStyle = "rounded-lg p-2 mt-3 hover:bg-blue-100";
  const iconSize = "24";

  return (
    <div>
      <Formik
        initialValues={{
          currentPassword: "********",
          newPassword: "",
          confirmPassword: "",
        }}
        validationSchema={updatePasswordValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ resetForm }) => (
          <Form>
            <div className="relative mt-4 mb-6">
              <div className="flex items-end gap-2">
                <div className="relative w-full">
                  <label className={labelStyle}>Password</label>
                  <Field
                    type="password"
                    name="currentPassword"
                    className={inputStyle}
                    placeholder="Current Password"
                    disabled={!isEditing}
                    autoComplete="off"
                    value={currentPassword}
                    onChange={(e) => {
                      setCurrentPassword(e.target.value);
                    }}
                  />
                  <div className={errorStyle}>
                    {currentPassword === "" && isEditing ? "Required" : null}
                  </div>
                  {isEditing && (
                    <div>
                      <Field
                        type="password"
                        name="newPassword"
                        className={inputStyle}
                        placeholder={!isEditing ? "" : "New Password"}
                        disabled={!isEditing}
                        autoComplete="off"
                      />
                      <div className={errorStyle}>
                        <ErrorMessage name="newPassword" />
                      </div>
                      <Field
                        type="password"
                        name="confirmPassword"
                        className={inputStyle}
                        placeholder={!isEditing ? "" : "Confirm New Password"}
                        disabled={!isEditing}
                        autoComplete="off"
                      />
                      <div className={errorStyle}>
                        <ErrorMessage name="confirmPassword" />
                      </div>
                    </div>
                  )}
                </div>
                {/* Edit button */}
                {!isEditing && (
                  <button
                    type="button"
                    className={editButtonStyle}
                    onClick={toggleEdit}
                  >
                    {/* Edit icon */}
                    <RiPencilLine size={iconSize} />
                  </button>
                )}
              </div>
              {/* Expandable buttons to save or cancel changes. */}
              {isEditing && (
                <div className="flex gap-x-3 justify-end">
                  <button
                    type="button"
                    className={buttonOutlineStyle}
                    onClick={() => {
                      toggleEdit();
                      setCurrentPassword("********");
                      resetForm();
                    }}
                  >
                    Cancel
                  </button>
                  <button type="submit" className={buttonStyle}>
                    Save
                  </button>
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
