import React from "react";
import { Link } from 'react-router-dom';
import CommunityLayout from '../../components/CommunityLayout';

function PrivacyPolicy() {
  return (
    <CommunityLayout>
      <div className="font-dmsans flex-col flex items-center">
        <div className="flex flex-col justify-center items-center flex-grow text-sm md:text-base lg:text-lg">
          <div className="border-b border-gray-300 mx-auto w-full md:w-3/4 lg:w-2/3 space-y-10">
            <div className="border-b border-gray-300 w-auto">
              <b className="text-2xl">UX Was Here Privacy Policy</b>
            </div>

            <p className="space-y-4">
              <div>Effective Date: March 17th, 2024</div>

              <div>Welcome to UX Was Here, the platform dedicated to elevating the UX design community. Your privacy is critically important to us, and this policy is designed to inform you about the collection, use, and protection of your personal information.</div>

              <div><b>1. Information We Collect</b></div>

              <div>We gather various types of information from our users for several reasons:</div>

              <div className="collect_info_list">
                <li>Personal Information: This includes but is not limited to your name, email address, and any other details you provide when registering, subscribing to our newsletter, or participating in our forums and events.</li>
                <li>Usage Data: We collect non-identifiable information on how the platform is accessed and used. This includes your browser type, the pages of our platform that you visit, the time spent on those pages, and other diagnostic data.</li>
                <li>Cookies and Tracking Data: UX Was Here uses cookies and similar tracking technologies to track activity on our platform and hold certain information to personalize and enhance your experience.</li>
              </div>

              <div><b>2. How We Use Your Information</b></div>

              <div>The information we collect helps us deliver, improve, and protect our services. Specifically, we use data to:</div>

              <div className="info_usage_list">
                <li>Provide and maintain our service</li>
                <li>Notify you about changes to our service</li>
                <li>Allow you to participate in interactive features</li>
                <li>Provide customer support</li>
                <li>Gather analysis or valuable information so that we can improve our service</li>
                <li>Monitor the usage of our service</li>
                <li>Detect, prevent and address technical issues</li>
                <li>Communicate with you about news, promotions, and updates related to our platform</li>
              </div>

              <div><b>3. Sharing Your Personal Data</b></div>

              <div>We do not sell, trade, or rent users' personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users. For operational purposes, we may share data with trusted partners to perform statistical analysis, send you an email or arrange for deliveries.</div>

              <div><b>4. Security of Data</b></div>

              <div>The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</div>

              <div><b>5. Your Rights</b></div>

              <div>UX Was Here aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Information. Whenever made possible, you can update your Personal Information directly within your account settings section. If you are unable to change this information within your account, please contact us to make the required changes.</div>

              <div className="rights_list">
                <li><b>Access / Update:</b> You have the right to request access to and receive a copy of any of your Personal Information we hold.</li>
                <li><b>Deletion:</b> You have the right to request deletion of your Personal Information, except where holding such information is necessary for compliance with a legal obligation or in connection with legal claims.</li>
              </div>

              <div><b>6. Data Retention</b></div>

              <div>We will retain your Personal Information only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies.</div>

              <div><b>7. Changes to This Privacy Policy</b></div>

              <div>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</div>

              <div><b>8. Children's Privacy</b></div>

              <div>Our Service does not address anyone under the age of 18 ("Children"). We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Children have provided us with Personal Information, please contact us. If we become aware that we have collected Personal Information from children without verification of parental consent, we take steps to remove that information from our servers.</div>

              <div><b>9. Contact Us</b></div>

              <div>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at <Link to="https://beta.uxwashere.com/feedback">https://beta.uxwashere.com/feedback</Link>.</div>

              <div>By using our service, you acknowledge that you have read and understand this Privacy Policy.</div>
            </p>
          </div>
        </div>
      </div>
    </CommunityLayout>
  );
}

export default PrivacyPolicy;