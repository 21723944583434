import React, { useState } from 'react'
import LoginModal from '../../../components/util/LoginModal';


export default function NotificationSignInPopup({close, isOpen}) {

  const [showLoginModal, setShowLoginModal] = useState(false);

  function openLoginModal() {
    setShowLoginModal(true);
  }

  return (
    <>
      {
        isOpen &&
        <div className="fixed top-16 right-3 my-2 rounded-md border bg-white z-50 shadow-lg flex">
          <div className="flex flex-col w-40 relative">
            <div className="p-4">
              <div id="displayName" className="text-sm font-medium">

                <img className="absolute right-2 top-2 cursor-pointer" onClick={() => close()} src="/assets/images/icons/cancel.svg" alt="Cancel"/>
                
                <span className="inline-block py-4 flex-wrap">
                  Please sign in to see notifications
                </span>

                <button onClick={openLoginModal} className="font-roboto bg-[#4079E2] hover:bg-primaryBlue text-white px-4 py-1 rounded-lg text-sm transition-all duration-300 ease-in-out">
                  Sign in
                </button>
              </div>
            </div>
          </div>
        </div>
      }
      <LoginModal setIsOpen={setShowLoginModal} modalIsOpen={showLoginModal} />
    </>
  )
}
