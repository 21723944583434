import React from 'react';
import { Helmet } from 'react-helmet-async';


export default function ShareMetaTags({title, description, imageURL, imageAlt}) {
    const doc = new DOMParser().parseFromString((decodeURIComponent(description)), 'text/html');
    const decodedDescription = doc.body.textContent || '';
    const RECOMMENDED_MAX_SHARE_LENGTH = 170;
    const descriptionToDisplay = decodedDescription.slice(0, RECOMMENDED_MAX_SHARE_LENGTH).toString();
    console.log("TEST", descriptionToDisplay);

  return (
    <Helmet>
        <title>{title}</title>
        <meta name='description' content={descriptionToDisplay} />
        <link rel="canonical" href={window.location.pathname} />

        <meta property="og:title" content={title} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={imageURL} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={descriptionToDisplay}/>
    </Helmet>
  );
}

