import Swal from "sweetalert2";
import Modal from "react-modal";
import {
  EmailShareButton,
  FacebookShareButton,
  GabShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  GabIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
  XIcon,
} from "react-share";
import { useEffect, useState } from "react";

export default function ShareItem({ id, modalIsOpen, setIsOpen }) {
  const [url, setUrl] = useState("");

  useEffect(() => {
    const url = generateShareURL(id);
    setUrl(url);
  }, []);

  const generateShareURL = (id) => {
    const currentPath = location.pathname;
    // Get the current route
    const basePath = "/" + currentPath.split("/")[1];
    // Construct the URL with the post ID
    return `${window.location.origin}${basePath}/${id}`;
  };

  const handleGTMShareButtonClick = (platform) => {
    window.dataLayer.push({
      event: platform + "Share", // Concatenates platform name with "share"
      platform: platform,
      sharedItemDetails: {
        url: url,
      },
    });
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "350px",
      height: "300px",
    },
  };

  // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
  Modal.setAppElement(document.getElementById("root"));

  let subtitle;

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#3c3c3c";
  }

  function closeModal() {
    setIsOpen(false);
  }

  function handleEditJob() {
    setShowPostingForm(!showPostingForm);
  }

  /**
   * Function to copy the URL to clipboard.
   */
  async function copyToClipboard() {
    try {
      //GTM share
      window.dataLayer.push({
        event: "shareMenuOpened",
        sharedItemDetails: {
          url: url,
        },
      });
      await navigator.clipboard.writeText(url);
      Swal.fire({
        title: "Copied!",
        icon: "success",
        text: "Post URL copied to clipboard.",
        timer: 1500,
        showConfirmButton: false,
      });
    } catch (error) {
      Swal.fire({
        title: "Error!",
        icon: "error",
        text: "Unable to copy to clipboard.",
      });
    }
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="mx-2 flex flex-col gap-4">
        <div className="flex justify-between items-center">
          <h2
            data-testid="share-post-popup"
            className="font-bold text-3xl"
            ref={(_subtitle) => (subtitle = _subtitle)}
          >
            Share Post
          </h2>
          <button
            className="text-xl"
            onClick={closeModal}
            data-testid="close-modal-button"
          >
            <img src="/assets/images/icons/cancel.svg" />
          </button>
        </div>

        <input className="" value={url} data-testid="url-input" readOnly />

        <button
          data-testid="copy-to-click-button"
          onClick={copyToClipboard}
          className="bg-blue-500 rounded text-white hover:bg-blue-700 flex items-center justify-center py-2 px-4 gap-2"
        >
          <img src="/assets/images/icons/clipboard.svg" alt="clip" />
          <span>Copy to clipboard</span>
        </button>

        <hr className="w-full border-b border-gray-300 my-2" />

        <div className="flex flex-row gap-2 ">
          <LinkedinShareButton
            url={url}
            data-testid="linkedin-share-button"
            onClick={() => handleGTMShareButtonClick("LinkedIn")}
          >
            <LinkedinIcon size={40} round />
          </LinkedinShareButton>
          <FacebookShareButton
            url={url}
            data-testid="facebook-share-button"
            onClick={() => handleGTMShareButtonClick("Facebook")}
          >
            <FacebookIcon size={40} round />
          </FacebookShareButton>
          <TwitterShareButton
            url={url}
            data-testid="twitter-share-button"
            onClick={() => handleGTMShareButtonClick("Twitter")}
          >
            <TwitterIcon size={40} round />
          </TwitterShareButton>
        </div>
      </div>
    </Modal>
  );
}
